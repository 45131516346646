import React, { useLayoutEffect, useRef, useState } from "react";
import ReactSelect, { components } from "react-select";
import { Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useEffect } from "react";

const SearchDropdown = ({
  isDisabled,
  isMulti,
  placeholdernotVisible,
  searchIcon,
  control,
  error,
  name,
  dataArray,
  inputRef,
  placeholder,
  label,
  handleInputChange,
  menuShouldBlockScroll,
  menuPlacement,
  isClearable,
  onChange,
  defaultValue,
  maxMenuHeight,
  referance,
  onKeyDown,
}) => {
  if (menuPlacement) {
  } else {
    menuPlacement = "auto";
  }
  if (menuShouldBlockScroll !== true) {
    menuShouldBlockScroll = false;
  }
  const ref = useRef(null);
  // const searchRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [placeHolderTop, setPlaceHolderTop] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const placeHolderPositionTop = useRef(null);

  useEffect(() => {
    //
    //
    placeHolderPositionTop.current = placeHolderTop;
  }, [placeHolderTop]);
  useEffect(() => {
    //
  }, [menuIsOpen]);

  useLayoutEffect(() => {
    if (isClearable == true) {
      let inPx = `${ref.current.offsetWidth - 96}px`;
      setWidth(inPx);
    } else {
      let inPx = `${ref.current.offsetWidth - 64}px`;
      setWidth(inPx);
    }
    // setWidth(ref.current.offsetWidth - 100);
  }, []);
  let isError = !!error?.message;
  let bgColor = "rgba(255, 255, 255, 1)";
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      position: "absolute",
      boxShadow: "0 20px 54px 0 rgba(0,0,0,0.2)",
      zIndex: 50,
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      minWidth: ref.current.offsetWidth,
      width: "fit-content",
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => ({
      ...provided,
      whiteSpace: "nowrap",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "rgba(222,235,255,1)"
        : isFocused
        ? "rgba(222,235,255,0.5)"
        : undefined,

      color: isDisabled
        ? undefined
        : isSelected
        ? "#000000"
        : isFocused
        ? "#000000"
        : undefined,
    }),
    control: (Colstyles, state) => ({
      ...Colstyles,
      borderRadius: "5px",
      minHeight: "36.3px",
      fontSize: "14px",
      maxHeight: "fit-content",
      display: "flex",
      flexWrap: isMulti ? "wrap" : "nowrap",
      border: isError
        ? state.isSelected
          ? "1px solid #DEEBFF"
          : state.isFocused
          ? "1px solid #DEEBFF"
          : state.hasValue || state.selectProps.inputValue
          ? "1px solid #d32f2f"
          : "1px solid #d32f2f"
        : state.hasValue || state.selectProps.inputValue
        ? ""
        : "",
      // whiteSpace: "nowrap",
    }),
    singleValue: (Singstyles) => ({
      ...Singstyles,
      paddingLeft: "4px",
      fontSize: "14px",
      maxWidth: width,
      textOverflow: "clipped",
    }),
    indicatorSeparator: (styles) => ({ display: "none", paddingX: "2px" }),
    container: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        width: width,
        // border:'2px solid black',
        overflow: "hidden",
        fontSize: "14px",
        paddingLeft: state.hasValue || state.selectProps.inputValue ? 2 : 7,
        whiteSpace: "nowrap",
        flexWrap: "wrap",
        fontStyle: "normal",
      };
    },
    input: (provided, state) => ({
      ...provided,
    }),
    placeholder: (provided, state) => {
      return {
        ...provided,

        display: placeholdernotVisible
          ? placeHolderTop
            ? "block"
            : "block"
          : "block",
        position: "absolute",
        borderRadius: "2px",
        color: placeHolderTop
          ? isDisabled
            ? "#eaeaea"
            : "#1976D2"
          : isError
          ? "#d32f2f"
          : "#9e9e9e",
        fontSize: "14px",

        // fontSize: placeHolderTop && 12,
        transition: "top 0.1s, font-size 0.1s",

        top: placeHolderTop ? -18 : 4,

        paddingLeft: placeHolderTop ? 4 : "",

        paddingRight: placeHolderTop ? 4 : "",

        backgroundColor: placeHolderTop
          ? isDisabled
            ? "#f0f0f0"
            : bgColor
          : "",

        zIndex: placeHolderTop ? 12 : "",

        fontStyle: "normal",
      };
    },
    // placeholder: (base, state) => ({
    //   ...base,
    //   position: "absolute",
    //   top:
    //     state.hasValue ||
    //     state.selectProps.inputValue ||
    //     state.selectProps.isFocused
    //       ? "-120%"
    //       : "0%",
    //   transition: "top 0.2s, font-size 0.2s",
    //   fontSize:
    //     (state.hasValue ||
    //       state.selectProps.inputValue ||
    //       state.selectProps.isFocused) &&
    //     14,
    // }),
  };
  const { IndicatorSeparator, Placeholder, ValueContainer } = components;

  const fnhandleInputChange = (inputValue, { action, prevInputValue }) => {
    handleInputChange(inputValue);
  };
  /////
  // const { ValueContainer, Placeholder } = components;

  // const CustomValueContainer = ({ children, ...props }) => {
  //   if (name == "maritalStatus") {
  //     //
  //     //   "working selectProps.inputValue",
  //     //   props.selectProps.inputValue
  //     // );
  //     //
  //   }
  //   return (
  //     <ValueContainer {...props}>
  //       <Placeholder {...props} isFocused={props.isFocused}>
  //         {props.selectProps.placeholder}
  //       </Placeholder>
  //       {React.Children.map(children, (child) =>
  //         child && child.type !== Placeholder ? child : null
  //       )}
  //     </ValueContainer>
  //   );
  // };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.key !== "placeholder" ? child : null
        )}
      </ValueContainer>
    );
  };

  return (
    <div className=" w-full" ref={ref}>
      <FormControl fullWidth>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <ReactSelect
              className="text-[14px] w-full"
              inputRef={inputRef}
              ref={referance}
              onKeyDown={onKeyDown}
              {...field}
              options={dataArray}
              defaultValue={defaultValue}
              isMulti={isMulti}
              maxMenuHeight={maxMenuHeight}
              label={label}
              isDisabled={isDisabled}
              placeholder={placeholder}
              openMenuOnClick={false}
              isClearable={isClearable}
              clearValue={true}
              styles={selectStyles}
              menuPlacement={menuPlacement}
              menuShouldBlockScroll={menuShouldBlockScroll}
              components={{
                DropdownIndicator: () =>
                  searchIcon ? (
                    <SearchIcon className="mr-1 text-slate-500" />
                  ) : (
                    <KeyboardArrowDown className="mr-1 text-gray-600" />
                  ),
                IndicatorSeparator: () => null,
                // Placeholder : CustomPlaceholder
                // ValueContainer: CustomValueContainer,
              }}
              //////////

              onInputChange={fnhandleInputChange}
            />
          )}
        />
        {/* <FormHelperText style={{ color: "#d32f2f" }} className='capitalize'>
            {error?.message}
          </FormHelperText> */}
      </FormControl>
    </div>
  );
};
export default SearchDropdown;
