import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";

import {
  fetchBirthDetails,
  fetchGenders,
  fetchPrefix,
} from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  successAlert,
  errorAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  generatePatientInfoChangeRequest,
  getPatientForDemographic,
} from "../../../services/pathology/demographicServices/DemographicServices";
import DemographicRequestsModal from "./DemographicRequestsModal";
import InputLimit from "../../../../common/components/FormFields/InputLimit";
import { isAfter } from "date-fns";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";

function Demographic() {
  const schema = yup.object().shape({
    prefix: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Please Select Prefix"),
        label: yup.string().required("Please Select Prefix"),
      }),
    firstName: yup
      .string()
      .required("Required")
      .min(1, "Add First Name")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      ),
    middleName: yup
      .string()
      .required("Required")
      .min(1, "Add First Name")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      ),
    lastName: yup
      .string()
      .required("Required")
      .min(1, "Add Last Name")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      ),

    gender: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Required"),

    date: yup
      .date()
      .typeError("Please Provide Date of Birth in Valid Format")
      .min("01/01/1902", "You are Not Eligible")
      .max(new Date(), "You can't be born in the future!")
      .required(),

    age: yup.number().max(121).required("Required"),
  });

  const {
    watch,
    control,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      searchByPatientName: null,
      patientType: "OPD",
      selectPrefix: false,
      selectFirstName: false,
      selectMiddleName: false,
      selectLastName: false,
      selectGender: false,
      selectBirth: false,
      prefix: null,
      firstName: "",
      middleName: "",
      lastName: "",
      gender: null,
      date: null,
      age: "",
      ageInYears: "",
      ageInMonths: "",
      ageInDays: "",
    },
  });

  let patientTypeValue = watch("patientType");
  let searchPatientValue = watch("searchByPatientName");
  let selectPrefixValue = watch("selectPrefix");
  let selectFirstNameValue = watch("selectFirstName");
  let selectMiddleNameValue = watch("selectMiddleName");
  let selectLastNameValue = watch("selectlastName");
  let selectGenderValue = watch("selectGender");
  let selectBirthValue = watch("selectBirth");
  const locationValue = useLocation();

  const [searchPatientOptions, setSearchPatientOptions] = useState([]);
  const [prefixOptions, setPrefixOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);

  const [finalObj, setFinalObj] = useState(null);

  const [openDemographicRequestsModal, setOpenDemographicRequestsModal] =
    useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openBackDrop, setOpenBackdrop] = useState(false);

  const [enteredAge, setEnteredAge] = React.useState("");

  const handleSearchByPatientName = (searchString) => {
    if (UseCheckConsecutiveCharacters(searchString) && searchString !== "") {
      getPatientForDemographic(searchString, patientTypeValue === "OPD" ? 0 : 1)
        .then((response) => response.data)
        .then((res) => {
          setSearchPatientOptions(res.result);
        })
        .catch((error) => error);
    }
  };

  const handleSubmitData = (dataObj) => {
    let tempObj = {
      opdIpd: patientTypeValue === "OPD" ? 0 : 1,
      firstName: dataObj.firstName,
      middleName: dataObj.middleName,
      lastName: dataObj.lastName,
      gender: dataObj.gender?.label,
      prefix: dataObj.prefix?.label,
      ageInYear: Number(dataObj.ageInYears),
      ageInMonth: Number(dataObj.ageInMonths),
      ageInDays: Number(dataObj.ageInDays),
      isFirstName: dataObj.selectFirstName,
      isMiddleName: dataObj.selectMiddleName,
      isLastName: dataObj.selectLastName,
      isGender: dataObj.selectGender,
      isPrefix: dataObj.selectPrefix,
      isAgeInYear: dataObj.selectBirth,
      isAgeInMonth: dataObj.selectBirth,
      isAgeInDays: dataObj.selectBirth,
      opdIpdId: searchPatientValue?.OpdIpdId,
      menuId: locationValue.state?.menuId,
      privilege: "",
    };
    setFinalObj(tempObj);
    setOpenConfirmationModal(true);
  };

  function handleSavePatientInfo() {
    setOpenBackdrop(true);
    generatePatientInfoChangeRequest(finalObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        setOpenConfirmationModal(false);
        reset();
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
        setOpenConfirmationModal(false);
      });
  }

  const getAgeDOB = (e) => {
    let dobValue = e;
    let dobGivenYear = dobValue.getFullYear();
    let dobGivenMonth = String(dobValue.getMonth() + 1).padStart(2, "0");
    let dobGivenDay = String(dobValue.getDate()).padStart(2, "0");
    let fullDOB = [dobGivenYear, dobGivenMonth, dobGivenDay].join("-");

    fetchBirthDetails(0, fullDOB)
      .then((response) => {
        setValue("age", response.data.result.years, { shouldValidate: true });
        setValue("ageInYears", response.data.result.years);
        setValue("ageInMonths", response.data.result.months);
        setValue("ageInDays", response.data.result.days);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    if (enteredAge !== "" && typeof enteredAge !== "undefined") {
      fetchBirthDetails(enteredAge, "NA")
        .then((response) => {
          setValue("date", response.data.result.dob, {
            shouldValidate: true,
          });
          setValue("ageInYears", enteredAge);
          setValue("ageInMonths", response.data.result.months);
          setValue("ageInDays", response.data.result.days);
        })
        .catch((error) => error);
    }
  }, [enteredAge]);

  useEffect(() => {
    fetchPrefix()
      .then((response) => response.data)
      .then((res) => {
        setPrefixOptions(res.result);
      })
      .catch((error) => error);

    fetchGenders()
      .then((response) => response.data)
      .then((res) => {
        setGenderOptions(res.result);
      })
      .catch((error) => error);
  }, []);

  useEffect(() => {
    if (searchPatientValue !== null) {
      setValue("prefix", {
        id: searchPatientValue?.PrefixId,
        value: searchPatientValue?.PrefixId,
        label: searchPatientValue?.Prefix,
      });
      setValue("firstName", searchPatientValue?.FirstName);
      setValue("middleName", searchPatientValue?.MiddleName);
      setValue("lastName", searchPatientValue?.LastName);
      setValue("gender", {
        id: searchPatientValue?.GenderId,
        value: searchPatientValue?.GenderId,
        label: searchPatientValue?.Gender,
      });
      setValue("date", searchPatientValue?.DateofBirth);
      setValue("age", searchPatientValue?.AgeYear);
      setValue("ageInYears", searchPatientValue?.AgeYear);
      setValue("ageInMonths", searchPatientValue?.AgeMonth);
      setValue("ageInDays", searchPatientValue?.AgeDay);
    }
  }, [searchPatientValue]);

  return (
    <div className="mt-16">
      <h1 className="text-center font-semibold text-customBlue text-xl">
        Demographic
      </h1>
      <div className="flex justify-between items-center mt-2">
        <div className="flex w-full space-x-2 items-center">
          <div className="flex space-x-2  ">
            <h2 className="pt-0.5 font-semibold">Patient Type &nbsp;: </h2>
            <RadioField
              control={control}
              name="patientType"
              dataArray={[
                { id: "OPD", value: "OPD", label: "OPD" },
                { id: "IPD", value: "IPD", label: "IPD" },
              ]}
            />
          </div>
          <div className="w-4/12">
            <SearchDropdown
              control={control}
              name="searchByPatientName"
              placeholder="Search By Patient Name/MR No./Mobile Number"
              isClearable={true}
              searchIcon={true}
              dataArray={searchPatientOptions}
              handleInputChange={handleSearchByPatientName}
            />
          </div>
        </div>
        <div>
          <CommonButton
            type="button"
            label="Request"
            className="border rounded border-customBlue text-customBlue"
            onClick={() => setOpenDemographicRequestsModal(true)}
          />
        </div>
      </div>

      {/* {searchPatientValue !== null ? ( */}
      <div className="flex justify-center">
        <div className="border rounded bg-white shadow mt-5 w-full">
          <h2 className="pl-3  font-semibold mt-2 text-lg">
            Patient Information
          </h2>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <div className="grid grid-cols-4  gap-3 px-5 py-5 ">
              <div className="w-full flex items-center">
                <CheckBoxField
                  control={control}
                  name="selectPrefix"
                  style={{ padding: "0px", marginLeft: "0px" }}
                />
                <DropdownField
                  control={control}
                  name="prefix"
                  placeholder="Prefix"
                  isDisabled={!selectPrefixValue}
                  dataArray={prefixOptions}
                  error={errors.prefix}
                />
              </div>
              <div className="flex items-center pl-3">
                <CheckBoxField
                  control={control}
                  name="selectFirstName"
                  style={{ padding: "0px", marginLeft: "0px" }}
                />
                <InputField
                  control={control}
                  name="firstName"
                  label="First Name"
                  disabled={!selectFirstNameValue}
                  error={errors.firstName}
                />
              </div>
              <div className="flex items-center pl-3">
                <CheckBoxField
                  control={control}
                  name="selectMiddleName"
                  style={{ padding: "0px", marginLeft: "0px" }}
                />

                <InputField
                  control={control}
                  name="middleName"
                  label="Middle Name"
                  disabled={!selectMiddleNameValue}
                  error={errors.middleName}
                />
              </div>
              <div className="flex items-center pl-3">
                <CheckBoxField
                  control={control}
                  name="selectLastName"
                  style={{ padding: "0px", marginLeft: "0px" }}
                />

                <InputField
                  control={control}
                  name="lastName"
                  label="Last Name"
                  disabled={!selectLastNameValue}
                  error={errors.lastName}
                />
              </div>
              <div className="flex items-center ">
                <CheckBoxField
                  control={control}
                  name="selectGender"
                  style={{ padding: "0px", marginLeft: "0px" }}
                />

                <DropdownField
                  control={control}
                  name="gender"
                  placeholder="Gender"
                  isDisabled={!selectGenderValue}
                  dataArray={genderOptions}
                  error={errors.gender}
                />
              </div>
              <div className="flex  items-center pl-3">
                <CheckBoxField
                  control={control}
                  style={{ padding: "0px", marginLeft: "0px" }}
                  name="selectBirth"
                />

                <DatePickerFieldNew
                  control={control}
                  error={errors.date}
                  name="date"
                  label="Date of Birth"
                  disabled={!selectBirthValue}
                  inputRef={{
                    ...register("date", {
                      onChange: (e) => {
                        isAfter(e?.target?.value, new Date(1900, 1, 1)) &&
                          getAgeDOB(e?.target?.value);
                      },
                    }),
                  }}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div className="flex space-x-2 items-center">
                <InputLimit
                  maxDigits={3}
                  name="age"
                  variant="outlined"
                  label="Age *"
                  error={errors.age}
                  control={control}
                  disabled={!selectBirthValue}
                  inputRef={{
                    ...register("age", {
                      onChange: (e) => {
                        setEnteredAge(e.target.value);
                      },
                    }),
                  }}
                  onKeyDown={(e) => {
                    if (
                      e?.key === "-" ||
                      e?.key === "+" ||
                      e?.code === "ArrowUp" ||
                      e?.code === "ArrowDown" ||
                      e?.code === "NumpadDecimal"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <InputField
                  name="ageInYears"
                  variant="outlined"
                  label="Years"
                  control={control}
                  disabled={true}
                />
              </div>
              <div className="flex space-x-2 items-center">
                <InputField
                  control={control}
                  name="ageInMonths"
                  label="Month"
                  disabled={true}
                />

                <InputField
                  control={control}
                  name="ageInDays"
                  label="Days"
                  disabled={true}
                />
              </div>
            </div>
            <div className="flex space-x-3 justify-end pr-5 pb-4">
              <CommonButton
                type="button"
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={() => {
                  reset();
                }}
              />
              <CommonButton
                type="submit"
                className="bg-customGreen text-white"
                label="Confirm"
              />
            </div>
          </form>
        </div>
      </div>
      {/* ) : (
        <p className="text-center text-gray-500 font-semibold my-40">
          Please Select Patient
          <span className="animate-pulse ">...</span>
        </p>
      )} */}
      <CommonBackDrop openBackdrop={openBackDrop} />

      {openDemographicRequestsModal && (
        <DemographicRequestsModal
          open={openDemographicRequestsModal}
          handleClose={() => setOpenDemographicRequestsModal(false)}
          locationValue={locationValue}
          setOpenBackdrop={setOpenBackdrop}
        />
      )}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={handleSavePatientInfo}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to update this record ?"
        confirmationButtonMsg="Update"
      />
    </div>
  );
}

export default Demographic;

