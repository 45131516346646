import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import {
  getIndentDetailsByIndentId,
  getIndentItemsForCounterSales,
} from "../PharmacyServices";
import CommonSelectableTable from "../common/CommonSelectableTable";
import CommonButton from "../../common/components/Buttons/CommonButton";

const GetIndentModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 4,
};

const loggedUser = JSON.parse(localStorage.getItem("userInfo"));
export default function IndentModal(props) {
  const [patientIndentItems, setPatientIndentItems] = React.useState("");
  const [indentId, setIndentId] = React.useState(null);
  const [storeId, setStoreId] = React.useState(null);
  const [indentDetails, setIndentDetails] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const { handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  let searchObject = {
    admissionId: props?.admissionId || null,
    fromDate: new Date(),
    fromStoreId: 0,
    indentNo: "",
    indentStatus: 2,
    isApproved: 2,
    isCancelled: 2,
    isForcefullyClosed: 2,
    isUrgent: 2,
    toDate: new Date(),
    toStoreId: null,
    page: 0,
    size: 10,
  };

  // useEffect(() => {
  //   console.log("selectedItems",selectedItems);

  //   props?.handlePatientInfo(
  //     selectedItems?.PatientId,
  //     selectedItems?.AdmissionId?.length > 0 ? 1 : 0
  //   );
  // }, [selectedItems]);

  React.useEffect(() => {
    if (
      // props?.admissionId > 0 &&
      props.open
    ) {
      getIndentItemsForCounterSales(searchObject)
        .then((response) => response.data)
        .then((res) => {
          setPatientIndentItems(res);
          console.log(res);
        });
    }
  }, [props.admissionId, props.open]);

  React.useEffect(() => {
    if (indentId !== null && storeId !== null) {
      getIndentDetailsByIndentId(storeId, indentId)
        .then((response) => response.data)
        .then((res) => {
          if (res.result?.length > 0) {
            let items = [];
            for (let indentObject of res.result) {
              indentObject.isChecked = true;
              items.push(indentObject);
              setSelectedItems(items);
            }
            let dataObject = { result: items };
            setIndentDetails(dataObject);
          }
        });
    }
  }, [indentId, storeId]);

  console.log("selectedItems",selectedItems);
  

  const handleGetIndentItems = () => {
    if (selectedItems?.length > 0) {
      let prescriptions = [];
      for (let object of selectedItems) {
        let prescriptionObject = {
          id: object?.Id,
          bins: object?.Bins,
          code: object?.Code,
          pharmacyItem: object?.Drug,
          BatchesRequired: object?.BatchesRequired,
          batchNo: "",
          balanceQuantity: object?.BalanceQty,
          IndentQty: object?.IndentQty,
          PendIssueQty:object?.PendIssueQty,
          expiryDate: "", 
          mrp: 0,
          sgstPercentage: 0,
          cgstPercentage: 0,
          rackName: object?.Rack,
          shelfName: object?.Shelf,
          rack: {
            id: object,
          },
          shelf: {
            id: object,
          },
          purchaseRate: 0,
          landedRate: 0,
          quantity: Number(object?.IndentQty),
          amount: 0,
          discountPercentage: 0,
          discountAmount: 0,
          netAmount: 0,
          mrpExcludeGst: 0,
          cgstAmount: "",
          sgstAmount: "",
          taxableAmount: 0,
          prescribeQuantity: 0,
          saleQuantity: Number(object?.IndentQty),
          itemMaster: {
            id: object?.Id,
          },
          genericId: object?.ItemGenericId,
          gstPercent: object?.VATPercentage,
          gstAmount: 0,
          conversion: object?.Conversion,
          IsConsignment: object?.IsConsignment,
        };
        prescriptions.push(prescriptionObject);
        props.setData(prescriptions);
      }
    }
     props.setResetIndentCount(props.resetIndentCount+1)
    props.close();
    setPatientIndentItems("");
    setIndentDetails("");
    setIndentId(null);
  };

  return (
    <div className="mt-10">
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={GetIndentModalStyle} className="max-h-[88%] xl:max-h-[80%]">
          <div className="grid grid-cols-1 md:grid-cols-1  w-full ">
            <CancelPresentationIconButton
              onClick={() => {
                props.close();
              }}
            />
            <div className="row">
              <div className="grid grid-cols-3">
                <div className="col-span-2 text-xl font-semibold my-2">
                  Get Indent
                </div>
                <div className="flex justify-end whitespace-nowrap gap-5 items-center">
                  <div className="h-5 w-5 bg-[#f5a6b9] -mr-2 rounded"></div>
                  <div className="">Corporate</div>
                  <div className="h-5 w-5 bg-[#f8d09c] -mr-2 rounded"></div>
                  <div>TPA</div>
                  <div className="h-5 w-5 bg-[#83b4e6] -mr-2 rounded"></div>
                  <div>PMC</div>
                  <div className="h-5 w-5 bg-[#93face] -mr-2 rounded"></div>
                  <div>PPN</div>
                </div>
              </div>
              <div className="p-2 grid grid-cols-2 lg:grid-cols-4 gap-2 border border-gray-400 rounded-xl bg-[#F1F1F1]">
                <div className="flex gap-2">
                  <h1 className="font-semibold text-sm text-black">
                    Patient Name
                  </h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-black font-normal">
                      {props.patientInfo?.patientName}
                    </span>
                  </div>
                </div>

                <div className="flex gap-2 space-x-8 xl:space-x-3">
                  <h1 className="font-semibold text-sm text-black">MRNo.</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-black font-normal">
                      {props.patientInfo?.mrNo}
                    </span>
                  </div>
                </div>
                <div className="flex gap-2 space-x-3 lg:space-x-11 ">
                  <h1 className="font-semibold text-sm text-black">Age</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-black font-normal">
                      {props.patientInfo?.AgeYear}
                    </span>
                  </div>
                </div>
                <div className="flex gap-2 space-x-5 xl:space-x-1">
                  <h1 className="font-semibold text-sm text-black">
                    Mobile No.
                  </h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-black font-normal">
                      {props.patientInfo?.MobileNo}
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 items-center my-2">
                {patientIndentItems?.result?.length > 0 ? (
                  <div>
                    <CommonSelectableTable
                      data={patientIndentItems}
                      setIndentId={setIndentId}
                      setStoreId={setStoreId}
                      component="main"
                      handlePatientInfo={props?.handlePatientInfo}
                    />
                  </div>
                ) : (
                  ""
                )}
                {indentDetails?.result?.length > 0 ? (
                  <div>
                    <CommonSelectableTable
                      data={indentDetails}
                      setData={setIndentDetails}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      component="child"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex justify-end my-2">
                {indentDetails?.result?.length > 0 && (
                  <CommonButton
                    label="Get Items"
                    className="bg-[#16A34A] text-white px-2 h-9 rounded"
                    type="button"
                    onClick={handleGetIndentItems}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

