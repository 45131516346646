import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
// import { getOtherServices, getSavedAdvices } from "./Services";
import CreateTemplateModal from "./CreateTemplateModal";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { ForceCloseIcon } from "../../../assets/CommonAssets";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "scroll",
  p: 1,
};

export default function AdviceTemplateModal(props) {
  const { open, close, adviceTemplates, setAdviceTemplates } = props;

  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [adviceList, setAdviceList] = React.useState([]);

  const handleCloseAddNew = () => {
    if (openAddNew === true) {
      setOpenAddNew(false);
    }
  };

  React.useEffect(() => {
    // getSavedAdvices()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     if (res?.result?.length > 0) {
    //       let templates = res?.result;
    //       if (templates?.length > 0) {
    //         let templateArray = [];
    //         for (let template of templates) {
    //           let templateObject = {
    //             id: template?.id,
    //             Template: template?.template,
    //             isChecked: false,
    //             Advices: JSON.parse(template?.advices),
    //           };
    //           templateArray.push(templateObject);
    //           setTemplates(templateArray);
    //         }
    //       }
    //     }
    //   })
    //   .catch((err) => err);
  }, []);

  React.useEffect(() => {
    if (templates?.length > 0) {
      let filtered = templates.filter(
        (template) => template?.isChecked === true
      );
      let advicesArray = [];

      if (filtered?.length > 0) {
        for (let template of filtered) {
          let advices = [...template.Advices];
          if (advices?.length > 0) {
            for (let adv of advices) {
              advicesArray.push(adv);
            }
          }
        }
        setAdviceList(advicesArray);
      }
    } else {
      setAdviceList([]);
    }
  }, [templates]);

  const handleAddAdviceTemplate = () => {
    let dataArray = [...templates];
    let filtered = dataArray.filter((item) => item?.isChecked === true);
    setAdviceTemplates([...filtered,...adviceTemplates]);
    close();
  };

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div className=" ">
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Advice Template
                </div>
              </div>
              <div className="flex items-center">
                <CommonButton
                  label={"Add New"}
                  type={"button"}
                  className={
                    "border border-customBlue text-customBlue px-3 h-9 rounded mr-5"
                  }
                  onClick={() => {
                    setOpenAddNew(true);
                  }}
                />
                <button
                  onClick={() => {
                    close();
                  }}
                  type="button"
                >
                  <ForceCloseIcon />
                </button>
              </div>
            </div>
            {templates?.length > 0 ? (
              <>
                <div className="my-2 flex gap-2 items-center">
                  <div className="w-[35%] flex justify-center items-center font-semibold px-2 py-1 border border-gray-200 rounded">
                    Template Name
                  </div>
                  <div className="w-[65%] flex justify-center items-center font-semibold px-2 py-1 border border-gray-200 rounded">
                    Advices
                  </div>
                </div>
                <div className="flex gap-2 ">
                  <div className="w-[40%] border rounded ">
                    {templates?.length > 0
                      ? templates.map((template, index) => {
                          return (
                            <div
                              onClick={() => {
                                setSelectedTemplate(template);
                                let dataArray = [...templates];
                                dataArray[index].isChecked =
                                  !dataArray[index].isChecked;
                                setTemplates(dataArray);
                              }}
                              className={`font-semibold border border-gray-200 px-2 py-1 ${
                                template.isChecked === true
                                  ? `bg-green-200`
                                  : ``
                              }`}
                            >
                              {template?.Template}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="">
                    {adviceList?.length > 0
                      ? adviceList.map((advice) => {
                          return (
                            <div className="border border-gray-200 rounded px-2 py-1">
                              •&nbsp;{advice}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {templates?.length > 0 && (
              <div className="flex justify-end my-2 px-2">
                <CommonButton
                  label={"Add"}
                  type={"button"}
                  className={"px-3 bg-[#368505] text-white h-9 rounded"}
                  onClick={() => {
                    handleAddAdviceTemplate();
                  }}
                />
              </div>
            )}
          </Box>
        </Modal>
        <CreateTemplateModal
          templates={templates}
          setTemplates={setTemplates}
          open={openAddNew}
          close={handleCloseAddNew}
        />
      </div>
    </div>
  );
}
