import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import {
  // AddIcon,
  ForceCloseIcon,
} from "../../../assets/CommonAssets";
import { useForm } from "react-hook-form";
import { TextareaAutosize } from "@mui/material";
// import { postClinicalFindings } from "./Services";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import InputField from "../../../common/components/FormFields/InputField";
import { errorAlert, successAlert } from "../../../common/components/Toasts/CustomToasts";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "visible",
  p: 1,
};

export default function CreateClinicalModal(props) {
  const { open, close, findings, setFindings, populateTable } = props;

  const defaultValues = {
    Template: "",
    Advice: "",
  };

  const { watch, setValue, control, register } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  let templateWatch = watch("Template");
  let AdviceWatch = watch("Advice");

  const handleAddTemplate = () => {
    // if (templateWatch !== "" && AdviceWatch !== "") {
    //   let templateObject = {
    //     id: null,
    //     template: templateWatch,
    //     templateDescription: AdviceWatch,
    //   };
    //   postClinicalFindings(templateObject)
    //     .then((response) => response.data)
    //     .then((res) => {
    //       successAlert(res?.message);
    //       close();
    //       populateTable();
    //       setValue("Template", "");
    //       setValue("Advice", "");
    //     })
    //     .catch((err) => {
    //       errorAlert(err?.message);
    //     });
    // }
  };

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div className=" ">
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Clinical Finding Template
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    close();
                  }}
                  type="button"
                >
                  <ForceCloseIcon />
                </button>
              </div>
            </div>
            <div className="flex gap-2 ">
              <div className="w-[30%]">
                <InputField
                  control={control}
                  name={"Template"}
                  label={"Template"}
                />
              </div>
              <div className="w-[70%]">
                <div className="">
                  <TextareaAutosize
                    {...register("Advice")}
                    name={"Advice"}
                    placeholder="Advice"
                    className="px-2 py-2 w-full border border-gray-200 rounded "
                    style={{
                      outline: "none",
                    }}
                    minRows={2}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <CommonButton
                label={"Save"}
                className={"px-3 bg-customGreen text-white rounded "}
                type="button"
                onClick={() => {
                  handleAddTemplate();
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
