import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

//
export const getAdministrationChartDrugList = (
  admissionId,
  STAT,
  SOS,
  menuId
) => {
  return apiClient.get(
    `drugAdmChart/drugAdmOrderList/${admissionId}/${STAT}/${SOS}/${menuId}`,
    {
      headers: authHeader(),
    }
  );
};

//
export const getSpecialInstructionsList = (admissionId, isClose) => {
  return apiClient.get(
    `drugAdmChart/specialInstructionList/${admissionId}/${isClose}`,
    {
      headers: authHeader(),
    }
  );
};

export const closePrescribedInstruction = (
  prescriptionDtlsId,
  menuId,
  privilege
) => {
  return apiClient.get(
    `drugAdmChart/closePrescribedInstruction/${prescriptionDtlsId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

//
export const getScheduleTimeSlotsList = (prescriptionDtlsId) => {
  return apiClient.get(`drugAdmChart/scheduleTimeSlots/${prescriptionDtlsId}`, {
    headers: authHeader(),
  });
};

//
export const getDrugAdmsList = (
  admissionId,
  page,
  size,
  menuId,
  todaysDrug
) => {
  return apiClient.get(
    `drugAdmChart/admDrugList/${admissionId}/${page}/${size}/${menuId}/${todaysDrug}`,
    { headers: authHeader() }
  );
};

export const saveDrugAdmOrder = async (addNewDrug) => {
  return await apiClient.post(`/drugAdmChart/saveDrugAdmOrder`, addNewDrug, {
    headers: authHeader(),
  });
};

// get time slots
export const getDrugAdmDtlsSlots = (prescriptionDtlsId) => {
  return apiClient.get(`drugAdmChart/drugAdmDtlsSlots/${prescriptionDtlsId}`, {
    headers: authHeader(),
  });
};

export const getInstructionsForDrug = () => {
  return apiClient.get(`drugAdmChart/instructionList`, {
    headers: authHeader(),
  });
};
// end

export const saveSpecialInstructions = async (addNewInstruction) => {
  return await apiClient.post(
    `/drugAdmChart/saveSpecialInstructions`,
    addNewInstruction,
    {
      headers: authHeader(),
    }
  );
};

export const updateSpecialInstructions = async (updateSpecialInstructions) => {
  return await apiClient.post(
    `/drugAdmChart/updateSpecialInstructions`,
    updateSpecialInstructions,
    {
      headers: authHeader(),
    }
  );
};

export const saveIVFluid = async (addNewDrug) => {
  return await apiClient.post(`/drugAdmChart/saveIVFluid`, addNewDrug, {
    headers: authHeader(),
  });
};

export const updateIvFluid = async (addNewDrug) => {
  return await apiClient.post(`/drugAdmChart/updateIvFluid`, addNewDrug, {
    headers: authHeader(),
  });
};

export const getIVFlowList = (admissionId) => {
  return apiClient.get(
    `drugAdmChart/intravenousInfusionTherapy/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPatientInfoForLogFile = (admissionId) => {
  return apiClient.get(`patientInfo/bill/${admissionId}/${1}`, {
    headers: authHeader(),
  });
};

// save iv flow checkbox
export const saveIsCancel = (status, ivId, menuId, privilege) => {
  return apiClient.get(
    `drugAdmChart/saveIsCancel/${status}/${ivId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveIsEnd = (status, ivId, menuId, privilege) => {
  return apiClient.get(
    `drugAdmChart/saveIsEnd/${status}/${ivId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveIsHold = (status, ivId, menuId, privilege) => {
  return apiClient.get(
    `drugAdmChart/saveIsHold/${status}/${ivId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveIsStart = (status, ivId, menuId, privilege) => {
  return apiClient.get(
    `drugAdmChart/saveIsStart/${status}/${ivId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveIsStop = (status, ivId, menuId, privilege) => {
  return apiClient.get(
    `drugAdmChart/saveIsStop/${status}/${ivId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};
// end

export const cancelPrescribedDrug = (prescriptionDtlsId, menuId, privilege) => {
  return apiClient.get(
    `drugAdmChart/cancelPrescribedDrug/${prescriptionDtlsId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const updateDrugAdministratorDetails = async (updateGivenDrug) => {
  return await apiClient.post(
    `/drugAdmChart/updateDrugAdministratorDetails`,
    updateGivenDrug,
    {
      headers: authHeader(),
    }
  );
};

// hold unhold drug
export const updateDrugHoldUnHoldStatus = (
  status,
  drugAdminDtlsId,
  updatedBy,
  drugAdminId,
  privilage
) => {
  return apiClient.get(
    `drugAdmChart/updateDrugHoldUnHoldStatus/${status}/${drugAdminDtlsId}/${updatedBy}/${drugAdminId}/${privilage}`,
    {
      headers: authHeader(),
    }
  );
};
// is close drug
export const cancelDrug = (drugAdminDtlsId, canceledBy, privilage) => {
  return apiClient.get(
    `drugAdmChart/cancelDrug/${drugAdminDtlsId}/${canceledBy}/${privilage}`,
    {
      headers: authHeader(),
    }
  );
};
// stopDrugAdminOrder
export const stopDrugAdminOrder = (drugAdminId, menuId, privilege) => {
  return apiClient.get(
    `drugAdmChart/stopDrugAdminOrder/${drugAdminId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

// Administrator Drug List updateDrugAdminOrder row
export const updateDrugAdminOrder = async (updateDrug) => {
  return await apiClient.post(
    `/drugAdmChart/updateDrugAdminOrder`,
    updateDrug,
    {
      headers: authHeader(),
    }
  );
};

export const getFrequencyForDrugOrder = (adminAs) => {
  return apiClient.get(`drugAdministrator/getFrequency/${adminAs}`, {
    headers: authHeader(),
  });
};
// update drug instruction
export const updateDrugInstruction = (
  prescriptionDtlsId,
  instruction,
  menuId,
  privilage
) => {
  return apiClient.get(
    `drugAdmChart/updateDrugInstruction/${prescriptionDtlsId}/${instruction}/${menuId}/${privilage}`,
    {
      headers: authHeader(),
    }
  );
};
// prescription print
export const fetchPrescriptionPrint = (obj) => {
  return apiClient.post(`/reports/nursing/prescription`, obj, {
    headers: authHeader(),
    responseType: "blob",
  });
};

// print
// /api/reports/nursing/medicationChart/{admissionId}
export const fetchPrintMedicationError = (printObj) => {
  return apiClient.post(`/reports/nursing/medicationChart`, printObj, {
    headers: authHeader(),
  });
};
export const fetchLogFilePrint = (admissionId) => {
  return apiClient.get(`/reports/nursing/getLogFileDetails/${admissionId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};
//http://192.168.0.229:5002/api/drugAdmChart/getTodaysDrugListOfPatientToGive/2007827

export const getTodaysDrugListOfPatientToGive = (admissionId) => {
  return apiClient.get(
    `/drugAdmChart/getTodaysDrugListOfPatientToGive/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

//drugAdmChart/getCasualtyDrug/{admissionId}
//drugAdmChart/createCasualtyPrescriptionOrder

export const getCasualtyDrug = (admissionId) => {
  return apiClient.get(`/drugAdmChart/getCasualtyDrug/${admissionId}`, {
    headers: authHeader(),
  });
};

export const createCasualtyPrescriptionOrder = (saveObj) => {
  return apiClient.post(
    `/drugAdmChart/createCasualtyPrescriptionOrder`,
    saveObj,
    {
      headers: authHeader(),
    }
  );
};

//drugAdmChart/changeDrugSequence

export const changeDrugSequence = (postObj) => {
  return apiClient.post(`/drugAdmChart/changeDrugSequence`, postObj, {
    headers: authHeader(),
  });
};
