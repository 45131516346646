import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { ForceCloseIcon } from "../../../assets/CommonAssets";


const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "60%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "auto",
  p: 1,
};

export default function PastHistoryModal(props) {
  const {
    selectedIndex,
    setSelectedIndex,
    resetCount,
    selectedHistory,
    setSelectedHistory,
  } = props;
  const [drugs, setDrugs] = React.useState([]);
  const defaultValues = {
    duration: "",
  };

  const { watch, control, register } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item["label"] === val["label"]);
      if (ind !== -1) {
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  const [historyOptions, setHistoryOptions] = React.useState([
    {
      id: 1,
      label: "Asthma",
      value: "Asthma",
      description: "",
      duration: "",
      since: "",
      isChecked: false,
    },
    {
      id: 2,
      label: "Diabetes",
      value: "Diabetes",
      description: "",
      duration: "",
      since: "",
      isChecked: false,
    },
    {
      id: 3,
      label: "Hypertension",
      value: "Hypertension",
      description: "",
      duration: "",
      since: "",
      isChecked: false,
    },
    {
      id: 4,
      label: "IHD",
      value: "IHD",
      description: "",
      duration: "",
      since: "",
      isChecked: false,
    },
    {
      id: 5,
      label: "Other",
      value: "Other",
      description: "",
      duration: "",
      since: "",
      isChecked: false,
    },
  ]);

  const [historyMap, setHistoryMap] = React.useState([]);

  console.log("historyMap", historyMap);
  

  React.useEffect(() => {
    if (historyOptions?.length > 0 || selectedHistory?.length > 0) {
      // let displayArray = [...historyOptions, ...selectedHistory];
      // setHistoryMap(mergeArray(displayArray));

      const secondArrayMap = new Map(
        selectedHistory.map((item) => [item.id, item])
      );
      const updatedArray = historyOptions.map((item) => {
        if (secondArrayMap.has(item.id)) {
          return { ...item, ...secondArrayMap.get(item.id) };
        }
        return item;
      });
      setHistoryMap(updatedArray);
    }
  }, [selectedHistory, historyOptions]);

  const handleSelect = (item, index) => {
    let dataArray = [...historyMap];
    dataArray[index].isChecked = !dataArray[index].isChecked;
    setHistoryMap(dataArray);

    let data = structuredClone(selectedHistory);
    if (data?.length > 0) {
      for (let object of data) {
        if (object.label === item?.label) {
          object.isChecked = !object.isChecked;
        }
      }
    }
  };

  const handleSelectItems = () => {
    let dataArray = [...historyMap];
    let filtered = dataArray.filter((item) => item.isChecked === true);
    setSelectedHistory(mergeArray([...filtered]));
    props.close();
  };

  React.useEffect(() => {}, [resetCount]);

  const handleSearchDrug = (e) => {
    let searchString = e;
  };

  const handleAddDuration = (duration, complaintIndex) => {
    let dataArray = [...historyMap];
    dataArray[complaintIndex].duration = duration;
    setHistoryMap(dataArray);
  };

  return (
    <div>
      <div>
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div>
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Past History
                </div>
              </div>
              <button
                onClick={() => {
                  props.close();
                }}
                type="button"
                className="mr-2"
              >
                <ForceCloseIcon />
              </button>
            </div>
            <div className="border-t border-slate-400 p-2 ">
              {historyMap?.length > 0
                ? historyMap?.map((item, index) => {
                    return (
                      <div
                        className={` flex gap-2 items-center py-1 px-3 border border-gray-200 ${
                          item?.isChecked === true ? `bg-green-200` : ``
                        }`}
                      >
                        <div
                          className="w-[25%] font-semibold"
                          onClick={() => {
                            handleSelect(item, index);
                          }}
                        >
                          {item?.label}
                        </div>
                        <div className="flex gap-2 items-center">
                          <input
                            name={`duration${item.id}`}
                            style={{ outline: "none" }}
                            placeholder="Since"
                            disabled={item?.isChecked === false ? true : false}
                            defaultValue={item?.since}
                            className="text-center w-20 border border-gray-400 rounded"
                            onChange={(e) => {
                              if (Number(e.target.value) > 0) {
                                let dataArray = [...historyMap];
                                dataArray[index].since = e.target.value;
                                dataArray[index].duration = "D";
                                setHistoryMap(dataArray);
                              } else {
                                let dataArray = [...historyMap];
                                dataArray[index].since = "";
                                dataArray[index].duration = "";
                                setHistoryMap(dataArray);
                              }
                            }}
                          />
                          <div
                            onClick={() => {
                              handleAddDuration("D", index);
                            }}
                            className={`h-6 w-6 rounded-full ${
                              item.duration === "D" && item.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            D
                          </div>
                          <div
                            onClick={() => {
                              handleAddDuration("M", index);
                            }}
                            className={`h-6 w-6 rounded-full ${
                              item.duration === "M" && item.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            M
                          </div>
                          <div
                            onClick={() => {
                              handleAddDuration("Y", index);
                            }}
                            className={`h-6 w-6 rounded-full ${
                              item.duration === "Y" && item.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            Y
                          </div>
                        </div>
                        <div className="w-[40%]">
                          <input
                            className="w-full text-start px-2 h-9 rounded border border-gray-400"
                            style={{ outline: "none" }}
                            placeholder="Description"
                            disabled={item?.isChecked === false ? true : false}
                            onChange={(e) => {
                              let dataArray = [...historyMap];
                              dataArray[index].description = e.target.value;
                              setHistoryMap(dataArray);
                            }}
                            defaultValue={
                              selectedIndex.includes(item?.id)
                                ? item?.description
                                : ""
                            }
                          />
                        </div>
                        {/* <div className="w-[20%]">
                          {item?.label === "Asthama" ||
                          item?.label === "Diabetes" ? (
                            <SearchDropdown
                              handleInputChange={handleSearchDrug}
                              control={control}
                              placeholder="Past Medication"
                              searchIcon={true}
                              isClearable={true}
                              clearSearchBar={true}
                              dataArray={drugs}
                              name={`searchDrug${item?.id}`}
                            />
                          ) : (
                            ""
                          )}
                        </div> */}
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="flex justify-end my-2 px-2">
              <CommonButton
                label={"Add"}
                type={"button"}
                className={"px-3 bg-[#368505] text-white h-9 rounded"}
                onClick={handleSelectItems}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
