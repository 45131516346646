import { Box, Modal } from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";
import {
  getDiDashboardDiet,
  getDiDashboardIntakeOutput,
  getDiDashboardLabParameters,
  getDiDashboardLines,
  getDiDashboardScores,
  getDiDashboardToDoList,
  getDiDashboardTreatmentAndInfusion,
  getDiDashboardVentilator,
  getDiDashboardVitals,
} from "../../../services/diPatientServices/DIPatientService";

const VitalsGraph = React.lazy(() => import("./VitalsGraph"));
const LabList = React.lazy(() => import("./LabList"));
const LinesList = React.lazy(() => import("./LinesList"));
const Scores = React.lazy(() => import("./Scores"));
const Ventilator = React.lazy(() => import("./Ventilator"));
const InputOutput = React.lazy(() => import("./InputOutput"));
const DiatList = React.lazy(() => import("./DiatList"));
const Treatment = React.lazy(() => import("./Treatment"));
const ToDoList = React.lazy(() => import("./ToDoList"));

function DIDashboard({ open, handleClose, setSelectedRow, selectedRow }) {
  const [vitalsGraphData, setVitalsGraphData] = useState(null);
  const [labListData, setLabListData] = useState(null);
  const [linesListData, setLinesListData] = useState(null);
  const [scoreData, setScoreData] = useState(null);
  const [ventilationData, setVentilationData] = useState(null);
  const [inputOutputData, setInputOutputData] = useState(null);
  const [dietData, setDietData] = useState(null);
  const [treatmentData, setTreatmentData] = useState(null);
  const [toDoListData, setToDoListData] = useState(null);

  const hasFetchedData = useRef(false);

  const fetchData = () => {
    const admissionId = selectedRow?.AdmissionId;
    if (!admissionId) return;
    const apiCalls = [
      getDiDashboardVitals(admissionId),
      getDiDashboardLabParameters(admissionId),
      getDiDashboardLines(admissionId),
      getDiDashboardScores(admissionId),
      getDiDashboardVentilator(admissionId),
      getDiDashboardIntakeOutput(admissionId),
      getDiDashboardDiet(admissionId),
      getDiDashboardTreatmentAndInfusion(admissionId),
      getDiDashboardToDoList(admissionId),
    ];
    Promise.all(
      apiCalls.map((call) =>
        call
          .then((response) => response.data.body.result)
          .catch((error) => ({ error }))
      )
    ).then(
      ([
        vitals,
        lab,
        lines,
        scores,
        ventilation,
        inputOutput,
        diet,
        treatment,
        toDoList,
      ]) => {
        setVitalsGraphData(vitals);
        setLabListData(lab);
        setLinesListData(lines);
        setScoreData(scores);
        setVentilationData(ventilation);
        setInputOutputData(inputOutput);
        setDietData(diet);
        setTreatmentData(treatment);
        setToDoListData(toDoList);
      }
    );
  };

  useEffect(() => {
    if (selectedRow !== null) {
      if (hasFetchedData.current) return;
      hasFetchedData.current = true;
      fetchData();
    }
  }, [selectedRow]);

  //   {
  //     "Bed No": "1012 D",
  //     "Patient Name": "KAMINI RAJESH ARORA",
  //     "ICU Days": 0,
  //     "Ventilation": false,
  //     "Oxygen": false,
  //     "DI": null,
  //     "QSOFA": 1,
  //     "GCS": 6,
  //     "MEWS": 8,
  //     "Apache Score": null,
  //     "AdmissionId": 1071144
  // }
  console.log("selectedRow");

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={ModalStyleFeedback}
        className="w-[95%] h-[95%] rounded-lg overflow-y-auto"
      >
        <CancelPresentationIconButton
          onClick={() => {
            handleClose();
            setSelectedRow(null);
          }}
        />
        <h1 className="2xl:text-2xl text-xl text-center py-1 font-semibold">
          Deterioration Index
        </h1>
        <div className="grid grid-cols-3 gap-2 pb-2">
          <div className="grid grid-cols-3 col-span-2 gap-2 p-2 bg-gray-100 rounded font-semibold whitespace-nowrap text-xs 2xl:text-sm">
            <h5 className="flex space-x-2">
              <span>Patient Name</span>
              <span>: {selectedRow?.["Patient Name"]}</span>
            </h5>
            <h5 className="flex space-x-[14px] 2xl:space-x-4">
              <span>UHID</span>
              <span>:{selectedRow?.MRNo}</span>
            </h5>
            <h5 className="flex space-x-2">
              <span>Age / Gender</span>
              <span>: {selectedRow?.AgeGender}</span>
            </h5>
            <h5 className="flex space-x-[17px] 2xl:space-x-[18.5px]">
              <span>Department</span>
              <span>: {selectedRow?.Department}</span>
            </h5>
            <h5 className="flex space-x-1">
              <span>Doctor</span>
              <span>: {selectedRow?.DoctorName}</span>
            </h5>
          </div>
          <div className="p-2 bg-[#DAEBFF] rounded text-customBlue text-xs 2xl:text-sm flex space-x-2">
            <h5 className="font-semibold">Diagnosis :</h5>
            <h5 className="font-semibold">
              {selectedRow?.Diagnosis &&
              JSON.parse(selectedRow.Diagnosis).length > 0
                ? JSON.parse(selectedRow.Diagnosis).map((list, index, arr) => (
                    <>
                      {list.Diagnosis}
                      {index < arr.length - 1 && ", "}
                    </>
                  ))
                : ""}
            </h5>
          </div>
        </div>
        <div className="flex space-x-2 h-auto w-full">
          <div className="w-[80%]">
            <Suspense
              fallback={
                <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                  <p> Loading Vitals Graph...</p>
                </div>
              }
            >
              <VitalsGraph vitalsGraphData={vitalsGraphData} />
            </Suspense>
          </div>
          <div className="grid grid-cols-2 h-auto gap-2 w-full whitespace-nowrap">
            <Suspense
              fallback={
                <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                  <p> Loading Lab List...</p>
                </div>
              }
            >
              <LabList labListData={labListData} />
            </Suspense>
            <Suspense
              fallback={
                <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                  <p> Loading Lines List...</p>
                </div>
              }
            >
              <LinesList linesListData={linesListData} />
            </Suspense>
            <Suspense
              fallback={
                <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                  <p> Loading Scores...</p>
                </div>
              }
            >
              <Scores scoreData={scoreData} />
            </Suspense>
            <Suspense
              fallback={
                <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                  <p> Loading Ventilator...</p>
                </div>
              }
            >
              <Ventilator ventilationData={ventilationData} />
            </Suspense>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 py-2 h-auto">
          <Suspense
            fallback={
              <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                <p> Loading Input/Output...</p>
              </div>
            }
          >
            <InputOutput inputOutputData={inputOutputData} />
          </Suspense>
          <Suspense
            fallback={
              <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                <p> Loading Diet List...</p>
              </div>
            }
          >
            <DiatList dietData={dietData} />
          </Suspense>
          <Suspense
            fallback={
              <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                <p> Loading Treatment...</p>
              </div>
            }
          >
            <Treatment treatmentData={treatmentData} />
          </Suspense>
          <Suspense
            fallback={
              <div className="text-center text-sm py-10 2xl:py-20 text-gray-600">
                <p> Loading To-Do List...</p>
              </div>
            }
          >
            <ToDoList toDoListData={toDoListData} />
          </Suspense>
        </div>
      </Box>
    </Modal>
  );
}

export default DIDashboard;

