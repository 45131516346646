import { yupResolver } from "@hookform/resolvers/yup";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";

import {
  getBedTransferPatientInfo,
  getPatientAdmissionAutoComplete,
} from "../../../commonServices/patientInfoServices/PatientInfoServices";
import {
  carePlanPrint,
  fetchAllCarePlan,
  fetchAllCarePlanList,
  fetchCarePlanDetails,
  saveCarePlan,
} from "../../services/carePlanServices/CarePlanServices";

import { Box, Modal, TextField, Tooltip } from "@mui/material";
import { format, isValid } from "date-fns";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import SearchIconButton from "../../../common/components/Buttons/SearchIconButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";

const removeHeaders = [
  "AdmissionId",
  "Diagnosis",
  "Investigation",
  "IvFluid",
  "MonitorFollow",
  "SurgPlanOther",
  "carePlanId",
  "arrivaldate",
  "arrivaltime",
  "desired_outcum",
  "Desired Outcum",
];
export default function CarePlan(props) {
  const { updateComponent } = props;
  /******************* UseFrom schema,defaultValues & destructuring************/
  const schema = yup.object().shape({
    provisionalDiagnosis: yup.string(),
    investigation: yup.string(),
    ivFluids: yup.string(),
    surgicalPlan: yup.string(),
    monitorFollowing: yup.string(),
    desireOutcome: yup.string(),
    carePlanSearch: "",
  });
  // form defaultValues
  const defaultValues = {
    provisionalDiagnosis: "",
    investigation: "",
    ivFluids: "",
    surgicalPlan: "",
    monitorFollowing: "",
    desireOutcome: "",
    carePlanSearch: "",
    fromDateCalender: new Date(),
    arrivalTime: new Date(),
    fromDate: new Date(),
    toDate: new Date(),
  };
  // use form destructuring
  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  /************************* state variables ***************************/
  const [open, setOpen] = React.useState(false);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [searchListOptions, setSearchListOptions] = React.useState([]);
  const [searchList, setSearchList] = React.useState("");
  const [data, setData] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);
  const [allowSave, setAllowSave] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [carePlan, setCarePlan] = React.useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [fromListDate, setFormListDate] = React.useState(new Date());
  const [toListDate, setToListDate] = React.useState(new Date());
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [userActions, setUserActions] = useState([]);
  const [menuIdVal, setMenuIdVal] = React.useState(null);
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [carePlanSearchVal, setCarePlanSearchVal] = React.useState(null);

  let getFromDate = format(
    new Date(watch("fromDate")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );
  let getToDate = format(
    new Date(watch("toDate")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );
  console.log("getFromDate", new Date(getFromDate));

  /*************************  other variables ***************************/
  // let carePlanSearchVal = watch("carePlanSearch");
  let location = useLocation();

  /*************************  useEffects ***************************/
  useEffect(() => {
    if (updateComponent) {
      setValue("arrivalTime", new Date());
      setValue("fromDateCalender", new Date());
      //
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
    }
  }, [updateComponent]);

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      setValue("arrivalTime", new Date());
    }, [MINUTE_MS]);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    populateTable();
  }, [open, props?.selectedPatient, getFromDate, getToDate]);

  function populateTable() {
    let searchListObj = {
      fromDate: getFromDate,
      searchId:
        props?.selectedPatient !== undefined
          ? props?.selectedPatient?.AdmissionId
          : searchList?.id,
      toDate: getToDate,
    };
    setLoadingSpinner(true);
    // if (open === true) {
    fetchAllCarePlan(searchListObj)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
        setLoadingSpinner(false);
      })
      .catch(() => {
        setLoadingSpinner(false);
      });
    // }
  }

  useEffect(() => {
    if (location?.state?.menuId || props?.menuId) {
      fetchUserActionsByMenuId(
        props?.menuId ? props?.menuId : location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
    }
  }, [props?.menuId]);

  /*************************  functions ***************************/

  const handleClose = () => setOpen(false);

  const handleOpenConfirmation = () => {
    if (
      (!!carePlanSearchVal && carePlanSearchVal["patientName"]) ||
      (!!rowData && rowData["Patient Name"]) ||
      props.isFromClinicalCarechart === true
    ) {
      setConfirmationOpen(true);
    } else {
      errorAlert("Please Search Patient");
    }
  };
  const handleCloseConfirmation = () => setConfirmationOpen(false);

  // get search options for patient search
  function handleChange(carePlanSearchResult) {
    if (carePlanSearchResult !== "") {
      getPatientAdmissionAutoComplete(carePlanSearchResult)
        .then((response) => response.data)
        .then((res) => {
          setSearchOptions(res.result);
        });
    }
  }

  function handleChangeList(searchString) {
    let searchListObj = {
      fromDate: getFromDate,
      searchString: searchString !== "" ? searchString : null,
      searchId: props?.selectedPatient?.AdmissionId,
      toDate: getToDate,
    };
    fetchAllCarePlanList(searchListObj)
      .then((response) => response.data)
      .then((res) => {
        setSearchListOptions(res.result);
      });
  }

  function autoSelectedValue(selectedValue) {
    if (selectedValue !== "") {
      setSearchList(selectedValue);
    } else {
      setSearchList("");
    }
  }

  // get patient information in admission id base
  function getPatientInformationById(admissionId) {
    getBedTransferPatientInfo(admissionId)
      .then((response) => response.data)
      .then((res) => {
        // setPatientInfoByAdmId(res.result);
        // errorAlert("Care Plan Exit For Patient!");
      });
  }

  // after click save button function call
  function onSubmitDataHandler(data) {
    let userInfoString = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfoString);
    let carePlanPostObj = {};
    let validArrivalTime = isValid(data.arrivalTime);

    handleOpenConfirmation();
    carePlanPostObj = {
      addedBy: userInfoObj.userId,
      admissionId:
        props?.isFromClinicalCarechart === true
          ? props?.selectedPatient?.AdmissionId
          : carePlanSearchVal?.AdmissionId,
      arrivalDate: selectedFromDate,
      arrivalTime: validArrivalTime
        ? format(data.arrivalTime, "yyyy-MM-dd'T'HH:mm:ss")
        : data.arrivalTime.concat("Z"),
      desireOutcome: data.desireOutcome ? data.desireOutcome : "",
      investigation: data.investigation ? data.investigation : "",
      ivFluids: data.ivFluids ? data.ivFluids : "",
      monitorFollowing: data.monitorFollowing ? data.monitorFollowing : "",
      provisionalDiagnosis: data.provisionalDiagnosis
        ? data.provisionalDiagnosis
        : "",
      surgicalPlan: data.surgicalPlan ? data.surgicalPlan : "",
      menuId: location?.state?.menuId,
      privilege: menuIdVal?.action,
      carePlanId:
        rowData?.ID !== undefined && rowData?.ID !== null
          ? rowData?.ID
          : rowData?.carePlanId !== undefined && rowData?.carePlanId !== null
          ? rowData?.carePlanId
          : undefined,
    };
    setCarePlan(carePlanPostObj);
  }

  function carePlanSave(carePlanData) {
    setOpenBackdrop(true);
    saveCarePlan(carePlanData)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setRowData(null);
          reset();
          setRowData(null);
          setAllowSave(true);
          setCarePlanSearchVal(null);

          if (props?.isFromClinicalCarechart === true) {
            props?.setOpenCarePlanModal(false);
          }
          setEdit(false);
          setCarePlanSearchVal(null);
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenBackdrop(false);
      });
  }

  function displayRowData(row) {
    let obj = {
      patientName:
        row?.["Patient Name"] ||
        props?.selectedPatient?.PatientName ||
        carePlanSearchVal?.patientName,
      MRNo:
        rowData?.MRNo ||
        props?.selectedPatient?.MRNo ||
        carePlanSearchVal?.MRNo,
    };

    console.log("row", row, "obj", obj);

    // setAllowSave(!!row ? false : true);

    setEdit(true);

    setValue("provisionalDiagnosis", row?.Diagnosis || "");
    setValue("investigation", row?.Investigation);
    setValue("ivFluids", row?.IvFluid);
    setValue("surgicalPlan", row?.SurgPlanOther);
    setValue("monitorFollowing", row?.MonitorFollow);
    setValue("desireOutcome", row?.desired_outcum);
    setValue("Patient Name", row?.["Patient Name"]);
    setValue("arrivalTime", `1900-01-01T${row?.arrivaltime}.000`, {
      shouldValidate: true,
    });
    setValue("fromDateCalender", `${row?.arrivaldate}T01:01:01.000`, {
      shouldValidate: true,
    });
    setRowData({ ...row, ...obj });
  }

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  //care plan print
  const handleOpenPrintModal = (id) => {
    carePlanPrint(id).then((response) => {
      setUrlforPrint(response);
      setOpenPrintModal(true);
    });
  };

  // actions and print
  const renderActions = (row, index) => {
    setOpen(true);
    return (
      <div className="flex">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === false ? (
                <>
                  <div className="flex gap-1 cursor-pointer">
                    {actions.action === "Edit" ? (
                      <Tooltip title="Edit Care Plan">
                        <button
                          onClick={() => {
                            displayRowData(row);
                          }}
                        >
                          <DriveFileRenameOutlineIcon
                            className="text-blue-700"
                            onClick={(e) => {
                              setEdit(true);
                              handleClose();
                            }}
                          />
                        </button>
                      </Tooltip>
                    ) : null}
                    {actions.action === "Print" ? (
                      <Tooltip title="Print Care Plan">
                        <button
                          onClick={() => {
                            handleOpenPrintModal(row?.carePlanId);
                          }}
                        >
                          <LocalPrintshopIcon sx={{ color: "#4B5563" }} />
                        </button>
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  const getCarePlanDetails = (AdmissionId) => {
    AdmissionId &&
      fetchCarePlanDetails(AdmissionId)
        .then((response) => response?.data)
        .then((res) => {
          console.log(res?.result);

          const { arrivaldate, arrivaltime, ...rest } = res?.result;

          let updatedObj = {
            arrivaldate: arrivaldate?.split("T")[0],
            arrivaltime: arrivaltime?.split("T")[1]?.split(".")[0],
            ...rest,
          };

          displayRowData(updatedObj);
        })
        .catch((err) => {
          displayRowData(null);
        });
  };

  useEffect(() => {
    getCarePlanDetails(
      carePlanSearchVal?.AdmissionId || props?.selectedPatient?.AdmissionId
    );
  }, [carePlanSearchVal, props?.selectedPatient]);

  // get selected row data
  // function handleSelectedRow(row) {}

  console.log(
    "props?.selectedPatient",
    props?.selectedPatient,

    "carePlanSearchVal",
    carePlanSearchVal
  );

  console.log("rowData", rowData);

  useEffect(() => {
    let allow =
      carePlanSearchVal?.Discharged ||
      rowData?.Discharged ||
      props?.selectedPatient?.Discharged;

    setAllowSave(allow ? false : true);
  }, [carePlanSearchVal, props?.selectedPatient, rowData]);

  return (
    <>
      <div className="pt-12">
        <div className="text-center text-2xl text-gray-700 font-Poppins">
          Care Plan
        </div>

        <div className="">
          <div className=" flex justify-between gap-3 md:flex md:w-full ">
            <div className=" lg:w-2/5 md:w-full">
              {props?.fromClinicalChart === true || edit === true ? null : (
                <fieldset
                  disabled={
                    props.isFromClinicalCarechart === true ? true : false
                  }
                >
                  <SearchDropdown
                    control={control}
                    name="carePlanSearch"
                    placeholder="Search By Patient Name/ MR No./Mobile No"
                    dataArray={searchOptions}
                    searchIcon={true}
                    isClearable={true}
                    isSearchable={true}
                    isDisabled={
                      props?.selectedPatient !== undefined ? true : false
                    }
                    handleInputChange={handleChange}
                    inputRef={{
                      ...register("carePlanSearch", {
                        onChange: (e) => {
                          if (
                            !!e.target.value &&
                            e.target.value?.isrecievebed
                          ) {
                            setCarePlanSearchVal(e.target.value);
                          } else if (
                            !!e.target.value &&
                            !e.target.value?.isrecievebed
                          ) {
                            warningAlert("Patient is not received on bed.");
                            reset(defaultValues);
                            setRowData(null);
                            setCarePlanSearchVal(null);
                            setCarePlanSearchVal(null);
                            setAllowSave(true);
                          } else {
                            reset(defaultValues);
                            setCarePlanSearchVal(null);
                            setRowData(null);
                            setCarePlanSearchVal(null);
                            setAllowSave(true);
                          }
                        },
                      }),
                    }}
                  />
                </fieldset>
              )}
            </div>
            <div className="">
              <CommonButton
                type="submit"
                label="View"
                className="bg-customBlue text-white"
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
          </div>
          <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
            {/* <legend className="ml-6 my-2 rounded-"></legend> */}
            <div className="lg:grid lg:grid-cols-5 md:grid md:grid-cols-2 gap-2 p-3">
              <div className="flex  items-center col-span-2 gap-2 w-full font-semibold text-sm py-1">
                <span className="w-28 font-semibold"> Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {/* {edit
                      ? (!!rowData && rowData["Patient Name"]) ||
                        rowData?.patientName
                      : props?.selectedPatient !== null &&
                        props?.selectedPatient !== undefined
                      ? props?.selectedPatient?.PatientName
                      : carePlanSearchVal?.patientName} */}
                    {rowData?.patientName ||
                      props?.selectedPatient?.PatientName ||
                      carePlanSearchVal?.patientName}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                <span className="w-16 font-semibold"> MR No. </span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {/* {edit
                      ? rowData && rowData.MRNo
                      : props?.selectedPatient !== null &&
                        props?.selectedPatient !== undefined
                      ? props?.selectedPatient?.MRNo
                      : carePlanSearchVal?.MRNo} */}
                    {rowData?.MRNo ||
                      props?.selectedPatient?.MRNo ||
                      carePlanSearchVal?.MRNo}
                  </span>
                </div>
              </div>

              <div className="">
                <DatePickerFieldNew
                  control={control}
                  name="fromDateCalender"
                  label="From Date"
                  value={new Date()}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                  inputRef={{
                    ...register("fromDateCalender", {
                      onChange: (e) => {
                        if (e.target.value.toString().length === 55) {
                          let dateVal = format(e.target.value, "yyyy-MM-dd");
                          let timeVal = dateVal + "T" + "00:00:00";
                          setSelectedFromDate(timeVal);
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div className="">
                <Controller
                  control={control}
                  name="arrivalTime"
                  render={({ field: { value, onChange } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopTimePicker
                        label="Reporting Time"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            name="arrivalTime"
                            {...params}
                            error={errors.arrivalTime}
                            helperText={errors.arrivalTime?.message}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: false,
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "14px",
                                position: "absolute",
                                top: "-2px",
                              },
                            }}
                            sx={{
                              svg: {
                                color: "#1e3a8a",
                                height: 22,
                                width: "100%",
                                marginRight: "16px",
                              },
                              backgroundColor: "white",
                              overflow: "visible",

                              "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-input": {
                                  // border: 0,
                                  fontSize: 14,
                                  height: "18px",
                                  width: "100%",

                                  borderColor: "  ",
                                  overflow: "hidden",
                                },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
          </fieldset>
        </div>
        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <fieldset
            // disabled={carePlanSearchVal === "" && rowData === "" ? true : false}
            className="grid grid-cols-2  gap-2 py-2 bg-white"
          >
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="provisionalDiagnosis"
                {...register("provisionalDiagnosis")}
                label="Provisional Diagnosis / Clinical Impression"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: "white" }}
                multiline
                minRows={2}
                fullWidth
              />
            </div>
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="investigation"
                {...register("investigation")}
                label="Investigation"
                error={errors.investigation}
                sx={{ backgroundColor: "white" }}
                InputLabelProps={{ shrink: true }}
                multiline
                minRows={2}
                fullWidth
              />
            </div>
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="ivFluids"
                {...register("ivFluids")}
                error={errors.ivFluids}
                sx={{ backgroundColor: "white" }}
                label="IV Fluids And Medications"
                InputLabelProps={{ shrink: true }}
                multiline
                minRows={2}
                fullWidth
              />
            </div>
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="surgicalPlan"
                error={errors.surgicalPlan}
                sx={{ backgroundColor: "white" }}
                {...register("surgicalPlan")}
                label="Surgical Plan / Other"
                InputLabelProps={{ shrink: true }}
                multiline
                minRows={2}
                fullWidth
              />
            </div>
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="monitorFollowing"
                error={errors.monitorFollowing}
                sx={{ backgroundColor: "white" }}
                {...register("monitorFollowing")}
                label="Monitor Following / Special Instructions If Any"
                InputLabelProps={{ shrink: true }}
                multiline
                minRows={2}
                fullWidth
              />
            </div>
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="desireOutcome"
                {...register("desireOutcome")}
                sx={{ backgroundColor: "white" }}
                error={errors.desireOutcome}
                label="Desired Outcome"
                InputLabelProps={{ shrink: true }}
                multiline
                minRows={2}
                fullWidth
              />
            </div>
          </fieldset>
          <div className="flex justify-end py-2 gap-2">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      reset();
                      setCarePlanSearchVal(null);
                      setSearchOptions([]);
                      setMenuIdVal(obj);
                      setEdit(false);
                      setAllowSave(true);
                      setRowData(null);
                    }}
                  />
                ) : null}
              </>
            ))}

            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" && allowSave ? (
                  <CommonButton
                    label="Save"
                    type="submit"
                    className="bg-customGreen text-white"
                    onClick={() => {
                      setMenuIdVal(obj);
                    }}
                  />
                ) : null}
              </>
            ))}
          </div>
        </form>
        <CommonBackDrop openBackdrop={openBackdrop} />
        {/* print modal open */}

        {/* open modal on view button click */}
        {open && (
          <Modal open={open}>
            <Box
              sx={{
                position: "absolute",
                top: "48%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90%",
                bgcolor: "background.paper",
                height: "80%",
                overflowY: "auto",
                p: 2,
              }}
            >
              <div>
                <CancelPresentationIconButton
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </div>
              <div className="py-2">
                <p className="text-center text-2xl text-gray-700 font-Poppins">
                  Care Plan List
                </p>
              </div>
              <div className="flex gap-2 md:grid-cols-3 p-1">
                <div className="lg:col-span-2 w-[200%]">
                  <fieldset
                    disabled={
                      props?.selectedPatient !== undefined ? true : false
                    }
                  >
                    <SearchBar
                      searchIcon={true}
                      isClearable={true}
                      name="carePlanListSearch"
                      placeholder="Search By Patient Name / MRNo./ Mobile No. "
                      dataArray={searchListOptions}
                      isSearchable={true}
                      handleInputChange={handleChangeList}
                      onChange={autoSelectedValue}
                    />
                  </fieldset>
                </div>
                <div className="w-full">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    // value={new Date()}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                    // inputRef={{
                    //   ...register("fromDate", {
                    //     onChange: (e) => {
                    //       if (e.target.value.toString().length === 55) {
                    //         let formatedDate = format(
                    //           e.target.value,
                    //           "yyyy-MM-dd"
                    //         );
                    //         let formatedDateTime =
                    //           formatedDate + "T" + "07:44:11.341Z";
                    //         setFormListDate(formatedDateTime);
                    //       }
                    //     },
                    //   }),
                    // }}
                  />
                </div>
                <div className="w-full">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    // value={new Date()}
                    disableFuture={true}
                    inputFormat="dd-MM-yyyy"
                    // inputRef={{
                    //   ...register("toDate", {
                    //     onChange: (e) => {
                    //       if (e.target.value.toString().length === 55) {
                    //         let formatedDate = format(
                    //           e.target.value,
                    //           "yyyy-MM-dd"
                    //         );
                    //         let formatedDateTime =
                    //           formatedDate + "T" + "00:00:00:000Z";
                    //         setToListDate(e.target.value);
                    //       }
                    //     },
                    //   }),
                    // }}
                  />
                </div>
                <div className="w-full">
                  <SearchIconButton
                    onClick={() => {
                      populateTable();
                    }}
                  />
                </div>

                <div className="w-full"></div>
              </div>
              {loadingSpinner ? (
                <div className="grid justify-center items-center pt-12">
                  <LoadingSpinner />
                </div>
              ) : null}
              {data.length > 0 && loadingSpinner === false ? (
                <CommonDynamicTableNew
                  dataResult={data}
                  highlightRow={true}
                  tableClass="h-96 2xl:h-[520px]"
                  // handleSelectedRow={handleSelectedRow}
                  renderActions={renderActions}
                  removeHeaders={removeHeaders}
                />
              ) : (
                <div className="flex justify-center">
                  <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                    No Record Found...
                  </h3>
                </div>
              )}
            </Box>
          </Modal>
        )}

        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleCloseConfirmation}
          confirmationSubmitFunc={() => {
            carePlanSave(carePlan);
            handleCloseConfirmation();
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Save Care Plan ?"
          confirmationButtonMsg="Save"
        />
        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={handleClosePrintModal}
            urlforPrint={urlforPrint}
          />
        ) : null}
      </div>
    </>
  );
}

