import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackdrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import { CapitalizeStatement } from "../../../common/components/Custom Hooks/CapitalizeStatement";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchFloor } from "../../../commonServices/miscellaneousServices/MiscServices";
import { drugAdminPatientList } from "../../../ipd/services/drugadministratororderservices/DrugAdministratOrorderServices";
import MedicationChartPrint from "../../prints/MedicationChartPrint";
import {
  changeDrugSequence,
  fetchLogFilePrint,
  fetchPrescriptionPrint,
  fetchPrintMedicationError,
  getAdministrationChartDrugList,
  getCasualtyDrug,
  getDrugAdmsList,
  getInstructionsForDrug,
  getPatientInfoForLogFile,
  getSpecialInstructionsList,
  saveSpecialInstructions,
} from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import AddIVModal from "./AddIVModal";
import AdministrationDrugListTable from "./AdministrationDrugListTable";
import CreateNewIndentDrugAdmChartModal from "./CreateNewIndentDrugAdmChartModal";
import DrugAdministrationOrderListTable from "./DrugAdministrationOrderListTable";
import DrugAdministratorOrderModal from "./DrugAdministratorOrderModal";
import IVFlowModal from "./IVFlowModal";
import SpecialInstructionTable from "./SpecialInstructionTable";
import ConfirmationModalForSpecialInstructions from "./common/ConfirmationModalForSpecialInstructions";
import CasualtyDrugModal from "./CasualtyDrugModal";

const actions = ["Edit", "ReSchedule", "Stop"];
const drugAction = ["Edit", "ReSchedule", "Stop"];

function DrugAdmisitrationChart(props) {
  const defaultValues = {
    id: "",
    floor: null,
    search: "",
    instructions: null,
    instructionIsClose: false,
    stat: false,
    sos: false,
    fromDate: new Date(),
    todate: new Date(),
    todaysDrug: false,
  };
  const { watch, register, control, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });
  //local variables
  let floorVal = watch("floor");
  let searchVal = watch("search");
  let instructionValue = watch("instructionIsClose");
  let STATValue = watch("stat");
  let SOSValue = watch("sos");
  let fromDateValue = watch("fromDate");
  let toDateValue = watch("todate");
  let todaysDrugValue = watch("todaysDrug");
  const printRefMedication = useRef(null);

  const location = useLocation();
  let instructionIsChecked;
  if (instructionValue === true) {
    instructionIsChecked = 1;
  } else {
    instructionIsChecked = 0;
  }
  // state Variables
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const [floorOptions, setFloorOptions] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [drugListData, setDrugListData] = useState([]);
  const [admDrugList, setAdmDrugList] = useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = useState([]);
  const [tempTableData, setTempTableData] = React.useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [specialInstructionData, setSpecialInstructionData] = useState([]);
  const [selectedAdmDrugRowList, setSelectedAdmDrugRowList] = useState(null);
  const [instructionOptions, setInstructionOptions] = useState([]);
  const [instructionsValue, setInstructionsValue] = useState(null);
  const [instructionBillChecked, setInstructionBillChecked] = useState(false);
  //drug order list Modal
  const [openDrugAdmOrderModal, setOpenDrugAdmOrderModal] = useState(false);
  const [drugScheduleloadingSpinner, setDrugScheduleLoadingSpinner] =
    useState(true);

  // open iv modal
  const [openAddIV, setOpenAddIV] = useState(false);
  const handleOpenAddIV = () => setOpenAddIV(true);
  const handleCloseAddIV = () => setOpenAddIV(false);
  // open iv flow modal
  const [openIVFlowModal, setIVFlowModal] = useState(false);
  const handleOpenIVFlowModal = () => setIVFlowModal(true);
  const handleCloseIVFlowModal = () => setIVFlowModal(false);
  // prscription print
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [openLoader, setOpenLoader] = useState(false);
  const [drugAdmListLoadingSpinner, setDrugAdmListLoadingSpinner] =
    useState(true);
  const [
    specialInstructionLoadingSpinner,
    setSpecialInstructionLoadingSpinner,
  ] = useState(true);
  const [openIndentModal, setOpenIndenModal] = useState(false);
  const [openCasualtyModal, setOpenCasualtyModal] = useState(false);
  const [specialInstructionValue, setSpecialInstructionValue] = useState("");
  const [timeSlotsArray, setTimeSlotsArray] = useState([]);
  const [sections, setSections] = useState([]);
  const [medicationPrintDataArr, setMedicationPrintDataArr] = useState([]);
  const [openMedicationPrintModal, setOpenMedicationPrintModal] =
    useState(false);
  // Confirmation modal
  const [
    openConfirmationModalForSpecialInstuctions,
    setOpenConfirmationModalForSpecialInstuctions,
  ] = useState(false);
  const [patientInfoForCasualtyDrug, setPatientInfoForCasualtyDrug] =
    useState(null);

  // drug order
  const handleOpenDrugAdmOrderModal = () => setOpenDrugAdmOrderModal(true);
  const handleCloseDrugAdmOrderModal = () => setOpenDrugAdmOrderModal(false);

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleCloseForSpecialInstuctions = () => {
    if (openConfirmationModalForSpecialInstuctions) {
      setOpenConfirmationModalForSpecialInstuctions(false);
    }
  };

  // open or Close button filter
  const toggleDrawerOpen = () => {
    setIsOpenFilter((prevIsOpen) => !prevIsOpen);
  };

  // get PatientList
  const populatePatientList = () => {
    let Obj = {
      floorId: floorVal ? floorVal.id : 0,
      searchString: searchVal,
    };
    if (props.patientInfo === undefined) {
      drugAdminPatientList(Obj)
        .then((response) => response.data)
        .then((res) => {
          setSearchResult(res.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  // drug administartion order list

  const handleStatChange = (e) => {
    if (e.target.checked === true) {
      setValue("stat", true);
      setValue("sos", false);
    } else if (e.target.checked === false) {
      setValue("stat", false);
    }
  };

  const handleSOSChange = (e) => {
    if (e.target.checked === true) {
      setValue("sos", true);
      setValue("stat", false);
    } else if (e.target.checked === false) {
      setValue("sos", false);
    }
  };

  const populateAdministrationOrderListTable = () => {
    setDrugScheduleLoadingSpinner(true);

    if (rowData !== null) {
      getAdministrationChartDrugList(
        rowData?.AdmissionId !== undefined
          ? rowData.AdmissionId
          : rowData.addmisionId,
        STATValue,
        SOSValue,
        props?.menuId !== undefined ? props.menuId : location?.state?.menuId
      )
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res.statusCode === 200) {
            setUserActions(res.actions);
            setDrugListData(res.result);
            setDrugScheduleLoadingSpinner(false);
          }
        })
        .catch((error) => {
          setDrugScheduleLoadingSpinner(false);
        });
    }
  };
  // get special intructions
  const populateSpecialInstructions = () => {
    setSpecialInstructionLoadingSpinner(true);
    if (rowData?.AdmissionId) {
      getSpecialInstructionsList(rowData?.AdmissionId, instructionIsChecked)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            setSpecialInstructionLoadingSpinner(false);
            setSpecialInstructionData(res);
          }
        })
        .catch((error) => {
          setSpecialInstructionLoadingSpinner(false);
        });
    }
  };

  // get tiemSlots

  //administrator drug list
  const getDrugList = () => {
    setDrugAdmListLoadingSpinner(true);

    if (rowData !== null) {
      getDrugAdmsList(
        rowData?.AdmissionId !== undefined
          ? rowData.AdmissionId
          : rowData?.admissionId,
        page,
        rowsPerPage,
        props?.menuId !== undefined ? props.menuId : location?.state?.menuId,
        todaysDrugValue
      )
        .then((response) => response.data)
        .then((res) => {
          if (res.result.length > 0) {
            const updatedData = res.result.map((item, index) => ({
              ...item,
              RowNumber: index,
            }));

            setAdmDrugList(res);
            setDrugAdmListLoadingSpinner(false);
            setDataResult(updatedData);
            setCount(res.count);
            setOpenLoader(false);
          } else {
            setAdmDrugList({ result: [], actions: [] });
            setDrugAdmListLoadingSpinner(false);
            setDataResult([]);
            setCount();
            setOpenLoader(false);
          }
        })
        .catch((error) => {
          setDrugAdmListLoadingSpinner(false);
          setOpenLoader(false);
        });
    }
  };

  console.log("admDrugList", dataResult);

  //end
  // get selected patient info
  function handleSelectedRow(row) {
    setRowData(row);
    handleFromDate(row?.PrescriptionDate);
  }

  const handleFromDate = (PrescriptionDate) => {
    //
    !!PrescriptionDate ? parseISO(PrescriptionDate) : new Date();

    const originalDate = !!PrescriptionDate
      ? parseISO(PrescriptionDate)
      : new Date();

    const timezoneOffset = originalDate.getTimezoneOffset() * 60000;

    const adjustedDate = new Date(originalDate.getTime() + timezoneOffset);

    setValue("fromDate", adjustedDate.toISOString());
  };

  useEffect(() => {
    handleFromDate(props?.patientInfo?.PrescriptionDate);
  }, []);

  // end

  const AddSpecialInstruction = () => {
    if (instructionsValue !== null) {
      let instructionObj = {
        addedBy: token.userId,
        admissionId: rowData?.AdmissionId,
        instructionDescription:
          specialInstructionValue !== ""
            ? CapitalizeStatement(specialInstructionValue)
            : CapitalizeStatement(instructionsValue.label),
        instructionId: instructionsValue.id,
        isFixedInstruction: instructionBillChecked,
        menuId:
          props.menuId !== undefined ? props.menuId : location?.state?.menuId,
        privilege: privilege,
      };
      saveSpecialInstructions(instructionObj)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
          populateSpecialInstructions();
          handleCloseForSpecialInstuctions();
          setInstructionsValue(null);
          setSpecialInstructionValue("");
          setInstructionBillChecked(false);
          setValue("instructions", null);
          setOpenLoader(false);
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
          setOpenLoader(false);
        });
    } else {
      warningAlert("Please Select Instruction First");
    }
  };

  // call cancel drug api
  function addRecord() {
    setOpenConfirmationModalForSpecialInstuctions(false);
    setOpenLoader(true);
  }

  const handleClickToAllPrint = () => {
    let fDate;
    let tDate;
    let fromFormatedDate = fromDateValue;
    let toFormatedDate = toDateValue;
    if (fromFormatedDate) {
      let fromDateStr = new Date(fromFormatedDate)
        .toISOString()
        .replaceAll("/", "-")
        .slice(0, -14);
      fDate = fromDateStr;
    }
    if (toFormatedDate) {
      let fromDateStr = new Date(toFormatedDate)
        .toISOString()
        .replaceAll("/", "-")
        .slice(0, -14);
      tDate = fromDateStr;
    }
    let obj = {
      admissionId: rowData.AdmissionId || props?.addmisionId,
      fromDate: fDate,
      toDate: tDate,
    };
    fetchPrescriptionPrint(obj).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  //Medication Error Print
  const handleOpenMedicationPrintModal = (addmisionId) => {
    let tempObj = {
      fromDate: format(new Date(fromDateValue), "yyyy-MM-dd"),
      toDate: format(new Date(toDateValue), "yyyy-MM-dd"),
      admissionId: addmisionId,
    };
    setOpenLoader(true);
    fetchPrintMedicationError(tempObj)
      .then((response) => response.data)
      .then((res) => {
        setMedicationPrintDataArr(res.result);
        const dataArray = [];
        const dateArr = [];
        res.result.MedicationChartDetails?.length > 0 &&
          res.result.MedicationChartDetails.forEach((med) => {
            med.medicationChartDateDtoList.forEach((mcddl) => {
              if (dateArr.findIndex((d) => d === mcddl.date) === -1)
                dateArr.push(mcddl.date);
            });
          });

        dateArr.length > 0 &&
          dateArr.forEach((date) => {
            const timeArr = [];
            res.result.MedicationChartDetails.forEach((med) => {
              med.medicationChartDateDtoList.forEach((mcddl) => {
                if (date === mcddl.date)
                  mcddl.medicationChartTimeDtoList.forEach((time) => {
                    if (timeArr.findIndex((t) => t === time.timeSlot) === -1)
                      timeArr.push(time.timeSlot);
                  });
              });
            });
            dataArray.push({ date, timeSlots: timeArr });
          });
        if (dataResult?.length > 0) {
          setTimeSlotsArray(dataArray.flatMap((d) => d.timeSlots));
          setOpenMedicationPrintModal(true);
          setOpenLoader(false);
        }
      })
      .catch((error) => {
        setOpenLoader(false);
      });
  };

  useEffect(() => {
    //administrator drug list
    if (rowData !== null) {
      getDrugList();
    }
  }, [rowData, todaysDrugValue]);

  useEffect(() => {
    if (dataResult?.length > 0) {
    }
  }, [dataResult]);
  //
  useEffect(() => {
    // drug administartion order list

    populateAdministrationOrderListTable();
    //end

    //get special instructions for table
    populateSpecialInstructions();
    //end
  }, [rowData]);

  useEffect(() => {
    // patienInfo
    if (rowData?.PatientId) {
      getPatientInfoForLogFile(rowData?.PatientId)
        .then((response) => response.data)
        .then((res) => {
          setPatientInfo(res.result);
        });
    }
  }, [rowData]);

  // get dropdown instructions list
  useEffect(() => {
    if (rowData?.AdmissionId) {
      getInstructionsForDrug().then((response) =>
        setInstructionOptions(response.data.result)
      );
    }
  }, [rowData]);

  //get special instructions for table
  useEffect(() => {
    populateSpecialInstructions();
  }, [instructionIsChecked]);

  useEffect(() => {
    // drug administartion order list
    populateAdministrationOrderListTable();
  }, [STATValue, SOSValue]);

  useEffect(() => {
    // get  floor
    fetchFloor()
      .then((response) => response.data)
      .then((res) => {
        setFloorOptions(res.result);
      });
    // end
  }, []);

  useEffect(() => {
    // get patient list
    populatePatientList();
  }, [searchVal, floorVal]);

  useEffect(() => {
    if (token.floor !== null) {
      setValue("floor", {
        id: token.floorId,
        value: token.floor,
        label: token.floor,
      });
    }
  }, []);

  /// clinical care chart
  useEffect(() => {
    if (props.patientInfo !== undefined) {
      setRowData(props.patientInfo);
    }
    if (props.selectedPatient !== undefined) {
      setRowData(props.selectedPatient);
    }
  }, [props]);

  const splitArrayIntoChunks = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  useEffect(() => {
    const calculateSections = () => {
      const firstSectionSlots = timeSlotsArray.slice(0, 2);
      const remainingSlots = timeSlotsArray.slice(3);

      const printableWidth = printRefMedication.current
        ? printRefMedication.current.offsetWidth
        : 800;
      const slotWidth = 100;
      const otherSections = splitArrayIntoChunks(remainingSlots, 6);
      setSections([firstSectionSlots, ...otherSections]);
    };

    calculateSections();
    window.addEventListener("resize", calculateSections);

    return () => {
      window.removeEventListener("resize", calculateSections);
    };
  }, [timeSlotsArray]);

  return (
    <div className="mt-16 px-1 w-full">
      <h1 className="text-center text-black text-lg font-bold pb-1">
        Drug Administrator Chart
      </h1>

      <div className="lg:flex lg:space-x-1 w-full">
        {props.patientInfo === undefined && (
          <>
            {isOpenFilter && (
              <div className="lg:w-[30%] xl:w-[28%] 2xl:w-[20%]">
                <div className="md:grid-cols-3 lg:grid-cols-1 grid gap-2 lg:gap-0 lg:gap-y-2 w-full my-2 lg:my-0">
                  <div className="w-full">
                    <DropdownField
                      control={control}
                      name="floor"
                      label="Floor "
                      dataArray={floorOptions}
                      isClearable={true}
                      placeholder="Floor"
                    />
                  </div>
                  <FormControl className="col-span-2">
                    <OutlinedInput
                      size="small"
                      fullWidth
                      {...register("search")}
                      sx={{
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontSize: "14px",
                        },
                      }}
                      className="bg-white h-9 text-[14px]"
                      name="search"
                      placeholder="Search by Patient Name / Mr No."
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div className="">
                  <div className="w-full">
                    <h5 className="font-bold mb-1 text-base 2xl:text-lg text-gray-600">
                      Patient List
                    </h5>
                    {searchResult.length ? (
                      <>
                        <CommonDynamicTableNew
                          dataResult={searchResult}
                          handleSelectedRow={handleSelectedRow}
                          removeHeaders={["PatientId", "AdmissionId"]}
                          tableClass={
                            "rounded h-[300px] xl:min-h-[650px] 2xl:max-h-[720px]"
                          }
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {props.patientInfo === undefined && (
          <>
            {isOpenFilter === false ? (
              <div
                className={`${searchResult.length > 0 ? "lg:w-[100px]" : ""}`}
              >
                <>
                  <div className="pb-2">
                    <span className="font-bold ">Patient List</span>
                  </div>
                  {searchResult.length ? (
                    <>
                      <CommonDynamicTableNew
                        dataResult={searchResult}
                        handleSelectedRow={handleSelectedRow}
                        removeHeaders={["PatientId", "AdmissionId"]}
                        tableClass={
                          "rounded max-h-[650px] min-h-[650px] 2xl:max-h-[720px]"
                        }
                      />
                    </>
                  ) : null}
                </>
              </div>
            ) : null}
          </>
        )}
        {props.patientInfo === undefined && (
          <div className="text-center align-middle items-center invisible md:hidden lg:visible lg:flex justify-end">
            {isOpenFilter === true ? (
              <button
                className="h-10 w-[0.80rem] flex items-center justify-center rounded-t rounded-b bg-customBlue text-white"
                onClick={toggleDrawerOpen}
              >
                <ArrowLeftIcon />
              </button>
            ) : (
              <button
                className="h-10 w-[0.80rem] flex items-center justify-center rounded-t rounded-b bg-customBlue text-white"
                onClick={toggleDrawerOpen}
              >
                <Tooltip title="Open List">
                  <ArrowRightIcon />
                </Tooltip>
              </button>
            )}
          </div>
        )}

        <div
          className={
            props.patientInfo !== undefined
              ? "w-full "
              : " lg:border-l-2 w-full l md:mt-2 lg:mt-0"
          }
        >
          <div className="bg-gray-100 p-2 shadow-md rounded-md lg:ml-2 border w-full">
            <div className="grid grid-cols-2 lg:flex lg:justify-between text-gray-300  lg:mx-2  text-xs items-center gap-1 w-full py-1">
              <div className="flex items-center gap-2 w-10/12 lg:w-4/12">
                <h1 className="text-black font-semibold whitespace-nowrap">
                  MR No.
                </h1>
                <div className="flex ml-3 space-x-2  items-center">
                  <span className="text-black">:</span>
                  <span className="text-black font-normal">
                    {rowData?.MRNo}
                  </span>
                </div>
              </div>
              <div
                className={
                  props.patientInfo !== undefined
                    ? "flex items-center gap-2 w-11/12 lg:w-8/12 xl:border-l-2"
                    : "flex items-center gap-2 w-11/12 lg:w-8/12 xl:border-l-2  xl:pl-2"
                }
              >
                <h1 className="font-semibold whitespace-nowrap text-customBlue">
                  Patient Name
                </h1>
                <div className="flex space-x-2 font-semibold items-center text-customBlue">
                  <span className="">:</span>
                  <span className="whitespace-nowrap">
                    {rowData?.["Patient Name"] || rowData?.PatientName}
                  </span>
                </div>
              </div>
              <div
                className={
                  props.patientInfo?.PatientName !== undefined
                    ? "flex space-x-[30.5px] items-center gap-2 w-11/12 lg:w-6/12 xl:border-l-2 "
                    : "flex items-center gap-2 w-11/12 lg:w-4/12 xl:border-l-2 space-x-[31px] xl:space-x-0  xl:pl-2"
                }
              >
                <h1 className="text-black font-semibold whitespace-nowrap">
                  Age
                </h1>
                <div className="flex space-x-2 items-center">
                  <span className="text-black">:</span>
                  <span className="text-black font-normal whitespace-nowrap">
                    {patientInfo?.ageYear || rowData?.AgeYear}
                  </span>
                </div>
              </div>
              <div
                className={
                  props.patientInfo?.DoctorName !== undefined
                    ? "flex space-x-8 items-center w-10/12 lg:w-8/12 xl:border-l-2"
                    : "flex items-center w-10/12 lg:w-6/12 xl:border-l-2 xl:pl-2 space-x-[32px]"
                }
              >
                <h1 className="text-black font-semibold whitespace-nowrap">
                  Dr. Name
                </h1>
                <div className="flex space-x-2 items-center ml-1 ">
                  <span className="text-black">:</span>
                  <span className="text-black font-normal whitespace-nowrap">
                    {rowData?.["Doctor Name"] || rowData?.DoctorName}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 w-full ">
            <div className="flex justify-between space-x-3 w-full items-center py-2">
              <div>
                <h1 className="font-semibold text-base 2xl:text-lg text-gray-600 whitespace-nowrap">
                  Drug Adm Order List
                </h1>
              </div>
              <div className="flex space-x-2 items-center w-full justify-center">
                <div className="flex space-x-2 items-center w-full">
                  <div className="w-full">
                    <DatePickerFieldNew
                      control={control}
                      name="fromDate"
                      label="From Date"
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="w-full">
                    <DatePickerFieldNew
                      control={control}
                      name="todate"
                      label="To Date"
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>

                <>
                  {dataResult?.length > 0 ? (
                    <Tooltip title="Print Prescription">
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        className="cursor-pointer"
                        onClick={() => {
                          const formatDate = (dateValue) => {
                            if (!dateValue) return null;
                            return new Date(dateValue)
                              .toISOString()
                              .slice(0, -14);
                          };
                          const obj = {
                            admissionId:
                              rowData.AdmissionId || props?.addmisionId,
                            fromDate: formatDate(fromDateValue),
                            toDate: formatDate(toDateValue),
                          };
                          fetchPrescriptionPrint(obj).then(setUrlforPrint);
                          setOpenPrintModal(true);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </>
              </div>
              <div className=" flex items-center justify-end space-x-2">
                <Controller
                  control={control}
                  name="stat"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          {...field}
                          checked={STATValue}
                          onChange={(e) => handleStatChange(e)}
                        />
                      }
                      label={
                        <span className="text-sm  2xl:text-base whitespace-nowrap">
                          STAT Medicines
                        </span>
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="sos"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          {...field}
                          checked={SOSValue}
                          onChange={(e) => handleSOSChange(e)}
                        />
                      }
                      label={
                        <span className="text-sm  2xl:text-base whitespace-nowrap">
                          SOS Medicines
                        </span>
                      }
                    />
                  )}
                />
                <div>
                  <CommonButton
                    type="button"
                    label="Casualty Drugs"
                    className="border border-customBlue text-customBlue"
                    onClick={() => {
                      if (rowData?.AdmissionId !== undefined) {
                        setOpenLoader(true);
                        getCasualtyDrug(rowData?.AdmissionId)
                          .then((response) => response.data)
                          .then((res) => {
                            setPatientInfoForCasualtyDrug(res.result);
                            setOpenCasualtyModal(true);
                            setPrivilege("Casualty Drugs");
                            setOpenLoader(false);
                          })
                          .catch((error) => {
                            errorAlert(error?.response?.data?.message);
                            setOpenLoader(false);
                          });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            {drugListData?.length > 0 ? (
              <DrugAdministrationOrderListTable
                data={drugListData}
                actions={actions}
                selectedPatientInfo={rowData}
                getDrugList={getDrugList}
                STATValue={STATValue}
                SOSValue={SOSValue}
                populateTable={populateAdministrationOrderListTable}
                userActions={userActions}
                setPrivilege={setPrivilege}
                setOpenLoader={setOpenLoader}
                patientInfo={props}
                menuId={props.menuId || location?.state?.menuId}
                privilege={privilege}
              />
            ) : (
              <div className="flex justify-center my-4 font-semibold text-sm text-gray-600">
                {rowData !== null && drugScheduleloadingSpinner === true ? (
                  <LoadingSpinner />
                ) : (
                  <span>No Records Found...</span>
                )}
              </div>
            )}
          </div>
          <div className="pt-2">
            <Divider color="#1e3a8a" sx={{ height: 2 }} />
          </div>
          <div className=" px-2">
            <div className="flex space-x-2 justify-between w-full items-center mt-2">
              <h1 className="font-semibold text-base 2xl:text-lg text-gray-600 whitespace-nowrap">
                Special Instructions
              </h1>
              <div className="w-5/12 flex space-x-2 items-center">
                <div className="w-full">
                  <DropdownField
                    control={control}
                    name="instructions"
                    placeholder="Instructions"
                    dataArray={instructionOptions}
                    isClearable={true}
                    inputRef={{
                      ...register("instructions", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setInstructionsValue(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>
                {userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" ? (
                      <>
                        <div>
                          <CommonButton
                            type="button"
                            className="border border-customBlue text-customBlue"
                            label="+ Add"
                            onClick={() => {
                              if (rowData === null) {
                                warningAlert("Please Select Patient First");
                              } else if (instructionsValue === null) {
                                warningAlert("Please Select Instruction");
                              } else {
                                setPrivilege(obj.action);
                                setOpenConfirmationModalForSpecialInstuctions(
                                  true
                                );
                              }
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
              <Controller
                name="instructionIsClose"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox name="instructionIsClose" {...field} />}
                    label="Closed Instruction"
                  />
                )}
              />
            </div>
            {specialInstructionData.hasOwnProperty("result") &&
            specialInstructionData.result.length > 0 ? (
              <SpecialInstructionTable
                data={specialInstructionData}
                actions={actions}
                rowData={rowData}
                populateTable={populateSpecialInstructions}
                userActions={userActions}
                menuId={props.menuId || location?.state?.menuId}
                privilege={privilege}
                setPrivilege={setPrivilege}
                setOpenLoader={setOpenLoader}
                instructionValue={instructionValue}
                patientInfo={props}
              />
            ) : (
              <div className="flex justify-center my-4 font-semibold text-sm text-gray-600">
                {specialInstructionLoadingSpinner === true &&
                rowData !== null ? (
                  <LoadingSpinner />
                ) : (
                  <span>No Records Found...</span>
                )}
              </div>
            )}
          </div>
          <div className="pt-2">
            <Divider color="#1e3a8a" sx={{ height: 2 }} />
          </div>
          <div className="px-2 my-5">
            <>
              <div className="xl:flex xl:justify-between items-center my-1 whitespace-nowrap space-x-2">
                <div className="flex space-x-2 items-center">
                  <h1 className="text-base 2xl:text-lg text-gray-600 font-semibold">
                    Administrator Drug List
                  </h1>
                  <CheckBoxField
                    name="todaysDrug"
                    control={control}
                    label="Todays Drug"
                  />
                </div>
                {rowData !== null && (
                  <div>
                    <CommonButton
                      type="button"
                      label="Create Indent"
                      className="border border-customBlue text-customBlue text-xs px-1 h-8"
                      onClick={() => {
                        setOpenIndenModal(true);
                      }}
                    />
                  </div>
                )}
                <div>
                  <CommonButton
                    type="button"
                    label="Change Sequence"
                    className="border border-customBlue text-customBlue text-xs px-1 h-8"
                    onClick={() => {
                      let modifiedArr = tempTableData.map((list, index) => {
                        return { ...list, RowNumber: index };
                      });

                      const indexChanged = dataResult.some((item, i) => {
                        return (
                          modifiedArr[i]?.["Item Name"] !== item["Item Name"]
                        );
                      });

                      let tempArr = [];
                      for (let obj of modifiedArr) {
                        let tempObj = {
                          drugAdminId: obj.DrugAdminHeaderId,
                          rowNumber: obj.RowNumber,
                        };
                        tempArr.push(tempObj);
                      }
                      if (indexChanged) {
                        setOpenLoader(true);
                        changeDrugSequence(tempArr)
                          .then((response) => response.data)
                          .then((res) => {
                            if (res.statusCode === 200) {
                              getDrugList();
                              successAlert(res.message);
                              setOpenLoader(false);
                            }
                          })
                          .catch((error) => {
                            errorAlert(error.message);
                            setOpenLoader(false);
                          });
                      } else {
                        warningAlert("Their is no changes in sequence ");
                      }
                      console.log("indexChanged", indexChanged);
                    }}
                  />
                </div>
                <div className="flex items-center space-x-3 text-xs py-2 xl:py-0">
                  <h1 className="flex items-center space-x-2">
                    <span
                      className="h-5 w-5  rounded"
                      style={{ backgroundColor: "#007EA9" }}
                    ></span>
                    <span>Outside Medication</span>
                  </h1>
                  <h1 className="flex items-center space-x-2">
                    <span
                      className="h-5 w-5 rounded"
                      style={{ backgroundColor: "#008000" }}
                    ></span>
                    <span>Once in a week drug</span>
                  </h1>
                  <h1 className="flex items-center space-x-2">
                    <span
                      className="h-5 w-5  rounded"
                      style={{ backgroundColor: "#0000C0" }}
                    ></span>
                    <span>Alternative day drug</span>
                  </h1>
                  <h1 className="flex items-center space-x-2">
                    <span
                      className="h-5 w-5  rounded"
                      style={{ backgroundColor: "#FF00FF" }}
                    ></span>
                    <span>Per hour drug</span>
                  </h1>
                  <h1 className="flex items-center space-x-2">
                    <span className="h-5 w-5  rounded bg-[#DC143C]"></span>
                    <span>Stopped drug</span>
                  </h1>
                  <h1 className="flex items-center space-x-2">
                    <span className="h-5 w-5  rounded bg-orange-600"></span>
                    <span>Hold drug</span>
                  </h1>
                </div>
              </div>

              {admDrugList.result?.length > 0 ? (
                <AdministrationDrugListTable
                  data={admDrugList}
                  setData={setAdmDrugList}
                  dataResult={dataResult}
                  setDataResult={setDataResult}
                  todaysDrugValue={todaysDrugValue}
                  actions={drugAction}
                  setSelectedAdmDrugRowList={setSelectedAdmDrugRowList}
                  selectedAdmDrugRowList={selectedAdmDrugRowList}
                  selectedPatientInfo={rowData}
                  getDrugList={getDrugList}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  tableApiFunc={getDrugAdmsList}
                  rowData={rowData}
                  count={count}
                  userActions={userActions}
                  menuId={props.menuId || location?.state?.menuId}
                  privilege={privilege}
                  setPrivilege={setPrivilege}
                  setOpenLoader={setOpenLoader}
                  loadingSpinner={drugScheduleloadingSpinner}
                  patientInfo={props}
                  menuIdnew={props.menuId}
                  setTempTableData={setTempTableData}
                  tempTableData={tempTableData}
                />
              ) : (
                <div className="flex justify-center my-28 2xl:my-40 font-semibold text-sm text-gray-600">
                  {rowData !== null && drugAdmListLoadingSpinner === true ? (
                    <LoadingSpinner />
                  ) : (
                    <span>No Records Found...</span>
                  )}
                </div>
              )}
            </>
          </div>
          <div className="flex space-x-3 items-center justify-end w-full mb-2">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Drug" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label=" Order Drug"
                      onClick={() => {
                        if (rowData?.AdmissionId) {
                          setPrivilege(obj.action);
                          handleOpenDrugAdmOrderModal();
                        } else {
                          setPrivilege("");
                          warningAlert("Please Select Patient First");
                        }
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}

            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "IV Fluids" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="+ IV Fluids"
                      onClick={() => {
                        if (rowData?.AdmissionId) {
                          setPrivilege(obj.action);
                          handleOpenAddIV();
                        } else {
                          setPrivilege("");
                          warningAlert("Please Select Patient First");
                        }
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}

            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "IV Flow" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="bg-customBlue text-white"
                      label="IV Flow"
                      onClick={() => {
                        if (rowData?.AdmissionId) {
                          setPrivilege(obj.action);
                          handleOpenIVFlowModal();
                        } else {
                          setPrivilege("");
                          warningAlert("Please Select Patient First");
                        }
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}

            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Log File" ? (
                  <>
                    <Tooltip title="Print Log File">
                      <button type="button" className="cursor-pointer ">
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563" }}
                          onClick={() => {
                            fetchLogFilePrint(rowData.AdmissionId).then(
                              setUrlforPrint
                            );
                            setOpenPrintModal(true);
                          }}
                        />
                      </button>
                    </Tooltip>
                  </>
                ) : null}
              </>
            ))}
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Print" ? (
                  <>
                    <Tooltip title="Print Medication Chart">
                      <button type="button" className="cursor-pointer ">
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563" }}
                          onClick={() => {
                            handleOpenMedicationPrintModal(
                              rowData?.AdmissionId
                            );
                          }}
                        />
                      </button>
                    </Tooltip>
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </div>
      {openLoader && <CommonBackdrop openBackdrop={openLoader} />}
      {openIndentModal && (
        <CreateNewIndentDrugAdmChartModal
          open={openIndentModal}
          handleClose={() => setOpenIndenModal(false)}
          data={admDrugList}
          menuId={props.menuId || location?.state?.menuId}
          setOpenLoader={setOpenLoader}
          patientDetails={rowData}
          privilege={"Create"}
        />
      )}

      {openDrugAdmOrderModal ? (
        <DrugAdministratorOrderModal
          open={openDrugAdmOrderModal}
          setOpen={setOpenDrugAdmOrderModal}
          handleOpen={handleOpenDrugAdmOrderModal}
          handleClose={handleCloseDrugAdmOrderModal}
          populateAdministrationOrderListTable={
            populateAdministrationOrderListTable
          }
          patientInfo={props.patientInfo}
          rowData={rowData}
          userActions={userActions}
          menuId={props.menuId || location?.state?.menuId}
          privilege={privilege}
          setOpenLoader={setOpenLoader}
          patientInfoClinical={props}
        />
      ) : (
        ""
      )}

      {openAddIV ? (
        <AddIVModal
          open={openAddIV}
          setOpen={setOpenAddIV}
          handleOpen={handleOpenAddIV}
          handleClose={handleCloseAddIV}
          rowData={rowData}
          menuId={props.menuId || location?.state?.menuId}
          privilege={privilege}
          setOpenLoader={setOpenLoader}
          patientInfo={props}
        />
      ) : (
        ""
      )}
      {openIVFlowModal ? (
        <IVFlowModal
          open={openIVFlowModal}
          setOpen={setIVFlowModal}
          handleOpen={handleOpenIVFlowModal}
          handleClose={handleCloseIVFlowModal}
          rowData={rowData}
          menuId={props.menuId || location?.state?.menuId}
          privilege={privilege}
          patientInfo={props}
          userActions={userActions}
          setOpenLoader={setOpenLoader}
          setInstructionOptions={setInstructionOptions}
          setInstructionsValue={setInstructionsValue}
        />
      ) : (
        ""
      )}
      {/* specialInstruction */}
      {openConfirmationModalForSpecialInstuctions && (
        <ConfirmationModalForSpecialInstructions
          confirmationOpen={openConfirmationModalForSpecialInstuctions}
          confirmationHandleClose={handleCloseForSpecialInstuctions}
          confirmationSubmitFunc={addRecord}
          instructionsValue={instructionsValue}
          rowData={rowData}
          populateSpecialInstructions={populateSpecialInstructions}
          AddSpecialInstruction={AddSpecialInstruction}
          setInstructionBillChecked={setInstructionBillChecked}
          setSpecialInstructionValue={setSpecialInstructionValue}
          specialInstructionValue={specialInstructionValue}
        />
      )}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleClickToAllPrint}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openMedicationPrintModal && timeSlotsArray.length > 0 && (
        <div className="hidden">
          <MedicationChartPrint
            dataResult={medicationPrintDataArr}
            sections={sections}
            printRef={printRefMedication}
            setOpenLoader={setOpenLoader}
          />
        </div>
      )}
      {openCasualtyModal && patientInfoForCasualtyDrug !== null && (
        <CasualtyDrugModal
          open={openCasualtyModal}
          handleClose={() => setOpenCasualtyModal(false)}
          rowData={rowData}
          menuId={props.menuId || location?.state?.menuId}
          privilege={privilege}
          patientInfo={props}
          userActions={userActions}
          setOpenLoader={setOpenLoader}
          setPrivilege={setPrivilege}
          setPatientInfoForCasualtyDrug={setPatientInfoForCasualtyDrug}
          patientInfoForCasualtyDrug={patientInfoForCasualtyDrug}
        />
      )}
    </div>
  );
}

export default DrugAdmisitrationChart;
