//import from the react library.
import * as React from "react";

//imports from the material ui (mui) library.
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//useKeyPress hook to detect the happening of the below key press events.
//arrowUpPressed , arrowDownPressed , arrowRightPressed,
//arrowLeftPressed , enterPressed
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  React.useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);
  return keyPressed;
};

export default function GenericDrugList(props) {
  //state variable to store the rowIndex ; that is to be highlighted.
  const [rowIndex, setRowIndex] = React.useState(0);

  const [selected, setSelected] = React.useState([]);

  //state variable to store the row object upon the press of arrow keys. state variable to store the row object upon clicking.
  const [selectedObj, setSelectedObj] = React.useState({});

  const { dataResult } = props;

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "IsTemplate",
    "IsCultureTest",
    "LabWorkOrderTestId",
  ]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClickRow = (index, row) => {
    setRowIndex(index);
  };

  const handleClick = (row, id) => {
    setSelectedObj(row);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    newSelected = newSelected.concat(id);

    if (selectedIndex === 0) {
      newSelected = [];
      setSelected(null);
    }
    setSelected(newSelected);
  };

  //key press up down
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const enterPressed = useKeyPress("Enter");

  React.useEffect(() => {
    setSelectedObj(dataResult[0]);
  }, []);

  React.useEffect(() => {
    if (enterPressed === true) {
      if (selectedObj !== null) {
        let fieldToFocus = document.getElementById(
          `BatchNo${props.indexToReplace}`
        );
        if (fieldToFocus) {
          fieldToFocus.focus();
        }
        props.setSelectedGenericDrug(selectedObj);
        props.setDataResult([]);
        props.setDrugsToDisplay([]);
        props.setSearchedDrug("");
      }
    }
  }, [enterPressed, selectedObj]);

  React.useEffect(() => {
    if (arrowUpPressed) {
      let rIndex = rowIndex;

      if (rowIndex !== 0) {
        rIndex = rIndex - 1;
        setSelectedObj(dataResult[rIndex]);
        setRowIndex(rIndex);
      }
    }
  }, [arrowUpPressed]);

  React.useEffect(() => {
    if (arrowDownPressed) {
      let rIndex = rowIndex;

      if (rowIndex !== dataResult.length - 1) {
        rIndex = rIndex + 1;
        setSelectedObj(dataResult[rIndex]);
        setRowIndex(rIndex);
      }
    }
  }, [arrowDownPressed]);

  //table start
  return (
    <div className="w-auto grid">
      <Box sx={{ width: "100%" }}>
        <Paper
        //   sx={{ width: props?.from === "alternateDrugModal" ? "40%" : "100%" }}
        >
          {/* pagination */}

          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded h-56 2xl:h-72 overflow-scroll"
          >
            <Table size="small" component={Paper}>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  {headers.map((header, index) => (
                    <TableCell className="whitespace-nowrap" key={index}>
                      <span className="text-gray-600 font-bold">{header}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {dataResult.length > 0
                  ? dataResult.map((row, index) => {
                      {
                        const isItemSelected = isSelected(row.Id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              handleClick(row, row.Id);
                              handleClickRow(index, row);
                            }}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                setSelectedObj(row);
                              }
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.Id}
                            selected={isItemSelected}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            style={{
                              backgroundColor:
                                rowIndex === index ? "#FFC44B" : "",
                            }}
                          >
                            {headers &&
                              headers.map((header, i) => (
                                <TableCell
                                  className="whitespace-nowrap"
                                  key={row.id}
                                >
                                  {row[header]}
                                </TableCell>
                              ))}
                          </TableRow>
                        );
                      }
                    })
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}

