import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import InputField from "../../common/components/FormFields/InputField";
import { successAlert } from "../../common/components/Toasts/Toasts";
//import { RemoveIcon } from "../../../../Common Components/assets/commonassets/CommonAssets";
import {
  CloseIcon
} from "../../assets/icons/CustomIcons";
import CommonButton from "../../common/components/Buttons/CommonButton";
import { errorAlert } from "../../common/components/Toasts/CustomToasts";
import {
  getImageUrl,
  newMenuOrSubMenu,
} from "../Services/privilegetofunctionalityServices/PrivilegeToFunctionalityServices";

//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  overflowY: "scroll",
  boxShadow: 20,
  p: 2,
};

// Dynamic Actions
const userActions = [
  {
    id: 0,
    action: "Add",
    isAction: false,
  },
  {
    id: 1,
    action: "Update",
    isAction: false,
  },
];
export default function AddSubMenuModal(props) {
  /*************** useform schema and destrecutring *****************/
  const schema = yup.object().shape({
    menuName: yup
      .string()
      .required("Required")
      .matches(
        /^[A-Za-z0-9\s]+$/,
        "Only alphabets are allowed for this field "
      ),
    menuRoute: yup
      .string()
      .required("Required")
      .matches(
        /^(?=.*[a-zA-Z0-9.!@#&*\-\u0080-\u052F])[\/a-zA-Z0-9\s.!@#&*',\-\u0080-\u052F]*$/,
        "Only alphabets are allowed for this field "
      ),
  });

  const defaultValues = {
    submenu: "",
    isDrawerMenu: true,
    isFavouriteIcon: false,
    menuRoute: null,
    file: null,
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  /***************state variables *****************/
  const [openPost, setOpenPost] = React.useState(false);
  const [finalData, setFinalData] = React.useState({});
  const [openPut, setOpenPut] = React.useState(false);
  const [selectedImages, setSelectedImages] = React.useState("");


  let isDrawerMenuVal = watch("isDrawerMenu");
  let isFavouriteIconVal = watch("isFavouriteIcon");


  /*************** useEffects *****************/
  React.useEffect(() => {
    if (props?.edit === true) {
      setValue("menuName", props?.nodeData?.label);
      setValue("menuRoute", props?.nodeData?.key);
      setValue("isFavouriteIcon", props?.nodeData?.isFavourite);
    }
  }, [props?.edit, props?.nodeData]);
  /*************** functions *****************/
  const handleClosePost = () => {
    setOpenPost(false);
  };

  const handleClosePut = () => {
    setOpenPut(false);
  };

  const handleChange = (e) => {
    let file = e?.target?.files[0];
    if (
      e?.target?.files[0]?.type === "image/png" ||
      e?.target?.files[0]?.type === "image/svg+xml"
    ) {
      if (file) {
        let reader = new FileReader();
        reader.onloadend = () => {
          let imgObject = {
            fileName: file.name,
            filePath: URL.createObjectURL(file),
            base64: reader.result.split(",")[1],
            showImage: reader.result,
          };
          setSelectedImages(imgObject);
        };
        reader.readAsDataURL(file);
      }
    } else {
      errorAlert("Please Select Image File  ");
    }
  };

  function arrayBufferToBase64(buffer) {
    var binary = "";

    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  useEffect(() => {
    if (
      props?.nodeData?.iconPath !== undefined &&
      props?.nodeData?.iconPath !== ""
    ) {
      let imagePath = props?.nodeData?.iconPath;
      getImageUrl(imagePath).then((response) => {
        let newImage =
          "data:image/jpg;base64," + arrayBufferToBase64(response.data);
        let imageID = document.getElementById("image");
        if (imageID) {
          imageID.src = newImage;
        }
        setSelectedImages(newImage);
      });
    }
  }, [props?.nodeData?.iconPath]);

  const onSubmitDataHandler = (data) => {
    if (props?.edit === true) {
      let postObj = {
        iconBase64: selectedImages?.base64,
        iconName: selectedImages?.fileName,
        id: props.nodeData.menuId ? props?.nodeData?.menuId : null,
        isDrawerMenu: isDrawerMenuVal,
        isMainMenu: props?.from === "mainMenu" ? true : false,
        level: props?.from === "mainMenu" ? 0 : props?.nodeData?.level + 1,
        menuId: "",
        // menuId: props?.nodeData?.menuId ? props?.nodeData?.menuId : null,
        menuName: data?.menuName,
        privilege: "",
        route: data?.menuRoute,
        underMenuId: props?.from === "mainMenu" ? 0 : props?.nodeData?.menuId,
      };
      setFinalData(postObj);
      setOpenPut(true);
    } else if (props?.edit === false) {
      let postObj = {
        iconBase64: selectedImages?.base64,
        iconName: selectedImages?.fileName,
        id: null,
        isDrawerMenu: isDrawerMenuVal,
        isFavIcon: isFavouriteIconVal,
        isMainMenu: props?.from === "mainMenu" ? true : false,
        level: props?.from === "mainMenu" ? 0 : props?.nodeData?.level + 1,
        menuId: "",
        menuName: data?.menuName,
        privilege: "",
        routes: data?.menuRoute,
        underMenuId: props?.from === "mainMenu" ? 0 : props?.nodeData?.menuId,
      };
      setFinalData(postObj);
      setOpenPost(true);
    }
  };

  //event listener function for the Add button on the modal form
  function addRecord() {
    //close the confirmation modal
    setOpenPost(false);

    // props.setOpenBackdrop(true);
    postMenuSubMenu(finalData);
  }

  function postMenuSubMenu(finalData) {
    newMenuOrSubMenu(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props?.fetchAllUserMenu();
        handleClosePost();
        props.handleClose();
        setOpenPost(false);
      });
  }

  //event listener function for the Update button on the Confirmation modal
  function updateRecord() {
    newMenuOrSubMenu(finalData)
      .then((response) => response.data)
      .then((res) => {
        props.handleClose();
        successAlert(res.message);
        props?.fetchAllUserMenu();
        handleClosePut();
        setOpenPut(false);
      });
  }

  return (
    <>
      {/* Model and table name start */}
      <div className="w-full grid justify-center items-center rounded lg:px-0 mt-4 p-2">
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props.handleClose();
                  props?.setEdit(false);
                  setSelectedImages(" ")
                  reset(defaultValues);
                }}
              />
            </div>
            <div className="row">
              <fieldset className="border border-gray-300 text-left lg:mx-auto lg:px-4 md:ml-0 md:mr-0 py-2 rounded lg:mt-6 lg:m-2 ">
                <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                  Add New System{" "}
                  {Number(props.nodeData?.level) === 0 ? "Main" : "Sub"} Menu
                </legend>
                <form
                  onSubmit={handleSubmit(onSubmitDataHandler)}
                  className="grid grid-cols-2 md:grid-cols-1 gap-2 md:px-2     "
                >
                  <div className="flex gap-2">
                    <fieldset onChange={(e) => {}}>
                      <CheckBoxField
                        sx={{ padding: 0 }}
                        defaultValue={false}
                        control={control}
                        name="isDrawerMenu"
                        label={"Drawer Menu"}
                      />
                    </fieldset>
                    {Number(props.nodeData?.level) > 0 ? (
                      <fieldset onChange={(e) => {}}>
                        <CheckBoxField
                          sx={{ padding: 0 }}
                          control={control}
                          name="isFavouriteIcon"
                          label={"Favourite Icon"}
                        />
                      </fieldset>
                    ) : null}
                    {isFavouriteIconVal ? (
                      <div className="w-full text-start  ">
                        <div className="flex gap-6 h-50">
                          <div>
                            <div className="border  p-1 w-56 rounded min-w-fit">
                              <fieldset
                              //disabled={selectedImages.length < 5 ? false : true}
                              >
                                <input
                                  type="file"
                                  className="text-sm text-red file:rounded file:py-1 file:border-none file:text-normal file:font-medium file:bg-customBlue file:text-white hover:file:cursor-pointer "
                                  name="file"
                                  {...register("isFavoiriteIcon")}
                                  accept="image/*"
                                  disabled={selectedImages !== ""}
                                  onChange={(e) => {
                                    if (e?.target?.checked === false) {
                                      setSelectedImages("");
                                    }
                                    handleChange(e);
                                  }}
                                  error={errors?.file}
                                />
                              </fieldset>
                            </div>
                            {/***   {selectedImages !== "" ? (
                              <p className="text-xs text-customRed">
                             You Can Upload Max 1 Images
                              </p>
                             ) : null}*/}
                          </div>
                          <div className="px-20 items-center">
                            {selectedImages !== "" ? (
                              <div className="border border-gray-2 p-1.5 h-12 w-12 cursor-pointer rounded relative">
                                <div
                                  className="-right-2 -top-2  absolute"
                                  onClick={() => {
                                    setSelectedImages("");
                                  }}
                                >
                                  {" "}
                                  <CloseIcon
                                    type="button"
                                    onClick={() => {
                                      setSelectedImages("");
                                    }}
                                    sx={{ height: "50px" }}
                                  />
                                </div>

                                <img
                                  src={selectedImages?.showImage}
                                  height={80}
                                  width={80}
                                  id={"image"}
                                  max-width={"100%"}
                                  max-height={"100%"}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="py-2 flex    md:grid-cols-2 lg:grid-cols-4 gap-3 items-center">
                    <div className="  w-full  ">
                      <InputField
                        name="menuName"
                        variant="outlined"
                        label={
                          Number(props.nodeData?.level) === 0
                            ? "Menu Name *"
                            : "Sub Menu Name *"
                        }
                        error={errors.menuName}
                        control={control}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </div>
                    <div className="  w-full  ">
                      <InputField
                        name="menuRoute"
                        variant="outlined"
                        label={
                          Number(props.nodeData?.level) === 0
                            ? "Menu Route *"
                            : "Sub Menu Route *"
                        }
                        error={errors.menuRoute}
                      disabled={props?.edit}
                        control={control}
                        // inputProps={{ style: { textTransform: "lowercase" } }}
                      />
                      {/**** <TextField
                        error={errors.menuRoute}
                        variant="outlined"
                        name="menuRoute"
                        label={
                          Number(props.nodeData?.level) === 0
                            ? "Menu Route *"
                            : "Sub Menu Route *"
                        }
                      />*/}
                    </div>

                    {Number(props.nodeData?.level) === 0 ? (
                      <div className="w-full text-start  ">
                        <div className="flex gap-6 h-50">
                          <div>
                            <div className="border  p-1 w-56 rounded min-w-fit">
                              <fieldset
                              //disabled={selectedImages.length < 5 ? false : true}
                              >
                                <input
                                  type="file"
                                  className="text-sm text-red file:rounded file:py-1 file:border-none file:text-normal file:font-medium file:bg-customBlue file:text-white hover:file:cursor-pointer "
                                  name="file"
                                  {...register("file")}
                                  accept="image/*"
                                  disabled={selectedImages !== ""}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  error={errors?.file}
                                />
                              </fieldset>
                            </div>
                            {/*****  {selectedImages !== "" ? (
                              <p className="text-xs text-customRed">
                                You Can Upload Max 1 Images
                              </p>
                            ) : null}*/}
                          </div>
                          <div className="px-20 items-center">
                            {selectedImages !== "" ? (
                              <div className="border border-gray-2 p-1.5 h-12 w-12 cursor-pointer rounded relative">
                                <div
                                  className="-right-2 -top-2  absolute"
                                  onClick={() => {
                                    setSelectedImages("");
                                  }}
                                >
                                  {" "}
                                  <CloseIcon
                                    type="button"
                                    onClick={() => {
                                      setSelectedImages("");
                                    }}
                                  />
                                </div>

                                <img
                                  src={selectedImages?.showImage}
                                  height={80}
                                  width={80}
                                  id={"image"}
                                  max-width={"100%"}
                                  max-height={"100%"}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex gap-4 justify-end py-2">
                    {userActions &&
                      userActions.map((actions) => {
                        if (
                          actions.action === "Update" &&
                          actions.isAction === false &&
                          props?.edit === true
                        ) {
                          return (
                            <>
                              <CommonButton
                                type="button"
                                label="Cancel"
                                onClick={() => {
                                  props?.handleClose();
                                }}
                                className="resetButton border border-customRed text-customRed"
                              />
                              <CommonButton
                                label="Update"
                                type="submit"
                                className="saveButton bg-customGreen text-white"
                              />
                            </>
                          );
                        } else if (
                          actions.action === "Add" &&
                          actions.isAction === false &&
                          props?.edit === false
                        ) {
                          return (
                            <>
                              <CommonButton
                                type="button"
                                label="Reset"
                                onClick={() => {
                                  reset(defaultValues);
                                  setSelectedImages(" ");
                                }}
                                className="resetButton border border-customRed text-customRed"
                              />
                              <CommonButton
                                label="Save"
                                type="submit"
                                className="saveButton bg-customGreen text-white"
                                onClick={() => {
                                  if (
                                    selectedImages !== "" &&
                                    selectedImages > 0
                                  ) {
                                    errorAlert("hhhhhhhhi");
                                  }
                                }}
                              />
                            </>
                          );
                        }
                      })}
                  </div>
                </form>
              </fieldset>
              <CommonBackDrop openBackdrop={props.openBackdrop} />
            </div>
          </Box>
        </Modal>

        <ConfirmationModal
          confirmationOpen={openPut}
          confirmationHandleClose={handleClosePut}
          confirmationSubmitFunc={updateRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to update this record ?"
          confirmationButtonMsg="Update"
        />

        {/* Confirmation modal for POST request */}
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={addRecord}
          confirmationLabel="Confirmation"
          confirmationMsg="Are You Sure Want To Create New Menu ?"
          confirmationButtonMsg="Add"
        />
      </div>
    </>
  );
}
