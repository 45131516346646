import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import { ForceCloseIcon } from "../../../assets/CommonAssets";
// import { getOtherServices } from "./Services";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";


const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "40%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "visible",
  p: 1,
};

export default function OtherServicesModal(props) {
  const [invs, setInvs] = React.useState([]);
  const {
    open,
    close,
    otherServices,
    setOtherServices,
    resetServices,
    departmentId,
  } = props;
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const [services, setServices] = React.useState([]);
  const [addedServices, setAddedServices] = React.useState([]);

  const defaultValues = {
    serahcOtherServices: null,
  };

  const { watch, control, setValue } = useForm({
    mode: "onchange",
    defaultValues: defaultValues,
  });

  const handleSearchServices = (search) => {
    // getOtherServices(search, loggedUser?.loginUnitId, departmentId)
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setServices(res?.result);
    //   })
    //   .catch((err) => err);
  };

  let serviceWatch = watch("searchOtherServices");

  React.useEffect(() => {
    if (serviceWatch !== null) {
      handleAddService();
    }
  }, [serviceWatch]);

  const handleAddService = () => {
    let filtered = addedServices.filter(
      (service) => service?.label === serviceWatch?.label
    );
    if (serviceWatch !== null && serviceWatch!== undefined && filtered?.length > 0) {
      errorAlert("Service already exists .");
      setValue("searchOtherServices", null);
    } else if(serviceWatch !== null &&serviceWatch!== undefined ){
      let dataArray = [...addedServices];
      let serviceObject = serviceWatch;
      serviceObject.Quantity = 1;
      dataArray.push(serviceObject);
      setAddedServices(dataArray);
      setValue("searchOtherServices", null);
    }
  };

  const handleAddServices = () => {
    setOtherServices(addedServices);
    close();
  };

  React.useEffect(() => {
    setAddedServices(otherServices);
  }, [resetServices]);

  return (
    <div>
      <div
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleAddService();
      //   }
      // }}
      >
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div>
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Other Services
                </div>
              </div>
              <button
                onClick={() => {
                  close();
                }}
                type="button"
              >
                <ForceCloseIcon />
              </button>
            </div>
            <div className="my-2">
              <SearchDropdown
                control={control}
                name={"searchOtherServices"}
                handleInputChange={handleSearchServices}
                dataArray={services}
                placeholder={"Other Services"}
                searchIcon={true}
                isClearable={true}
              />
            </div>
            <div className="mt-2 ">
              {addedServices?.length > 0
                ? addedServices.map((service, serviceIndex) => {
                    return (
                      <div className="flex border border-gray-200 px-2 py-1">
                        <div className="w-[80%] truncate">{service?.label}</div>
                        <div className="">{service?.Quantity}</div>
                      </div>
                    );
                  })
                : ""}
            </div>

            <div className="flex justify-end my-2 px-2">
              <CommonButton
                label={"Add"}
                type={"button"}
                className={"px-3 bg-[#368505] text-white h-9 rounded"}
                onClick={() => {
                  handleAddServices();
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
