export const GeneratePrintForLIMS = (contentRef, isA5 = false) => {
    const clonedContent = contentRef.current.cloneNode(true);
  
    // Determine page size based on the isA5 prop
    const pageSize = isA5 ? "5.83in 8.27in" : "8.5in 11in"; // A5 vs A4
  
    const printStyles = `
      body {
        font-family: Arial, sans-serif;
      }
  
      @media screen {
        #pdfContent {
          display: none !important;
        }
      }
  
      @media print {
        @page {
          size: ${pageSize};
        }
  
        /* Hide all content except for the content to be printed */
        body * {
          visibility: hidden;
          overflow: hidden;
          height: 0;
        }
  
        #pdfContent, #pdfContent * {
          visibility: visible;
          height: auto;
          overflow: visible;
        }
  
        /* Ensure list items and bullets are visible without borders */
        #pdfContent ul, #pdfContent ol {
          margin: 0;
          padding: 0;
          list-style-position: inside; /* Ensure bullets are inside the list item */
        }
  
        #pdfContent li {
          visibility: visible; /* Ensure list items are visible */
          list-style-type: disc; /* Default bullet style */
          margin-left: 1.5em; /* Add margin for proper bullet placement */
          border: none; /* Ensure no border around list items */
        }
  
        /* Optional: Apply borders to the list container (ul or ol) if needed */
        /* #pdfContent ul, #pdfContent ol {
          border: 1px solid black;
        } */
  
        /* Ensure table borders are visible */
        #pdfContent table {
          width: 100%; /* Optional: Make the table width 100% for full page width */
          border-collapse: collapse; /* Collapse borders to make them appear continuous */
        }
  
        #pdfContent th, #pdfContent td {
          border: 1px solid black; /* Apply border to table header and data cells */
          padding: 8px; /* Optional: Add padding inside table cells */
          text-align: left; /* Optional: Align text to the left in table cells */
        }
  
        #pdfContent th {
          background-color: #f2f2f2; /* Optional: Add background color to header */
        }
      }
    `;
  
    // Create a new style element for print styles
    const style = document.createElement("style");
    style.innerHTML = printStyles;
  
    // Set an ID for the cloned content div
    clonedContent.id = "pdfContent";
  
    // Append the style element and cloned content to the document body
    document.body.appendChild(style);
    document.body.appendChild(clonedContent);
  
    // Print the content
    window.print();
  
    // Cleanup: Remove the added style element and cloned content
    document.body.removeChild(style);
    document.body.removeChild(clonedContent);
  };