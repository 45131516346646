import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import {
  // AddIcon,
  ForceCloseIcon
} from "../../../assets/CommonAssets";

// import { getOtherServices, saveAdvice } from "./Services";
import { TextareaAutosize } from "@mui/material";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import InputField from "../../../common/components/FormFields/InputField";
import { errorAlert, successAlert } from "../../../common/components/Toasts/CustomToasts";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "visible",
  p: 1,
};

export default function CreateTemplateModal(props) {
  const { open, close, templates, setTemplates } = props;
  const [templateData, setTemplateData] = React.useState([]);

  const defaultValues = {
    templateName: "",
    advice: "",
  };

  const { watch, setValue, control, register } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  let template = watch("templateName");

  const handleAddTemplate = () => {
    if (template !== "" && templateData?.length === 0) {
      let dataArray = [...templateData];
      let templateObject = {
        Template: template,
        Advices: [],
        isChecked: false,
      };
      dataArray.push(templateObject);
      setTemplateData(dataArray);
    }
  };

  let adviceWatch = watch("advice");

  const handleAddAdvice = () => {
    if (template !== "" && adviceWatch !== "") {
      let data = [...templateData];
      data[0]?.Advices.push(adviceWatch);
      setTemplateData(data);
      setValue("advice", "");
    }
  };

  const handleAdd = () => {
    if (templateData?.length > 0 && templateData?.[0]?.Advices?.length > 0) {
      let dataArray = [...templates];
      dataArray.push(templateData[0]);
      setTemplates(dataArray);
      setTemplateData([]);
      setValue("templateName", "");
      setValue("advice", "");

      let templateObject = {
        id: null,
        template: templateData?.[0]?.Template,
        advices: templateData[0]?.Advices,
      };
      // saveAdvice(templateObject)
      //   .then((response) => response.data)
      //   .then((res) => {
      //     successAlert(res?.message);
      //   })
      //   .catch((err) => {
      //     errorAlert;
      //   });

      close();
    }
  };

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div className=" ">
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Advice Template
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    close();
                  }}
                  type="button"
                >
                  <ForceCloseIcon />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 items-center">
              <div>
                <InputField
                  disabled={templateData[0]?.Advices?.length > 0 ? true : false}
                  control={control}
                  name={"templateName"}
                  label="Template"
                />
              </div>
              <div className="col-span-2 flex items-center">
                <TextareaAutosize
                  onFocusCapture={() => {
                    handleAddTemplate();
                  }}
                  name="advice"
                  placeholder="Advice"
                  className="w-full px-2 py-1 rounded border border-gray-200"
                  style={{
                    outline: "none",
                  }}
                  {...register("advice")}
                  // minRows={}
                />
                <CommonButton
                  className={"px-2"}
                  type={"button"}
                  // label={<AddIcon />}
                  onClick={() => {
                    handleAddAdvice();
                  }}
                />
              </div>
            </div>
            {templateData?.length > 0 ? (
              <>
                <div className="my-2 flex gap-2 items-center">
                  <div className="w-[35%] flex justify-center items-center font-semibold px-2 py-1 border border-gray-200 rounded">
                    Template Name
                  </div>
                  <div className="w-[65%] flex justify-center items-center font-semibold px-2 py-1 border border-gray-200 rounded">
                    Advices
                  </div>
                </div>
                <div className="my-2 flex gap-2 items-center">
                  <div className="w-[35%] font-semibold rounded">
                    {templateData?.[0]?.Advices?.length > 0
                      ? templateData.map((template) => {
                          return (
                            <div className="whitespace-nowrap flex  px-2">
                              <div className=" ">{template?.Template}</div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="w-[65%]">
                    {templateData[0]?.Advices?.length > 0
                      ? templateData[0]?.Advices.map((advice) => {
                          return (
                            <div className="border border-gray-200 rounded px-2 py-1">
                              •&nbsp;{advice}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="flex justify-end my-2 px-2">
              <CommonButton
                label={"Add"}
                type={"button"}
                className={"px-3 bg-[#368505] text-white h-9 rounded"}
                onClick={() => {
                  handleAdd();
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
