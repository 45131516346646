//import from react library
import * as React from "react";

//imports from mui library
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { Box, Grid, Modal, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

//imports from the Common Components
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DropdownField from "../../common/components/FormFields/DropdownField";
import InputField from "../../common/components/FormFields/InputField";

//importing the styles applied to the modal.

//imports from react hook form
import { Controller, useFieldArray, useForm } from "react-hook-form";

//imports from the yup library
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  getBankList,
  getPaymentMode,
} from "../../billing/services/BillingServices";
import CommonButton from "../../common/components/Buttons/CommonButton";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
import {
  fetchConcessionAuthority,
  fetchUpiTypes,
} from "../../commonServices/miscellaneousServices/MiscServices";
import { saveCounterSales } from "../PharmacyServices";
let renderCount = 0;

export default function BillInformation(props) {
  renderCount++;

  //yup validation schema that is going to be applied on the field array.
  const paymentSchema = yup.object().shape({
    paymentCart: yup.array().of(
      yup.object().shape({
        // validation schema for paymentMode dropdown
        paymentMode: yup
          .object()
          .nullable()
          .shape({
            value: yup.string().required(" "),
            label: yup.string().required(" "),
          })
          .required(" "),

        //validation schema for amount input field
        amount: yup.number().min(0).required("Required"),

        //validation schema for referenceNumber input field
        referenceNumber: yup.string().when("paymentMode", (paymentMode) => {
          let paymentModeName;

          if (paymentMode) {
            paymentModeName = paymentMode.label;
          }

          if (paymentModeName === "CHEQUE") {
            return yup
              .string()
              .required("Reference Number Required")
              .length(6, "Only 6 Digits allowed");
          }

          if (
            paymentModeName === "DEBIT CARD" ||
            paymentModeName === "CREDIT CARD"
          ) {
            return yup
              .string()
              .required("Reference Number Required")
              .length(4, "Only 4 Digits allowed");
          }

          if (
            paymentModeName === "UPI" ||
            paymentModeName === "ECS" ||
            paymentModeName === "ONLINE"
          ) {
            return yup
              .string()
              .required("Reference Number Required")
              .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
              .min(1)
              .max(20);
            // .length(20, "Only 6 Digits allowed");
          }

          if (paymentModeName === "CASH") {
            return yup.string().notRequired();
          }
        }),

        upiOption: yup
          .object()
          .nullable()
          .shape({
            value: yup.string(),
            label: yup.string(),
          })
          .when("paymentMode", (paymentMode) => {
            let paymentModeName;

            if (paymentMode) {
              paymentModeName = paymentMode.label;
            }

            if (paymentModeName === "UPI") {
              return yup
                .object()
                .required(" ")
                .nullable()
                .shape({
                  value: yup.string().required(" "),
                  label: yup.string().required(" "),
                });
            } else {
              return yup
                .object()
                .notRequired()
                .nullable()
                .shape({
                  value: yup.string().notRequired(),
                  label: yup.string().notRequired(),
                })
                .notRequired();
            }
          }),
        chequeAuthor: yup
          .object()
          .nullable()
          .shape({
            value: yup.string(),
            label: yup.string(),
          })
          .when("paymentMode", (paymentMode) => {
            let paymentModeName;

            if (paymentMode) {
              paymentModeName = paymentMode.label;
            }

            if (paymentModeName === "CHEQUE") {
              return yup.object().required(" ").nullable().shape();
            } else {
              return yup
                .object()
                .notRequired()
                .nullable()
                .shape({
                  value: yup.string().notRequired(),
                  label: yup.string().notRequired(),
                })
                .notRequired();
            }
          }),

        bankName: yup
          .object()
          .nullable()
          .shape({
            value: yup.string(),
            label: yup.string(),
          })
          .when("paymentMode", (paymentMode) => {
            let paymentModeName;

            if (paymentMode) {
              paymentModeName = paymentMode.label;
            }

            if (
              paymentModeName === "CREDIT CARD" ||
              paymentModeName === "CHEQUE" ||
              paymentModeName === "ECS" ||
              paymentModeName === "DEBIT CARD" ||
              paymentModeName === "ONLINE"
            ) {
              return yup
                .object()
                .required(" ")
                .nullable()
                .shape({
                  value: yup.string().required(" "),
                  label: yup.string().required(" "),
                });
            } else {
              return yup
                .object()
                .notRequired()
                .nullable()
                .shape({
                  value: yup.string().notRequired(),
                  label: yup.string().notRequired(),
                })
                .notRequired();
            }
          }),
      })
    ),

    concessionAuthority: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .when(() => {
        //apply the validation ; if and only if the concessionAuthority dropdown is visible.
        if (authorityDropdown === true) {
          return yup
            .object()
            .required(" ")
            .nullable()
            .shape({
              value: yup.string().required(" "),
              label: yup.string().required(" "),
            })
            .required(" ");
        } else if (authorityDropdown === false) {
          return yup
            .object()
            .notRequired()
            .nullable()
            .shape({
              value: yup.string().notRequired(),
              label: yup.string().notRequired(),
            })
            .notRequired();
        }
      }),

    //validation schema for Consume Amount input field
    //apply the validations to the InputField ; if and only
    //consumeAdvanceFlag is true.
    // consumeAmount: yup.number().when((consumeAmount) => {
    //   if (consumeAdvanceFlag === true) {
    //     return yup.number().min(0.1).required("Required");
    //   } else if (consumeAdvanceFlag === false) {
    //     return yup.string().notRequired();
    //   }
    // }),

    consumeAmount: yup.number().when((consumeAmount) => {
      if (consumeAdvanceFlag === true) {
        return yup.number().min(0.1);
      } else if (consumeAdvanceFlag === false) {
        return yup.string().notRequired();
      }
    }),
  });

  //getting the today's date object into the variable called "today"
  let today = new Date();

  const defaultValues = {
    paymentCart: [
      {
        paymentMode: null,
        amount: "",
        referenceNumber: "",
        bankName: null,
        paymentDate: today,
        upiOption: null,
      },
    ],
    concessionAuthority: null,
    consumeAmount: "",
    consumeAdvance: false,
  };

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    register,
    trigger,
    clearErrors,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(paymentSchema),
    defaultValues,
  });

  //declaring the field array called "paymentCart"
  const { fields, append, remove } = useFieldArray({
    name: "paymentCart",
    control,
  });
  //state variable to store the bank list options
  const [bankList, setBankList] = React.useState([]);

  const [upiOptionsList, setUpiOptionsList] = React.useState([]);

  //state variable to store the options of payment mode
  const [paymentModeList, setPaymentModeList] = React.useState([]);

  //state variable to open or close the confirmation for field arrays being displayed
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  //state variable to open or close the confirmation for field arrays not being displayed
  const [
    openConfirmationForNoFields,
    setOpenConfirmationForNoFields,
  ] = React.useState(false);

  //state variable to hold the netPayableAmount's value
  const [netPayableAmountVal, setNetPayableAmountVal] = React.useState(0);

  //state variable to display the consume amount error message
  const [consumeErrorMsgOne, setConsumeErrorMsgOne] = React.useState("");

  const [consumeErrorMsgTwo, setConsumeErrorMsgTwo] = React.useState("");

  const [netPayErrorMessage, setNetPayErrorMessage] = React.useState("");

  //state variable to store the advance of the patient
  const [advance, setAdvance] = React.useState(null);

  //state variable to hold the state of consume advance checkbox
  const [consumeAdvanceFlag, setConsumedAdvanceFlag] = React.useState(false);

  //state variable to set the error on payment mode dropdown when CASH option is selected more than once.
  const [customCashError, setCustomCashError] = React.useState(false);

  const [showAddIcon, setShowAddIcon] = React.useState(false);

  //state variable to populate the concessionAuthority dropdown.
  const [
    concessionAuthorityArray,
    setConcessionAuthorityArray,
  ] = React.useState([]);

  //state variable to open the bill settlement confirmation modal
  const [
    billSettlementConfirmation,
    setBillSettlementConfirmation,
  ] = React.useState(false);

  //state variable to display or hide the authority dropdown
  const [authorityDropdown, setAuthorityDropdown] = React.useState(false);
  const [consumedAdvanceVal, setConsumedAdvanceVal] = React.useState(0);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [
    displayCreditAuthorityLabel,
    setDisplayCreditAuthorityLabel,
  ] = React.useState(true);
  const [finalMedicineArray, setFinalMedicineArray] = React.useState([]);
  const [finalPostObject, setFinalPostObject] = React.useState("");

  React.useEffect(() => {
    let precsriptions = [...props.finalPrescriptions.result];
    let requiredArr = [];
    for (let obj of precsriptions) {
      if (obj.quantity > 0) {
        let medicineObj = {};
        let dateString;

        if (obj.expiryDate !== "") {
          dateString =
            obj?.expiryDate
              .toString()
              .split("/")
              .reverse()
              .join("/")
              .replaceAll("/", "-") + "T00:00:00.000Z";
        } else {
          dateString = "1900-01-01T00:00:00.000Z";
        }
        
        medicineObj.balanceQty = obj.balanceQuantity;
        medicineObj.batchRequired = obj.BatchesRequired
        medicineObj.amount = obj.amount;
        medicineObj.batchCode = obj.batchNo;
        medicineObj.concession = obj.discountAmount;
        medicineObj.concessionPercentage = obj.discountPercentage;
        medicineObj.conversion = obj.conversion;
        medicineObj.discount = obj.discountAmount;
        medicineObj.discountPercentage = obj.discountPercentage;
        medicineObj.expiryDate = dateString;
        medicineObj.isConsignment = obj.IsConsignment;
        medicineObj.issueQty = obj.quantity;
        medicineObj.itemId = obj.id;
        medicineObj.kitId = 0;
        medicineObj.kitItemQty = 0;
        medicineObj.kitQt = 0;
        medicineObj.mrp = obj.mrp;
        medicineObj.otherTaxAmount = 0;
        medicineObj.prescriptionId = 0;
        medicineObj.purchaseRate = obj.purchaseRate ? obj.purchaseRate : 0;
        medicineObj.qtyAvlForReturn = 0;
        medicineObj.sellingPrice = obj.netAmount;
        medicineObj.staffFree = 0;
        medicineObj.staffFreePercentage = 0;
        medicineObj.taxAmount = 0;
        medicineObj.totalAmount = obj.netAmount;
        medicineObj.totalForVat = 0;
        medicineObj.unitLandedRate = obj.landedRate ? obj.landedRate : 0;
        medicineObj.vatAmount = Number(obj.gstAmount);
        medicineObj.vatInclusive = 0;
        medicineObj.vatPercentage = Number(obj.gstPercent);
        requiredArr.push(medicineObj);
        setFinalMedicineArray(requiredArr);
      }
    }
  }, [props.finalPrescriptions]);

  //trace the paymentCart array
  const cartArr = watch("paymentCart");

  //Call the services to patch the drop down values
  React.useEffect(() => {
    fetchUpiTypes().then((response) => {
      setUpiOptionsList(response.data.result);
    });

    setValue("consumeAdvance", false);
    setConsumedAdvanceFlag(false);

    //calling the api to populate the bank list dropdown
    getBankList().then((response) => {
      setBankList(response.data.result);
    });

    //calling the api to populate the paymentmode list dropdown
    getPaymentMode().then((response) => {
      setPaymentModeList(response.data.result);
      //set the value of the first field's dropdown field to "CASH"
      setValue(`paymentCart.[0].paymentMode`, response.data.result[1]);
    });

    //Calling the api to polulate the Concession Authority list dropdown
    fetchConcessionAuthority()
      .then((response) => response.data)
      .then((res) => {
        setConcessionAuthorityArray(res.result);
      });

    //storing the patient's advance in the state variable called "advance"
    if (props.patientAdvanceDetails === 0) {
      setAdvance(0);
    } else if (props.patientAdvanceDetails > 0) {
      setAdvance(props.patientAdvanceDetails);
    }

    //store the patient's advance balance into the variable "tempAdvance"
    let tempAdvance = props.patientAdvanceDetails;

    //if the available patient advance is null ; then set the props.getValues("netPayableAmount") value in the
    //state variable netPayableAmountVal.
    if (tempAdvance === null) {
      setNetPayableAmountVal(props.amountPayable);

      let netPayVal = props.amountPayable;

      //if companyId is greater than 0
      //set the value of first field as 0
      //else set the value of first field as netPayVal.
      if (props.companyId > 0) {
        setValue(`paymentCart.[0].amount`, 0);
      } else if (props.companyId === 0) {
        setValue(`paymentCart.[0].amount`, props.amountPayable);
      }
    }

    setNetPayableAmountVal(props.amountPayable);
    if (tempAdvance !== null) {
      //if the available advance balance is 0 ; then set the value of netPayableAmountVal state variable.
      let netPayVal = props.amountPayable;
      //if companyId is greater than 0
      //set the value of first field as 0
      //else set the value of first field as netPayVal.
      if (props.companyId > 0) {
        setValue(`paymentCart.[0].amount`, 0);
      } else if (props.companyId === 0) {
        setValue(`paymentCart.[0].amount`, props.amountPayable);
      }
    }
  }, [props.amountPayable]);

  //When the consumeAdvance checkbox is unticked ; then the following below things should happen.
  React.useEffect(() => {
    if (consumeAdvanceFlag === false || consumeAdvanceFlag === "false") {
      setConsumedAdvanceVal(0);
      setValue("consumeAmount", 0);
      //when we are not consuming any amounts from the advance ; then at that time ;
      //The netPayableAmountVal is equal to the Total Bill Amount.
      setNetPayableAmountVal(props.amountPayable);

      //remove all the fields except the first field.
      remove();
      let netPayVal = props.amountPayable;

      //when the checkbox is unchecked ; then at that time set the value of first field's amount ; same as the state variable netPayableAmountVal.
      //if companyId is greater than 0
      //set the value of first field as 0
      //else set the value of first field as netPayVal.
      if (props.companyId > 0) {
        setValue(`paymentCart.[0].amount`, 0);
      } else if (props.companyId === 0) {
        setValue(`paymentCart.[0].amount`, netPayVal);
      }

      //set the payment mode to CASH
      setValue(`paymentCart.[0].paymentMode`, {
        id: 4,
        label: "CASH",
        value: "CASH",
      });
      setValue("consumeAmount", "");
      //clear the errors if any
      clearErrors("consumeAmount");

      //To make the errors to go away ; because before unchecking the "Consume Amount" flag if by chance the
      //user clicks on the save button ; then the trigger() function would make the error to go away.
      // trigger();
    }
  }, [consumeAdvanceFlag]);

  // Whenever there is increment in the field array ; at that time we need to handle the
  // display of ConcessionAuthority dropdown.
  React.useEffect(() => {
    // whenever the field is appended ; the remaining amount is populated in the next field's Amount input field ;
    // so that sum of all the amount input fields becomes equal to netPayableAmountVal.
    // Then at that time we need to handle the display of concessionAuthority dropdown.
    //when companyId is 0 ; at that time we do not set the first field's Amount InputField to 0.
    if (cartArr.length >= 1 && props.companyId === 0) {
      // function to control the display of the concessionAuthority dropdown

      let source = "if block of [cartArr] useEffect ";
      displayConcessionAuthorityDropdown(source);
    }
  }, [cartArr]);

  //function to calculate the sum of all the amounts typed in the amount input field of useFieldArray
  function calculateFieldAmountSum() {
    let totalAmount = 0;

    //Calculate the sum of all the paid amounts from various payment modes and store it in the variable totalAmount.
    for (let cartObj of cartArr) {
      if (cartArr.length > 1) {
        if (cartObj && cartObj.amount) {
          totalAmount = Number(totalAmount) + Number(cartObj.amount);
        }
      } else if (cartArr.length === 1 && cartObj.amount) {
        totalAmount = Number(cartObj.amount);
      }
    }

    return totalAmount;
  }

  //function to close the confirmation modal
  const confirmationHandleClose = () => {
    if (confirmationOpen === true) {
      setConfirmationOpen(false);
    }
  };

  //function to close the confirmation modal
  const closeConfirmationForNoFields = () => {
    if (openConfirmationForNoFields === true) {
      setOpenConfirmationForNoFields(false);
    }
  };

  //function to close the bill settlement confirmation modal
  const billSettlementConfirmationClose = () => {
    if (billSettlementConfirmation === true) {
      setBillSettlementConfirmation(false);
    }
  };

  //function that is called after clicking the submit button of the payment info modal
  function onSubmitDataHandler(data) {
    //close the confirmation modal
    confirmationHandleClose();

    // //close the bill information modal
    props.setOpen(false);

    let admissionId;
    let opdIpd;

    if (props.selectedPatientType === "OPD") {
      admissionId = props.patientInfo?.VisitId;
      opdIpd = 0;
    } else if (props.selectedPatientType === "IPD") {
      admissionId = props.patientInfo?.AdmissionId;
      opdIpd = 1;
    } else if (props.selectedPatientType === "Counter") {
      admissionId = 0;
      opdIpd = 2;
    }

    let companyId;
    if (props.patientInfo?.CompanyId > 0) {
      companyId = props.patientInfo?.CompanyId;
    } else {
      companyId = 0;
    }

    let paymentInfoList = [];

    let paymentInfoListObj = {};

    let isAdvanceConsume = getValues("consumeAdvance");

    let consumeAdvance = getValues("consumeAmount");
    let advanceUsed = 0;
    if (consumeAdvance > 0) {
      advanceUsed = consumeAdvance;
    }

    paymentInfoListObj.isAdvanceConsume = isAdvanceConsume;
    paymentInfoListObj.consumeAdvance = consumeAdvance;
    paymentInfoListObj.paymentInfoList = paymentInfoList;

    let concessionAuthorisedObj = getValues("concessionAuthority");

    let paymentCart = data.paymentCart;

    let paidAmountFinal = 0;
    let a = 0;

    for (let paymentObj of paymentCart) {
      a = paymentObj.amount;
      paidAmountFinal = parseInt(paidAmountFinal) + parseInt(a);
    }

    for (let formObj of paymentCart) {
      let obj = {
        amount: 0,
        bankId: 0,
        paymentDate: "",
        paymentType: {
          id: 0,
          label: "",
          value: "",
        },
        referenceNumber: "",
        upiTypeId: null,
      };

      obj.amount = formObj.amount;
      obj.paymentType = formObj.paymentMode;

      if (formObj.hasOwnProperty("referenceNumber")) {
        obj.referenceNumber = formObj.referenceNumber;
      }

      if (formObj.hasOwnProperty("bankName")) {
        if (formObj.bankName) {
          obj.bankId = formObj.bankName.id;
        }
      }

      if (formObj.hasOwnProperty("paymentDate")) {
        obj.paymentDate = formObj.paymentDate;
      }

      if (formObj.hasOwnProperty("upiOption")) {
        obj.upiTypeId = formObj.upiOption.id;
      }

      if (formObj.hasOwnProperty("chequeAuthor")) {
        obj.chequeAuthorisedBy = formObj.chequeAuthor.id;
      }

      if (concessionAuthorisedObj) {
        obj.creditAuthorisedBy = concessionAuthorisedObj?.id;
      }

      paymentInfoList.push(obj);
    }

    let finalObj = {
      addedBy: loginObj?.userId,
      areaId: 0,
      balanceAmountNonSelf: 0,
      balanceAmountSelf: 0,
      cashCounterId: loginObj?.cashCounterId,
      cash_Credit_Staff: 0,
      chargeId: 0,
      cityId: 0,
      companyId: companyId,
      computerName: "",
      concessionAmount: 0,
      counterSaleDetailsDto: finalMedicineArray,
      cpOrMrp: 0,
      crAmount: 0,
      discountAmount: Number(props.netPayableAmountVal.totalDiscountAmount),
      doctorId: 0,
      doctorName: props.patientInfo.doctor,
      empId: 0,
      isCancelled: true,
      isConsignment: true,
      isCpBill: true,
      isFree: true,
      isOld: true,
      isPrinted: true,
      mrno:
        Number(props?.patientInfo?.mrNo) > 0
          ? Number(props.patientInfo?.mrNo)
          : 0,
      opdIpd: opdIpd,
      opdIpdExternalId: admissionId,
      patientType: 0,
      paymentForPharmacyDto: {
        addedBy: 0,
        admissionId: admissionId,
        advanceNo: "",
        advanceRefund: 0,
        advanceUsed: 0,
        billAmount: 0,
        billBalanceAmount: 0,
        billId: 0,
        cancellationDate: "2023-04-14T13:23:52.209Z",
        cancellationReason: "",
        cancellationTime: "2023-04-14T13:23:52.209Z",
        cashCounterId: 0,
        cashPayAmount: 0,
        ccBankId: 0,
        ccNumber: "",
        ccPayAmount: 0,
        ccValidityDate: "2023-04-14T13:23:52.209Z",
        chequeAuthorisedBy: 0,
        chequeBankId: 0,
        chequeCleared: true,
        chequeDate: "2023-04-14T13:23:52.209Z",
        chequeNumber: "",
        chequePayAmount: 0,
        companyId: 0,
        creditAuthorisedBy: 0,
        dcBankId: 0,
        dcNumber: "",
        dcPayAmount: 0,
        dcValidityDate: "2023-04-14T13:23:52.209Z",
        ddBankId: 0,
        ddDate: "2023-04-14T13:23:52.209Z",
        ddNumber: "",
        ddPayAmount: 0,
        isBadDebit: true,
        isCancelled: true,
        isCash: true,
        isPrinted: true,
        narration: "",
        opdIpd: opdIpd,
        isAdvanceConsume: isAdvanceConsume,
        patientId:
          Number(props.patientInfo?.PatientId) > 0
            ? Number(props.patientInfo?.PatientId)
            : 0,
        patientAdvanceAmount: props.patientAdvanceDetails,
        patientAdvanceId: 0,
        patientAdvanceUsed: Number(advanceUsed),
        paymentInfoList:
          Number(consumeAdvance).toFixed(2) <
          Number(props.amountPayable).toFixed(2)
            ? paymentInfoList
            : [
                {
                  amount: 0,
                  paymentType: {
                    id: 4,
                    label: "CASH",
                    value: "CASH",
                  },
                },
              ],
        paymentType: 0,
        refundAmount: 0,
        refundId: 0,
        staffFreeAmount: 0,
        tdsPercent: 0,
        totalAmount: 0,
        trustAdvanceAmount: 0,
        trustAdvanceUsed: 0,
        trustAdvancedId: 0,
        trustId: 0,
        updatedBy: 0,
        upiPayAmount: 0,
        upiRefNo: "",
      },
      percentage: 0,
      reasonOfFree: 0,
      remark: props.remarkString,
      roundingAmount: Number(props.netPayableAmountVal.totalRoundingAmount),
      serviceChargeAmount: 0,
      serviceCharges: 0,
      settled: true,
      staffFree: 0,
      storeId: loginObj?.storeId,
      totalAmount: Number(props.netPayableAmountVal.totalAmount),
      totalAmountPayable: Number(props.netPayableAmountVal.totalPaidAmount),
      totalOtherTaxAmount: 0,
      totalTaxAmount: 0,
      totalVatAmount: Number(props.netPayableAmountVal.totalGSTAmount),
      privilege: props.privilege,
      menuId: props.menuId,
    };

    if (props.selectedPatientType === "Counter") {
      finalObj.patientName =
        props.counterInfo?.firstName + " " + props.counterInfo?.lastName;
      finalObj.otherDoctorName = props.counterInfo?.doctorName?.label;
      finalObj.patientAddress = props.counterInfo?.address;
      finalObj.mobileNo = props.counterInfo?.mobileNo;
    } else {
      finalObj.patientName = props.patientInfo.patientName;
      finalObj.otherDoctorName = "";
      finalObj.patientAddress = "";
      finalObj.mobileNo = "";
    }
    props.setOpenBackdrop(true);
    saveCounterSales(finalObj)
      .then((response) => response.data)
      .then((res) => {
        // let billId = res.result;
        // props.openPrintWindow(billId);
        successAlert(res.message);
        props.closePaymentModal();
        props.resetFunction();
        props.setResetQtyFlag(0);
        props.resetFields();
        props.setPatientInfo(null);
        props.setOpenBackdrop(false);
        props.openPrintWindow(res.result);
      })
      .catch((error) => {
        errorAlert(error.message || error.response.data.message);
        props.setOpenBackdrop(false);
      });

    paymentInfoListObj.paymentInfoList = paymentInfoList;

    //The below variable's value is sent to the postObj.

    let totaSumOfAmountFields = calculateFieldAmountSum();
  }

  //function to trigger the validations on the current field ; whose corresponding '+' icon is going to be clicked
  function appendPaymentField(fieldIndex) {
    let requiredObj = cartArr[fieldIndex];

    //if the payment mode is "UPI".
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "UPI") {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (totalAmountVal < netPayableAmountVal && customCashError === false) {
          let nextFieldAmountVal = netPayableAmountVal - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        }
      } else {
        trigger();
      }
    }

    //if the payment mode is "CASH"
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "CASH") {
      if (requiredObj.amount || requiredObj.amount === 0) {
        let totalAmountVal = calculateFieldAmountSum();

        if (totalAmountVal < netPayableAmountVal && customCashError === false) {
          let nextFieldAmountVal = netPayableAmountVal - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        }
      } else {
        trigger();
      }
    }

    //if the payment mode is one of "CHEQUE" , "CREDIT CARD" , "ECS" , "DEBIT CARD"
    if (
      requiredObj.paymentMode &&
      (requiredObj.paymentMode.label === "CHEQUE" ||
        requiredObj.paymentMode.label === "CREDIT CARD" ||
        requiredObj.paymentMode.label === "ECS" ||
        requiredObj.paymentMode.label === "ONLINE" ||
        requiredObj.paymentMode.label === "DEBIT CARD")
    ) {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber &&
        requiredObj.bankName &&
        requiredObj.bankName.label !== " "
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (totalAmountVal < netPayableAmountVal && customCashError === false) {
          let nextFieldAmountVal = netPayableAmountVal - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        }
      } else {
        trigger();
      }
    }

    //if the paymentMode is not selected -- that is when it is undefined ; then at that time also trigger the validation.
    if (!requiredObj.paymentMode) {
      trigger();
    }

    //if the paymentMode is selected as blank ; then at that time also trigger the validation.
    if (requiredObj.paymentMode.label === " ") {
      trigger();
    }
  }

  //function to remove the field whenever the "-" icon is clicked.
  function removePaymentFieldRow(fieldIndex) {
    let totalSumVal = 0;

    for (let index = 0; index < cartArr.length; index++) {
      if (cartArr.length > 1) {
        if (cartArr[index] && cartArr[index].amount) {
          if (index !== fieldIndex) {
            totalSumVal = Number(totalSumVal) + Number(cartArr[index].amount);
          }
        }
      } else if (cartArr.length === 1 && cartArr[index].amount) {
        totalSumVal = Number(cartArr[index].amount);
      }
    }

    remove(fieldIndex);

    let consumeAdvanceFlag = getValues("consumeAdvance");

    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (
      advance !== 0 &&
      (consumeAdvanceFlag === true || consumeAdvanceFlag === "true")
    ) {
      //Here the "requiredBillAmount" means the Total Bill Amount.
      let requiredBillAmount = props.amountPayable;

      let consumeAmountVal = getValues("consumeAmount");

      if (consumeAmountVal === "") {
        consumeAmountVal = 0;
      }

      let tempNetPayVal = requiredBillAmount - consumeAmountVal;

      if (totalSumVal > tempNetPayVal) {
        setNetPayErrorMessage("Total amount exceeded net payable amount");
      } else {
        setNetPayErrorMessage("");
      }
    }

    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (
      advance !== 0 &&
      (consumeAdvanceFlag === false || consumeAdvanceFlag === "false")
    ) {
      //Here the "requiredBillAmount" means the Total Bill Amount.
      let requiredBillAmount = props.amountPayable;

      let tempNetPayVal = requiredBillAmount;

      if (totalSumVal > tempNetPayVal) {
        setNetPayErrorMessage("Total amount exceeded net payable amount");
      } else {
        setNetPayErrorMessage("");
      }
    }

    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (advance === 0) {
      //Here the "requiredBillAmount" means the Total Bill Amount.
      let requiredBillAmount = props.amountPayable;

      let tempNetPayVal = requiredBillAmount;

      if (totalSumVal > tempNetPayVal) {
        setNetPayErrorMessage("Total amount exceeded net payable amount");
      } else {
        setNetPayErrorMessage("");
      }
    }
  }

  //function to detect the number of occurences of "CASH" option.
  //CASH option is allowed only once.
  function detectDuplicateCashOption() {
    let cashOptionCountIndicator = 0;

    for (let cartObj of cartArr) {
      if (cartObj.paymentMode.label === "CASH") {
        cashOptionCountIndicator = cashOptionCountIndicator + 1;
      }
    }

    if (cashOptionCountIndicator > 1) {
      setCustomCashError(true);
    } else {
      setCustomCashError(false);
    }
  }

  //the function which decides when to display the concessionAuthority dropdown.
  function displayConcessionAuthorityDropdown(sourceOfFunctionCall) {
    let totalSumVal = calculateFieldAmountSum();
    //if the sum of all the Amount fields in the field array is less than netPayableAmountVal
    //then show the concessionAuthority dropdown.
    if (totalSumVal < netPayableAmountVal) {
      setAuthorityDropdown(true);
    } else {
      setAuthorityDropdown(false);
      //reset the value of concessionAuthority dropdown
      setValue("concessionAuthority", null);
      //clear the error of concessionAuthority dropdown.
      clearErrors("concessionAuthority");
    }
  }

  //function to correct the last field's Amount input field ;
  //when the Cancel button of the bill settlement confirmation modal is clicked.
  function correctTheLastFieldAmountValue() {
    if (fields.length > 1) {
      //Getting the last index of the field array.
      let lastIndexOfFieldArray = fields.length - 1;

      //calculating the sum of all the amount fields.
      let totalOfFieldAmountsVal = calculateFieldAmountSum();

      //getting the current value of the last field's amount input field.
      let currentLastFieldAmountValue = getValues(
        `paymentCart.[${lastIndexOfFieldArray}].amount`
      );

      currentLastFieldAmountValue = Number(currentLastFieldAmountValue);

      //The variable sumOfPreviousFieldsExceptLastField contains sum of all the amount input fields except the last field's amount input field.
      let sumOfPreviousFieldsExceptLastField =
        totalOfFieldAmountsVal - currentLastFieldAmountValue;

      //lastIndexAmountVal is the value so that totalOfFieldAmountsVal becomes equal to netPayableAmountVal
      let lastIndexAmountVal =
        netPayableAmountVal - sumOfPreviousFieldsExceptLastField;

      //set the value of last field's amount input field.
      //This is the remaining amount that makes the sum of all the amount fields equal to netPayableAmountVal
      setValue(
        `paymentCart.[${lastIndexOfFieldArray}].amount`,
        lastIndexAmountVal
      );

      //function to control the display of the concessionAuthority dropdown
      let source =
        "fields.length > 1 if block of correctTheLastFieldAmountValue function.";
      displayConcessionAuthorityDropdown(source);
    }

    //if there is only one field then patch the netPayable amount val into the
    //amount input field of the first field.
    if (fields.length === 1) {
      setValue(`paymentCart.[0].amount`, netPayableAmountVal);
      //function to control the display of the concessionAuthority dropdown
      let source =
        "fields.length === 1 if block of correctTheLastFieldAmountValue function.";
      displayConcessionAuthorityDropdown(source);
    }
  }

  //on change event listener function for the amount input field in the field array.
  function handleAmountChange() {
    //The below if block is to display or hide the "+" icon only at the first field ; when the length of the field is 1.
    if (cartArr.length === 1) {
      let firstFieldAmount = Number(cartArr[0].amount);
      if (firstFieldAmount === netPayableAmountVal) {
        setShowAddIcon(false);
      } else if (firstFieldAmount < netPayableAmountVal) {
        setShowAddIcon(true);
      }
    }

    let totalSumVal = calculateFieldAmountSum();

    let consumeAdvanceFlag = getValues("consumeAdvance");

    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (
      advance !== 0 &&
      (consumeAdvanceFlag === true || consumeAdvanceFlag === "true")
    ) {
      //Here the "requiredBillAmount" means the Total Bill Amount.
      let requiredBillAmount = props.amountPayable;

      let consumeAmountVal = getValues("consumeAmount");

      if (consumeAmountVal === "") {
        consumeAmountVal = 0;
      }

      let tempNetPayVal = requiredBillAmount - consumeAmountVal;

      if (totalSumVal > tempNetPayVal) {
        setNetPayErrorMessage("Total amount exceeded net payable amount");
      } else {
        setNetPayErrorMessage("");
      }
    }

    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (
      advance !== 0 &&
      (consumeAdvanceFlag === false || consumeAdvanceFlag === "false")
    ) {
      //Here the "requiredBillAmount" means the Total Bill Amount.
      let requiredBillAmount = props.amountPayable;

      let tempNetPayVal = requiredBillAmount;

      if (totalSumVal > tempNetPayVal) {
        setNetPayErrorMessage("Total amount exceeded net payable amount");
      } else {
        setNetPayErrorMessage("");
      }
    }

    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (advance === 0) {
      //Here the "requiredBillAmount" means the Total Bill Amount.
      let requiredBillAmount = props.amountPayable;

      let tempNetPayVal = requiredBillAmount;

      if (totalSumVal > tempNetPayVal) {
        setNetPayErrorMessage("Total amount exceeded net payable amount");
      } else {
        setNetPayErrorMessage("");
      }
    }

    //function to control the display of the concessionAuthority dropdown on the change of Amount input field.
    // displayConcessionAuthorityDropdown();
  }

  //change handler to handle the changes in consumeAmount input field
  function handleConsumeAmountChange(consumedAmountNum) {
    //Here the "requiredBillAmount" means the Total Bill Amount.
    let requiredBillAmountVal = Number(props.amountPayable);

    let consumeAdvanceFlagVal = getValues("consumeAdvance");

    //We need to change the "netPayableAmountVal" upon changes in consumeAmountVal.
    let tempNetPayableAmountVal =
      Number(requiredBillAmountVal) - Number(consumedAmountNum);

    tempNetPayableAmountVal = Number(tempNetPayableAmountVal.toFixed(2));

    //When the advance is not null and the amount is being consumed ; then at that time
    //remove all the fields except the first one.
    if (props.patientAdvanceDetails !== 0 && consumeAdvanceFlag === true) {
      if (fields.length > 1) {
        remove();
        setShowAddIcon(false);
      }
    }

    //Set the latest net payable amount into the first field.
    //remove all the fields except the first one.
    setValue(`paymentCart.[0].amount`, tempNetPayableAmountVal);

    //If there is any previous error on the first field's amount InputField
    clearErrors(`paymentCart.[0].amount`);

    //set the payment mode to CASH
    setValue(`paymentCart.[0].paymentMode`, {
      id: 4,
      label: "CASH",
      value: "CASH",
    });

    tempNetPayableAmountVal = Number(tempNetPayableAmountVal.toFixed(2));

    //setting the state variable to update the ui
    if (Number(tempNetPayableAmountVal) === 0.0) {
      setNetPayableAmountVal(0);
    } else {
      setNetPayableAmountVal(tempNetPayableAmountVal);
    }
    //show the below error message if the consumed amount's value exceeds available patient advance.
    if (consumedAmountNum > props.patientAdvanceDetails) {
      setConsumeErrorMsgOne(
        "Note* : Consume Amount exceeded available balance"
      );
    }

    //show the below error message ; if consumedAmountNum becomes greater than the Total Bill Amount.
    if (
      consumedAmountNum > requiredBillAmountVal ||
      tempNetPayableAmountVal < 0.0
    ) {
      setConsumeErrorMsgTwo("Note* : Consume Amount exceeded bill amount");
    }

    //Do not show any error message ; if the consumed amount value is less than or equal to available advance consumed amount value is less than or equal to Total Bill Amount .
    if (consumedAmountNum <= props.patientAdvanceDetails) {
      setConsumeErrorMsgOne("");
    }

    // Do not show any error message if the consumed amount value is less than or equal to Total Bill Amount.
    if (consumedAmountNum <= requiredBillAmountVal) {
      setConsumeErrorMsgTwo("");
    }
  }

  //Post the opd bill when the condition
  //(netPayableAmountVal === 0 && consumeAdvanceFlag === true) is met.
  let loginObj = JSON.parse(localStorage.getItem("userInfo"));

  function postOpdBillWithNoFieldArray() {
    props.setOpenBackdrop(true);
  }

  //Post the opd bill when the field array is visible.
  function postOpdBillWithFieldArray() {
    //below is the logic to open the confirmation modal when there is mechanism of concession authority dropdown.
    //sum of all the amounts in the field
    let totalOfFieldAmounts = 0;
    for (let cartObj of cartArr) {
      if (cartArr.length > 1) {
        if (cartObj && cartObj.amount) {
          totalOfFieldAmounts =
            Number(totalOfFieldAmounts) + Number(cartObj.amount);
        }
      } else if (cartArr.length === 1 && cartObj.amount) {
        totalOfFieldAmounts = Number(cartObj.amount);
      }
    }

    if (consumedAdvanceVal > 0) {
      totalOfFieldAmounts =
        Number(totalOfFieldAmounts) + Number(consumedAdvanceVal);
    }
    if (
      netPayErrorMessage === "" &&
      isValid === true &&
      Number(totalOfFieldAmounts) === Number(props.amountPayable) &&
      authorityDropdown === false &&
      consumeErrorMsgOne === "" &&
      consumeErrorMsgTwo === ""
    ) {
      // clearErrors("consumeAmount");
      //open the confirmation modal
      setConfirmationOpen(true);
    }

    //open the confirmation modal for bill settlement when the below conditions are met.
    if (
      netPayErrorMessage === "" &&
      isValid === true &&
      Number(totalOfFieldAmounts) < Number(props.amountPayable) &&
      authorityDropdown === false &&
      consumeErrorMsgOne === "" &&
      consumeErrorMsgTwo === ""
    ) {
      //open the confirmation modal for bill settlement
      setBillSettlementConfirmation(true);
    }

    // open the confirmation modal for saving the bill.
    //when the concessionAuthority dropdown is visible.
    if (
      netPayErrorMessage === "" &&
      isValid === true &&
      Number(totalOfFieldAmounts) < Number(props.amountPayable) &&
      authorityDropdown === true &&
      consumeErrorMsgOne === "" &&
      consumeErrorMsgTwo === ""
    ) {
      //open the confirmation modal
      setConfirmationOpen(true);
    }

    //If the form is not valid then trigger the errors.
    if (isValid === false) {
      trigger();
    }
  }

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "80%",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    overflowY: "scroll",
    p: 2,
  };

  return (
    <>
      <div className=" bg-white px-6">
        <Modal
          open={props.open}
          // onClose={() => {
          //   props.closePaymentModal();
          // }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  props.closePaymentModal();
                  setValue(`paymentCart.[0].amount`, 0);
                  let paymentObj = paymentModeList.filter(
                    (item) => item.label === "CASH"
                  );
                  setValue(`paymentCart.[0].paymentMode`, paymentObj[0]);
                  renderCount = 0;
                }}
                style={{
                  paddingLeft: 10,
                }}
              />
            </div>
            <div className="row">
              <fieldset className="border border-gray-300 text-left   lg:mx-auto lg:px-4 md:ml-0 md:mr-0  rounded   lg:m-2 ">
                <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                  Bill Information
                </legend>
                <form
                  onSubmit={onSubmitDataHandler}
                  className="grid grid-cols-1 md:grid-cols-1  gap-2"
                >
                  <div className=" bg-white px-2 ">
                    <>
                      {/* Paitent Inf0 */}
                      {props.selectedPatient &&
                      props.selectedPatientType !== "Counter" ? (
                        <fieldset className="mx-auto border w-full rounded my-3 bg-gray-100">
                          <legend className="ml-6 my-2 rounded-xl">
                            <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                              Patient Information
                            </p>
                          </legend>
                          <>
                            <div className="py-2 grid  lg:grid-cols-4 2xl:grid-cols-4 gap-2 p-3 ">
                              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                                <span className=" font-semibold w-28">
                                  Patient Name
                                </span>
                                <div className="flex space-x-2 items-center">
                                  <span>:</span>
                                  <span className="text-gray-700 font-normal">
                                    {props.selectedPatient &&
                                      props.selectedPatient.patientName}
                                  </span>
                                </div>
                              </div>
                              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                                <span className=" w-28">MR No.</span>
                                <div className="flex space-x-2 items-center">
                                  <span>:</span>
                                  <span className="text-gray-700 font-normal">
                                    {props.selectedPatient &&
                                      props.selectedPatient.mrNo}
                                  </span>
                                </div>
                              </div>

                              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                                <span className=" w-28">Age</span>
                                <div className="flex space-x-2 items-center">
                                  <span>:</span>
                                  <span className="text-gray-700 font-normal">
                                    {props.selectedPatient &&
                                      props.selectedPatient.AgeYear}
                                  </span>
                                </div>
                              </div>

                              <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                                <span className=" w-28">Mobile No.</span>
                                <div className="flex space-x-2 items-center">
                                  <span>:</span>
                                  <span className="text-gray-700 font-normal">
                                    {props.selectedPatient &&
                                      props.selectedPatient.MobileNo}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        </fieldset>
                      ) : (
                        ""
                      )}

                      {/* Advance Information */}
                      {/* Different ui are shown depending on the availability of 
                       Value of patient advance */}
                      {props.patientAdvanceDetails ? (
                        <fieldset className="mx-auto border w-full rounded my-3 bg-gray-100 px-4">
                          <legend className="ml-2 rounded-xl">
                            <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                              Patient Bill Information
                            </p>
                          </legend>
                          <div className=" flex gap-2  ">
                            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                              <span className=" font-semibold w-28 whitespace-nowrap ">
                                Advance Available &nbsp;: &nbsp; ₹&nbsp;
                                {props.patientAdvanceDetails === null
                                  ? "0"
                                  : props.patientAdvanceDetails}
                              </span>
                            </div>
                            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                              <div className="flex gap-2 items-center">
                                <span>
                                  <input
                                    type="checkbox"
                                    id="consumeAdvance"
                                    name="consumeAdvance"
                                    {...register("consumeAdvance")}
                                    // value="false"
                                    onChange={(e) => {
                                      //if the consumeAmount flag is unticked then there is no question of "Consume Amount" input field
                                      if (e.target.checked === false) {
                                        setConsumeErrorMsgOne("");
                                        setConsumeErrorMsgTwo("");
                                      }

                                      setConsumedAdvanceFlag(e.target.checked);
                                    }}
                                  />
                                </span>
                                <span className="ml-2 whitespace-nowrap">
                                  <label htmlFor="consumeAdvance">
                                    Consume Advance
                                  </label>
                                </span>

                                {consumeAdvanceFlag === true ? (
                                  <>
                                    <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                                      <InputField
                                        control={control}
                                        name="consumeAmount"
                                        label="Consume Amount"
                                        type="number"
                                        onWheel={(e) => {}}
                                        error={errors.consumeAmount}
                                        inputRef={{
                                          ...register(`consumeAmount`, {
                                            onChange: (e) => {
                                              let consumedAmountNum = Number(
                                                e.target.value
                                              );
                                              setConsumedAdvanceVal(
                                                consumedAmountNum
                                              );

                                              handleConsumeAmountChange(
                                                consumedAmountNum
                                              );
                                            },
                                          }),
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>

                            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                              <span className=" font-semibold w-28 whitespace-nowrap">
                                Bill Amount
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>:</span>
                                <span className="font-semibold w-28 whitespace-nowrap ">
                                  ₹ {props.amountPayable}
                                </span>
                              </div>
                            </div>

                            <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                              <span className="font-semibold w-28">
                                Net Payable Amount
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                                </span>
                                <span className="font-semibold w-28 whitespace-nowrap  ">
                                  ₹ {netPayableAmountVal}
                                </span>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      ) : (
                        <fieldset className="mx-auto border w-full rounded my-3 bg-gray-100 px-4">
                          <legend className="ml-2 rounded-xl">
                            <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                              Patient Bill Information
                            </p>
                          </legend>
                          <div className=" flex gap-2  ">
                            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                              <span className=" font-semibold w-28 whitespace-nowrap">
                                Advance Available &nbsp;: &nbsp; ₹&nbsp;
                                {props.patientAdvanceDetails === null
                                  ? "0"
                                  : props.patientAdvanceDetails}
                              </span>
                            </div>

                            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                              <span className=" font-semibold w-28 whitespace-nowrap">
                                Bill Amount
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>:</span>
                                <span className="font-semibold w-28 whitespace-nowrap">
                                  ₹ {props.amountPayable}
                                </span>
                              </div>
                            </div>

                            <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                              <span className="font-semibold w-28">
                                Net Payable Amount
                              </span>
                              <div className="flex space-x-2 items-center">
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                                </span>
                                <span className="font-semibold w-28 whitespace-nowrap">
                                  ₹ {netPayableAmountVal}
                                </span>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      )}

                      <div>
                        {consumeErrorMsgOne !== "" ? (
                          <span className="text-red-500 flex">
                            {consumeErrorMsgOne}
                          </span>
                        ) : null}
                      </div>

                      <div>
                        {consumeErrorMsgTwo !== "" ? (
                          <span className="text-red-500 flex">
                            {consumeErrorMsgTwo}
                          </span>
                        ) : null}
                      </div>

                      {netPayableAmountVal === 0 ||
                      netPayableAmountVal < 0 ||
                      (consumeAdvanceFlag === true &&
                        (consumeErrorMsgOne !== "" ||
                          consumeErrorMsgTwo !== "")) ||
                      (consumeAdvanceFlag === true &&
                        consumeErrorMsgOne !== "" &&
                        consumeErrorMsgTwo !== "") ? (
                        <Grid
                          item
                          lg={1.5}
                          md={1.5}
                          className="flex justify-end gap-2 py-2"
                        >
                          <CommonButton
                            label="Cancel"
                            type="button"
                            className="h-9  px-2 border border-red-700 text-red-700 rounded text-sm 2xl:text-base font-medium"
                            onClick={() => {
                              props.closePaymentModal();
                              renderCount = 0;
                            }}
                          />
                          <CommonButton
                            label="Save"
                            type="button"
                            onClick={() => {
                              if (
                                netPayErrorMessage === "" &&
                                consumeErrorMsgOne === "" &&
                                consumeErrorMsgTwo === ""
                              )
                                setOpenConfirmationForNoFields(true);
                            }}
                            className="h-9 px-3  bg-green-700 text-white rounded text-base font-medium "
                          />
                        </Grid>
                      ) : (
                        <div className="w-full">
                          <fieldset className="mx-auto border w-full rounded my-3">
                            <legend className="ml-6 my-0 rounded-xl">
                              <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                                Payment Details :-
                                <span className=" text-red-500">
                                  {netPayErrorMessage}
                                </span>
                              </p>
                            </legend>

                            <div className="mx-auto px-6 py-2">
                              {fields.map((field, index) => {
                                let dropdownObj = watch(
                                  `paymentCart.[${index}].paymentMode`
                                );
                                return (
                                  <Grid container spacing={2} key={field.id}>
                                    <>
                                      <Grid
                                        item
                                        lg={2.5}
                                        md={6}
                                        sm={6}
                                        sx={{ marginY: "0.5rem" }}
                                      >
                                        <DropdownField
                                          control={control}
                                          name={`paymentCart.[${index}].paymentMode`}
                                          error={
                                            cartArr[index].paymentMode
                                              ?.label === "CASH" &&
                                            customCashError === true
                                              ? {
                                                  message:
                                                    "Cash Allowed only once.",
                                                }
                                              : errors.paymentCart?.[index]
                                                  ?.paymentMode
                                          }
                                          label="Payment Type"
                                          dataArray={paymentModeList}
                                          isSearchable={false}
                                          placeholder="Payment Type *"
                                          inputRef={{
                                            ...register(
                                              `paymentCart.[${index}].paymentMode`,
                                              {
                                                onChange: (e) => {
                                                  detectDuplicateCashOption();
                                                },
                                              }
                                            ),
                                          }}
                                        />
                                      </Grid>

                                      {dropdownObj &&
                                      dropdownObj.label === "UPI" ? (
                                        <>
                                          <Grid
                                            item
                                            lg={2.5}
                                            md={6}
                                            sm={6}
                                            sx={{ marginY: "0.5rem" }}
                                          >
                                            <DropdownField
                                              control={control}
                                              name={`paymentCart.[${index}].upiOption`}
                                              label="Select UPI Option"
                                              dataArray={upiOptionsList}
                                              isSearchable={false}
                                              isClearable={false}
                                              placeholder="Select UPI Option"
                                              error={
                                                errors.paymentCart?.[index]
                                                  ?.upiOption
                                              }
                                              searchIcon={false}
                                            />
                                          </Grid>
                                        </>
                                      ) : null}

                                      <Grid
                                        item
                                        lg={2}
                                        md={6}
                                        sm={6}
                                        sx={{ marginY: "0.5rem" }}
                                      >
                                        <InputField
                                          name={`paymentCart.[${index}].amount`}
                                          error={
                                            errors.paymentCart?.[index]?.amount
                                          }
                                          variant="outlined"
                                          label="Amount"
                                          control={control}
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          inputRef={{
                                            ...register(
                                              `paymentCart.[${index}].amount`,
                                              {
                                                onChange: (e) => {
                                                  handleAmountChange();
                                                },
                                              }
                                            ),
                                          }}
                                        />
                                      </Grid>

                                      {dropdownObj &&
                                      dropdownObj.label === "CHEQUE" ? (
                                        <>
                                          <Grid
                                            item
                                            lg={2.5}
                                            md={6}
                                            sm={6}
                                            sx={{ marginY: "0.5rem" }}
                                          >
                                            <DropdownField
                                              control={control}
                                              name={`paymentCart.[${index}].chequeAuthor`}
                                              menuPlacement="bottom"
                                              label="Cheque Auth. By"
                                              dataArray={
                                                concessionAuthorityArray
                                              }
                                              isSearchable={false}
                                              isClearable={false}
                                              placeholder="Cheque Auth. By"
                                              error={
                                                errors.paymentCart?.[index]
                                                  ?.chequeAuthor
                                              }
                                              searchIcon={false}
                                            />
                                          </Grid>
                                        </>
                                      ) : null}
                                    </>

                                    {dropdownObj &&
                                    (dropdownObj.label === "DEBIT CARD" ||
                                      dropdownObj.label === "CREDIT CARD" ||
                                      dropdownObj.label === "ECS" ||
                                      dropdownObj.label === "ONLINE" ||
                                      dropdownObj.label === "CHEQUE" ||
                                      dropdownObj.label === "UPI") ? (
                                      <>
                                        <Grid
                                          item
                                          lg={2}
                                          md={6}
                                          sm={6}
                                          sx={{ marginY: "0.5rem" }}
                                        >
                                          <InputField
                                            name={`paymentCart.[${index}].referenceNumber`}
                                            variant="outlined"
                                            label="Reference Number"
                                            control={control}
                                            error={
                                              errors.paymentCart?.[index]
                                                ?.referenceNumber
                                            }
                                            // type="number"
                                            onWheel={(e) => e.target.blur()}
                                          />
                                        </Grid>
                                      </>
                                    ) : null}

                                    {dropdownObj &&
                                    (dropdownObj.label === "DEBIT CARD" ||
                                      dropdownObj.label === "CREDIT CARD" ||
                                      dropdownObj.label === "ECS" ||
                                      dropdownObj.label === "ONLINE" ||
                                      dropdownObj.label === "CHEQUE") ? (
                                      <>
                                        <Grid
                                          item
                                          lg={2.5}
                                          md={6}
                                          sm={6}
                                          sx={{ marginY: "0.5rem" }}
                                        >
                                          <DropdownField
                                            control={control}
                                            name={`paymentCart.[${index}].bankName`}
                                            label="Bank Name"
                                            dataArray={bankList}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Bank Name *"
                                            error={
                                              errors.paymentCart?.[index]
                                                ?.bankName
                                            }
                                            searchIcon={true}
                                          />
                                        </Grid>
                                      </>
                                    ) : null}

                                    {/* Date Picker Component */}
                                    {dropdownObj &&
                                    (dropdownObj.label === "DEBIT CARD" ||
                                      dropdownObj.label === "CREDIT CARD" ||
                                      dropdownObj.label === "ECS" ||
                                      dropdownObj.label === "ONLINE" ||
                                      dropdownObj.label === "CHEQUE") ? (
                                      <>
                                        <Grid
                                          lg={2}
                                          sm={6}
                                          sx={{
                                            marginY: "1.6rem",
                                            marginLeft: 2,
                                          }}
                                        >
                                          <Controller
                                            control={control}
                                            defaultValue={new Date()}
                                            rules={{
                                              required: true,
                                            }}
                                            render={({ field }) => (
                                              <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                              >
                                                <DatePicker
                                                  inputProps={{
                                                    readOnly: true,
                                                  }}
                                                  renderInput={(props) => (
                                                    <TextField
                                                      className="bg-white"
                                                      fullWidth
                                                      size="small"
                                                      {...props}
                                                      InputLabelProps={{
                                                        style: {
                                                          fontSize: "14px",
                                                          position: "absolute",
                                                          top: "-2px",
                                                        },
                                                      }}
                                                      type="date"
                                                      variant="outlined"
                                                      label="Registration Date"
                                                      name="paymentDate"
                                                      sx={{
                                                        svg: {
                                                          color: "#1e3a8a",
                                                          height: 22,
                                                          width: "100%",
                                                          marginRight: "16px",
                                                        },
                                                        backgroundColor:
                                                          "white",
                                                        overflow: "visible",

                                                        "& .MuiOutlinedInput-root": {
                                                          "& .MuiOutlinedInput-input": {
                                                            // border: 0,
                                                            fontSize: 14,
                                                            height: "18px",
                                                            width: "100%",

                                                            borderColor:
                                                              "#0B83A5",
                                                            overflow: "hidden",
                                                          },
                                                          "& .MuiFormLabel-root": {
                                                            fontSize: "14px",
                                                          },
                                                        },
                                                      }}
                                                      // onClick={(e) => setOpenDate(true)}
                                                    />
                                                  )}
                                                  inputFormat="dd/MM/yyyy"
                                                  // disablePast
                                                  {...field}
                                                  onAccept={(e) => {}}
                                                />
                                              </LocalizationProvider>
                                            )}
                                            name={`paymentCart.[${index}].paymentDate`}
                                          />
                                        </Grid>
                                      </>
                                    ) : null}

                                    <div className="flex items-center">
                                      {/* If index is zero ; then only show the '+' icon button. */}
                                      {/* else show both the icon buttons '+' icon button and '-' icon button. */}
                                      {showAddIcon === true && index === 0 ? (
                                        <AddOutlinedIcon
                                          className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                          onClick={() => {
                                            if (netPayErrorMessage === "") {
                                              appendPaymentField(index);
                                            }
                                          }}
                                        />
                                      ) : null}

                                      {index > 0 && fields.length > 1 ? (
                                        <>
                                          <RemoveOutlinedIcon
                                            className="mt-2 mx-1 rounded-full border-2 border-red-600"
                                            onClick={() => {
                                              if (fields.length > 1) {
                                                removePaymentFieldRow(index);
                                              }
                                            }}
                                          />
                                          <AddOutlinedIcon
                                            className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                            onClick={() => {
                                              if (netPayErrorMessage === "") {
                                                appendPaymentField(index);
                                              }
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                  </Grid>
                                );
                              })}
                            </div>

                            {authorityDropdown ? (
                              <div className="mx-auto px-6 py-2 whitespace-nowrap">
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    lg={2.5}
                                    md={6}
                                    sm={6}
                                    sx={{ marginY: "0.5rem" }}
                                  >
                                    {displayCreditAuthorityLabel ? (
                                      <p className="md:mx-2 md:px-2 lg:px-2  text-red-700">
                                        Select Credit Authority
                                      </p>
                                    ) : null}
                                    <DropdownField
                                      control={control}
                                      name="concessionAuthority"
                                      label="Concession Authority"
                                      dataArray={concessionAuthorityArray}
                                      placeholder="Concession Authority"
                                      error={errors.concessionAuthority}
                                      isSearchable={false}
                                      inputRef={{
                                        ...register("concessionAuthority", {
                                          onChange: (e) => {
                                            let concessionAuthorityObj =
                                              e.target.value;

                                            if (
                                              concessionAuthorityObj &&
                                              concessionAuthorityObj.id !== 1
                                            ) {
                                              setDisplayCreditAuthorityLabel(
                                                false
                                              );
                                            }
                                          },
                                        }),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            ) : null}
                          </fieldset>

                          <Grid
                            item
                            lg={1.5}
                            md={1.5}
                            className="flex justify-end gap-2 py-2"
                          >
                            <CommonButton
                              label="Cancel"
                              type="button"
                              className="h-9  px-2 border border-red-700 text-red-700 rounded text-sm 2xl:text-base font-medium"
                              onClick={() => {
                                props.closePaymentModal();
                                let paymentObj = paymentModeList.filter(
                                  (item) => item.label === "CASH"
                                );
                                setValue(`paymentCart.[0].amount`, 0);
                                setValue(
                                  `paymentCart.[0].paymentMode`,
                                  paymentObj[0]
                                );
                                renderCount = 0;
                              }}
                            />
                            <CommonButton
                              label="Save"
                              type="button"
                              onClick={() => {
                                postOpdBillWithFieldArray();
                              }}
                              className="h-9 px-3  bg-green-700 text-white rounded text-base font-medium "
                            />
                          </Grid>
                        </div>
                      )}
                    </>
                  </div>
                </form>
              </fieldset>
            </div>
          </Box>
        </Modal>
      </div>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={confirmationHandleClose}
        confirmationSubmitFunc={() => {
          handleSubmit(onSubmitDataHandler)();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Payment Details?"
        confirmationButtonMsg="Save Payment"
      />

      {/* ConfirmationModal when there are no fieldArrays displayed on the modal. */}
      <ConfirmationModal
        confirmationOpen={openConfirmationForNoFields}
        confirmationHandleClose={closeConfirmationForNoFields}
        confirmationSubmitFunc={() => {
          handleSubmit(onSubmitDataHandler)();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Payment Details?"
        confirmationButtonMsg="Save Payment"
      />

      <ConfirmationModal
        confirmationOpen={billSettlementConfirmation}
        confirmationHandleClose={() => {
          //event listener for "Cancel" button.
          billSettlementConfirmationClose();
          correctTheLastFieldAmountValue();
        }}
        confirmationSubmitFunc={() => {
          //event listener for "Settle Bill" button.
          //the function which decides when to display the concessionAuthority dropdown.
          displayConcessionAuthorityDropdown();
          billSettlementConfirmationClose();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Do You Want To Settle This Bill ?"
        confirmationButtonMsg="Settle Bill"
      />
      <CommonBackDrop openBackdrop={openBackdrop} />
    </>
  );
}
