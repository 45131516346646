import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputArea from "../../../common/components/FormFields/InputArea";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { updateDrugAdministratorDetails } from "../../services/drugAdministrationChartService/DrugAdministartionChartService";

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  height: "35%",
  overflowX: "hidden",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

function DrugAdministratorGivenByModal(props) {
  //the object to reset the form to blank values
  const defaultValues = {
    id: "",
    newTime: new Date(),
    drugNote: "",
  };
  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    clearErrors,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [finalData, setFinalData] = useState({});

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => {
    if (openConfirmationModal) {
      setOpenConfirmationModal(false);
    }
  };
  // local storage
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let newTimeValue = watch("newTime");

  function posGivenBy(obj) {
    updateDrugAdministratorDetails(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.handleClose();
        props.populateTable();
        props.setSelectedRow(null);
        setFinalData({});
        reset(defaultValues);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        handleCloseConfirmationModal();
      });
  }

  function addRecord() {
    setOpenConfirmationModal(false);
    posGivenBy(finalData);
  }

  const onSubmitDataHandler = (data) => {
    let postObj = {
      addedBy: token.userId,
      administratorDate: new Date().toISOString(),
      administratorId: token.userId,
      administratorTime: newTimeValue.toISOString(),
      drugAdminDtlsId: props.selectedRow.id,
      drugAdminNote: data.drugNote,
      menuId: props?.menuId,
      privilege: props.privilege,
      isDrugAdminDone: props.selectedRow["Click To Administrator Drug"],
    };
    setFinalData(postObj);
    setOpenConfirmationModal(true);
  };
  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="h-[80%] w-[80%] p-4 ">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              reset(defaultValues);
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <h1 className="text-lg font-semibold my-2 flex justify-center">
              Edit Time
            </h1>
            <div className=" grid gap-2">
              <div className="w-full my-1">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    control={control}
                    name="newTime"
                    render={({ field }) => (
                      <DesktopTimePicker
                        label="Time"
                        value={field.value}
                        disabled
                        onChange={field.onChange}
                        renderInput={(params) => (
                          <TextField
                            className="bg-white"
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              svg: { color: "#0B83A5" },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <InputArea
                control={control}
                name="drugNote"
                label="Note"
                minRows={4}
                maxRows={5}
              />
              {props.userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <div className="flex items-center justify-end my-3">
                        <CommonButton
                          type="submit"
                          className="bg-customGreen text-white"
                          label="Save"
                          onClick={props.setPrivilege(obj.action)}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleCloseConfirmationModal}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Do You Really Want To AdministartorDrug On This Time ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
export default React.memo(DrugAdministratorGivenByModal);
