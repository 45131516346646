import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

export const getPatientForDemographic = (searchString, opdIpd) => {
  return apiClient.get(
    `/pathology/getPatientForDemographic/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const searchRequestsLists = (searchString, opdIpd) => {
  return apiClient.get(
    `/pathology/getPatientForDemographic/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

export const generatePatientInfoChangeRequest = (postObj) => {
  return apiClient.post(
    `/pathology/generatePatientInfoChangeRequest`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};


export const patientInfoChangeRequestList = (postObj) => {
  return apiClient.post(`/pathology/patientInfoChangeRequestList`, postObj, {
    headers: authHeader(),
  });
};


export const approvePtInfoChangeRequest = (postObj) => {
  return apiClient.post(`/pathology/approvePtInfoChangeRequest`, postObj, {
    headers: authHeader(),
  });
};