import React from "react";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import InputField from "../../common/components/FormFields/InputField";
//imports from the yup library
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import * as yup from "yup";
import CommonButton from "../../common/components/Buttons/CommonButton";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";
import { getPaymentModeList } from "../PharmacyServices";
import BillInformation from "./BillInformation";
// import { getPaymentModeList } from "../pharmacyServices/pharmaServices";

export default function IpdForm(props) {
  let loginObj = JSON.parse(localStorage.getItem("userInfo"));

  const [billModalDisplay, setBillModalDisplay] = React.useState(false);
  const [customError, setCustomError] = React.useState(false);
  const [patientAdvance, setPatientAdvance] = React.useState(0);

  const schema = yup.object().shape({
    totalItems: yup.string().required("Required"),

    totalQuantity: yup.string().required("Required"),

    totalAmount: yup.string().required("Required"),

    discountAmount: yup.string(),

    gstAmount: yup.string().required("Required"),
    roundingAmount: yup.string().required("Required"),
    netPayAmount: yup.string().required("Required"),
    paidAmt: yup.string().required("Required"),
    creditAmount: yup.string(),
  });

  const defaultValues = {
    remark: "",
    totalItems: "",
    totalQuantity: "",
    totalAmount: "",
    discountPercentage: "",
    discountAmount: "",
    gstAmount: "",
    roundingAmount: "",
    netPayAmount: "",
    paidAmt: "",
    creditAmount: "",
    cashPayment: true,
    credit: false,
    effectiveInIpd: false,
  };

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (props.calculatedFormData !== null) {
      setValue("totalItems", props.calculatedFormData.totalDrugItem);

      setValue("totalQuantity", props.calculatedFormData.totalQuantityOfDrugs);

      setValue("totalAmount", props.calculatedFormData.totalAmount);

      setValue("gstAmount", props.calculatedFormData.totalGSTAmount);

      setValue("netPayAmount", props.calculatedFormData.totalNetAmount);

      setValue("roundingAmount", props.calculatedFormData.totalRoundingAmount);

      setValue("paidAmt", props.calculatedFormData.totalPaidAmount);

      setValue("discountAmount", props.calculatedFormData.totalDiscountAmount);

      setValue(
        "discountPercentage",
        props.calculatedFormData.totalDiscountPercentage
      );
    }
  }, [props.calculatedFormData]);

  React.useEffect(() => {
    if (props.patientInfo === null) {
      reset(defaultValues);
    }
    if (props.patientInfo?.patientCategory === "Insurance") {
      setValue("credit", true);
      setValue("effectiveInIpd", true);
    } else if (props.patientInfo?.patientCategory === "Self") {
      setValue("cashPayment", true);
    } else if (props.patientInfo?.patientCategory === "Corporate") {
      setValue("credit", true);
      setValue("cashPayment", true);
    }
  }, [props.patientInfo]);

  React.useEffect(() => {
    if (props.selectedPatientType === "IPD") {
      reset(defaultValues);
    }
  }, [props.selectedPatientType]);

  React.useEffect(() => {
    if (
      props.patientInfo !== null &&
      props.patientInfo?.PatientAdvance !== null
    ) {
      setPatientAdvance(props.patientInfo?.PatientAdvance);
    }
  }, [props.patientInfo]);

  //When there is no prescription data ; then at that time clear the entire form.
  React.useEffect(() => {
    if (props.prescriptionLength === 0) {
      reset(defaultValues);
    }
  }, [props.prescriptionLength]);

  React.useEffect(() => {
    if (props.proceedButtonStatus === false) {
      reset(defaultValues);
      props.setPatientInfo(null);
      props.setValue("searchPatient", null);
    }
  }, [props.proceedButtonStatus]);

  let credit = watch("credit");

  const onSubmitDataHandler = (data) => {
    //to only execute the saving of form data when the Proceed button is clicked
    props.setProceedButtonStatus(true);

    props.setFinalFormData(data);

    //to set the form fields as blank
    // reset(defaultValues);
  };

  let cashPaymentMode = watch("cashPayment");

  let effectiveInIpd = watch("effectiveInIpd");

  const handleClosePaymentModal = () => {
    if (billModalDisplay === true) {
      setBillModalDisplay(false);
    }
  };

  const remarkString = watch("remark");
  let watchDiscount = watch("discountPercentage");

  const resetFunction = () => {
    reset(defaultValues);
    props.setPatientInfo(null);
    props.setValue("searchPatient", null);
    setPatientAdvance(0);
  };

  const [paymentModeList, setPaymentModeList] = React.useState([]);
  React.useEffect(() => {
    getPaymentModeList()
      .then((response) => response.data.result)
      .then((res) => {
        let temp = [...res];
        let requiredArr = [];
        for (let obj of temp) {
          let requiredObj = {};
          requiredObj.id = obj.id;
          requiredObj.label = obj.label;
          requiredObj.value = obj.value;
          requiredArr.push(requiredObj);

          setPaymentModeList(requiredArr);
        }
      });

    // let netPayAmount = props.getValues("totalBill");
  }, []);

  const [isProceedDisabled, setIsProceedDisabled] = React.useState(false);
  React.useEffect(() => {
    let temp = [...props.finalPrescriptions.result];
    for (let obj of temp) {
      if (
        obj.quantity === null ||
        obj.quantity === 0 ||
        props.isValidQuantity === false
      ) {
        setIsProceedDisabled(true);
      } else if (
        obj["prescribeQuantity"] &&
        obj["prescribeQuantity"] !== obj.quantity
      ) {
        setIsProceedDisabled(true);
      } else {
        setIsProceedDisabled(false);
      }
    }
  }, [props.finalPrescriptions]);

  const submitPaymentInfoModal = (data) => {};

  const checkValidSales = () => {
    let dataArray = [...props.finalPrescriptions.result];

    for (let medicine of dataArray) {
      if (Number(medicine?.quantity) > Number(medicine?.balanceQuantity)) {
        return false;
      } else if (
        medicine?.BatchesRequired === true &&
        medicine.batchNo === ""
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmitDataHandler);
          }}
        >
          <div className="grid grid-cols-2 gap-2">
            {/* Remark to total quantity fields */}
            <div className="grid grid-cols-4 gap-2">
              <div className="col-span-2">
                <InputField
                  name="remark"
                  variant="outlined"
                  label="Remark"
                  control={control}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </div>

              <div>
                <InputField
                  name="totalItems"
                  variant="outlined"
                  label="Total Items*"
                  type="number"
                  error={errors.totalItems}
                  control={control}
                  disabled={true}
                  defaultValue=""
                />
              </div>

              <div>
                <InputField
                  name="totalQuantity"
                  variant="outlined"
                  label="Total Qty.*"
                  type="number"
                  control={control}
                  error={errors.totalQuantity}
                  disabled={true}
                  defaultValue=""
                />
              </div>
            </div>

            {/* Total Amount to credit fields */}
            <div className="grid grid-cols-4 gap-2">
              <div>
                <InputField
                  name="totalAmount"
                  variant="outlined"
                  label="Total Amt.*"
                  type="number"
                  control={control}
                  error={errors.totalAmount}
                  disabled={true}
                  defaultValue=""
                />
              </div>

              <div>
                <TextField
                  size="small"
                  type="number"
                  defaultValue={0}
                  disabled={
                    props?.patientInfo === null ||
                    !loginObj?.isDiscountApplicable
                      ? true
                      : false
                  }
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  InputLabelProps={{
                    shrink: watchDiscount !== "" ? true : false,
                  }}
                  color={customError ? "error" : "primary"}
                  sx={{
                    input: {
                      color: customError ? "#d32f2f" : "#212529",
                    },
                    label: {
                      color: customError ? "#d32f2f" : "#212529",
                    },
                  }}
                  name="discountPercentage"
                  variant="outlined"
                  label="Disc.(%)"
                  {...register("discountPercentage")}
                  onChange={(e) => {
                    props.setDiscountPercent(e.target.value);
                    if (e.target.value > 100) {
                      setCustomError(true);
                    } else if (e.target.value === 100 || e.target.value < 100) {
                      setCustomError(false);
                    }
                  }}
                  control={control}
                />
              </div>

              <div>
                <InputField
                  name="discountAmount"
                  disabled={
                    props?.patientInfo === null ||
                    !loginObj?.isDiscountApplicable
                      ? true
                      : false
                  }
                  variant="outlined"
                  label="Disc.Amt"
                  type="number"
                  control={control}
                  error={errors.discountAmount}
                  inputRef={{
                    ...register(`discountAmount`, {
                      onChange: (e) => {
                        props.setDiscountAmount(e.target.value);
                      },
                    }),
                  }}
                />
              </div>

              <div>
                <InputField
                  name="creditAmount"
                  variant="outlined"
                  label="Credit"
                  type="number"
                  control={control}
                  error={errors.creditAmount}
                  disabled={true}
                  defaultValue=""
                />
              </div>
            </div>

            {/* GST Amount to Paid Amount */}
            <div className="grid grid-cols-4 gap-2">
              <div>
                <InputField
                  name="gstAmount"
                  variant="outlined"
                  label="GST Amount*"
                  type="number"
                  control={control}
                  error={errors.gstAmount}
                  disabled={true}
                  defaultValue=""
                />
              </div>

              <div>
                <InputField
                  name="roundingAmount"
                  variant="outlined"
                  label="Rounding Amt.*"
                  type="number"
                  control={control}
                  error={errors.roundingAmount}
                  disabled={true}
                  defaultValue=""
                />
              </div>

              <div>
                <InputField
                  name="netPayAmount"
                  variant="outlined"
                  label="Net Pay Amt.*"
                  type="number"
                  control={control}
                  error={errors.netPayAmount}
                  disabled={true}
                  defaultValue=""
                />
              </div>

              <div>
                <InputField
                  name="paidAmt"
                  disabled={
                    props.patientInfo === null ||
                    props.finalPrescriptions.result === null
                      ? true
                      : false
                  }
                  variant="outlined"
                  label="Paid Amt.*"
                  type="number"
                  control={control}
                  error={errors.paidAmt}
                />
              </div>
            </div>
            {/* From CheckBox to Proceed Button */}
            <div className="flex justify-between items-center">
              <div className="grid grid-cols-3 gap-4">
                <div className=" whitespace-nowrap">
                  <fieldset>
                    <CheckBoxField
                      control={control}
                      name="cashPayment"
                      label="Cash Payment"
                      placeholder="Cash Payment"
                    />
                  </fieldset>
                </div>

                <div className="ml-2">
                  <CheckBoxField
                    control={control}
                    name="credit"
                    label="Credit"
                    placeholder="Credit"
                  />
                </div>

                {credit === true ? (
                  <div className="m-1 whitespace-nowrap">
                    <CheckBoxField
                      control={control}
                      name="effectiveInIpd"
                      label="Effective In IPD"
                      placeholder="Effective In IPD"
                    />
                  </div>
                ) : null}
              </div>
              <div className="grid grid-cols-2">
                <div className="whitespace-nowrap flex gap-2"></div>

                <div className="whitespace-nowrap flex gap-2 justify-end">
                  <CommonButton
                    type="button"
                    className="h-9 px-3 border border-customRed text-customRed rounded text-base font-medium"
                    onClick={() => {
                      reset(defaultValues);
                      props.setPatientInfo(null);
                      props.setValue("searchPatient", null);
                      props.setResetQtyFlag(1);
                      props.setResetStates(props.resetStates + 1);
                    }}
                    label="Reset"
                  />

                  {props.userActions.map((actions) => (
                    <>
                      {!actions.isAction ? (
                        <>
                          {actions.action === "Create" ? (
                            <div
                              className=""
                              onClick={() => {
                                props.setPrivilege(actions.action);
                              }}
                            >
                              <CommonButton
                                label="Proceed"
                                className="h-9 px-3  bg-green-600 text-white rounded text-base font-medium"
                                type="submit"
                                onClick={(e) => {
                                  checkValidSales();
                                  e.preventDefault();
                                  props.setPrivilege(actions.action);
                                  if (
                                    cashPaymentMode === false &&
                                    props.calculatedFormData.totalDrugItem !==
                                      0 &&
                                    props.isValidQuantity === true &&
                                    checkValidSales() === true
                                  ) {
                                    handleSubmit(onSubmitDataHandler)();
                                    setBillModalDisplay(true);
                                  } else if (
                                    cashPaymentMode === true &&
                                    props.calculatedFormData.totalDrugItem !== 0
                                  ) {
                                    props.saveCounterSalesPureCash(
                                      props.calculatedFormData
                                    );
                                  } else if (props.patientInfo === null) {
                                    warningAlert(
                                      "Please select patient first..!"
                                    );
                                  } else if (props.isValidQuantity === false) {
                                    warningAlert("Quantity Can't Be Zero.");
                                  } else if (checkValidSales() === false) {
                                    warningAlert(
                                      "Please check records are valid..!"
                                    );
                                  }
                                }}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* //open this modal when cashpayment is uncheked */}
      {/* //open the confirmationmodal when the cashpayment is cheked */}
      {billModalDisplay ? (
        <BillInformation
          open={billModalDisplay}
          setOpen={setBillModalDisplay} //
          selectedPatient={props.patientInfo}
          closePaymentModal={handleClosePaymentModal}
          submitPaymentInfoModal={submitPaymentInfoModal}
          amountPayable={props.calculatedFormData.totalPaidAmount}
          companyId={0} //
          patientAdvance={patientAdvance}
          finalPrescriptions={props.finalPrescriptions} //
          netPayableAmountVal={props.calculatedFormData} //
          remarkString={remarkString} //
          resetFunction={resetFunction} //
          selectedPatientType={props.selectedPatientType} //
          setPatientInfo={props.setPatientInfo} //
          openPrintWindow={props.openPrintWindow} //
          setResetQtyFlag={props.setResetQtyFlag} //
          resetFields={props.resetFunction} //
          finalFormData={props.finalFormData} //
          patientInfo={props.patientInfo} //
          setOpenBackdrop={props.setOpenBackdrop} //
          privilege={props.privilege} //
          menuId={props.menuId} //
        />
      ) : null}
    </>
  );
}

