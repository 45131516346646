import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { approvePtInfoChangeRequest } from "../../../services/pathology/demographicServices/DemographicServices";

export default function ApprovedModal({
  open,
  handleClose,
  selectedApprovedRow,
  setSelectedApprovedRow,
  populateTable,
  locationValue,
  setOpenBackdrop,
}) {
  const {
    watch,
    control,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      searchByPatientName: null,
      patientType: "OPD",
      selectPrefix: true,
      selectFirstName: true,
      selectMiddleName: true,
      selectLastName: true,
      selectGender: true,
      selectBirth: true,
      prefix: null,
      firstName: "",
      middleName: "",
      lastName: "",
      gender: null,
      date: null,
      age: "",
      ageInYears: "",
      ageInMonths: "",
      ageInDays: "",
    },
  });

  let selectPrefixValue = watch("selectPrefix");
  let selectFirstNameValue = watch("selectFirstName");
  let selectMiddleNameValue = watch("selectMiddleName");
  let selectLastNameValue = watch("selectlastName");
  let selectGenderValue = watch("selectGenderName");
  let selectBirthValue = watch("selectBirth");

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  function handleSaveApprovedPatientInfoChangeRequest() {
    let tempObj = {
      requestId: selectedApprovedRow?.requestId,
      menuId: locationValue?.state?.menuId,
      opdIpd: selectedApprovedRow?.opdIpd,
      opdIpdId: selectedApprovedRow?.opdIpdId,
      privilege: "",
    };
    setOpenBackdrop(true);
    approvePtInfoChangeRequest(tempObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setSelectedApprovedRow(null);
        handleClose();
        populateTable();
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        errorAlert(error?.response?.data?.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
        populateTable();
        handleClose();
      });
  }
  console.log("error", selectedApprovedRow);

  useEffect(() => {
    if (selectedApprovedRow !== null) {
      setValue("prefix", {
        id: selectedApprovedRow?.PrefixId,
        value: selectedApprovedRow?.PrefixId,
        label: selectedApprovedRow?.prefix,
      });
      setValue("firstName", selectedApprovedRow?.FirstName);
      setValue("middleName", selectedApprovedRow?.MiddleName);
      setValue("lastName", selectedApprovedRow?.LastName);
      setValue("gender", {
        id: selectedApprovedRow?.GenderId,
        value: selectedApprovedRow?.GenderId,
        label: selectedApprovedRow?.gender,
      });
      // setValue("date", selectedApprovedRow?.DateofBirth);
      setValue("age", selectedApprovedRow?.ageInYear);
      setValue("ageInYears", selectedApprovedRow?.ageInYear);
      setValue("ageInMonths", selectedApprovedRow?.ageInMonth);
      setValue("ageInDays", selectedApprovedRow?.ageInDays);
      setValue("selectPrefix", selectedApprovedRow?.isPrefix);
      setValue("selectFirstName", selectedApprovedRow?.isFirstName);
      setValue("selectMiddleName", selectedApprovedRow?.isMiddleName);
      setValue("selectLastName", selectedApprovedRow?.isLastName);
      setValue("selectGender", selectedApprovedRow?.isGender);
      setValue("date", selectedApprovedRow?.isAgeInYear);
    }
  }, [selectedApprovedRow]);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="min-w-[60%] max-w-[60%] rounded-md h-[30%] 2xl:h-[25%] overflow-y-auto"
        >
          <h1 className="text-center font-semibold text-customBlue text-xl">
            Approved
          </h1>
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          {/* <div className="grid grid-cols-2 gap-2 items-center p-2 border rounded bg-gray-100 font-semibold text-sm text-gray-600 mt-1">
            <div>
              <h5 className="text-customBlue flex space-x-[53.5px]">
                <span> Patient Name &nbsp;</span>
                <span> : &nbsp;{selectedApprovedRow?.["Patient Name"]}</span>
              </h5>
            </div>
            <div>
              <h5 className="flex space-x-[102.5px]">
                <span>MR No.&nbsp;</span>
                <span> : &nbsp;{selectedApprovedRow?.MRNo}</span>
              </h5>
            </div>
            <div>
              <h5>
                Requested Date Time &nbsp; : &nbsp;
                {selectedApprovedRow?.["Requested Date Time"]}
              </h5>
            </div>
            <div>
              <h5>
                Request Generated By &nbsp; : &nbsp;
                {selectedApprovedRow?.["Request Generated By"]}
              </h5>
            </div>
          </div> */}

          <div className="grid grid-cols-4  gap-3 px-5 py-5 ">
            <div className="w-full flex items-center">
              <CheckBoxField
                control={control}
                name="selectPrefix"
                style={{ padding: "0px", marginLeft: "0px" }}
                disabled={!selectPrefixValue}
              />
              <DropdownField
                control={control}
                name="prefix"
                placeholder="Prefix"
                disabled={!selectPrefixValue}
              />
            </div>
            <div className="flex items-center pl-3">
              <CheckBoxField
                control={control}
                name="selectFirstName"
                style={{ padding: "0px", marginLeft: "0px" }}
                disabled={!selectFirstNameValue}
              />
              <InputField
                control={control}
                name="firstName"
                label="First Name"
                disabled={!selectFirstNameValue}
              />
            </div>
            <div className="flex items-center pl-3">
              <CheckBoxField
                control={control}
                name="selectMiddleName"
                style={{ padding: "0px", marginLeft: "0px" }}
                disabled={!selectMiddleNameValue}
              />

              <InputField
                control={control}
                name="middleName"
                label="Middle Name"
                disabled={!selectMiddleNameValue}
              />
            </div>
            <div className="flex items-center pl-3">
              <CheckBoxField
                control={control}
                name="selectLastName"
                style={{ padding: "0px", marginLeft: "0px" }}
                disabled={!selectLastNameValue}
              />

              <InputField
                control={control}
                name="lastName"
                label="Last Name"
                disabled={!selectLastNameValue}
              />
            </div>
            <div className="flex items-center ">
              <CheckBoxField
                control={control}
                name="selectGender"
                style={{ padding: "0px", marginLeft: "0px" }}
                disabled={!selectGenderValue}
              />

              <DropdownField
                control={control}
                name="gender"
                placeholder="Gender"
                isDisabled={true}
                disabled={!selectGenderValue}

                // dataArray={genderOptions}
              />
            </div>

            <div className="flex space-x-2 items-center pl-3">
              {/* <InputLimit
                maxDigits={3}
                name="age"
                variant="outlined"
                label="Age *"
                control={control}
                disabled={true}
              /> */}
              <CheckBoxField
                control={control}
                name="date"
                style={{ padding: "0px", marginLeft: "0px" }}
                disabled={!selectBirthValue}
              />
              <InputField
                name="ageInYears"
                variant="outlined"
                label="Years"
                control={control}
                disabled={!selectBirthValue}
              />

              <InputField
                control={control}
                name="ageInMonths"
                label="Month"
                disabled={true}
              />

              <InputField
                control={control}
                name="ageInDays"
                label="Days"
                disabled={true}
              />
            </div>
          </div>
          <div className="flex justify-end ">
            <CommonButton
              type="button"
              label="Approved"
              className="bg-customGreen text-white"
              onClick={() => setOpenConfirmationModal(true)}
            />
          </div>

          {/* <div className="my-2 flex justify-end">
            <CommonButton
              type="button"
              label="Approved"
              className="bg-customGreen text-white"
              onClick={() => setOpenConfirmationModal(true)}
            />
          </div> */}
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={handleSaveApprovedPatientInfoChangeRequest}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to approved this record ?"
        confirmationButtonMsg="Update"
      />
    </div>
  );
}

