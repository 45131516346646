import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import AddBtn from "../../../../common/components/Buttons/AddBtn";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputEnter from "../../../../common/components/FormFields/InputEnter";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchKitList,
  fetchPatientDetails,
  fetchPatientList,
} from "../../../services/commonServices/CommonServices";
import {
  fetchAlternateDrugNameTable,
  fetchDrugNameTable,
  fetchPrescribedBy,
  postPatientIndent,
} from "../../../services/generalStore/PatientIndentServices/PatientIndentServices";
import { fetchItemKitData } from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import AlternateDrugTable from "../storeIndent/AlternateDrugTable";
import DrugTable from "../storeIndent/DrugTable";
import IndentListModal from "./IndentListModal";
import ItemDetailsTable from "./ItemDetailsTable";

//radio button data
const IsUrgentRadio = [
  {
    id: "Yes",
    value: "Yes",
    label: "Yes",
  },
  {
    id: "No",
    value: "No",
    label: "No",
  },
];

const PatientIndent = (props) => {
  const schema = yup.object().shape({
    indentToStore: yup
      .object()
      .required("Required")
      .nullable()
      .when("indentTo", (indentTo) => {
        if (indentTo === "Store") {
          return yup.object().nullable().required("Required").shape({
            value: yup.string(),
            label: yup.string(),
          });
        } else if (indentTo === "Unit") {
          return yup.object().nullable().notRequired().shape({
            value: yup.string(),
            label: yup.string(),
          });
        }
      }),
  });
  const defaultValues = {
    totalAmount: 0,
    itemkitValue: null,
    netPayableAmount: 0,
    itemClass: null,
    indentToStore: null,
    roundedAmount: 0,
    "input-1": "",
    "input-2": "",
    itemKitQty: "",
    isUrgentRadio: "No",
    searchPatient: null,
    remark: "",
    presribedBy: null,
  };
  const {
    watch,
    trigger,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  //
  //search string of "search by item/input-1" input
  const [drugSearchString, setDrugSearchString] = useState("");
  // IndentListModal
  const [openIndentListModal, setOpenIndentListModal] = useState(false);
  const handleOpenIndentListModal = () => setOpenIndentListModal(true);
  const handleCloseIndentListModal = () => setOpenIndentListModal(false);
  //table data
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [drugTableData, setDrugTableData] = useState([]);
  const [altDrugTableData, setAltDrugTableData] = useState([]);
  const [selectedGenericId, setSelectedGenericId] = useState();
  const [selectedDrugRowData, setSelectedDrugRowData] = useState(null);
  //last selected index
  const [lastIndex, setLastIndex] = useState();
  // itm drg tbl
  const [selectedItemDtlsID, setSelectedItemDtlsID] = useState(null);
  //Alt drug tbl Row id
  const [selectedAltDrugRowID, setSelectedAltDrugRowID] = useState(null);
  // drug tbl Row id
  const [selectedDrugRowID, setSelectedDrugRowID] = useState({});
  const [selectedDrugData, setSelectedDrugData] = useState([]);
  //item Kit List
  const [itemKitList, setItemKitList] = useState();
  const [searchItemKitValue, setSearchItemKitValue] = useState(null);
  const [itemKitData, setItemKitData] = useState([]); // selected ItemKitData
  //error tbl inpt
  const [errorToTblInput, setErrorToTblInput] = useState(false);
  //store name
  const [storeName, setStoreName] = useState([]);
  const [prescribedByOptionsList, setPrescribedByOptionsList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);
  const [isValidQuantity, setIsValidQuantity] = useState(true);
  //backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [privilege, setPrivilege] = useState("");
  const [userActions, setUserActions] = useState([]);
  const location = useLocation();

  // use ref for setting up focus into inputfield
  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const FocusDrugTable = useRef();
  const removeFocusSearch = useRef(null);
  const inputRefItemKitQty = useRef(null);
  const AddClickRefrence = useRef();
  const toStoreDropdownRef = useRef(null);
  const presribedByRef = useRef(null);
  const patientSearchRef = useRef(null);
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let storeId = token.storeId;
  // watch variables
  let isUrgent = watch("isUrgentRadio");
  let isUrgentType;
  let itemKit = watch("itemkitValue");
  let deptStore1 = watch("indentToStore");
  let watchInput1 = watch("input-1");
  let presribedByValue = watch("presribedBy");
  let patientSearchValue = watch("searchPatient");
  let quantityValue = watch("input-2");
  let quantityKitQty = watch("itemKitQty");

  if (isUrgent === "Yes") {
    isUrgentType = true;
  } else if (isUrgent === "No") {
    isUrgentType = false;
  }

  const handleViewIndentModal = () => {
    handleOpenIndentListModal();
  };
  // patient list autocomplet //fetchPatientDetails
  const searchPatient = (e) => {
    if (e !== "") {
      fetchPatientList(e)
        .then((response) => response.data)
        .then((res) => {
          setPatientList(res.result);
        });
    }
  };

  const searchPrescribedBy = (searchString) => {
    if (searchString !== "") {
      fetchPrescribedBy(searchString)
        .then((response) => response.data)
        .then((res) => {
          setPrescribedByOptionsList(res.result);
        });
    }
  };
  //search by item kit

  const searchByItemKit = (autoSearchString) => {
    if (autoSearchString !== "") {
      fetchKitList(autoSearchString)
        .then((response) => response.data)
        .then((res) => {
          setItemKitList(res.result);
        });
    }
  };
  // Alternate drug name data altDrugObj
  const getAlternateDrugNameTable = () => {
    if (selectedDrugRowData !== null && selectedDrugRowData !== undefined) {
      let altDrugObj = {
        deptStore: 1,
        deptStoreId: deptStore1?.id,
        genericId: selectedDrugRowData.ItemGenericId,
        isConsignment: selectedDrugRowData.IsConsignment ? 1 : 0,
        itemCode: "%", //selectedDrugRowData.Code,
        itemName: "%", //selectedDrugRowData.Drug,
      };
      fetchAlternateDrugNameTable(altDrugObj)
        .then((response) => response.data)
        .then((res) => {
          setAltDrugTableData(res.result);
        });
    }
  };

  //
  const removeFocusInputSearch = () => {
    if (removeFocusSearch.current) {
      removeFocusSearch.current.blur();
    }
  };

  //
  const mergeArray = (arr) => {
    const map = new Map();
    arr.forEach((val) => {
      const existing = map.get(val.Id);
      if (existing) {
        existing["Indent Qty"] += val["Indent Qty"];
      } else {
        map.set(val.Id, { ...val });
      }
    });
    return Array.from(map.values());
  };

  const addToItemDetailsTbl = () => {
    if (quantityValue !== "") {
      selectedDrug["Indent Qty"] = parseInt(quantityValue);
      selectedDrug.Amount = quantityValue * selectedDrug.MRP;
      let arr = [...selectedDrugData];
      const existingItem = arr.find((item) => item.Id === selectedDrug.Id);
      if (existingItem) {
        warningAlert("Drug already exists!");
        inputRefOne.current.focus();
        setValue("input-1", "");
        setValue("input-2", "");
        setSelectedDrug(null);

        setDrugSearchString("");
        return;
      }

      arr.push(selectedDrug);
      setSelectedDrugData(mergeArray(arr));

      let totalQuantity = 0;
      for (let i = 0; i < arr.length; i++) {
        totalQuantity += arr[i]["Indent Qty"];
      }
      let totalAmount = 0;
      for (let i = 0; i < arr.length; i++) {
        let quantity = arr[i]["Indent Qty"];
        let amount = quantity * arr[i].MRP;
        arr[i].Amount = amount;
        totalAmount += amount;
      }
      setValue("input-1", "");
      setValue("input-2", "");
      setSelectedDrug(null);
    }

    // Focus on input 1 after adding
    const e = { target: { name: "input-0" } };
    onEnterFocusNext(e);
  };

  const addToItemKitTbl = () => {
    if (itemKitData?.length > 0) {
      for (let obj of itemKitData) {
        if (quantityKitQty !== "") {
          let qty = Number(quantityKitQty);
          if (!isNaN(qty)) {
            obj["Indent Qty"] = obj.Quantity * qty;
            obj.Amount = qty * obj.MRP;
            let arr = [...selectedDrugData];
            const newArr = arr.concat(itemKitData);
            setSelectedDrugData(mergeArray(newArr));
            setValue("itemkitValue", null);
            setValue("itemKitQty", "");
          } else {
            console.error("quantityKitQty is not a valid number");
          }
        }
      }
    } else {
      errorAlert("No Kit Data Found!");
    }
  };

  function focusServicesSearchDropdown() {
    let fieldsetTag = AddClickRefrence.current;
    let inputTagCollection = fieldsetTag.getElementsByTagName("input");
    let inputTagOne = inputTagCollection[0];
    inputTagOne.focus();
  }

  const onEnterFocusNext = (e) => {
    if (e && e.target) {
      const name = e.target.name;
      const [fieldName, fieldIndex] = name.split("-");
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=input-${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  //finalObj
  const handleFormSubmit = async () => {
    try {
      if (!patientDetails) {
        warningAlert("Please Select Patient!");
        return;
      }

      if (selectedDrugData.length === 0) {
        warningAlert("Please Create Indent!");
        return;
      }

      if (errorToTblInput) {
        warningAlert("Please Issue Valid Quantity!");
        return;
      }

      if (!errorToTblInput && selectedDrugData.length > 0 && isValidQuantity) {
        setOpenConfirmationModal(true);
      } else {
        errorAlert("Quantity Exceed");
      }
    } catch (error) {
      errorAlert(error.message);
    }
  };

  // enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleConfirmation = async () => {
    try {
      setOpenBackdrop(true);
      setOpenConfirmationModal(false);

      // Rest of your code for building TblData and finalObj
      const TblData = selectedDrugData.map((item) => ({
        isClosed: false,
        itemId: item.Id,
        indentDetailsId: item.indentDetailsId || 0,
        pendIssueQty: item["Indent Qty"],
        pendQty: item["Indent Qty"],
        rate: item.MRP,
        reqdQty: item["Indent Qty"],
        totalCost: item.Amount,
      }));

      const finalObj = {
        menuId: location?.state?.menuId || props.menuId,
        privilege,
        addedBy: token.userId,
        admissionId: patientDetails.AdmissionId,
        drId: 0,
        indentId: 0,
        isUrgent: isUrgentType,
        cashCounterId: token.cashCounterId,
        quantity: getValues("totalQuantity"),
        remark: getValues("remark"),
        totalValue: getValues("totalAmount"),
        totalVatAmount: 0,
        updatedBy: token.userId,
        fromStoreId: token.storeId,
        toStoreId: deptStore1?.id || 0,
        totalAmountPayable: getValues("netPayableAmount"),
        patientIndentDtlsDto: TblData,
      };

      const response = await postPatientIndent(finalObj);
      if (response.data.statusCode === 302) {
        warningAlert(response.data.message);
      } else if (response.data.statusCode === 200) {
        successAlert(response.data.message);
        reset(defaultValues);
        setOpenBackdrop(false);
        setSelectedDrugData([]);
        if (props.menuId) {
          props.setOpen(false);
        }
        setPatientList([]);
        setPatientDetails(null);
        setAltDrugTableData([]);
        setDrugTableData([]);
        setSelectedAltDrugRowID(null);
        setSelectedDrug(null);
        setSelectedDrugRowData(null);
        setPrescribedByOptionsList([]);
      }
    } catch (error) {
      errorAlert(error.message);
    } finally {
      setOpenBackdrop(false);
    }
  };

  useEffect(() => {
    if (itemKit !== null) {
      inputRefItemKitQty.current?.focus();
    }
    if (deptStore1 === null) {
      let fieldsetTag = toStoreDropdownRef.current;
      let inputTagCollection = fieldsetTag.getElementsByTagName("input");
      let inputTagOne = inputTagCollection[0];
      inputTagOne.focus();
    }
    if (
      watchInput1 === "" &&
      deptStore1 !== null &&
      props.patientInfo === undefined &&
      patientDetails !== null
    ) {
      inputRefOne.current.focus();
    }
    if (
      watchInput1 === "" &&
      deptStore1 !== null &&
      props.patientInfo !== undefined
    ) {
      inputRefOne.current.focus();
    }

    if (presribedByValue !== null && patientSearchValue === null) {
      let fieldsetTag = patientSearchRef.current;
      let inputTagCollection = fieldsetTag.getElementsByTagName("input");
      let inputTagOne = inputTagCollection[0];
      inputTagOne.focus();
    }
    if (deptStore1 === null) {
      setValue("searchPatient", null);
      setValue("presribedBy", null);
    }
  }, [
    watchInput1,
    deptStore1,
    patientDetails,
    presribedByValue,
    patientSearchValue,
    props.patientInfo,
  ]);

  useEffect(() => {
    if (deptStore1 !== null) {
      let fieldsetTag = presribedByRef.current;
      let inputTagCollection = fieldsetTag.getElementsByTagName("input");
      let inputTagOne = inputTagCollection[0];
      inputTagOne.focus();
    }
  }, [deptStore1]);

  useEffect(() => {
    if (itemKit !== null) {
      inputRefItemKitQty.current?.focus();
    }
  }, [itemKit]);

  useEffect(() => {
    if (location?.state?.menuId || props.menuId) {
      fetchUserActionsByMenuId(
        props.menuId ? props.menuId : location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
    }
  }, [location, props.menuId]);

  useEffect(() => {
    const drugListObj = {
      deptStore: 1,
      deptStoreId: deptStore1?.id,
      isConsignment: 0,
      itemCode: "%",
      itemName: "%",
      searchString: drugSearchString,
      deptStore1: storeId, //to store
      itemClass: 0,
      storePatient: 0,
    };
    if (drugSearchString !== "") {
      fetchDrugNameTable(drugListObj)
        .then((response) => response.data)
        .then((res) => {
          setDrugTableData(res.result);
        });
    }
  }, [drugSearchString]);

  //
  useEffect(() => {
    setValue("fromStore", token.storeName);
    fetchApplicableStores(token.storeId)
      .then((response) => response.data)
      .then((res) => {
        setStoreName(res.result);
      });
  }, [token.storeId, token.storeName]);

  useEffect(() => {
    if (patientSearchValue !== null) {
      fetchPatientDetails(patientSearchValue.id)
        .then((response) => response.data)
        .then((res) => {
          if (res.result?.IsNursingClearanceDone) {
            warningAlert(
              "Nursing clearance has been done indent can not be created for this patient."
            );
            setPatientDetails(null);
          } else {
            if (res.result?.LockIndent) {
              warningAlert(
                "Patient Is On Cash Kindly Contact Billing And Inform Relative "
              );
            }
            setPatientDetails(res.result);
          }
        });
    } else if (patientSearchValue === null && props.patientInfo === undefined) {
      if (props.patientInfo?.IsNursingClearanceDone) {
        warningAlert("Nursing clearance has been given to this patient.");
        setPatientDetails(null);
      } else {
        setPatientDetails(props.patientInfo);
        if (props?.patientInfo?.LockIndent) {
          warningAlert(
            "Patient Is On Cash Kindly Contact Billing And Inform Relative "
          );
        }
        setDrugTableData([]);
        setAltDrugTableData([]);
        setSelectedDrugData([]);
      }
    } else {
      if (props.patientInfo?.IsNursingClearanceDone) {
        warningAlert("Nursing clearance has been given to this patient.");
        setPatientDetails(null);
      } else {
        if (props?.patientInfo?.LockIndent) {
          warningAlert(
            "Patient Is On Cash Kindly Contact Billing And Inform Relative "
          );
        }
        setPatientDetails(props.patientInfo);
      }
    }
  }, [patientSearchValue, props.patientInfo]);

  useEffect(() => {
    if (searchItemKitValue !== null) {
      fetchItemKitData(
        searchItemKitValue && searchItemKitValue.id,
        token.storeId
      )
        .then((response) => response.data)
        .then((res) => {
          setItemKitData(res.result);
        });
    }
  }, [selectedDrugRowData, searchItemKitValue]);

  useEffect(() => {
    getAlternateDrugNameTable();
    if (selectedDrug !== null && patientDetails !== null) {
      setValue(
        "input-1",
        selectedDrug && selectedDrug["Item Name"]
          ? selectedDrug["Item Name"]
          : selectedDrug.Drug
      );
      inputRefTwo.current.focus();
    }
  }, [selectedDrug, selectedDrugRowData]);
  console.log("patientDetails", patientDetails);

  return (
    <>
      <div className="mt-12">
        <h1 className="text-center text-black font-bold text-xl flex justify-center my-2">
          Patient Indent
        </h1>
        <form>
          <div className="grid grid-cols-6 xl:grid-cols-10 gap-3">
            <div className="col-span-3 lg:col-span-2 ">
              <InputField
                control={control}
                name="fromStore"
                disabled={token.storeId ? true : false}
                label="Indent From Store"
              />
            </div>

            {/* //Indent To Store// */}
            <div
              className="col-span-3 lg:col-span-2 xl:col-span-3 z-50"
              ref={toStoreDropdownRef}
            >
              <DropdownField
                control={control}
                name="indentToStore"
                error={errors.indentToStore}
                placeholder="To Store"
                isSearchable={true}
                isClearable={true}
                dataArray={storeName}
                inputRef={{
                  ...register(`indentToStore`, {
                    onChange: (e) => {
                      if (e.target.value === null) {
                        setValue("indentToStore", null);
                      }
                    },
                  }),
                }}
              />
            </div>
            <div className="col-span-2 flex md:justify-start gap-2 items-center text-sm whitespace-nowrap">
              <p className="text-gray-700 font-semibold">
                Is Urgent &nbsp; : &nbsp;
              </p>
              <div className="mt-1">
                <RadioField
                  label=""
                  name="isUrgentRadio"
                  control={control}
                  dataArray={IsUrgentRadio}
                />
              </div>
            </div>
            <div
              className="col-span-4 lg:col-span-2 xl:col-span-3"
              ref={presribedByRef}
            >
              <SearchDropdown
                name="presribedBy"
                control={control}
                placeholder="Prescribed By"
                dataArray={prescribedByOptionsList}
                onChange={handleKeyPress}
                handleInputChange={searchPrescribedBy}
              />
            </div>

            {/* searchBar  */}
            <div
              className="col-span-4 lg:col-span-3  xl:col-span-4 z-40"
              ref={patientSearchRef}
            >
              <SearchDropdown
                control={control}
                name="searchPatient"
                isDisabled={
                  props.patientInfo || deptStore1 === null ? true : false
                }
                placeholder="Search By Patient Name / Indent No."
                searchIcon={true}
                isSearchable={true}
                isClearable
                dataArray={patientList}
                onChange={handleKeyPress}
                handleInputChange={searchPatient}
              />
            </div>

            {/* buttons */}
            <div className="2xl:col-span-6 flex justify-end">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "View" ? (
                    <>
                      <CommonButton
                        type="button"
                        className="bg-customBlue text-white"
                        label="View Indent"
                        onClick={() => {
                          handleViewIndentModal();
                          setPrivilege(obj.action);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>

          {/* patient Details */}
          <fieldset
            className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
          >
            <div className=" grid grid-cols-4 lg:grid-cols-5 ">
              <div className="flex items-center gap-2 w-full col-span-4 lg:col-span-2 font-semibold text-sm py-1">
                <span className=" font-semibold w-24">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientDetails?.patientName ||
                      patientDetails?.["Patient Name"] ||
                      props?.selectedPatient?.PatientName}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 lg:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold md:w-24 lg:w-14">IPD No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientDetails?.IpdNo || props?.selectedPatient?.IpdNo}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 lg:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-8">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientDetails?.AgeYear || props?.selectedPatient?.AgeYear}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 lg:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold md:w-24 lg:w-14">Bed No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientDetails?.BedNo ||
                      patientDetails?.["Bed No"] ||
                      props?.selectedPatient?.BedNo}
                  </span>
                </div>
              </div>
            </div>
          </fieldset>

          {/* item search */}
          <div className="mt-2">
            <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
              Item Details
            </span>
            <div className="grid lg:grid-cols-2 gap-2 w-full mt-2">
              <div className="flex space-x-2">
                <fieldset
                  className="col-span-2 w-full md:mb-3 md:col-span-4 lg:mb-0"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setValue("input-1", "");
                      setDrugSearchString("");
                      setSelectedDrug(null);
                    } else {
                      setDrugSearchString(e.target.value);
                    }
                  }}
                >
                  <InputEnter
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        onEnterFocusNext();
                      }
                    }}
                    control={control}
                    name="input-1"
                    label="Search by Item "
                    placeholder="Search by Item"
                    disabled={patientDetails === null ? true : false}
                    {...register("input-1")}
                    inputRef={inputRefOne}
                  />
                </fieldset>

                <div className="lg:w-24">
                  <Controller
                    control={control}
                    name="input-2"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            addToItemDetailsTbl();
                          }
                          if (e.key === "-" || e.key === "+") {
                            e.target.blur();
                          }
                        }}
                        inputRef={inputRefTwo}
                        size="small"
                        type="number"
                        InputLabelProps={{
                          style: { fontSize: "14px" },
                        }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            height: "36.3px",
                            background: "white",
                          },
                        }}
                        name="input-2"
                        variant="outlined"
                        label="Qty"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setValue("input-2", newValue);
                          if (newValue === "0") {
                            setValue("input-2", "1");
                          }
                        }}
                        disabled={patientDetails === null ? true : false}
                      />
                    )}
                  />
                </div>

                <div>
                  <AddBtn
                    type="button"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        addToItemDetailsTbl();
                      }
                    }}
                    onClick={addToItemDetailsTbl}
                  />
                </div>

                <div className="  bg-gray-500 xl:grid md:hidden lg:hidden ">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem={true}
                  />
                </div>
              </div>

              <div className="flex space-x-2 w-full">
                <div className="w-full z-30" ref={AddClickRefrence}>
                  <SearchDropdown
                    control={control}
                    name="itemkitValue"
                    label="Search By Item Kit"
                    placeholder="Search by Item Kit"
                    searchIcon={true}
                    isDisabled={patientDetails === null ? true : false}
                    isSearchable={true}
                    isClearable={true}
                    handleInputChange={searchByItemKit} //searchString
                    dataArray={itemKitList}
                    inputRef={{
                      ...register(`itemkitValue`, {
                        onChange: (e) => {
                          if (e.target.value === null) {
                            setSearchItemKitValue(null);
                            setValue("itemKitQty", "");
                          } else {
                            setSearchItemKitValue(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div className="lg:w-24">
                  <Controller
                    control={control}
                    name="itemKitQty"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            addToItemKitTbl();
                            focusServicesSearchDropdown();
                          }
                          if (e.key === "-" || e.key === "+") {
                            e.target.blur();
                          }
                        }}
                        inputRef={inputRefItemKitQty}
                        size="small"
                        type="number"
                        InputLabelProps={{
                          style: { fontSize: "14px" },
                        }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            height: "36.3px",
                            background: "white",
                          },
                        }}
                        name="itemKitQty"
                        variant="outlined"
                        label="Qty"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // Use setValue from react-hook-form to update the value
                          setValue("itemKitQty", newValue);
                          if (newValue === "0") {
                            setValue("itemKitQty", "1");
                          }
                        }}
                        disabled={deptStore1 === null ? true : false}
                      />
                    )}
                  />
                </div>

                <div>
                  <AddBtn
                    type="button"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    onClick={() => {
                      addToItemKitTbl();
                      setSearchItemKitValue(null);
                      focusServicesSearchDropdown();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-2 gap-3">
            <div>
              {drugTableData.length > 0 && watchInput1 !== "" ? (
                <>
                  <div>
                    <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                      Drug Name
                    </span>
                  </div>
                  <div ref={FocusDrugTable}>
                    <DrugTable
                      data={drugTableData}
                      selectedItemDtlsID={selectedItemDtlsID}
                      setSelectedDrug={setSelectedDrug}
                      setSelectedItemDtlsID={setSelectedItemDtlsID}
                      setDrugTableData={setDrugTableData}
                      setAltDrugTableData={setAltDrugTableData}
                      selectedGenericId={selectedGenericId}
                      setSelectedGenericId={setSelectedGenericId}
                      setSelectedDrugRowData={setSelectedDrugRowData}
                      setSelectedDrugData={setSelectedDrugData}
                      selectedDrugData={selectedDrugData}
                      selectedDrugRowID={selectedDrugRowID}
                      setSelectedDrugRowID={setSelectedDrugRowID}
                      lastIndex={lastIndex}
                      setLastIndex={setLastIndex}
                      setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                      selectedAltDrugRowID={selectedAltDrugRowID}
                      setDrugSearchString={setDrugSearchString}
                      removeFocusInputSearch={removeFocusInputSearch}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div>
              {altDrugTableData.length > 0 &&
              drugTableData.length &&
              watchInput1 !== "" ? (
                <>
                  <div>
                    <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                      Alternate Drug Name
                    </span>
                  </div>

                  <div>
                    <AlternateDrugTable
                      data={altDrugTableData}
                      setSelectedDrug={setSelectedDrug}
                      selectedItemDtlsID={selectedItemDtlsID}
                      setSelectedItemDtlsID={setSelectedItemDtlsID}
                      setAltDrugTableData={setAltDrugTableData}
                      setDrugTableData={setDrugTableData}
                      setSelectedDrugData={setSelectedDrugData}
                      selectedDrugData={selectedDrugData}
                      setSelectedDrugRowData={setSelectedDrugRowData}
                      selectedAltDrugRowID={selectedAltDrugRowID}
                      setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                      setSelectedDrugRowID={setSelectedDrugRowID}
                      selectedDrugRowID={selectedDrugRowID}
                      setDrugSearchString={setDrugSearchString}
                      lastIndex={lastIndex}
                      removeFocusInputSearch={removeFocusInputSearch}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="mt-2">
            <ItemDetailsTable
              data={selectedDrugData}
              selectedDrug={selectedDrug}
              setSelectedDrugData={setSelectedDrugData}
              selectedItemDtlsID={selectedItemDtlsID}
              setSelectedItemDtlsID={setSelectedItemDtlsID}
              FocusDrugTable={FocusDrugTable}
              drugTableData={drugTableData}
              onEnterFocusNext={onEnterFocusNext}
              setErrorToTblInput={setErrorToTblInput}
              errorToTblInput={errorToTblInput}
              setIsValidQuantity={setIsValidQuantity}
              setOpenConfirmationModal={setOpenConfirmationModal}
            />
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="">
              <TextField
                id="outlined-multiline-static"
                name="remark"
                {...register("remark")}
                label="Remark"
                multiline
                InputLabelProps={{ shrink: true }}
                rows={1}
                fullWidth
              />
            </div>
            {/* buttons */}
            <div className="flex space-x-3 justify-end">
              {!props.patientInfo?.IsNursingClearanceDone &&
                userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Create" ? (
                      <>
                        <CommonButton
                          type="button"
                          className="border border-customRed text-customRed"
                          label="Reset"
                          onClick={() => {
                            setValue("totalAmount", "");
                            setValue("indentToStore", null);
                            setValue("input-1", "");
                            setValue("input-2", "");
                            setValue("isUrgentRadio", "No");
                            setValue("itemKitQty", "");
                            setValue("netPayableAmount", "");
                            setValue("remark", "");
                            setValue("searchPatient", null);
                            setValue("itemkitValue", null);
                            setValue("itemClass", null);
                            setValue("presribedBy", null);
                            setPatientDetails(null);
                            setDrugTableData([]);
                            setSelectedAltDrugRowID(null);
                            setSelectedDrug(null);
                            setSelectedDrugData([]);
                            setSelectedDrugRowData(null);
                            setIsValidQuantity(true);
                          }}
                        />
                        <CommonButton
                          type="button"
                          className="bg-customGreen text-white"
                          label="Save"
                          onClick={() => {
                            setPrivilege(obj.action);
                            trigger();
                            handleFormSubmit();
                          }}
                        />
                      </>
                    ) : null}
                  </>
                ))}
            </div>
          </div>
        </form>
      </div>

      {/* indent list modal */}
      {openIndentListModal ? (
        <IndentListModal
          open={openIndentListModal}
          setOpen={setOpenIndentListModal}
          handleOpen={handleOpenIndentListModal}
          handleClose={handleCloseIndentListModal}
          userActions={userActions}
          menuId={location?.state?.menuId}
          privilege={privilege}
          setPrivilege={setPrivilege}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleConfirmation()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default PatientIndent;
