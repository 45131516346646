import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { ModalStyleFeedback } from "../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { createCasualtyPrescriptionOrder } from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import { useForm } from "react-hook-form";

function IVFlowModal(props) {
  const { watch, control } = useForm({
    mode: "onChange",
    defaultValues: {
      isChecked: false,
    },
  });

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  let isCheckedValue = watch("isChecked");

  function saveCasualtyOrder() {
    let tempObj = {
      admissionId: props.rowData?.AdmissionId,
      menuId: props.menuId,
      privilege: props.privilege,
    };
    props.setOpenLoader(true);
    createCasualtyPrescriptionOrder(tempObj)
      .then((response) => response.data)
      .then((res) => {
        props.handleClose();
        props.setOpenLoader(false);
        successAlert(res.message);
        setOpenConfirmationModal(false);
        props.setPrivilege("");
        props.setPatientInfoForCasualtyDrug(null);
      })
      .catch((error) => {
        errorAlert(error.message);
        props.setOpenLoader(false);
      });
  }

  console.log(
    "props.patientInfoForCasualtyDrug",
    props.patientInfoForCasualtyDrug
  );

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="max-h-[80%] h-[57%] 2xl:h-[45%] w-[65%] overflow-y-auto "
        >
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              props.setPatientInfoForCasualtyDrug(null);
              props.setPrivilege("");
            }}
          />

          <h1 className="text-xl text-center font-semibold">
            Casualty Drug Order
          </h1>
          <div>
            <div className=" bg-gray-100 p-1 rounded w-full flex justify-between items-center">
              <p className=" font-semibold text-sm text-customBlue">
                <span className=""> Patient Name &nbsp; : &nbsp;</span>
                {props.patientInfoForCasualtyDrug?.patientName}
              </p>
              <p className=" font-semibold  text-sm ">
                <span className="">
                  Admission Date &nbsp; : &nbsp;
                </span>
                {props.patientInfoForCasualtyDrug?.AdmissionDate}
              </p>
            </div>
            <div>
              {props.patientInfoForCasualtyDrug?.medicationList?.length > 0 ? (
                <CommonDynamicTableNew
                  dataResult={props.patientInfoForCasualtyDrug?.medicationList}
                  tableClass={"h-[280px] border rounded capitalize"}
                  highlightRow={false}
                  removeHeaders={["ptMedicationId"]}
                />
              ) : (
                <p className="my-10 text-center text-gray-600">
                  No Records Found...
                </p>
              )}
            </div>
            <div className="mb-1 flex justify-between items-center">
              <p className="text-xs flex   items-center">
                <CheckBoxField control={control} name="isChecked" />I have
                confirmed the emergency and admission information of the
                patient.
              </p>
              {isCheckedValue && (
                <CommonButton
                  type="button"
                  label="Create Order"
                  className="bg-customGreen text-white"
                  onClick={() => setOpenConfirmationModal(true)}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={saveCasualtyOrder}
        confirmationLabel="Confirmation"
        confirmationMsg={"Are You Sure To Create Casualty Drug Order"}
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}
export default React.memo(IVFlowModal);

