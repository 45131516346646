import React, { useEffect, useRef } from "react";
import { Checkbox } from "@mui/material";
import { bloodRequisitionNotePrint } from "../services/bloodAquisitionServices/BloodAquisitionServices";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";

export default function BloodRequisitionNotePrint(props) {
  let printRef = useRef();

  const [dataResult, setDataResult] = React.useState();

  React.useEffect(() => {
    bloodRequisitionNotePrint(props?.admissionId, props?.requisitionId)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [props]);

  let CheckTrue;

  if (dataResult?.RequisitionDetails?.Routine === 1) {
    CheckTrue = true;
  } else {
    CheckTrue = false;
  }

  let EmergencyTrue;

  if (dataResult?.RequisitionDetails?.Emergency === 1) {
    EmergencyTrue = true;
  } else {
    EmergencyTrue = false;
  }

  let AdultCheck;

  if (dataResult?.RequisitionDetails?.Adult === 1) {
    AdultCheck = true;
  } else {
    AdultCheck = false;
  }

  let PediatricCheck;

  if (dataResult?.RequisitionDetails?.Pediatric === 1) {
    PediatricCheck = true;
  } else {
    PediatricCheck = false;
  }

  let oneReceived;
  let twoAwaited;

  if (dataResult?.RequisitionDetails?.Pediatric === 1) {
    oneReceived = true;
    twoAwaited = false;
  } else {
    oneReceived = false;
    twoAwaited = true;
  }

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <div>
        <div className="flex text-center w-full">
          <div className="w-full">
            <h1 className="font-semibold ml-40">BLOOD REQUISITION NOTE</h1>
            <div className="text-[11px] text-start">
              Inamdar Hospital,S No. 15,Near KPCT Mall,Fatima Nagar,
              <br /> Pune 411040.Tel.: 020 - 30502244, 66812222/44
            </div>
          </div>
          <div className="-mt-12 w-40">
            <InamdarHospiLogo />
          </div>
        </div>
        {/* <div className="grid grid-cols-3">
          <div className="col-span-2">
            <div className="flex justify-end">
              <h1 className=" text-center font-semibold">
                BLOOD REQUISITION NOTE
              </h1>
            </div>
            <div className=" text-[11px]">
              Inamdar Hospital,S No. 15,Near KPCT Mall,Fatima Nagar,
              <br /> Pune 411040.Tel.: 020 - 30502244, 66812222/44
            </div>
          </div>
          <div className="flex justify-end">
            <InamdarHospiLogo />
          </div>
        </div> */}

        <div className=" border-r-[1px] border-black border-l-[1px]  border-[1px]  ">
          <div className=" border-b-[1px] border-black p-[1px] bg-black font-semibold text-[15px] text-center">
            BEFORE FILLING THE FORM PLEASE READ THE FOLLOWING INSTRUCTIONS
            CAREFULLY
          </div>
          <p className=" p-1 text-[11px]">
            <span className="font-semibold"> 1.Blood Reservation :</span> To
            Reserve blood in blood storage/ blood bank please fill this form
            correctly and completely. Along with this form send to cc EDTA
            sample of patient. Reservation can be done 48 hrs before planned use
            and the blood / blood product will be reserved in the blood storage/
            blood bank for the patient for a period of 72 hrs only.
          </p>

          <p className=" p-1 text-[11px] my-2">
            <span className=" font-semibold">2.Blood Issue :</span> To issue
            blood from blood storage/ blood bank an issue note needs to be sent.
            The issue note will authroise release of the blood / blood product
            from the blood storage / blood bank for patients use. Once blood is
            issued for a particular patient it will be used for that patient
            only and cannot be returned for use by someone else
          </p>

          <p className=" p-1  text-[11px]">
            <span className=" font-semibold">3.Cancellation :</span> In case
            there is doubt on the time of transfusion / instructions from
            consultant, blood should not be issued from blood storage / blood
            bank until clarification is obtained. In a situation blood has been
            issued from blood storage / blood bank and the transfusion is
            cancelled, the cancellation note should be sent to the blood storage
            providing reason for cancellation.
          </p>
          <h1 className=" text-center text-[14px] font-semibold">
            AVOID WASTAGE OF BLOOD / BLOOD PRODUCTS. ISSUE SENSIBLY
          </h1>
        </div>

        <div className=" border-b-[1px] border-black border-[1px]">
          <div className="  grid grid-cols-[50%_50%] ">
            <div className=" border-b-[1px]  border-r-[1px] border-black  p-1">
              <div className=" flex space-x-3 text-[11px]">
                <span className=" font-semibold">Mr No.</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                <span>{dataResult?.MRNo}</span>
              </div>
              <div className=" flex space-x-3 text-[11px]">
                <span className=" font-semibold">Patient Name</span>
                <span>:</span>
                <span>{dataResult?.PatientName}</span>
              </div>
              <div className=" flex gap-5">
                <div className=" flex space-x-3 text-[11px]">
                  <span className=" font-semibold">Age</span>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  </span>
                  <span>{dataResult?.AgeYear}</span>
                </div>
                <div className=" flex space-x-3 text-[11px]">
                  <span className=" font-semibold">Sex</span>
                  <span>&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;:</span>
                  <span>{dataResult?.Gender}</span>
                </div>
              </div>

              <div className=" flex gap-5">
                <div className=" flex space-x-3 text-[11px]">
                  <span className=" font-semibold">Consultant</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                  <span>{dataResult?.DoctorName}</span>
                </div>
                <div className=" flex space-x-3 text-[11px]">
                  <span className=" font-semibold">Bed No</span>
                  <span>&nbsp;&nbsp;:</span>
                  <span>{dataResult?.BedNo}</span>
                </div>
              </div>
            </div>
            <div className="items-center text-[11px] p-1  gap-3 ">
              <div className=" flex justify-end">
                <div>
                  Routine <Checkbox checked={CheckTrue} />{" "}
                </div>
                <div>
                  Emergency <Checkbox checked={EmergencyTrue} />{" "}
                </div>
              </div>
              <span className=" flex justify-end">
                Remember to take consent while reserving blood
              </span>
            </div>
          </div>
          <div className="p-1  flex justify-between">
            <div className=" text-[11px] space-x-5">
              <span className=" font-semibold">
                History Of Previous transfusion :-
              </span>
              <span>&nbsp;</span>
            </div>
            <div className=" text-[11px] space-x-5">
              <span className=" font-semibold">
                Diagnosis : {dataResult?.RequisitionDetails?.Diagnosis}
              </span>
            </div>
          </div>
          <div className=" grid grid-cols-2">
            <div className=" flex space-x-3 text-[11px] p-1">
              <h1>Date :</h1>
              <span>{dataResult?.RequisitionDetails?.TransfusionDate}</span>
            </div>
            <div className=" flex space-x-3 text-[11px] p-1">
              <h1>Name Of institution :</h1>
              <span>{dataResult?.RequisitionDetails?.InstitutionName}</span>
            </div>
          </div>
          <div className=" p-1 grid grid-cols-4 text-[11px]">
            <div className=" space-x-3 col-span-2">
              <span className=" font-semibold">
                Blood/Plasma/Platelets etc :
              </span>
              <span>{dataResult?.RequisitionDetails?.Blood_Plasma}</span>
            </div>
            <div>
              <span className=" font-semibold">ABO Group :</span>
              <span>{dataResult?.RequisitionDetails?.ABOGroup}</span>
            </div>
            <div>
              <span className=" font-semibold">Rh :</span>
              <span>{dataResult?.RequisitionDetails?.RH}</span>
            </div>
            <div>
              <span className=" font-semibold">Any Transfusion reaction :</span>
              <span>{dataResult?.RequisitionDetails?.TransfusionReaction}</span>
            </div>
          </div>
          <div className=" p-1 flex items-center space-x-7 text-[11px]">
            <span className=" font-semibold">Required Blood :</span>
            <div>
              Adult <Checkbox checked={AdultCheck} />{" "}
            </div>
            <div>
              Pediatric <Checkbox checke={PediatricCheck} />{" "}
            </div>
          </div>
          <div className="border-[1px] border-black mx-2 grid grid-cols-8  ">
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>Whole Blood</div>
              <div className=" border-t-[1px] border-black">RDP</div>
            </div>
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>{dataResult?.RequisitionDetails?.WholeBloodQty}</div>
              <div className=" border-t-[1px] border-black">
                {dataResult?.RequisitionDetails?.RDPQty}
              </div>
            </div>
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>PCV</div>
              <div className=" border-t-[1px] border-black">CRYPRECIPITATE</div>
            </div>
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>{dataResult?.RequisitionDetails?.PCVQty}</div>
              <div className=" border-t-[1px] border-black">
                {dataResult?.RequisitionDetails?.CRYOPRECIPITATEQty}
              </div>
            </div>
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>FFP</div>
              <div className=" border-t-[1px] border-black">SDP</div>
            </div>
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>{dataResult?.RequisitionDetails?.FFPQty}</div>
              <div className=" border-t-[1px] border-black">
                {dataResult?.RequisitionDetails?.SDPQty}
              </div>
            </div>
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>&nbsp;</div>
              <div className=" border-t-[1px] border-black">Other</div>
            </div>
            <div className=" text-[11px] border-r-[1px] border-black text-center">
              <div>&nbsp;</div>
              <div className=" border-t-[1px] border-black">
                {dataResult?.RequisitionDetails?.Other}
              </div>
            </div>
          </div>
          <div className=" my-3 grid grid-cols-2 p-1">
            <div className=" space-x-[13px] text-[11px]">
              <span>On Date</span>
              <span> &nbsp;:&nbsp;</span>
              <span>{dataResult?.RequisitionDetails?.OnDate}</span>
            </div>
            <div className=" space-x-3 text-[11px]">
              <span>At Time</span>
              <span>:</span>
              <span>{dataResult?.RequisitionDetails?.AtTime}</span>
            </div>
            <div className=" space-x-3 text-[11px]">
              <span>Location</span>
              <span>&nbsp;&nbsp;:&nbsp;</span>
              <span>{dataResult?.RequisitionDetails?.Location}</span>
            </div>
            <div className=" space-x-3 text-[11px]">
              <span>GYNEAC OT</span>
            </div>
            <div className=" space-x-3  text-[11px]">
              <span>Consultant authorizing blood trnsfusion</span>
              <span>:</span>
              <span>{dataResult?.RequisitionDetails?.DoctorName}</span>
            </div>
          </div>
        </div>
        <div className="  mt-3 grid grid-cols-3 px-1">
          <span className=" font-semibold text-[12px]">
            RMO (Name and Signature)
          </span>
          <span className="  text-[12px]">
            {dataResult?.RequisitionDetails?.RMOName}
          </span>
          <span className=" ">
            / <hr className="border-black " />
          </span>
        </div>
        <div className=" my-1 border-[1px] border-black">
          <span className="p-1 font-semibold text-[11px]">
            For Blood Storage
          </span>
          <div className="p-1 grid grid-cols-3 text-[11px]">
            <span>Date & Time Reserving this from</span>
            <div className=" flex space-x-5">
              <span>{dataResult?.RequisitionDetails?.ReceivedDateTime}</span>
            </div>
            <div className=" flex space-x-5">
              <span>Received by</span>
              <span>{dataResult?.RequisitionDetails?.ReceivedBy}</span>
            </div>
          </div>
          <div className=" grid grid-cols-3 text-[11px]">
            <span>Sample Cross For match</span>
            <div className=" items-center space-x-5">
              <span>Received</span>
              <Checkbox checked={oneReceived} size="small" />
            </div>
            <div className=" items-center space-x-[25px]">
              <span>Awaited</span>
              <Checkbox checked={twoAwaited} size="small" />
            </div>
          </div>
          <div className=" grid grid-cols-2 gap-2 px-1">
            {/* <div className=" ">
              <table className="border-[1px] border-black w-full">
                <thead>
                  <tr className="border-b-[1px] text-[11px] border-black grid grid-cols-4">
                    <th className="border-r-[1px] border-black">Bag no</th>
                    <th className="border-r-[1px] border-black">Blood group</th>
                    <th className="border-r-[1px] border-black">
                      Cross Matched date
                    </th>
                    <th className="">M.O</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="grid grid-cols-4">
                    <td className="pt-36 border-r-[1px] border-black"></td>
                    <td className="pt-36 border-r-[1px] border-black"></td>
                    <td className="pt-36 border-r-[1px] border-black"></td>
                    <td className="pt-36 border-r-[1px] border-black"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="  ">
              <table className="  border-[1px] border-black w-full">
                <tr className="   border-b-[1px] text-[11px] border-black grid grid-cols-4">
                  <th className=" border-r-[1px] border-black">Bag no</th>
                  <th className=" border-r-[1px] border-black">Blood group</th>
                  <th className=" border-r-[1px] border-black">
                    Cross Matched date
                  </th>
                  <th className="">M.O</th>
                </tr>
                <tr className="grid grid-cols-4">
                  <td className=" pt-36 border-r-[1px] border-black"></td>
                  <td className=" pt-36 border-r-[1px] border-black"></td>
                  <td className=" pt-36 border-r-[1px] border-black"></td>
                  <td className=" pt-36 border-r-[1px] border-black"></td>
                </tr>
              </table>
            </div> */}
            <table className="border border-collapse border-black">
              <thead>
                <tr className="text-sm">
                  <th className="border border-collapse border-black w-14">
                    Bag no
                  </th>
                  <th className="border border-collapse border-black w-[88px]">
                    Blood group
                  </th>
                  <th className="border border-collapse border-black">
                    Cross Matched date
                  </th>
                  <th className="border border-collapse border-black w-12">
                    M.O
                  </th>
                </tr>
                <tr className="h-20">
                  <td className="border border-collapse border-black pt-[187px]"></td>
                  <td className="border border-collapse border-black pt-[187px]"></td>
                  <td className="border border-collapse border-black pt-[187px]"></td>
                  <td className="border border-collapse border-black pt-[187px]"></td>
                </tr>
              </thead>
            </table>

            <table className="border border-collapse border-black">
              <thead>
                <tr className="text-sm">
                  <th className="border border-collapse border-black w-14">
                    Bag no
                  </th>
                  <th className="border border-collapse border-black w-[88px]">
                    Blood group
                  </th>
                  <th className="border border-collapse border-black">
                    Cross Matched date
                  </th>
                  <th className="border border-collapse border-black w-12">
                    M.O
                  </th>
                </tr>
                <tr className="h-20">
                  <td className="border border-collapse border-black pt-[187px]"></td>
                  <td className="border border-collapse border-black pt-[187px]"></td>
                  <td className="border border-collapse border-black pt-[187px]"></td>
                  <td className="border border-collapse border-black pt-[187px]"></td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

