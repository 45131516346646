import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { CapitalizeStatement } from "../../../common/components/Custom Hooks/CapitalizeStatement";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import { ModalStyleFeedback } from "../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getDrugFrequencyDropdown,
  getRouteDropdown,
} from "../../../ipd/services/drugadministratororderservices/DrugAdministratOrorderServices";
import { updateDrugAdminOrder } from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";

function AdministratorDrugListEditRowModal(props) {
  const schema = yup.object().shape({
    route: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    frequency: yup
      .object()
      .required("Required")
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    // dose: yup
    //   .string()
    //   .required("Required"),
    // .matches(/^[+1-9]+[0-9]*$/, "Only numbers are allowed"),
    // instruction: yup.string().required("Required"),
    duration: yup
      .string()
      .required("Required")
      .matches(/^[+1-9]+[0-9]*$/, "Only numbers are allowed"),
  });

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      dose: "",
      route: null,
      frequency: null,
      duration: "",
      instruction: "",
      isOutside: false,
    },
  });

  const [finalData, setFinalData] = useState(null);
  const [routeOptions, setRouteOptions] = useState([]);
  const [drugFrequencyOptions, setDrugFrequencyOptions] = useState([]);
  const [openBackdrop, setOpenLoader] = useState(false);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  // local storage
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let routeValue = watch("route");
  let doseValue = watch("dose");

  function postDrugListEditObj() {
    setOpenLoader(true);
    updateDrugAdminOrder(finalData)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setFinalData(null);
        setOpenConfirmationModal(false);
        props.getDrugList();
        props.handleClose();
        props.setRow(null);
        setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenLoader(false);
      });
  }

  const onSubmitDataHandler = (data) => {
    let durationValue = getValues("duration");
    let frequencyValue = getValues("frequency");
    let instructionValue = getValues("instruction");
    let isOutsideValue = getValues("isOutside");

    let postObj = {
      dose: doseValue,
      doseId: frequencyValue.id,
      drugAdminId: props.row?.DrugAdminHeaderId,
      duration: Number(durationValue),
      frequency: frequencyValue.label,
      instruction: CapitalizeStatement(instructionValue),
      menuId:
        props.patientInfo.menuId !== undefined
          ? props.patientInfo.menuId
          : props.menuId,
      privilege: props.privilege,
      route: routeValue.label,
      routeId: routeValue.id || routeValue.Id,
      updatedBy: token.userId,
      isOutsideMedication: isOutsideValue,
      prescriptionDetailId: props.row.PrescriptionDetailId,
    };
    setFinalData(postObj);
    trigger();
    setOpenConfirmationModal(true);
  };

  useEffect(() => {
    //getRoute dropdownlist
    getRouteDropdown()
      .then((response) => response.data)
      .then((res) => {
        setRouteOptions(res.result);
      });
    //getFrequency dropdown serivce
    getDrugFrequencyDropdown()
      .then((response) => response.data)
      .then((res) => {
        setDrugFrequencyOptions(res.result);
      });
  }, []);

  useEffect(() => {
    if (props.row) {
      let routeObj = {
        id: props.row["RouteId"],
        label: props.row["Route"],
        value: props.row["RouteId"],
      };
      let frequencyObj = {
        id: props.row["DoseId"],
        label: props.row["Frequency"],
        value: props.row["DoseId"],
      };

      setValue("dose", props.row?.Dose);
      setValue(
        "isOutside",
        props.row?.IsOutsideMedication === 1 ? true : false
      );
      setValue("route", routeObj);
      setValue("frequency", frequencyObj);
      setValue("duration", props.row?.PatchDuration);
      setValue("instruction", props?.row?.DrugInstruction);
    }
  }, [props.row]);

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="h-[40%] 2xl:h-[30%] max-h-[80%] overflow-y-auto w-[80%] p-2 rounded"
        >
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <h1 className="text-lg font-semibold my-2 flex justify-center">
              Administrator Drug List
            </h1>

            <div className="bg-gray-100 rounded-md border w-full">
              <div className="grid lg:grid-cols-2  lg:justify-between text-gray-300  mx-2 text-xs  2xl:text-sm items-center gap-1 w-full py-2">
                <div className="flex items-center space-x-2  text-black whitespace-nowrap">
                  <h1 className="font-semibold flex space-x-[16.5px] lg:space-x-1">
                    <span>Drug Name </span> <span>:</span>
                  </h1>
                  <div className="flex space items-center">
                    <span className="">{props.row["Item Name"]}</span>
                  </div>
                </div>

                <div className="flex items-center gap-2 xl:border-l-2  text-black whitespace-nowrap">
                  <h1 className="text-black font-semibold whitespace-nowrap">
                    Patient Name :
                  </h1>
                  <div className="flex space-x-2 items-center">
                    <span className="">
                      {props.rowData?.["Patient Name"] ||
                        props.rowData?.PatientName}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2 text-black whitespace-nowrap">
                  <h1 className="font-semibold flex space-x-[38px] lg:space-x-6 xl:space-x-7">
                    <span>MR No.</span> <span>:</span>
                  </h1>
                  <div className="flex ml-3 space-x-2  items-center">
                    <span className="">{props.rowData?.MRNo}</span>
                  </div>
                </div>
                <div className="flex items-center  xl:border-l-2 text-black whitespace-nowrap">
                  <h1 className="font-semibold flex space-x-[28.5px] lg:space-x-[27px] xl:space-x-[32px]">
                    <span>Dr. Name</span> <span>:</span>
                  </h1>
                  <div className="flex space-x-2 items-center ml-1 ">
                    <span className="">
                      {props.rowData?.["Doctor Name"] ||
                        props.rowData?.DoctorName}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 xl:grid-cols-4 gap-3 mt-4">
              <InputField
                control={control}
                name="dose"
                label="Dose"
                error={errors.dose}
              />
              <div className="z-10">
                <DropdownField
                  control={control}
                  placeholder="Route"
                  name="route"
                  maxMenuHeight={100}
                  error={errors.route}
                  menuPlacement="bottom"
                  dataArray={routeOptions}
                />
              </div>
              <div className="z-50">
                <DropdownField
                  control={control}
                  placeholder="Frequency"
                  maxMenuHeight={100}
                  name="frequency"
                  error={errors.frequency}
                  menuPlacement="bottom"
                  dataArray={drugFrequencyOptions}
                  isDisabled={
                    props.row.Frequency === "STAT" ||
                    props.row.Frequency === "SOS" ||
                    props.row.Frequency === "Per Hour"
                      ? true
                      : false
                  }
                />
              </div>
              <InputField
                control={control}
                name="duration"
                label="Duration"
                error={errors.duration}
              />
              <InputField
                control={control}
                name="instruction"
                label="Instruction"
                // error={errors.instruction}
              />
              <CheckBoxField
                control={control}
                name="isOutside"
                label="Is Outside"
              />
            </div>

            <div className="flex space-x-2 items-center justify-end mt-2">
              <CommonButton
                type="button"
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={() => {
                  reset();
                }}
              />
              <CommonButton
                type="button"
                label="Update"
                className="bg-customGreen text-white"
                onClick={() => {
                  onSubmitDataHandler();
                }}
              />
            </div>
          </form>
        </Box>
      </Modal>
      {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={postDrugListEditObj}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
export default React.memo(AdministratorDrugListEditRowModal);
