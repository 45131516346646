import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  fetchDepartment,
  fetchServicesSearch,
} from "../../../commonServices/miscellaneousServices/MiscServices";

import {
  fetchClassesApplicableTo,
  fetchConfigurationDetails,
  fetchGroups,
  updateAutoCharges,
  updateConfiguration,
} from "../../services/cofigurationFormServices/CofigurationFormServices";
import CommonDivider from "../../../common/components/divider/CommonDivider";

const ConfigurationForm = () => {
  //let location = useLocation();

  // const token = JSON.parse(localStorage.getItem("userInfo"));
  const [userActions, setUserActions] = useState([
    {
      id: 1,
      action: "Create",
      isAction: false,
      menuId: null,
      menuKey: null,
    },
  ]);
  // const [privilege, setPrivilege] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  const [servicesArr, setServicesArr] = useState([]);
  const [departmentArr, setDepartmentArr] = useState([]);
  const [groupArr, setGroupArr] = useState([]);
  // const [storeArr, setStoreArr] = useState([]);
  const [classArr, setClassArr] = useState([]);
  //const [selectedPatient, setSelectedPatient] = useState(null);

  // //PendingAntibioticList modal
  // const [openPendingAntibioticListModal, setOpenPendingAntibioticListModal] =
  //   useState(false);
  // const handleOpenPendingAntibioticListModal = () =>
  //   setOpenPendingAntibioticListModal(true);
  // const handleClosePendingAntibioticListModal = () =>
  //   setOpenPendingAntibioticListModal(false);
  ///

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
    defaultValues: {
      adminChargePer: "",
      adminPer: "",
      emergencyPer: "",
      cvpService: null,
      firstVisitService: null,
      reVisitService: null,
      registrationService: null,
      roomService: null,
      ambulanceService: null,
      oxygenCharge: null,
      cheifPathologist: null,
      pathologist: null,
      radiologist: null,
      microbiologist: null,
      bilirubinTest: null,
      sugarLevelService: null,
      endotrachealTubeService: null,
      rylesTubeService: null,
      foleysCathater: null,
      intracathService: null,
      nonPathGroup: null,
      pathologyGroup: null,
      pathologyStore: null,
      nursingDepartment: null,
      pharmacyDepartment: null,
      pharmacyStore: null,
      radiologyStore: null,
      pathCultureTest: null,
      emerChargePer: "",
      // otGroup: null,
      surgeryGroup: null,
      otMedicine: null,
      otStore: null,
      billingDept: "",
      ipdClass: null,
      opdClass: null,
      purchaseStore: null,
      unit: null,
      ventilationCharge: null,

      //new
      isEnableScheduler: false,
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    register,
    getValues,
    setError,
    resetField,
  } = methods;

  const handleServiceData = (searchString) => {
    //
    searchString &&
      fetchServicesSearch(searchString)
        .then((response) => response.data)
        .then((res) => {
          setServicesArr(res.result);
        });
  };

  const handleDepartmentData = (searchString) => {
    //
    searchString &&
      fetchDepartment(searchString)
        .then((response) => response.data)
        .then((res) => {
          setDepartmentArr(res.result);
        });
  };

  useEffect(() => {
    getGroupData();
    // getStoreData();
    getClassesData();
  }, []);

  const getGroupData = () => {
    fetchGroups()
      .then((response) => response.data)
      .then((res) => {
        setGroupArr(res.result);
      });
  };

  // const getStoreData = () => {
  //   fetchStore()
  //     .then((response) => response.data)
  //     .then((res) => {
  //       setStoreArr(res.result);
  //     });
  // };

  const getClassesData = () => {
    fetchClassesApplicableTo(2)
      .then((response) => response.data)
      .then((res) => {
        setClassArr(res.result);
      });
  };

  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = (data) => {
    setOpenConfirmationModal(false);

    const finalObj = {
      adminChargePer: data.adminChargePer,
      // adminPer: data,
      ambulanceServiceId: data.ambulanceService.id,
      bilirubinTestId: data.bilirubinTest.id,
      billingDepartmentId: data.billingDept.id,
      chiefPathologistId: data.cheifPathologist.id,
      cvpServiceId: data.cvpService.id,
      emerChargePer: data.emerChargePer,
      // emergencyPer: data.emergencyPer,//
      // emergencyPercentage: data,
      endotrachealTubeServiceId: data.endotrachealTubeService.id,
      faYear: format(data.faYear, "yyyy-MM-dd'T'HH:mm:ss.000'Z'"), //
      firstVisitService: data.firstVisitService.id,
      foleysCathater: data.foleysCathater.id,
      intracathServiceId: data.intracathService.id,
      ipdClassId: data.ipdClass.id,
      nursingDepartmentId: data.nursingDepartment.id,
      opdClassId: data.opdClass.id,
      otChargesServiceId: data.otChargesService.id,
      //otGroup: data.firstVisitService.id,
      otMedicineId: data.otMedicine.id,
      oxygenChargeId: data.oxygenCharge.id,
      pharmacyDepartmentId: data.pharmacyDepartment.id,
      reVisitService: data.reVisitService.id,
      registrationServiceId: data.registrationService.id,
      roomServiceId: data.roomService.id,
      rylesTubeServiceId: data.rylesTubeService.id,
      sugarLevelServiceId: data.sugarLevelService.id,
      unit: data.unit.id,
      ventilationChargeId: data.ventilationCharge.id,
      //new
      enableScheduler: data?.isEnableScheduler || false,
    };

    setOpenBackdrop(true);

    updateConfiguration(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          // setSelectedPatient(null);
          handlePatchData();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    handlePatchData();
  }, [servicesArr]);

  const handlePatchData = () => {
    fetchConfigurationDetails()
      .then((response) => response.data)
      .then((res) => {
        // setClassArr(res.result);

        setValue("isEnableScheduler", res?.result?.enableScheduler);
        setValue("faYear", new Date(res?.result?.FAYear));
        setValue("adminChargePer", res?.result?.AdminChargePer);
        setValue("adminPer", res?.result?.AdminPer);
        setValue("emergencyPer", res?.result?.EmergencyPer);
        setValue("cvpService", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPService,
          value: res?.result?.CVPServiceId,
        });
        setValue("firstVisitService", {
          id: res?.result?.FirstVisitServiceId,
          label: res?.result?.FirstVisitService,
          value: res?.result?.FirstVisitService,
        });
        setValue("reVisitService", {
          id: res?.result?.ReVisitServiceId,
          label: res?.result?.ReVisitService,
          value: res?.result?.ReVisitService,
        });
        setValue("registrationService", {
          id: res?.result?.RegistrationServiceid,
          label: res?.result?.RegistrationService,
          value: res?.result?.RegistrationService,
        });
        setValue("roomService", {
          id: res?.result?.RoomServiceId,
          label: res?.result?.RoomService,
          value: res?.result?.RoomService,
        });
        setValue("ambulanceService", {
          id: res?.result?.AmbulanceServiceId,
          label: res?.result?.AmbulanceService,
          value: res?.result?.AmbulanceService,
        });
        setValue("oxygenCharge", {
          id: res?.result?.OxygenChargeId,
          label: res?.result?.OxygenCharge,
          value: res?.result?.OxygenCharge,
        });
        setValue("cheifPathologist", {
          id: res?.result?.ChiefPathologistId,
          label: res?.result?.ChiefPathologist,
          value: res?.result?.ChiefPathologist,
        });
        setValue("pathologist", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("radiologist", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("microbiologist", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("bilirubinTest", {
          id: res?.result?.BilirubinTestId,
          label: res?.result?.BilirubinTest,
          value: res?.result?.BilirubinTest,
        });
        setValue("sugarLevelService", {
          id: res?.result?.SugarLevelServiceId,
          label: res?.result?.SugarLevelService,
          value: res?.result?.SugarLevelService,
        });
        setValue("endotrachealTubeService", {
          id: res?.result?.EndotrachealTubeServiceId,
          label: res?.result?.EndotrachealTubeService,
          value: res?.result?.EndotrachealTubeService,
        });
        setValue("rylesTubeService", {
          id: res?.result?.RylesTubeServiceId,
          label: res?.result?.RylesTubeService,
          value: res?.result?.RylesTubeService,
        });
        setValue("foleysCathater", {
          id: res?.result?.FoleysCathaterId,
          label: res?.result?.FoleysCathater,
          value: res?.result?.FoleysCathater,
        });
        setValue("intracathService", {
          id: res?.result?.IntracathServiceId,
          label: res?.result?.IntracathService,
          value: res?.result?.IntracathService,
        });
        setValue("nonPathGroup", {
          id: res?.result?.NonPathGroup,
          label: res?.result?.NonPathGroupDesc,
          value: res?.result?.NonPathGroupDesc,
        });
        setValue("pathologyGroup", {
          id: res?.result?.PathologyGroup,
          label: res?.result?.PathologyGroupDesc,
          value: res?.result?.PathologyGroupDesc,
        });
        setValue("pathologyStore", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("nursingDepartment", {
          id: res?.result?.NursingDepartmentId,
          label: res?.result?.NursingDepartment,
          value: res?.result?.NursingDepartment,
        });
        setValue("pharmacyDepartment", {
          id: res?.result?.PharmacyDepartmentId,
          label: res?.result?.PharmacyDepartment,
          value: res?.result?.PharmacyDepartment,
        });
        setValue("pharmacyStore", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("radiologyStore", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("pathCultureTest", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("emerChargePer", res?.result?.EmerChargePer);
        setValue("otChargesService", {
          id: res?.result?.OTChargesServiceId,
          label: res?.result?.OTChargesService,
          value: res?.result?.OTChargesService,
        });
        setValue("surgeryGroup", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("otMedicine", {
          id: res?.result?.OTMedicineId,
          label: res?.result?.OTMedicine,
          value: res?.result?.OTMedicine,
        });
        setValue("otStore", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("billingDept", {
          id: res?.result?.BillingDepartmentId,
          label: res?.result?.BillingDepartment,
          value: res?.result?.BillingDepartment,
        });
        setValue("ipdClass", {
          id: res?.result?.IPdClassId,
          label: res?.result?.IPdClass,
          value: res?.result?.IPdClass,
        });
        setValue("opdClass", {
          id: res?.result?.OPDClassId,
          label: res?.result?.OPDClass,
          value: res?.result?.OPDClass,
        });
        setValue("purchaseStore", {
          id: res?.result?.CVPServiceId,
          label: res?.result?.CVPServiceId,
          value: res?.result?.CVPServiceId,
        });
        setValue("unit", {
          id: res?.result?.UnitId,
          label: res?.result?.Unit,
          value: res?.result?.Unit,
        });
        setValue("ventilationCharge", {
          id: res?.result?.VentilationChargeId,
          label: res?.result?.VentilationCharge,
          value: res?.result?.VentilationCharge,
        });
      });
  };

  return (
    <div className="mt-12 mb-2">
      <div className="text-center text-black font-bold text-xl col-span-9 mb-2">
        Configuration Form
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-5 gap-3">
          <DatePickerFieldNew
            control={control}
            name="faYear"
            label="FAYear"
            value={new Date()}
            disableFuture={false}
            disablePast={false}
            inputFormat="dd-MM-yyyy"
          />
        </div>
        {/* Service Details */}
        <p className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins my-2">
          Service Details
        </p>
        <div className="grid lg:grid-cols-5  md:grid-cols-3 gap-3">
          <div className="">
            <InputField
              name="adminChargePer"
              label="Administration Charges(%)"
              variant="outlined"
              //error={errors.adminChargePer}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          <div className="">
            <InputField
              name="emergencyPer"
              label="Emergency (%)"
              variant="outlined"
              //error={errors.emergencyPer}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          <div className="">
            {/* <InputField
              name="cvpService"
              label="CVP Service"
              variant="outlined"
              //error={errors.cvpService}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            /> */}

            <SearchDropdown
              control={control}
              // error={errors.cvpService}
              name="cvpService"
              placeholder="CVP Service"
              dataArray={servicesArr}
              isSearchable={false}
              handleInputChange={(e) => {
                //
                handleServiceData(e);
              }}
            />
          </div>

          <div className="">
            {/* <SearchDropdown
              control={control}
              // error={errors.firstVisitService}
              name="firstVisitService"
              placeholder="First Visit Service"
              dataArray={servicesArr}
              isSearchable={false}
              handleInputChange={(e) => {
                
                handleServiceData(e);
              }}
            /> */}

            <SearchDropdown
              control={control}
              placeholder="Service"
              name="firstVisitService"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
              // error={errors.searchService}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              name="reVisitService"
              placeholder="Re-Visit Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              name="registrationService"
              placeholder="Registration Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.roomService}
              name="roomService"
              placeholder="Room Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.ambulanceService}
              name="ambulanceService"
              placeholder="Ambulance Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.oxygenCharge}
              name="oxygenCharge"
              placeholder="Oxygen Charge"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>
          <div>
            <CheckBoxField
              control={control}
              name="isEnableScheduler"
              label="Enable Scheduler"
            />
          </div>
        </div>

        <hr className="border my-2 md:col-span-3 lg:col-span-6 divide-x-8 bg-slate-300 border-slate-300" />

        {/* Doctors */}
        <p className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins my-2">
          Doctors
        </p>
        <div className="grid lg:grid-cols-6  md:grid-cols-3 gap-3">
          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.cheifPathologist}
              name="cheifPathologist"
              placeholder="Cheif Pathologist"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          {/* <div className="">
            <SearchDropdown
              control={control}
              // error={errors.pathologist}
              name="pathologist"
              placeholder="Pathologist"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.radiologist}
              name="radiologist"
              placeholder="Radiologist"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.microbiologist}
              name="microbiologist"
              placeholder="Microbiologist"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div> */}
        </div>

        <hr className="border my-2 md:col-span-3 lg:col-span-6 divide-x-8 bg-slate-300 border-slate-300" />

        {/* Investigations */}
        <p className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins my-2">
          Investigations
        </p>
        <div className="grid lg:grid-cols-6  md:grid-cols-3 gap-3">
          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.bilirubinTest}
              name="bilirubinTest"
              placeholder="Bilirubin Test"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.sugarLevelService}
              name="sugarLevelService"
              placeholder="Sugar Level Service "
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.endotrachealTubeService}
              name="endotrachealTubeService"
              placeholder="endotrachealTube Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.rylesTubeService}
              name="rylesTubeService"
              placeholder="Ryles Tube Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.foleysCathater}
              name="foleysCathater"
              placeholder="Foleys Cathater"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.intracathService}
              name="intracathService"
              placeholder="IntraCathater Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              // error={errors.nonPathGroup}
              name="nonPathGroup"
              placeholder="Non Pathology Group"
              searchIcon={true}
              dataArray={groupArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              // error={errors.pathologyGroup}
              name="pathologyGroup"
              placeholder="Pathology Group"
              searchIcon={true}
              dataArray={groupArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          {/* <div className="">
            <DropdownField
              control={control}
              // error={errors.pathologyStore}
              name="pathologyStore"
              placeholder="Pathology Store"
              searchIcon={true}
              dataArray={storeArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div> */}

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.nursingDepartment}
              name="nursingDepartment"
              placeholder="Nursing Department"
              searchIcon={true}
              dataArray={departmentArr}
              isClearable={true}
              handleInputChange={handleDepartmentData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.pharmacyDepartment}
              name="pharmacyDepartment"
              placeholder="Pharmacy Department"
              searchIcon={true}
              dataArray={departmentArr}
              isClearable={true}
              handleInputChange={handleDepartmentData}
            />
          </div>

          {/* <div className="">
            <DropdownField
              control={control}
              // error={errors.pharmacyStore}
              name="pharmacyStore"
              placeholder="Pharmacy Store"
              searchIcon={true}
              dataArray={storeArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <DropdownField
              control={control}
              // error={errors.radiologyStore}
              name="radiologyStore"
              placeholder="Radiology Store"
              searchIcon={true}
              dataArray={storeArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.pathCultureTest}
              name="pathCultureTest"
              placeholder="Pathology Culture Test"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div> */}
        </div>

        <hr className="border my-2 md:col-span-3 lg:col-span-6 divide-x-8 bg-slate-300 border-slate-300" />

        {/* OT services */}
        <p className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins my-2">
          OT services
        </p>
        <div className="grid lg:grid-cols-6  md:grid-cols-3 gap-3">
          <div className="">
            <InputField
              name="emerChargePer"
              label="Emergency Charges (%)"
              variant="outlined"
              //error={errors.emerChargePer}
              control={control}
              disabled={false}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.otChargesService}
              name="otChargesService"
              placeholder="OT Charges Service"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>
          {/*
          <div className="">
            <DropdownField
              control={control}
              // error={errors.otGroup}
              name="otGroup"
              placeholder="OT Group"
              searchIcon={true}
              dataArray={groupArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

           <div className="">
            <DropdownField
              control={control}
              // error={errors.surgeryGroup}
              name="surgeryGroup"
              placeholder="Surgery Group"
              searchIcon={true}
              dataArray={groupArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div> */}

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.otMedicine}
              name="otMedicine"
              placeholder="OT Medicine"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          {/* <div className="">
            <DropdownField
              control={control}
              // error={errors.otStore}
              name="otStore"
              placeholder="OT Store"
              searchIcon={true}
              dataArray={storeArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div> */}
        </div>

        <hr className="border my-2 md:col-span-3 lg:col-span-6 divide-x-8 bg-slate-300 border-slate-300" />

        {/* OTHERS*/}
        <p className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins my-2">
          Others
        </p>
        <div className="grid lg:grid-cols-6  md:grid-cols-3 gap-3">
          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.ipdClass}
              name="billingDept"
              placeholder="Billing Department"
              searchIcon={true}
              dataArray={departmentArr}
              isClearable={true}
              handleInputChange={handleDepartmentData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.ipdClass}
              name="ipdClass"
              placeholder="IPD Class"
              searchIcon={true}
              dataArray={classArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.opdClass}
              name="opdClass"
              placeholder="OPD Class"
              searchIcon={true}
              dataArray={classArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          {/* <div className="">
            <DropdownField
              control={control}
              // error={errors.purchaseStore}
              name="purchaseStore"
              placeholder="Purchase Store"
              searchIcon={true}
              dataArray={storeArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div> */}

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.unit}
              name="unit"
              placeholder="Unit"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>

          <div className="">
            <SearchDropdown
              control={control}
              // error={errors.ventilationCharge}
              name="ventilationCharge"
              placeholder="Ventilation Charge"
              searchIcon={true}
              dataArray={servicesArr}
              isClearable={true}
              handleInputChange={handleServiceData}
            />
          </div>
        </div>
        <div className="my-2">
          <CommonDivider color="#cbd5e1" height={2} />
          <h2 className=" lg:col-span-2 md:col-span-3 mr-3 font-bold tracking-wide text-sm font-Poppins my-2">
            Update Auto Charges
          </h2>
          <div>
            <CommonButton
              type="button"
              label="Update"
              className="bg-customGreen text-white"
              onClick={() => {
                setOpenBackdrop(true);
                updateAutoCharges()
                  .then((response) => response.data)
                  .then((res) => {
                    successAlert(res.message);
                    setOpenBackdrop(false);
                  })
                  .catch((error) => {
                    errorAlert(error.message);
                    setOpenBackdrop(false);
                  });
              }}
            />
          </div>
        </div>
        <CommonDivider color="#cbd5e1" height={2} />

        <div className="col-span-4 grid justify-end pt-1">
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" ? (
                <>
                  <div className="flex justify-end gap-2">
                    <CommonButton
                      label="Reset"
                      className="border border-customRed text-customRed"
                      onClick={() => {
                        handlePatchData();
                      }}
                    />
                    <CommonButton
                      type={"submit"}
                      label="Save"
                      className="bg-customGreen text-white"
                    />
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </form>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(allFormData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default ConfigurationForm;

