import CachedIcon from "@mui/icons-material/Cached";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import BatchSelectionModal from "./BatchSelectionModal";
import SubstituteDrugModal from "./SubstituteDrugModal";

///key press action
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function ItemDetailsTable(props) {
  const {
    setSelectedDrugData,
    selectedDrugData,
    openBatchModal,
    setOpenBatchModal,
    handleOpenBatchModal,
    handleCloseBatchModal,
    FocusBatchModal,
    FocusDrugTable,
    selectedItemDtlsID,
    setSelectedItemDtlsID,
    setTotalQuantity,
    setTotalAmount,
    setTotalGstAmount,
    batchInputFocus,
    selectedDrug,
    setSelectedDrug,
    batchSelctedRowData,
    setBatchSelctedRowData,
    batchInsideTableInput,
    setBatchInsideTableInput,
    setErrorToTblInput,
    selctedRowData,
    setSelctedRowData,
    setItemToBeSubstituted,
    itemToBeSubstituted,
    FocusQuantityInput,
    focusBatchInput,
    selectSubstitudeDrug,
    setSelectSubstitudeDrug,
    previousBalanceQty,
    previousBatchRowData,
  } = props;

  const [openSubstituteDrugModal, setOpenSubstituteDrugModal] =
    React.useState(false);
  const handleOpenSubstituteDrugModal = () => setOpenSubstituteDrugModal(true);
  const handleCloseSubstituteDrugModal = () =>
    setOpenSubstituteDrugModal(false);

  //batch table data
  const [batchData, setBatchData] = useState(null);
  const [index, setIndex] = React.useState(null);

  //for error input

  const [qtyChange, setQtyChange] = useState(0);

  /////
  console.log("selectedDrug", selectedDrug);

  var arrLength = props.data.length;
  //add required field in arr
  useEffect(() => {
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i].BatchNumber === undefined) {
        props.data[i].BatchNumber = "";
      }
      if (
        props.data[i].Quantity === undefined &&
        props.data[i].Quantity === null
      ) {
        props.data[i].Quantity = 0;
      }
      if (props.data[i].Amount === undefined) {
        props.data[i].Amount = 0;
      }
      if (props.data[i].IndentQty === undefined) {
        props.data[i].IndentQty = 0;
      }
      if (props.data[i].PendIssueQty === undefined) {
        props.data[i].PendIssueQty = 0;
      }
      if (selectSubstitudeDrug !== null) {
        let focusBatch = document.getElementById(
          `batchInput${selectSubstitudeDrug}`
        );

        if (focusBatch) {
          focusBatch.focus();
        }
      }
      setSelectedDrugData(props.data);
    }
  }, [props.data.length, selectSubstitudeDrug]);

  console.log("selectSubstitudeDrug", selctedRowData, selectSubstitudeDrug);
  // Function to focus input based on index

  const focusQuantity = (index) => {
    if (FocusQuantityInput.current[index]) {
      FocusQuantityInput.current[index].focus();
    }
  };

  ///keypress fuctionality code
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");

  //up
  useEffect(() => {
    if (arrowUpPressed) {
      if (
        (FocusBatchModal.current === undefined ||
          FocusBatchModal.current === null) &&
        (FocusDrugTable.current === undefined ||
          FocusDrugTable.current === null)
      ) {
        if (selectedItemDtlsID <= 0) {
          setSelectedItemDtlsID(arrLength - 1);
        } else if (selectedItemDtlsID !== 0) {
          setSelectedItemDtlsID(selectedItemDtlsID - 1);
        }
      }
    }
  }, [arrowUpPressed, FocusBatchModal]);

  //down
  useEffect(() => {
    if (arrowDownPressed) {
      if (
        (FocusBatchModal.current === undefined ||
          FocusBatchModal.current === null) &&
        (FocusDrugTable.current === undefined ||
          FocusDrugTable.current === null)
      ) {
        if (selectedItemDtlsID >= arrLength - 1) {
          setSelectedItemDtlsID(0);
        } else if (selectedItemDtlsID !== arrLength) {
          setSelectedItemDtlsID(selectedItemDtlsID + 1);
        }
      }
    }
  }, [arrowDownPressed, FocusBatchModal]);

  const QtyAmtCalculations = () => {
    let temp = [...props.data];

    for (let item of temp) {
      if (Number(item.Quantity > item.BalanceQty) || item.Quantity === "") {
        item.error = true;
      } else {
        item.error = false;
      }
      let checkError = temp?.some((val) => val?.error === true);
      if (checkError === true) {
        setErrorToTblInput(true);
      } else {
        setErrorToTblInput(false);
      }
    }
    console.log("itemitem123", temp);

    /////////////////

    // Case-1: Total quantity calculations on change
    let totalQuantity = props.data.reduce(
      (total, item) => Number(total) + Number(item.Quantity || 0),
      0
    );
    setTotalQuantity(totalQuantity);

    // Case-2: Calculations for amount according to quantity
    let totalAmount = props.data.reduce((total, item) => {
      const quantity = item.Quantity || 0;
      const amount = quantity * (item.UnitMRP || 0);
      item.Amount = amount.toFixed(2);
      return total + amount;
    }, 0);
    setTotalAmount(totalAmount);

    // Case-2: Calculations for Total Gst
    let totalGstAmt = props.data.reduce(
      (total, item) => Number(total) + Number(item.GstAmount || 0),
      0
    );
    setTotalGstAmount(totalGstAmt);
  };

  useEffect(() => {
    QtyAmtCalculations();
  }, [qtyChange, props.data, selectedItemDtlsID]);

  // Batch onchange
  const handleTableBatchInputChange = (e, index) => {
    props.data[index].BatchCode = e.target.value;
  };

  // quantity onchange
  const handleTableQuantityChange = (e, index) => {
    let onChangeQty = e.target.value;
    let temp = [...props.data];
    temp[index].Quantity = onChangeQty;
    temp[index].PendIssueQty =
      Number(temp[index].IndentQty) - Number(onChangeQty);

    let cost = onChangeQty * Number(temp[index].UnitMRP || 0);
    let gstPerc = Number(temp[index].VATPercentage);

    temp[index].GstAmount = ((cost * gstPerc) / 100).toFixed(2);

    console.log("temp[index].GstAmount", temp[index].GstAmount);
    props.setSelectedDrugData(temp);

    //if else to avoid NaN in calculations when the table input gets empty
    if (onChangeQty !== "") {
      setQtyChange(parseInt(onChangeQty));
    } else if (onChangeQty === "") {
      setQtyChange(parseInt(0));
    }
  };
  //
  //
  const deleteRow = (index) => {
    let newTemplateData = [...props.data];
    newTemplateData.splice(index, 1);
    props.setSelectedDrugData(newTemplateData);
    //
    handleTableQuantityChange();
  };
  //update batch number
  console.log("previousBatchRowData", previousBatchRowData);

  useEffect(() => {
    if (!props.data || !batchSelctedRowData || batchData === null) return;
    if (index === null || index < 0 || index >= props.data.length) return;

    let temp = [...props.data];
    const selectedItem = temp[index];
    if (!selectedItem) return;
    previousBalanceQty.current = selectedItem.BalanceQty;
    if (!Array.isArray(previousBatchRowData.current)) {
      previousBatchRowData.current = [];
    }
    previousBatchRowData.current.push(selectedItem);
    selectedItem.UnitMRP = batchSelctedRowData.UnitMRP;
    selectedItem.BatchNumber = batchSelctedRowData.BatchCode;
    selectedItem.BatchExpDate = batchSelctedRowData.BatchExpDatePatch;
    const unitMRP = Number(selectedItem.UnitMRP || 0);
    const cost = Number(selectedItem.Quantity) * unitMRP;
    const gstPerc = Number(
      selectedItem.VATPer || selectedItem.VATPercentage || 0
    );
    const GstAmount = ((cost * gstPerc) / 100).toFixed(2);
    selectedItem.GstAmount = GstAmount;

    if (Number(selectedItem.Quantity) > Number(batchSelctedRowData.BalQty)) {
      const originalQuantity = Number(selectedItem.Quantity);
      const balanceQty = Number(batchSelctedRowData.BalQty);
      const indentQty = Number(
        selectedItem.IndentQty - batchSelctedRowData.BalQty || 0
      );
      selectedItem.Quantity = Math.min(balanceQty, originalQuantity);
      selectedItem.BalanceQty = balanceQty;

      temp[index] = {
        ...temp[index],
        Quantity: selectedItem.Quantity,
        IndentQty: selectedItem.Quantity,
        PendIssueQty:
          previousBatchRowData.current[index].BalanceQty -
          selectedItem.Quantity,
        BalanceQty: balanceQty,
      };
      const remainingQty = originalQuantity - selectedItem.Quantity;
      if (remainingQty > 0) {
        temp.splice(index + 1, 0, {
          ...selectedItem,
          Quantity: remainingQty,
          BalanceQty: previousBalanceQty.current - selectedItem.Quantity,
          IndentQty: indentQty,
          PendIssueQty:
            remainingQty > 0
              ? selectedItem.IndentQty - selectedItem.Quantity
              : selectedItem.PendIssueQty,
          BatchNumber: "",
        });
      }
      props.setSelectedDrugData(temp);
      setIndex(null);
    } else {
      selectedItem.PendIssueQty =
        Number(selectedItem.IndentQty || 0) - Number(selectedItem.Quantity);
      props.setSelectedDrugData(temp);
      setIndex(null);
    }

    QtyAmtCalculations();
  }, [batchSelctedRowData, batchData, props.data, index]);

  console.log("batchSelctedRowData", batchSelctedRowData);

  const handleKeypressForBatch = (e, items, index) => {
    if (e.key === "Enter") {
      setSelctedRowData(items);
      handleOpenBatchModal();
      setBatchInsideTableInput(true);
      focusQuantity(index);
      setIndex(index);
    }
  };

  console.log("focusBatchInput", focusBatchInput);
  //////substitute selection table on icon click
  const handleSubstituteDrug = (index, items) => {
    setSelectSubstitudeDrug(index);
    setSelctedRowData(items);
    setItemToBeSubstituted({ ...items, selecteRowIndex: index });
    handleOpenSubstituteDrugModal();
  };

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-44 xl:h-96"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.2,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Action
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Batch Number
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Balance Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Indent Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Pending Issue Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        UnitMRP
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Amount
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Rack
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Shelf
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Bins
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data &&
                    props.data.map((items, index) => {
                      return (
                        <TableRow
                          ref={batchInputFocus}
                          key={index}
                          // tabIndex={-1}
                          hover={false}
                          sx={{
                            backgroundColor: items?.xschedule
                              ? "#99f2b1"
                              : items?.Hschedule === true
                              ? "#99ddf7"
                              : "",
                            "& td": {
                              paddingY: 0.2,
                            },
                          }}
                        >
                          <TableCell>
                            <div className="flex">
                              <Tooltip title="Delete">
                                <DeleteOutlineOutlinedIcon
                                  className="text-customRed hover:cursor-pointer"
                                  onClick={() => deleteRow(index)}
                                />
                              </Tooltip>
                              <Tooltip title="Substitute">
                                <CachedIcon
                                  className="text-sky-700 hover:cursor-pointer"
                                  onClick={() =>
                                    handleSubstituteDrug(index, items)
                                  }
                                />
                              </Tooltip>
                            </div>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Code}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Drug}
                          </TableCell>

                          {/* //Batch no// */}
                          <TableCell className="whitespace-nowrap">
                            <div>
                              <input
                                onKeyDown={(e) =>
                                  handleKeypressForBatch(e, items, index)
                                }
                                onFocus={() => {
                                  setSelectedItemDtlsID(index);
                                }}
                                onBlur={() => {
                                  setBatchData([]);
                                  setBatchSelctedRowData(null);
                                  setIndex(null);
                                }}
                                onChange={(e) =>
                                  handleTableBatchInputChange(e, index)
                                }
                                ref={(el) => {
                                  if (focusBatchInput.current) {
                                    focusBatchInput.current[index] = el;
                                  }
                                }}
                                className={
                                  (items.BatchesRequired === true ||
                                    items.BatchesRequired === "Yes") &&
                                  items.BatchNumber === ""
                                    ? "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                                    : "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                }
                                value={items.BatchNumber}
                                onWheel={(event) => event.currentTarget.blur()}
                                name={`batchInput${[index]}`}
                                id={`batchInput${index}`}
                              />
                            </div>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items?.BalanceQty?.toFixed(2)}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.IndentQty}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.PendIssueQty}
                          </TableCell>

                          {/* //qty// */}
                          <TableCell className="whitespace-nowrap">
                            <span>
                              <input
                                className={
                                  items.error && items.error === true
                                    ? //items.IndentQty < items.Quantity
                                      "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                                    : "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                }
                                style={{
                                  border:
                                    items.BatchNumber !== "" &&
                                    items.Quantity > items.BalanceQty
                                      ? "1px solid #ef4444"
                                      : "",
                                }}
                                ref={(el) => {
                                  if (FocusQuantityInput.current) {
                                    FocusQuantityInput.current[index] = el;
                                  }
                                }}
                                onFocus={() => {
                                  setSelectedItemDtlsID(index);
                                  setBatchSelctedRowData(null);
                                  setIndex(null);
                                }}
                                onChange={(e) => {
                                  setQtyChange(0);
                                  if (
                                    (items.BatchesRequired === true ||
                                      items.BatchesRequired === "Yes") &&
                                    items.BatchNumber === ""
                                  ) {
                                    warningAlert(
                                      "Please Select Batch Before Enter Quantity..."
                                    );
                                  } else {
                                    handleTableQuantityChange(e, index);
                                  }
                                }}
                                value={items.Quantity}
                                type="number"
                                min="0"
                                onWheel={(event) => event.currentTarget.blur()}
                                onKeyDown={(e) => {
                                  if (
                                    e?.key === "-" ||
                                    e?.key === "+" ||
                                    e?.code === "ArrowUp" ||
                                    e?.code === "ArrowDown" ||
                                    e?.code === "NumpadDecimal"
                                  ) {
                                    e.preventDefault();
                                  }

                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    let nextField = document.getElementById(
                                      `batchInput${index + 1}`
                                    );
                                    if (nextField) {
                                      nextField.focus();
                                    }

                                    setSelectSubstitudeDrug(null);
                                  }
                                }}
                                name={`Quantity${[index]}`}
                              />
                            </span>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.UnitMRP}
                          </TableCell>

                          {/*<TableCell className="whitespace-nowrap">
                        {items.MRP}
                          </TableCell>*/}

                          <TableCell className="whitespace-nowrap">
                            {items.Amount}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Rack}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Shelf}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Bins}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>

      {/* Batch Modal */}
      {openBatchModal ? (
        <BatchSelectionModal
          open={openBatchModal}
          setOpen={setOpenBatchModal}
          handleOpen={handleOpenBatchModal}
          handleClose={handleCloseBatchModal}
          selctedRowData={selctedRowData}
          setSelctedRowData={setSelctedRowData}
          //
          FocusBatchModal={FocusBatchModal}
          //
          batchSelctedRowData={batchSelctedRowData}
          setBatchSelctedRowData={setBatchSelctedRowData}
          batchData={batchData}
          setBatchData={setBatchData}
          previousBatchRowData={previousBatchRowData}
          //
          selectedDrug={selectedDrug}
          setSelectedDrug={setSelectedDrug}
          batchInsideTableInput={batchInsideTableInput}
          // onEnterFocusNext={onEnterFocusNext}
          //inputRefThree={inputRefThree}
          //
          setSelectedDrugData={setSelectedDrugData}
          selectedDrugData={selectedDrugData}
          selectedItemDtlsID={selectedItemDtlsID}
          setIndex={setIndex}

          //
        />
      ) : null}

      {/* Substitute Drug Modal */}
      {openSubstituteDrugModal ? (
        <SubstituteDrugModal
          open={openSubstituteDrugModal}
          setOpen={setOpenSubstituteDrugModal}
          handleOpen={handleOpenSubstituteDrugModal}
          handleClose={handleCloseSubstituteDrugModal}
          selctedRowData={selctedRowData}
          setSelctedRowData={setSelctedRowData}
          selectSubstitudeDrug={selectSubstitudeDrug}
          //
          setSelectedDrugData={setSelectedDrugData}
          selectedDrugData={selectedDrugData}
          selectedItemDtlsID={selectedItemDtlsID}
          //
          FocusBatchModal={FocusBatchModal}
          //
          batchSelctedRowData={batchSelctedRowData}
          setBatchSelctedRowData={setBatchSelctedRowData}
          batchData={batchData}
          setBatchData={setBatchData}
          //
          selectedDrug={selectedDrug}
          setSelectedDrug={setSelectedDrug}
          batchInsideTableInput={batchInsideTableInput}
          //
          itemToBeSubstituted={itemToBeSubstituted}
        />
      ) : null}
    </>
  );
}
