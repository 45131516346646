import React from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";

const MedicationChartPrint = ({ dataResult, sections, printRef }) => {
  const [printMedia, setPrintMedia] = React.useState([]);

  const generatePDF = () => {
    // Clone the content of the div for printing
    const clonedContent = printRef.current.cloneNode(true);
    const printStyles = `
     body {
       font-family: Arial, sans-serif;
     }
     .print:last-child {
      page-break-after: auto;
    }
     @media screen {
      @page {
        size: a4 landscape;
        margin: 0;
        padding:0;
      }
       #pdfContent {
         display: none !important;
        }
     }
     @media print {
       body * {
         visibility: hidden;
       }
       #pdfContent, #pdfContent * {
         visibility: visible;
       }
       #pdfContent {
         position: absolute;
         left: 0;
         top: 0;
       }
     }
   `;

    const style = document.createElement("style");
    style.innerHTML = printStyles;

    clonedContent.id = "pdfContent";
    document.body.appendChild(style);
    document.body.appendChild(clonedContent);

    window.print();

    document.body.removeChild(style);
    document.body.removeChild(clonedContent);
  };

  React.useEffect(() => {
    if (printMedia?.length > 0) {
      setTimeout(() => {
        generatePDF();
        setPrintMedia([]);
      }, 100);
    }
  }, [printMedia]);

  React.useEffect(() => {
    if (dataResult) {
      setTimeout(() => {
        setPrintMedia(dataResult?.MedicationChartDetails);
      }, 100);
    }
  }, [dataResult]);

  console.log("printMedia", sections);

  return (
    <div className="w-[100%]">
      <div ref={printRef} className="w-[100%]">
        <div className=" grid grid-cols-1 ">
          <div className="mr-2 ">
            <h6 className="font-semibold text-[15px]  text-center">
              Inamdar Multispeciality Hospital
            </h6>
            <p className=" text-[11px]  text-center">
              Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
            </p>
            <h6 className="font-semibold text-[11px]  text-center">
              Phone No : 020-66812222/44
            </h6>
            <div className=" flex justify-end gap-2 -mt-[50px]">
              <InamdarHospiLogo />
            </div>
          </div>
        </div>
        <div className="text-center ">
          <h1 className="font-semibold text-[13px]">MEDICATION CHART</h1>
        </div>
        <div className="">
          <div className="border-b-[1px] p-1 border-t-[1px] border-black grid grid-cols-[16%_1%_33%_16%_1%_33%] mt-2">
            <div className="font-semibold">MRNo</div>
            <div className="font-semibold">:</div>
            <div>{dataResult.MRNo}</div>
            <div className="font-semibold">Age</div>
            <div className="font-semibold">:</div>
            <div>{dataResult.AgeYear}</div>
            <div className="font-semibold">Patient Name</div>
            <div className="font-semibold">:</div>
            <div>{dataResult.PatientName}</div>
            <div className="font-semibold">Bed No</div>
            <div className="font-semibold">:</div>
            <div>{dataResult.BedNo}</div>
            <div className="font-semibold">Consultant</div>
            <div className="font-semibold">:</div>
            <div>{dataResult.MRNo} XYZ</div>
            <div className="font-semibold">Height</div>
            <div className="font-semibold">:</div>
            <div>{dataResult.Height}</div>
            <div className="font-semibold">Weight</div>
            <div className="font-semibold">:</div>
            <div>{dataResult.Weight}</div>
          </div>
        </div>
        {sections.map((section, index) => (
          <div
            key={index}
            className="medication-section whitespace-nowrap text-center w-[100%]"
          >
            <table className="medication-table my-[47px]  w-[100%]">
              <thead>
                <tr className="border-l border-r border-t border-black text-center">
                  {index === 0 && (
                    <>
                      <th
                        className="border-r border-l border-black px-2"
                        rowSpan={2}
                      >
                        Presc. Date
                      </th>
                      <th className="border-r border-black px-2" rowSpan={2}>
                        Start Date
                      </th>
                      <th className="border-r border-black px-2" rowSpan={2}>
                        End Date
                      </th>
                      <th className="border-r border-black px-2" rowSpan={2}>
                        Name of the Medication
                      </th>
                      <th className="border-r border-black px-2" rowSpan={2}>
                        Dose
                      </th>
                      <th className="border-r border-black px-2" rowSpan={2}>
                        Route
                      </th>
                      <th rowSpan={2}>Freq</th>
                    </>
                  )}
                  <th
                    colSpan={section.length}
                    className="border-l border-r border-black"
                  >
                    <h6 className="flex justify-center ">
                      {
                        dataResult.MedicationChartDetails[index]
                          ?.medicationChartDateDtoList[0]?.date
                      }
                    </h6>
                  </th>
                </tr>
                <tr>
                  {section.map((timeSlot, index) => (
                    <th key={index} className="border space-x-1 border-black">
                      {timeSlot}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataResult.MedicationChartDetails.map(
                  (medication, medIndex) => (
                    <tr key={medIndex}>
                      {index === 0 && (
                        <>
                          <td className="border border-black px-2 w-36">
                            {medication.prescDateTime}
                          </td>
                          <td
                            className="py-2 border border-black rotate-header"
                            style={{
                              writingMode: "vertical-rl",
                              transform: "rotate(180deg)",
                            }}
                          >
                            {medication.startDate}
                          </td>
                          <td
                            className="py-2 border border-black rotate-header"
                            style={{
                              writingMode: "vertical-rl",
                              transform: "rotate(180deg)",
                            }}
                          > 
                            {medication.endDate}
                          </td>
                          <td className="border border-black px-2 whitespace-pre-wrap">
                            {medication.nameOfMedication}
                          </td>
                          <td className="px-2 border border-black">
                            {medication.dose}
                          </td>
                          <td className="px-2 border border-black">
                            {medication.route}
                          </td>
                          <td className="border border-black px-2">
                            {medication.frequency}
                          </td>
                        </>
                      )}
                      {section.map((timeSlot, timeIndex) => {
                        const timeSlotData = medication.medicationChartDateDtoList
                          .find(
                            (mcddl) =>
                              mcddl.date ===
                              medication.medicationChartDateDtoList[0].date
                          )
                          ?.medicationChartTimeDtoList.find(
                            (time) => time.timeSlot === timeSlot
                          );

                        return (
                          <td
                            key={timeIndex}
                            className="border border-black text-center whitespace-nowrap px-2"
                          >
                            {timeSlotData && timeSlotData.isDrugAdminDone ? (
                              <span className="text-center flex flex-col space-y-1 font-semibold">
                                <input
                                  type="checkbox"
                                  checked={timeSlotData.isDrugAdminDone}
                                  className="my-2"
                                />
                                {timeSlotData.adminDoneDateTime && (
                                  <>
                                    <span className="">
                                      {
                                        timeSlotData.adminDoneDateTime.split(
                                          " "
                                        )[0]
                                      }
                                    </span>
                                    <span className="">
                                      {
                                        timeSlotData.adminDoneDateTime.split(
                                          " "
                                        )[1]
                                      }{" "}
                                      {
                                        timeSlotData.adminDoneDateTime.split(
                                          " "
                                        )[2]
                                      }
                                    </span>
                                  </>
                                )}
                                <span className="">
                                  {timeSlotData.adminDoneBy}
                                </span>
                              </span>
                            ) : (
                              <div className="min-w-[120px] min-h-[110px]"></div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicationChartPrint;
