import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { getDIPatientDashboardList } from "../../services/diPatientServices/DIPatientService";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import DIDashboard from "./didashboard/DIDashoboard";

const sortByOptions = [
  {
    value: 1,
    label: "DI",
  },
  {
    value: 2,
    label: "MEWS",
  },
  {
    value: 3,
    label: "GCS",
  },
  {
    value: 4,
    label: "QSOFA",
  },
  {
    value: 5,
    label: "Apache Score",
  },
];

export default function DIPatients() {
  const { watch, control, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      sortBy: null,
    },
  });

  let sortByValue = watch("sortBy");

  const [diPatientListData, setDIPatientListData] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [openDIDashboardModal, setOpenDIDashboardModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [page, setPage] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  function populateTable() {
    const filterdSortBy = sortByOptions.filter((list) => list.label === "DI");
    setLoadingSpinner(true);
    getDIPatientDashboardList(
      sortByValue?.label !== undefined
        ? sortByValue?.label
        : filterdSortBy[0].label
    )
      .then((response) => response.data)
      .then((res) => {
        console.log("resres", res);

        setDIPatientListData(res.body.result);
        setLoadingSpinner(false);
      })
      .catch((error) => setLoadingSpinner(false));
  }

  //   const populateTable = (forPagination) => {
  //     const defaultParams = {
  //       page: !forPagination ? 0 : 0,
  //       size: rowsPerPage,
  //     };
  //     setLoadingSpinner(true);
  //     getDIPatientDashboardList(sortByValue?.label)
  //       .then((response) => {
  //         return response.data;
  //       })
  //       .then((res) => {
  //         setLoadingSpinner(false);
  //         if (forPagination) {
  //           setDIPatientListData((prevData) => [...prevData, ...res.result]);
  //         } else {
  //           setDIPatientListData(res.body.result);
  //           setPage(0);
  //         }
  //         setCount(res.count);
  //         setLoadingSpinner(false);
  //       })
  //       .catch((error) => {
  //         setLoadingSpinner(false);
  //       });
  //   };

  const rowBackgroundColor = (row, index) => {
    if (row?.["Apache Score"] !== null) {
      return "#81C784";
    } else if (row?.DI !== null) {
      return "#FF6F6F";
    } else if (row?.GCS !== null) {
      return "#BA68C8";
    } else if (row?.["QSOFA"] !== null) {
      return "#4FC3F7";
    } else if (row?.MEWS !== null) {
      return "#FFB74D";
    } else {
      return "";
    }
    //   else if (row?.Ventilation !== false) {
    //     return "#9fa8da";
    //   } else if (row?.Oxygen !== false) {
    //     return "#9fa8da";
    //   }
  };

  function handleSelectedRow(row) {
    console.log("selectedRow", row);
    setSelectedRow(row);
    setOpenDIDashboardModal(true);
  }

  useEffect(() => {
    const filterdSortBy = sortByOptions.filter((list) => list.label === "DI");
    setValue("sortBy", filterdSortBy[0]);
  }, []);

  useEffect(() => {
    if (sortByValue !== null) {
      populateTable();
    }
  }, [sortByValue]);

  return (
    <div className="mt-16">
      <h1 className="text-center font-semibold text-xl text-customBlue">
        DI Patients
      </h1>
      <div className="flex space-x-3 items-center">
        <div className="w-[16%]">
          <DropdownField
            control={control}
            name="sortBy"
            placeholder="Sort By"
            dataArray={sortByOptions}
          />
        </div>
        {/* <div className="flex space-x-3 justify-between text-xs whitespace-nowrap">
          <div className="flex items-center space-x-2">
            <span
              className="h-4 w-4 rounded"
              style={{ backgroundColor: "#FF6F6F" }}
            ></span>
            <span>DI</span>
          </div>

          <h1 className="flex items-center space-x-2">
            <span
              className="h-4 w-4 rounded"
              style={{ backgroundColor: "#FFB74D" }}
            ></span>
            <span>MEWS</span>
          </h1>

          <h1 className="flex items-center space-x-2">
            <span
              className="h-4 w-4 rounded"
              style={{ backgroundColor: "#BA68C8" }}
            ></span>
            <span>GCS</span>
          </h1>

          <h1 className="flex items-center space-x-2">
            <span
              className="h-4 w-4 rounded"
              style={{ backgroundColor: "#4FC3F7" }}
            ></span>
            <span>QSOFA</span>
          </h1>

          <h1 className="flex items-center space-x-2">
            <span
              className="h-4 w-4 rounded"
              style={{ backgroundColor: "#81C784" }}
            ></span>
            <span>Apache Score</span>
          </h1>
        </div> */}
      </div>

      {loadingSpinner && (
        <div className="flex justify-center my-40">
          <LoadingSpinner />
        </div>
      )}
      {loadingSpinner === false && diPatientListData?.length > 0 ? (
        // <CommonDynamicTablePaginationNew
        //   dataResult={diPatientListData}
        //   populateTable={populateTable}
        //   page={page}
        //   setPage={setPage}
        //   rowsPerPage={rowsPerPage}
        //   count={count}
        //   removeHeaders={["AdmissionId"]}
        //   highlightRow={false}
        //   //   renderActions={renderActions}
        //   tableClass={"h-[400px] 2xl:h-[700px]"}
        // />
        <CommonDynamicTableNew
          dataResult={diPatientListData}
          removeHeaders={["AdmissionId"]}
          highlightRow={true}
          tableClass={"h-[400px] 2xl:h-[700px]"}
          //   rowBackgroundColor={rowBackgroundColor}
          //   renderInput={renderInput}
          handleSelectedRow={handleSelectedRow}
        />
      ) : (
        <>
          {loadingSpinner === false && (
            <p className="text-center my-40">
              No Records Found<span className="animate-pulse">...</span>
            </p>
          )}
        </>
      )}
      {openDIDashboardModal && (
        <DIDashboard
          open={openDIDashboardModal}
          handleClose={() => setOpenDIDashboardModal(false)}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
    </div>
  );
}

