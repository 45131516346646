import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";

import { fetchAlternateDrugNameTable } from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import SubstituteDrugTable from "./SubstituteDrugTable";
import { useRef } from "react";
import InputField from "../../../../common/components/FormFields/InputField";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import { useForm } from "react-hook-form";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "50%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function SubstituteDrugModal(props) {
  var token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    open,
    selctedRowData,
    handleClose,
    setSelectedDrugData,
    selectedDrugData,
    selectedItemDtlsID,
    setSelectedDrug,
    //
    itemToBeSubstituted,
    focusBatchNumber,
  } = props;

  const { control } = useForm({
    mode: "onChange",
  });

  //table data
  const [altDrugTableData, setAltDrugTableData] = useState([]);
  const [substituteData, setSubstituteData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  //Alt drug tbl Row id
  const [selectedAltDrugRowID, setSelectedAltDrugRowID] = React.useState(0);

  // drug tbl Row id
  const [selectedDrugRowID, setSelectedDrugRowID] = React.useState(0);
  //
  const focusSearch = useRef(null);

  ///object created outside table input
  console.log("selctedRowData", selctedRowData);

  if (selctedRowData !== null && selctedRowData !== undefined) {
    var altDrugObj = {
      deptStore: 1,
      deptStoreId: token.storeId,
      //genericId: selctedRowData.Id,
      genericId: selctedRowData?.itemgenericid || selctedRowData?.ItemGenericId,
      isConsignment: selctedRowData.IsConsignment ? 1 : 0,
      itemCode: "%", //selectedDrugRowData.Code,
      itemName: "%", //selectedDrugRowData.Drug,
      itemClassId:
        selctedRowData?.ItemClass === 0 ? null : selctedRowData?.ItemClass,
    };
  }

  useEffect(() => {
    // alternate drug table object
    setShowSpinner(true);

    fetchAlternateDrugNameTable(altDrugObj)
      .then((response) => response.data)
      .then((res) => {
        setAltDrugTableData(res.result);
        setSubstituteData(res.result);
        focusSearch.current.focus();
        setShowSpinner(false);
      });
  }, [selctedRowData]);

  const handleTableSort = (searchString) => {
    let temp = [...substituteData];
    if (searchString !== "") {
      temp = temp.filter((item) =>
        item?.Drug?.toLowerCase().includes(searchString.toLowerCase())
      );
    } else {
      temp = [...substituteData];
    }
    setAltDrugTableData(temp);
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={(e) => {
              handleClose();
              e.preventDefault();
            }}
          />
          <div>
            <div className="my-2">
              <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
                Drug Substitute
              </span>
            </div>
            <div className="">
              <fieldset
                className="border border-gray-300 col-span-3 w-full
             text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
              >
                <div className=" grid  grid-cols-3 2xl:grid-cols-3  ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Description</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selctedRowData && selctedRowData.Drug}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Item Code</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selctedRowData && selctedRowData.Code}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div
              className="mt-3 w-96"
              onChange={(e) => {
                if (e.target) {
                  handleTableSort(e.target.value);
                }
              }}
            >
              <InputField
                control={control}
                name="searchSubstitute"
                label="Search Substitute Drug"
                isSearchable={true}
                isClearable={true}
                inputRef={focusSearch}
              />
            </div>

            <div>
              {/* drug name table */}
              <div className="grid  mt-2 gap-3">
                {/* alt drug tbl */}
                <div>
                  <div>
                    <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                      Alternate Drug Name
                    </span>
                  </div>
                  {showSpinner ? (
                    <div className="flex justify-center mt-20">
                      <LoadingSpinner />
                    </div>
                  ) : altDrugTableData.length > 0 ? (
                    <div>
                      <SubstituteDrugTable
                        data={altDrugTableData}
                        //
                        setSelectedDrug={setSelectedDrug}
                        selectedItemDtlsID={selectedItemDtlsID}
                        // setSelectedItemDtlsID={setSelectedItemDtlsID}
                        setAltDrugTableData={setAltDrugTableData}
                        selectSubstitudeDrug={props.selectSubstitudeDrug}
                        //
                        setSelectedDrugData={setSelectedDrugData}
                        selectedDrugData={selectedDrugData}
                        //row ids
                        selectedAltDrugRowID={selectedAltDrugRowID}
                        setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                        //
                        setSelectedDrugRowID={setSelectedDrugRowID}
                        selectedDrugRowID={selectedDrugRowID}
                        //
                        handleClose={handleClose}
                        itemToBeSubstituted={itemToBeSubstituted}
                      />
                    </div>
                  ) : (
                    <div className="text-center mt-6">No Data Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
