import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import InputField from "../../../../common/components/FormFields/InputField";
import POMultipleTable from "../common/POMultipleTable";
import SearchIndentButton from "../../../../common/components/Buttons/SearchIndentButton";
import POMultipleIndentModal from "./POMultipleIndentModal";
import RadioField from "../../../../common/components/FormFields/RadioField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fetchAllPurchaseOrderList,
  poItemDetailsSearch,
} from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import {
  addNewPurchaseOrderMultiple,
  supplierListForPOMultiple,
} from "../../../services/generalStore/pomultipleServices/POMultipleServices";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import POListModal from "../purchaseOrder/POListModal";
import InputLimit from "../../../../common/components/FormFields/InputLimit";
import MultiIndentAgainstPOItemTable from "./radioindent/MultiIndentAgainstPOItemTable";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";

//radio button
const poType = [
  {
    id: "Direct",
    value: "Direct",
    label: "Direct",
  },
  {
    id: "Indent",
    value: "Indent",
    label: "Indent",
  },
];

const schedule = [
  {
    id: "For Next 3 Months As Per Tel. Confirmation",
    value: "For Next 3 Months As Per Tel. Confirmation",
    label: "For Next 3 Months As Per Tel. Confirmation",
  },
  {
    id: "Immediate",
    value: "Immediate",
    label: "Immediate",
  },
];

export default function PurchaseOrder() {
  //schema
  // const schema = yup.object().shape({
  //   schedule: yup
  //     .object()
  //     .required("Required")
  //     .nullable()
  //     .shape({
  //       value: yup.string().required("Required"),
  //       label: yup.string().required("Required"),
  //     }),
  // });

  //use form
  const defaultValues = {
    storeName: null,
    supplier: null,
    schedule: null,
    poType: "Direct",
    otherCharges: Number(0),
    searchItemCode: null,
    qty: "",
    freeQty: "",
    uom: "",
    rate: Number(0),
    totalAmount: Number(0),
    discPercentage: 0,
    discAmount: 0,
    gstPercentage: Number(0),
    gstAmount: Number(0),
    netAmount: Number(0),
    specification: "",
    mrp: Number(0),
    guaranteewarranty: "",
    otherTax: Number(0),
    remark: "",
  };

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    register,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues,
  });

  //state variables
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [finalData, setFinalData] = React.useState({});
  const [poListModalSupplierID, setPoListModalSupplierID] = React.useState(0);
  const [itemOptions, setItemOptions] = React.useState();
  const [supplierOptions, setSupplierOptions] = React.useState([]);
  const [supplierId, setSupplierId] = React.useState(null);
  const [openIndent, setOpenIndent] = useState(false);
  const handleOpenIndent = () => setOpenIndent(true);
  const handleCloseIndent = () => setOpenIndent(false);
  const [poMultipleData, setPoMultipleData] = useState([]); // Item Details Data Add In the table
  const [poListModal, setPOListModal] = useState(false);
  const handlePOListOpen = () => setPOListModal(true);
  const handlePOListClose = () => setPOListModal(false);

  const [selectedPOListFromDate, setSelectedPOListFromDate] = React.useState(
    new Date()
  );
  const [selectedPOListToDate, setSelectedPOListToDate] = React.useState(
    new Date()
  );
  const [isCancelled, setisCancelled] = React.useState(false);
  // const [isAmmendment, setIsAmmendmentpo] = React.useState(false); // Comment coz in table ammendent value not getting
  const [isOrderPlaced, setIsOrderPlaced] = React.useState(2);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [data, setData] = React.useState({
    result: [],
    actions: [],
  });
  const [dataResult, setDataResult] = React.useState([]);
  const [multiIndentAgainstItem, setMultiIndentAgainstItem] = React.useState(
    []
  );
  const [requiredTableArr, setRequiredTableArr] = React.useState([]); //Final Table Array

  //Calculate Discount Amount , gstAmount , netAmount
  const [totalDiscountAmount, setTotalDiscountAmount] = React.useState(0);
  const [totalGstAmount, setTotalGstAmount] = React.useState(0);
  const [totalNetAmount, setTotalNetAmount] = React.useState(0);

  const [privilege, setPrivilege] = React.useState("");

  const [userActions, setUserActions] = useState([]);

  const location = useLocation();
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  //other variables
  let searchItemCode = watch("searchItemCode");
  let supplier = watch("supplier");
  let POTypeVal = watch("poType");
  let getUserInfo = JSON.parse(localStorage.getItem("userInfo"));

  let doTypeVal;

  React.useEffect(() => {
    if (POTypeVal === "Direct") {
      doTypeVal = false;
    } else if (POTypeVal === "Indent") {
      doTypeVal = true;
    }
  }, [POTypeVal]);

  //PO List Modal
  React.useEffect(() => {
    populatePOListTable();
  }, []);

  let defaultParams = {
    fromDate: selectedPOListFromDate,
    isAmmendment: false,
    isCancelled: isCancelled,
    isOrderPlaced: isOrderPlaced,
    itemId: 0,
    storeId: getUserInfo.storeId,
    supplierId: poListModalSupplierID,
    toDate: selectedPOListToDate,
    searchString: "",
    page: 0,
    size: rowsPerPage,
    menuId: location?.state?.menuId,
  };
  const populatePOListTable = () => {
    fetchAllPurchaseOrderList(defaultParams)
      .then((response) => {
        setCount(response.data.count);
        return response.data;
      })
      .then((res) => {
        setData(res);
        setDataResult(res.result);
      });
  };

  //Calculation Start
  let rate = watch("rate");
  let qty = watch("qty");
  let totalAmount = watch("totalAmount");
  let discPercentage = watch("discPercentage");
  let discAmount = watch("discAmount");
  let gstPercentage = watch("gstPercentage");
  let gstAmount = watch("gstAmount");
  let netAmount = watch("netAmount");

  //Calculation
  useEffect(() => {
    let totalAmount = Number(rate) * Number(qty);
    setValue("totalAmount", Number(totalAmount).toFixed(2));

    let discountAmt = Number(totalAmount / 100) * Number(discPercentage);
    setValue("discAmount", Number(discountAmt).toFixed(2));

    let discountedAmt = Number(totalAmount) - Number(discountAmt);

    let gstAmount = Number(Number(discountedAmt) * Number(gstPercentage)) / 100;
    setValue("gstAmount", Number(gstAmount).toFixed(2));

    let totalAmtMDiscountAmt = Number(totalAmount) - Number(discountAmt);
    let netAmount = totalAmtMDiscountAmt + Number(gstAmount);
    setValue("netAmount", Number(netAmount).toFixed(2));
  }, [qty, rate, discPercentage, gstPercentage]);
  //Calculation End

  useEffect(() => {
    supplierListForPOMultiple(searchItemCode?.id)
      .then((response) => response.data)
      .then((res) => {
        setSupplierOptions(res.result);
      });

    if (searchItemCode !== null) {
      setValue("uom", searchItemCode.StockingUOM);
    } else if (searchItemCode === null) {
      setValue("uom", "");
    }

    if (searchItemCode !== null) {
      setValue("rate", searchItemCode.PurchaseRate);
    } else if (searchItemCode === null) {
      setValue("rate", 0);
    }

    if (searchItemCode !== null) {
      setValue("gstPercentage", searchItemCode.VAT);
    } else if (searchItemCode === null) {
      setValue("gstPercentage", 0);
    }

    if (searchItemCode !== null) {
      setValue("mrp", searchItemCode.MRP);
    } else if (searchItemCode === null) {
      setValue("mrp", 0);
    }

    if (searchItemCode === null) {
      setValue("supplier", null);
      setValue("qty", "");
      setValue("freeQty", "");
    }
  }, [searchItemCode]);

  React.useEffect(() => {
    setValue("storeName", getUserInfo.storeName);
  }, [getUserInfo.storeName]);

  const handleChange = (autoSearchItemString) => {
    if (
      autoSearchItemString !== "" &&
      UseCheckConsecutiveCharacters(autoSearchItemString)
    ) {
      let obj = {
        deptStoreId: getUserInfo.storeId,
        itemCode: "%",
        itemName: "%",
        searchString: autoSearchItemString,
        supplierId: 0,
      };
      poItemDetailsSearch(obj)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          setItemOptions(res.result);
        });
    }
  };

  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.id === val.id);
      if (ind !== -1) {
        errorAlert("Item Already Present...!");
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  const addItemsDetailsData = () => {
    let searchItemCode = getValues("searchItemCode");
    let freeQty = getValues("freeQty");
    let supplier = getValues("supplier");
    let uom = getValues("uom");
    let specification = getValues("specification");
    let mrp = getValues("mrp");
    let QtyNumVal = Number(qty);

    if (
      searchItemCode !== null &&
      supplier !== null &&
      qty !== "" &&
      QtyNumVal !== 0 &&
      QtyNumVal > 0
      // qty <= 999
    ) {
      let itemObj = {
        searchItemCode: {
          id: searchItemCode.id,
          label: searchItemCode.label,
        },
        supplier: supplier.label,
        doQty: Number(qty),
        freeQty: Number(freeQty) ? Number(freeQty) : 0,
        uom: uom,
        rate: Number(rate),
        totalAmount: Number(totalAmount).toFixed(2),
        discPercentage: Number(discPercentage),
        discAmount: Number(discAmount).toFixed(2),
        vatPer: Number(gstPercentage),
        vatAmt: Number(gstAmount).toFixed(2),
        id: searchItemCode.id,
        netAmount: Number(netAmount).toFixed(2),
        specification: specification,
        mrp: Number(mrp),
        creditPeriod: "",
        expDeliveryDate: "2023-05-05T11:13:27.340Z",
        indentDetailsId: 0,
        isOrderPlaced: true,
        itemId: searchItemCode.id,
        itemManufacturedById: 0,
        markForClosed: false,
        pendQty: 0,
        supplierId: supplierId,
      };
      let arr = [...poMultipleData];
      arr.push(itemObj);
      setPoMultipleData(mergeArray(arr));
    }

    if (searchItemCode === null) {
      // && supplier === null && qty === ""
      setError("searchItemCode", { type: "custom", message: "Required" });
      // setError("supplier", { type: "custom", message: "Required" });
      // setError("qty", { type: "custom", message: "Required" });
    }
    if (supplier === null && qty === "") {
      setError("supplier", { type: "custom", message: "Required" });
      setError("qty", { type: "custom", message: "Required" });
    } else if (searchItemCode === null) {
      setError("searchItemCode", { type: "custom", message: "Required" });
    } else if (supplier === null) {
      setError("supplier", { type: "custom", message: "Required" });
    } else if (qty === "") {
      setError("qty", { type: "custom", message: "Required" });
    }
  };

  ///Final Calculation
  useEffect(() => {
    if (POTypeVal === "Direct") {
      let discAmt = 0;
      let gstAmt = 0;
      let netAmt = 0;
      if (poMultipleData.length > 0) {
        for (let poObj of poMultipleData) {
          discAmt = Number(Number(discAmt) + Number(poObj.discAmount));
          gstAmt = Number(Number(gstAmt) + Number(poObj.vatAmt));
          netAmt = Number(Number(netAmt) + Number(poObj.netAmount));
        }

        setTotalDiscountAmount(discAmt);
        setTotalGstAmount(gstAmt);
        setTotalNetAmount(netAmt);
      } else if (poMultipleData.length === 0) {
        setTotalDiscountAmount(discAmt);
        setTotalGstAmount(gstAmt);
        setTotalNetAmount(netAmt);
      }
    } else if (POTypeVal === "Indent") {
      let discAmt = 0;
      let gstAmt = 0;
      let netAmt = 0;
      if (requiredTableArr.length > 0) {
        for (let poObj of requiredTableArr) {
          discAmt = Number(Number(discAmt) + Number(poObj.discAmount));
          gstAmt = Number(Number(gstAmt) + Number(poObj.gstAmount));
          netAmt = Number(Number(netAmt) + Number(poObj.netAmount));
        }

        setTotalDiscountAmount(discAmt);
        setTotalGstAmount(gstAmt);
        setTotalNetAmount(netAmt);
      } else if (requiredTableArr.length === 0) {
        setTotalDiscountAmount(discAmt);
        setTotalGstAmount(gstAmt);
        setTotalNetAmount(netAmt);
      }
    }
  }, [poMultipleData, requiredTableArr, POTypeVal]);

  const onSubmitDataHandler = (data) => {
    if (POTypeVal === "Direct") {
      let purchaseOrderMultipleFinalObj = [];
      for (let poMultipleObj of poMultipleData) {
        let requiredObj = {
          addedBy: getUserInfo.userId,
          amount: Number(Number(poMultipleObj.totalAmount).toFixed(2)),
          creditPeriod: poMultipleObj.creditPeriod,
          discAmt: Number(Number(poMultipleObj.discAmount).toFixed(2)),
          discPer: Number(poMultipleObj.discPercentage),
          doQty: Number(poMultipleObj.doQty),
          expDeliveryDate: poMultipleObj.expDeliveryDate,
          freeQty: Number(poMultipleObj.freeQty),
          indentDetailsId: poMultipleObj.indentDetailsId,
          isOrderPlaced: poMultipleObj.isOrderPlaced,
          itemId: poMultipleObj.itemId,
          itemManufacturedById: poMultipleObj.itemManufacturedById,
          markForClosed: poMultipleObj.markForClosed,
          mrp: Number(poMultipleObj.mrp),
          netAmount: Number(Number(poMultipleObj.netAmount).toFixed(2)),
          pendQty: Number(poMultipleObj.pendQty),
          rate: Number(poMultipleObj.rate),
          specifications: poMultipleObj.specification,
          storeId: getUserInfo.storeId,
          supplierId: Number(poMultipleObj.supplierId),
          vatAmt: Number(Number(poMultipleObj.vatAmt).toFixed(2)),
          vatPer: Number(poMultipleObj.vatPer),
          doType: doTypeVal,
        };
        purchaseOrderMultipleFinalObj.push(requiredObj);
      }
      setOpenPost(true);
      setFinalData(purchaseOrderMultipleFinalObj);
    }

    if (POTypeVal === "Indent") {
      let poMultipleIndentFinalObj = [];
      for (let poMultipleIndentObj of requiredTableArr) {
        let requiredObj = {
          addedBy: getUserInfo.userId,
          amount: Number(Number(poMultipleIndentObj.totalAmount).toFixed(2)),
          creditPeriod: poMultipleIndentObj.creditPeriod,
          discAmt: Number(Number(poMultipleIndentObj.discAmount).toFixed(2)),
          discPer: Number(poMultipleIndentObj.gstPercent),
          doQty: poMultipleIndentObj.POQty,
          expDeliveryDate: poMultipleIndentObj.expDeliveryDate,
          freeQty: Number(poMultipleIndentObj.freeQty),
          indentDetailsId: poMultipleIndentObj.indentDetailsId,
          isOrderPlaced: poMultipleIndentObj.isOrderPlaced,
          itemId: poMultipleIndentObj.itemId,
          itemManufacturedById: poMultipleIndentObj.itemManufacturedById,
          markForClosed: poMultipleIndentObj.markForClosed,
          mrp: poMultipleIndentObj.mrp,
          netAmount: Number(Number(poMultipleIndentObj.netAmount).toFixed(2)),
          pendQty: poMultipleIndentObj.pendQty,
          rate: poMultipleIndentObj.Rate,
          specifications: poMultipleIndentObj.specification,
          storeId: getUserInfo.storeId,
          supplierId: poMultipleIndentObj?.supplierObj.id,
          vatAmt: Number(Number(poMultipleIndentObj.gstAmount).toFixed(2)),
          vatPer: Number(poMultipleIndentObj.gstPercent),
          doType: doTypeVal,
        };
        poMultipleIndentFinalObj.push(requiredObj);
      }

      setOpenPost(true);
      setFinalData(poMultipleIndentFinalObj);
    }
  };

  function postPurchaseOrderMultiple(obj) {
    addNewPurchaseOrderMultiple(location?.state?.menuId, privilege, obj)
      .then((response) => {
        if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          populatePOListTable();
          reset(defaultValues);
          setPoMultipleData([]);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  function addRecord() {
    setOpenPost(false);
    setOpenBackdrop(true);
    postPurchaseOrderMultiple(finalData);
  }

  let itemWatch = watch("searchItemCode");
  let supplierInputRef = useRef();
  React.useEffect(() => {
    if (supplierInputRef && itemWatch !== null) {
      supplierInputRef.current?.focus();
    }
  }, [itemWatch]);

  function dontAllowOnKeyUpDownSpecialChar(e) {
    if (
      e?.key === "e" ||
      e?.key === "-" ||
      e?.key === "+" ||
      e?.code === "ArrowUp" ||
      e?.code === "ArrowDown" ||
      e?.code === "NumpadDecimal"
    ) {
      e.preventDefault();
    }
  }
  return (
    <>
      <div className="pt-12 px-2 w-full">
        <div className="text-center text-black font-bold text-xl py-2 ">
          Purchase Order(Multiple)
        </div>

        <form onSubmit={handleSubmit(onSubmitDataHandler)}>
          <div className="flex gap-4 items-center justify-between px-2 pb-3">
            <div className="flex gap-4 justify-between ">
              <div className=" w-80 pt-1 ">
                <InputField
                  name="storeName"
                  label="Store*"
                  placeholder="Store*"
                  control={control}
                  error={errors.storeName}
                  disabled={true}
                />
              </div>

              <div className="flex  whitespace-nowrap col-span-2 gap-3 items-center border rounded px-2 p-0.5">
                <p className="font-semibold">PO Type :</p>
                <div className="px-2 flex pt-2 whitespace-nowrap">
                  <RadioField
                    name="poType"
                    control={control}
                    dataArray={poType}
                  />
                </div>
              </div>
              {POTypeVal === "Indent" ? (
                <SearchIndentButton
                  onClick={() => {
                    handleOpenIndent();
                  }}
                />
              ) : (
                ""
              )}
            </div>

            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "PO List" ? (
                  <CommonButton
                    type="button"
                    className="bg-customBlue text-white"
                    label="Purchase Order List"
                    onClick={() => {
                      handlePOListOpen();
                    }}
                  />
                ) : null}
              </>
            ))}
          </div>

          <hr className="w-full border-t-1 border-blue-800" />

          {POTypeVal === "Direct" ? (
            <>
              <div className="grid grid-cols-5 lg:grid-cols-7 xl:grid-cols-10 2xl:grid-cols-12 gap-3 mt-4 w-full">
                <div className="col-span-3 flex space-x-3 items-center">
                  <label className="font-semibold text-lg whitespace-nowrap ">
                    Item Details
                  </label>
                  <SearchDropdown
                    control={control}
                    searchIcon={true}
                    name="searchItemCode"
                    placeholder="Search Item Code"
                    isSearchable={true}
                    isClearable={true}
                    dataArray={itemOptions}
                    handleInputChange={handleChange}
                    error={errors.searchItemCode}
                  />
                </div>
                <div className=" w-full col-span-2">
                  <DropdownField
                    control={control}
                    error={errors.supplier}
                    name="supplier"
                    placeholder="Supplier"
                    dataArray={supplierOptions}
                    // isClearable={true}
                    isDisabled={searchItemCode === null}
                    inputRef={{
                      ...register("supplier", {
                        onChange: (e) => {
                          setSupplierId(e.target.value.id);
                        },
                      }),
                    }}
                  />
                </div>
                <div
                  className=""
                  onKeyDown={(e) => {
                    dontAllowOnKeyUpDownSpecialChar(e);
                  }}
                >
                  <InputField
                    name="qty"
                    label=" Qty"
                    placeholder="Qty"
                    type="number"
                    control={control}
                    error={errors.qty}
                  />
                </div>
                <div
                  className=""
                  onKeyDown={(e) => {
                    dontAllowOnKeyUpDownSpecialChar(e);
                  }}
                >
                  <InputField
                    name="freeQty"
                    label="Free Qty"
                    placeholder="Free Qty"
                    type="number"
                    control={control}
                    error={errors.freeQty}
                  />
                </div>
                <div className="">
                  <InputField
                    name="uom"
                    label=" UOM"
                    placeholder="UOM"
                    control={control}
                    error={errors.uom}
                    disabled={true}
                  />
                </div>
                <div
                  className=""
                  onKeyDown={(e) => {
                    dontAllowOnKeyUpDownSpecialChar(e);
                  }}
                >
                  <InputField
                    name="rate"
                    label=" Rate"
                    placeholder="Rate"
                    type="number"
                    control={control}
                    error={errors.rate}
                  />
                </div>
                <div className="">
                  <InputField
                    name="totalAmount"
                    label="Total Amount"
                    placeholder="Total Amount"
                    type="number"
                    control={control}
                    error={errors.totalAmount}
                    disabled={true}
                  />
                </div>

                <div
                  onKeyDown={(e) => {
                    dontAllowOnKeyUpDownSpecialChar(e);
                  }}
                  className=""
                >
                  <InputLimit
                    maxDigits={3}
                    name="discPercentage"
                    label="Disc %"
                    placeholder="Disc %"
                    type="number"
                    control={control}
                    error={errors.uom}
                    inputRef={{
                      ...register("discPercentage", {
                        onChange: (e) => {
                          if (e.target.value === "") {
                            setValue("discPercentage", Number(0));
                          }
                          if (e.target.value > 100) {
                            setValue("discPercentage", Number(100));
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="">
                  <InputField
                    name="discAmount"
                    label="Disc Amount"
                    type="number"
                    control={control}
                    error={errors.discAmount}
                    disabled={true}
                  />
                </div>
                <div
                  className=""
                  onKeyDown={(e) => {
                    dontAllowOnKeyUpDownSpecialChar(e);
                  }}
                >
                  <InputLimit
                    maxDigits={3}
                    name="gstPercentage"
                    label="GST %"
                    placeholder="GST %"
                    type="number"
                    control={control}
                    error={errors.gstPercentage}
                    inputRef={{
                      ...register("gstPercentage", {
                        onChange: (e) => {
                          if (e.target.value === "") {
                            setValue("gstPercentage", Number(0));
                          }
                          if (e.target.value > 100) {
                            setValue("gstPercentage", Number(100));
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="">
                  <InputField
                    name="gstAmount"
                    label="GST Amount"
                    type="number"
                    placeholder="GST Amount"
                    control={control}
                    error={errors.gstAmount}
                    disabled={true}
                  />
                </div>

                <div className="">
                  <InputField
                    name="netAmount"
                    label="Net Amount"
                    type="number"
                    placeholder="Net Amount"
                    control={control}
                    error={errors.netAmount}
                    disabled={true}
                  />
                </div>
                <div
                  className=""
                  onKeyDown={(e) => {
                    dontAllowOnKeyUpDownSpecialChar(e);
                  }}
                >
                  <InputField
                    name="mrp"
                    label="MRP"
                    type="number"
                    placeholder="MRP"
                    control={control}
                    error={errors.mrp}
                  />
                </div>
                <div className="col-span-2">
                  <InputField
                    name="specification"
                    label="Specification"
                    placeholder="Specification"
                    control={control}
                    error={errors.specification}
                  />
                </div>
                <div className="">
                  <CommonButton
                    type="button"
                    className="bg-customGreen text-white"
                    label="Add"
                    onClick={() => {
                      addItemsDetailsData();
                      if (
                        searchItemCode !== null &&
                        supplier !== null &&
                        qty !== ""
                      ) {
                        setValue("searchItemCode", null);
                        setValue("supplier", null);
                        setValue("uom", "");
                        setValue("qty", "");
                        setValue("freeQty", "");
                        setValue("rate", Number(0));
                        setValue("totalAmount", Number(0));
                        setValue("discPercentage", Number(0));
                        setValue("discAmount", Number(0));
                        setValue("gstPercentage", Number(0));
                        setValue("gstAmount", Number(0));
                        setValue("netAmount", Number(0));
                        setValue("specification", "");
                        setValue("mrp", Number(0));
                      }
                    }}
                  />
                </div>
              </div>

              <div className="w-full">
                <POMultipleTable
                  poMultipleData={poMultipleData}
                  setPoMultipleData={setPoMultipleData}
                />
              </div>
            </>
          ) : (
            <div className="py-4">
              <MultiIndentAgainstPOItemTable
                multiIndentAgainstItem={multiIndentAgainstItem}
                setMultiIndentAgainstItem={setMultiIndentAgainstItem}
                supplierOptions={supplierOptions}
                requiredTableArr={requiredTableArr}
                setRequiredTableArr={setRequiredTableArr}
              />
            </div>
          )}

          <div className="grid grid-cols-4 gap-2 py-2">
            <div className=" w-full">
              <InputField
                control={control}
                name="guaranteewarranty"
                label="Guarantee / Warranty"
              />
            </div>
            <div className=" w-full">
              <DropdownField
                control={control}
                // error={errors.schedule}
                name="schedule"
                placeholder="Schedule"
                dataArray={schedule}
              />
            </div>

            <div>
              <InputField control={control} name="otherTax" label="Other Tax" />
            </div>
            <div className="">
              <InputField control={control} name="remark" label="Remark" />
            </div>

            <div className="col-span-3 grid grid-cols-3  gap-2 py-2">
              <div className="flex gap-2 text-sm">
                <h1 className="text-black font-semibold flex space-x-2">
                  <span className="">Total Disc Amt </span>
                  <span className=""> : ₹</span>
                </h1>
                <h1 className="font-normal">
                  {Number(
                    totalDiscountAmount
                      ? Number(totalDiscountAmount).toFixed(2)
                      : 0
                  )}
                </h1>
              </div>

              <div className="flex gap-2 text-sm">
                <h1 className="text-black font-semibold flex space-x-2">
                  <span className="">Total GST Amt </span>
                  <span className=""> : ₹</span>
                </h1>

                <h1 className="font-normal">
                  {Number(
                    totalGstAmount ? Number(totalGstAmount).toFixed(2) : 0
                  )}
                </h1>
              </div>

              <div className="flex gap-2 text-sm">
                <h1 className="text-black font-semibold flex space-x-2 lg:space-x-2">
                  <span className="whitespace-nowrap">Total Net Amt</span>
                  <span className=""> : ₹</span>
                </h1>
                <h1 className="font-normal">
                  {Number(
                    totalNetAmount ? Number(totalNetAmount).toFixed(2) : 0
                  )}
                </h1>
              </div>
            </div>
          </div>
          <div className="flex justify-end py-2 gap-2">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" ? (
                  <>
                    <CommonButton
                      type="button"
                      className="border border-customRed text-customRed"
                      label="Reset"
                      onClick={() => {
                        reset(defaultValues);
                        setPoMultipleData([]);
                        setSupplierId(null);
                        setMultiIndentAgainstItem([]);
                        setRequiredTableArr([]);
                      }}
                    />
                    <CommonButton
                      type="submit"
                      className="bg-customGreen text-white"
                      label="Save"
                      onClick={() => {
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
        </form>
      </div>

      <CommonBackDrop openBackdrop={openBackdrop} />

      <POMultipleIndentModal
        open={openIndent}
        setOpen={setOpenIndent}
        handleOpen={handleOpenIndent}
        handleClose={handleCloseIndent}
        setMultiIndentAgainstItem={setMultiIndentAgainstItem}
        multiIndentAgainstItem={multiIndentAgainstItem}
      />

      <POListModal
        poListModal={poListModal}
        setPoListModalSupplierID={setPoListModalSupplierID}
        getUserInfo={getUserInfo}
        handlePOListClose={handlePOListClose}
        setisCancelled={setisCancelled}
        setIsOrderPlaced={setIsOrderPlaced}
        data={data}
        setData={setData}
        populatePOListTable={populatePOListTable}
        selectedPOListFromDate={selectedPOListFromDate}
        setSelectedPOListFromDate={setSelectedPOListFromDate}
        selectedPOListToDate={selectedPOListToDate}
        setSelectedPOListToDate={setSelectedPOListToDate}
        //Table
        defaultParams={defaultParams}
        tableApiFunc={fetchAllPurchaseOrderList}
        dataResult={dataResult}
        setDataResult={setDataResult}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
        isCancelled={isCancelled}
        isOrderPlaced={isOrderPlaced}
        poListModalSupplierID={poListModalSupplierID}
        userActions={userActions}
        privilege={privilege}
        setPrivilege={setPrivilege}
        menuId={location?.state?.menuId}
      />

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}
