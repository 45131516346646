import { Edit, EditOff } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getDrugAdmDtlsSlots,
  stopDrugAdminOrder,
} from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import AdministratorDrugListEditRowModal from "./AdministratorDrugListEditRowModal";
import DrugAdministratorDetailsModal from "./DrugAdministratorDetailsModal";
import DrugScheduleModal from "./DrugScheduleModal";
import {
  EditIcon,
  EditIconDisabled,
} from "../../../assets/icons/InvestigationIcon";

const StyledCell = styled.div`
  &.AlternativeDayDrug {
    color: #0000c0;
  }
  &.OnceInaWeekDrug {
    color: #008000;
  }
  &.OutsideMedicines {
    color: #007ea9 !important;
  }
  &.PerHourDrug {
    color: #ff00ff;
  }
  &.IsHoldDrugAdmin {
    color: #ea580c !important;
  }
  &.StopDrug {
    color: #dc143c !important;
  }
`;

function AdministrationDrugList(props) {
  const {
    tableApiFunc,
    dataResult,
    setDataResult,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    rowData,
    count,
    setTempTableData,
    tempTableData,
  } = props;

  // open rescheduleModal
  const [openRescheduleModal, setOpenResceduleModal] = React.useState(false);
  const handleOpenRescheduleModal = () => setOpenResceduleModal(true);
  const handleCloseRescheduleModal = () => setOpenResceduleModal(false);

  //stop drug confirmation modal
  const [openStopDrugConfirmationModal, setOpenConfirmationModal] =
    useState(false);
  const handleOpenStopDrug = () => setOpenConfirmationModal(true);
  const handleCloseStopDrug = () => setOpenConfirmationModal(false);

  //actions
  const [editRowModal, setEditRowModal] = React.useState(false);
  const handleOpenEditModal = () => setEditRowModal(true);
  const handleCloseEditModal = () => setEditRowModal(false);
  const [oldCount, setOldCount] = React.useState();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [drugDetailsLoadingSpinner, setDrugDetailsLoadingSpinner] =
    useState(true);
  const [drugAdmDtlsSlots, setDrugAdmDtlsSlots] = useState([]);
  const [selectedRowDrugSchedule, setSelectedRowDrugSchedule] = useState(null);

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  useEffect(() => {
    setTempTableData(dataResult);
  }, [dataResult]);

  //set rows object to table
  const allHeaders = Object.keys(
    tempTableData.length > 0 ? tempTableData[0] : []
  );

  const headers = removeHeaders(allHeaders, [
    "DrugAdminHeaderId",
    "Per Hr. Drug",
    "IsOutsideMedication",
    "Once in Week",
    "Alternative Day",
    "IsStopDrugAdmin",
    "ItemId",
    "DoseId",
    "PrescriptionDetailId",
    "IsHoldDrugAdmin",
    "IssueQty",
    "DrugAdminQty",
    "BalanceQty",
    "RouteId",
    "RowNumber",
    "AdminAs",
    "IsChemoDrug",
    "PatchDuration",
    "Stop Date Time",
    "PrescriptionDetailId",
    "DrugAdminDtlId",
    "DrugInstruction",
  ]);

  const checkCallApi = (pageNo, rowsPerPageNo, oldTotal) => {
    pageNo = pageNo + 1;
    let newTotal = pageNo * rowsPerPageNo;
    let arr = [...dataResult];
    let totalDataLength = count;
    let availableDataLength = arr.length;
    if (totalDataLength > availableDataLength) {
      // page has moved forwar

      // if i dont have total record to show
      if (newTotal > availableDataLength) {
        //
        let require = newTotal - availableDataLength;
        let toShow = availableDataLength + require;

        // check available vs needed
        // needed is less than count
        let needed = 0;
        if (toShow < totalDataLength) {
          needed = newTotal - oldTotal;
          callAgainTableApi(needed);
        } else if (toShow > totalDataLength) {
          needed = totalDataLength - availableDataLength; // after
          // Before // needed = toShow - totalDataLength
          callAgainTableApi(needed);
        } else {
          needed = rowsPerPageNo;
          callAgainTableApi(needed);
        }
      }
    }
  };

  const callAgainTableApi = (recordsNeeded) => {
    tableApiFunc(
      rowData?.AdmissionId,
      page,
      rowsPerPage,
      props.menuId,
      props.todaysDrugValue
    )
      .then((response) => response.data)
      .then((res) => {
        let incomingData = res.result;
        setTempTableData(incomingData);
        setDataResult(incomingData);
      });
  };

  const handlePageChange = (event, newPage) => {
    if (newPage > page) {
      // Next button clicked, call API
      setOldCount((page + 1) * rowsPerPage);
      setPage(newPage);
    } else if (newPage < page) {
      // Previous button clicked, only update the page state
      setPage(newPage);
    }
  };

  //5,10.25 change as per the selection
  const handleChangeRowsPerPage = (event) => {
    setOldCount((page + 1) * rowsPerPage);
    let newRows = parseInt(event.target.value, 10);
    let newTotal = (page + 1) * newRows;
    let additionalRecord = newTotal - count;

    if (additionalRecord > newRows) {
      setPage(page - 1);
    }
    setRowsPerPage(newRows);
  };

  // stopDrugAPi
  const stopDrug = () => {
    props.setOpenLoader(true);
    stopDrugAdminOrder(
      selectedRow?.DrugAdminHeaderId,
      props.patientInfo.menuId !== undefined
        ? props.patientInfo.menuId
        : props?.menuId,
      props?.privilege
    )
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        props.getDrugList();
        props.setOpenLoader(false);
        setSelectedRow(null);
        handleCloseStopDrug();
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        props.setOpenLoader(false);
      });
  };

  const handleUp = (index) => {
    if (index > 0) {
      setTempTableData((prevState) => {
        const updatedData = [...prevState];
        const temp = updatedData[index];
        updatedData[index] = updatedData[index - 1];
        updatedData[index - 1] = temp;
        return updatedData;
      });
    }
  };

  const handleDown = (index) => {
    if (index < tempTableData.length - 1) {
      setTempTableData((prevState) => {
        const updatedData = [...prevState];
        const temp = updatedData[index];
        updatedData[index] = updatedData[index + 1];
        updatedData[index + 1] = temp;
        return updatedData;
      });
    }
  };

  function getCssClass(index, header) {
    const row = tempTableData[index];
    if (row.IsStopDrugAdmin) {
      return "StopDrug";
    } else if (row.IsOutsideMedication) {
      return "OutsideMedicines";
    } else if (row["Per Hr. Drug"]) {
      return "PerHourDrug";
    } else if (row["Once in Week"]) {
      return "OnceInaWeekDrug";
    } else if (row["IsHoldDrugAdmin"]) {
      return "IsHoldDrugAdmin";
    } else if (row["Alternative Day"]) {
      return "AlternativeDayDrug";
    }
  }

  useEffect(() => {
    if (oldCount !== undefined) {
      checkCallApi(page, rowsPerPage, oldCount);
    }
  }, [page, rowsPerPage, oldCount]);

  // useEffect(() => {
  //   setTempTableData(props.data.result);
  // }, [props.data]);

  const populateTimeSlots = () => {
    setDrugDetailsLoadingSpinner(true);
    if (selectedRow !== null) {
      getDrugAdmDtlsSlots(selectedRow.PrescriptionDetailId)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            setDrugAdmDtlsSlots(res);
            setDrugDetailsLoadingSpinner(false);
          }
        })
        .catch((error) => {
          setDrugDetailsLoadingSpinner(false);
        });
    }
  };

  useEffect(() => {
    populateTimeSlots();
  }, [selectedRow]);

  // <TablePagination
  //   sx={{
  //     ".MuiTablePagination-toolbar": {
  //       // backgroundColor: "lightblue",
  //       minHeight: "35px",
  //     },
  //   }}
  //   rowsPerPageOptions={[10, 25, 50]}
  //   component="div"
  //   count={count}
  //   rowsPerPage={rowsPerPage}
  //   page={page}
  //   onPageChange={handlePageChange}
  //   onRowsPerPageChange={handleChangeRowsPerPage}
  // />

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-[260px] border 2xl:h-[300px]"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Actions
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Sequence
                      </span>
                    </TableCell>
                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tempTableData &&
                    tempTableData.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          onClick={() => {
                            setSelectedRowDrugSchedule(row);
                          }}
                        >
                          {props.actions.length > 0 ? (
                            <TableCell className="px-4 py-1 flex whitespace-nowrap ">
                              <div
                                className="flex"
                                style={{ lineHeight: "0.43px" }}
                              >
                                {props.userActions.map((actions, i) => (
                                  <>
                                    {actions.isAction === true ? (
                                      <>
                                        {actions.action === "Stop Drug" ? (
                                          <>
                                            {row.IsStopDrugAdmin === false ? (
                                              <Tooltip title="Stop Drug">
                                                <span
                                                  value="click"
                                                  className="text-red-500 mr-3 cursor-pointer"
                                                  onClick={(e) => {
                                                    props.setPrivilege(
                                                      actions.action
                                                    );
                                                    setSelectedRow(row);
                                                    handleOpenStopDrug();
                                                  }}
                                                >
                                                  <DoDisturbIcon
                                                    style={{
                                                      display:
                                                        row.IsStopDrugAdmin
                                                          ? "hidden"
                                                          : "",
                                                    }}
                                                  />
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : null}
                                  </>
                                ))}

                                {props.userActions.map((actions, i) => (
                                  <>
                                    {actions.isAction === true ? (
                                      <>
                                        {actions.action === "Reschedule" ? (
                                          <>
                                            {row.IsStopDrugAdmin === false ? (
                                              <Tooltip title="Reschedule">
                                                <span
                                                  value="click"
                                                  className="text-customBlue mr-3 cursor-pointer"
                                                  onClick={() => {
                                                    props.setPrivilege(
                                                      actions.action
                                                    );

                                                    handleOpenRescheduleModal();
                                                  }}
                                                >
                                                  <CalendarMonthIcon />
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : null}
                                  </>
                                ))}

                                {props.userActions.map((actions, i) => (
                                  <>
                                    {actions.isAction === true ? (
                                      <>
                                        {actions.action === "Edit" ? (
                                          <>
                                            {row.Frequency !== "STAT" &&
                                            row.Frequency !== "SOS" &&
                                            row.Frequency !== "Stat/sos" &&
                                            row.IsStopDrugAdmin === false ? (
                                              <Tooltip title="Edit">
                                                <span
                                                  className="text-customBlue mr-3 cursor-pointer"
                                                  onClick={() => {
                                                    props.setPrivilege(
                                                      actions.action
                                                    );
                                                    handleOpenEditModal();
                                                  }}
                                                >
                                                  <EditIcon />
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              <EditIconDisabled />
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : null}
                                  </>
                                ))}
                              </div>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          <TableCell key={index}>
                            <div className="flex w-full space-x-1">
                              <button
                                type="button"
                                className={
                                  index > 0
                                    ? "bg-white h-[20px] w-[20px] mx-1 my-2 visible justify-end rounded"
                                    : "invisible ml-2"
                                }
                                onClick={() => {
                                  handleUp(index);
                                }}
                              >
                                <ArrowUpwardIcon
                                  fontSize="small"
                                  className="text-customBlue"
                                />
                              </button>
                              <button
                                type="button"
                                className={
                                  index < dataResult.length - 1
                                    ? "bg-white h-[20px] w-[20px] my-2 visible justify-end rounded-md"
                                    : "invisible w-[20px] ml-[12px]"
                                }
                                onClick={() => {
                                  handleDown(index);
                                }}
                              >
                                <ArrowDownwardIcon
                                  fontSize="small"
                                  className="text-customOrange"
                                />
                              </button>
                            </div>
                          </TableCell>

                          {headers &&
                            headers.map((header) => {
                              return (
                                <TableCell
                                  key={header}
                                  className="whitespace-nowrap"
                                  onClick={(e) => {
                                    // if (row.IsStopDrugAdmin === false) {
                                    handleOpen();
                                    // }
                                    setSelectedRow(row);
                                  }}
                                  style={{
                                    backgroundColor:
                                      selectedRow === index ? "#FFC44B" : "",
                                  }}
                                >
                                  <StyledCell
                                    key={header}
                                    className={getCssClass(index, header)}
                                    style={{
                                      textDecoration: row.IsStopDrugAdmin
                                        ? "line-through"
                                        : "",
                                    }}
                                  >
                                    {row[header]}
                                  </StyledCell>
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        {open ? (
          <div>
            <DrugAdministratorDetailsModal
              open={open}
              setOpen={setOpen}
              row={selectedRow}
              handleOpen={handleOpen}
              tableData={tempTableData}
              selectedAdmDrugRowList={selectedRow}
              setSelectedRow={setSelectedRow}
              handleClose={handleClose}
              drugAdmDtlsSlots={drugAdmDtlsSlots}
              setDrugAdmDtlsSlots={setDrugAdmDtlsSlots}
              populateTable={populateTimeSlots}
              getDrugList={props.getDrugList}
              rowData={props.rowData}
              menuId={props.menuId}
              userActions={props.userActions}
              privilege={props.privilege}
              setPrivilege={props.setPrivilege}
              selectedPatientInfo={props.selectedPatientInfo}
              setOpenLoader={props.setOpenLoader}
              drugDetailsLoadingSpinner={drugDetailsLoadingSpinner}
              setDrugDetailsLoadingSpinner={setDrugDetailsLoadingSpinner}
              patientInfo={props.patientInfo}
              setTempTableData={setTempTableData}
              tempTableData={tempTableData}
            />
          </div>
        ) : (
          ""
        )}
        {openRescheduleModal ? (
          <DrugScheduleModal
            open={openRescheduleModal}
            setOpen={setOpenResceduleModal}
            handleOpen={handleOpenRescheduleModal}
            row={selectedRowDrugSchedule}
            setRow={setSelectedRowDrugSchedule}
            handleClose={handleCloseRescheduleModal}
            selectedPatientInfo={props.selectedPatientInfo}
            populateTable={props.getDrugList}
            getDrugList={props.getDrugList}
            userActions={props.userActions}
            rowData={props.rowData}
            menuId={props.menuId}
            privilege={props.privilege}
            setPrivilege={props.setPrivilege}
            editRow={true}
            setOpenLoader={props.setOpenLoader}
            loadingSpinner={props.loadingSpinner}
            patientInfo={props.patientInfo}
            menuIdnew={props.menuIdnew}
          />
        ) : (
          ""
        )}
      </div>
      {editRowModal ? (
        <AdministratorDrugListEditRowModal
          open={editRowModal}
          handleClose={handleCloseEditModal}
          row={selectedRowDrugSchedule}
          setRow={setSelectedRowDrugSchedule}
          getDrugList={props.getDrugList}
          menuId={props.menuId}
          privilege={props.privilege}
          rowData={props.rowData}
          setOpenLoader={props.setOpenLoader}
          patientInfo={props.patientInfo}
        />
      ) : (
        ""
      )}
      <ConfirmationModal
        confirmationOpen={openStopDrugConfirmationModal}
        confirmationHandleClose={handleCloseStopDrug}
        confirmationSubmitFunc={stopDrug}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to stop Drug ?"
        confirmationButtonMsg="Yes"
      />
    </>
  );
}
export default React.memo(AdministrationDrugList);
