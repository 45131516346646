import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { format, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import { getDrugNameDropdown } from "../../../ipd/services/drugadministratororderservices/DrugAdministratOrorderServices";
import {
  getScheduleTimeSlotsList,
  saveDrugAdmOrder,
} from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import DrugScheduleTable from "./DrugScheduleTable";

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: { sm: 300, md: 400, lg: 500 },
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

export default function DrugScheduleModal(props) {
  const defaultValues = {
    drugName: null,
    isSubsitude: false,
  };
  // const schema = {};
  const { control, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
    defaultValues,
  });

  function displayView(row) {
    //
  }

  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [finalData, setFinalData] = useState({});
  const [drugNameOptions, setDrugNameOptions] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  // local variable
  let isSubsitudeValue = watch("isSubsitude");
  let drugNameValue = watch("drugName");

  // Confirmation modal
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  // PrescriptionDetailId
  useEffect(() => {
    getScheduleTimeSlotsList(props.row?.PrescriptionDetailId)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          // if (
          //   props.row?.Frequency === "Stat/sos" ||
          //   props.row?.Frequency === "SOS" ||
          //   props.row?.Frequency === "STAT"
          // ) {
          // if (res.result?.length > 0) {
          //   const newData = [...res.result];

          //   for (let i = 0; i < newData.length; i++) { 
          //     const timeSlotKey = Object.keys(newData[i].timeDto[0]).find(
          //       (key) => key !== "ID"
          //     );
          //     newData[i].timeDto[0][timeSlotKey] = props.row["Start Time"];

          //     setTimeSlotsData(newData);
          //     setLoadingSpinner(false);
          //   }
          // }
          // } else {
          setTimeSlotsData(res.result);
          setLoadingSpinner(false);
          // }
        }
      })
      .catch((error) => {
        setLoadingSpinner(false);
      });
  }, [props.row]);

  // search by drug Name
  const handleSearchDrugName = (e) => {
    if (e.length > 0) {
      getDrugNameDropdown(e).then((response) => {
        setDrugNameOptions(response.data.result);
      });
    }
  };

  function postDrugAdmOrder(obj) {
    saveDrugAdmOrder(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setFinalData(null);
        setTimeSlotsData([]);
        props.populateTable();
        props.getDrugList();
        props.handleClose();
        reset(defaultValues);
        props.setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.message);
        handleClosePost();
        props.setOpenLoader(false);
      });
  }

  function addRecord() {
    props.setOpenLoader(true);
    setOpenPost(false);
    postDrugAdmOrder(finalData);
  }

  const onSubmitDataHandler = () => {
    let drugAdmDetailsDto = [];
    if (timeSlotsData.length > 0) {
      let tempHeader = timeSlotsData.filter((val) => val?.timeDto !== null);

      for (let obj of tempHeader) {
        const timeSloteDate = obj.date;
        const dateObject = new Date(timeSloteDate);
        const formattedDate = dateObject.toISOString();
        for (let reqObj of obj.timeDto) {
          let requiredObj = {
            administratorBy: 0,
            closeDrugAdmin: false,
            closeDrugAdminBy: 0,
            drugAdminDate: formattedDate, // date
            drugDose: props.row.Dose,
            drugFrequency: props.row.Frequency,
            drugRoute: props.row.Route,
            isDrugAdminDone: false,
            isHoldDrugAdmin: false,
            isNewDrugSchRec: false,
            id: reqObj.ID,//new key
            timeSlot: Object.values(reqObj).find(
              (slot) => typeof slot === "string"
            ),
          };
          drugAdmDetailsDto.push(requiredObj);
        }
      }
    }
    // remove + formate from the row startDate and endDate

    let fromDate = props.row["Start Date"];
    const startFormattedDate = format(
      parse(fromDate, "dd-MM-yyyy", new Date()),
      "yyyy-MM-dd"
    );

    let finalStartDate = format(
      new Date(startFormattedDate),
      "yyyy-MM-dd'T'hh:mm:ss.000"
    );

    const endDate = props.row["End Date"];
    const endFormatedDate = format(
      parse(endDate, "dd-MM-yyyy", new Date()),
      "yyyy-MM-dd"
    );
    let finalEndDate = format(
      new Date(endFormatedDate),
      "yyyy-MM-dd'T'hh:mm:ss.000"
    );

    const postObj = {
      admissionId: props?.selectedPatientInfo.AdmissionId,
      dose: props.row?.Dose,
      isSubsitude: isSubsitudeValue,
      doseId: props.row?.DoseId,
      drugAdmDetailsDto: drugAdmDetailsDto,
      drugAdminQty: 0,
      drugId:
        drugNameValue !== null
          ? drugNameValue.id
          : props.row?.DrugId || props.row?.ItemId,
      drugName:
        drugNameValue !== null
          ? drugNameValue.label
          : props.row?.["Item Name"] || props.row?.["Item Name"],
      duration: props.row?.Duration,
      endDate: finalEndDate,
      isStopDrugAdmin: false,
      issueQty: 0,
      patientId:
        props?.selectedPatientInfo.PatientId === undefined
          ? props.patientInfo.PatientId
          : props?.selectedPatientInfo.PatientId,
      prescriptionDtlsId: props.row?.PrescriptionDetailId,
      routeId: props.row?.RouteId,
      rowNumber: 0,
      startDate: finalStartDate,
      stopBy: 0,
      updatedBy: 0,
      menuId:
        props.patientInfo.menuId === undefined
          ? props.menuId
          : props.patientInfo.menuId,
      privilege: props.privilege,
    };

    if (props.userActions !== undefined) {
      for (let obj of props.userActions) {
        if (props.privilege === undefined) {
          if (obj.action === "Reschedule") {
            postObj.privilege = "Reschedule";
          }
        }
      }
    } else {
      postObj.privilege = props.privilege;
    }
    setFinalData(postObj);
    setOpenPost(true);
  };

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className=" p-4 ">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
            }}
          />
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <div className="row">
              <h1 className="flex justify-center  text-xl font-semibold">
                Drug Schedule
              </h1>
              <div className="flex justify-between items-center text-xs 2xl:text-sm p-2 font-semibold text-gray-600 rounded bg-gray-100">
                <div className="flex space-x-2 items-center">
                  <h1>Drug Name &nbsp; :</h1>
                  <h1>{props.row?.["Item Name"]}</h1>
                </div>
                <div className="flex space-x-2 items-center">
                  <h1>Route &nbsp; :</h1>
                  <h1>{props.row?.Route}</h1>
                </div>
                <div className="flex space-x-2 items-center">
                  <h1>Dr.Name &nbsp; :</h1>
                  <h1>{props.selectedPatientInfo?.["Doctor Name"]}</h1>
                </div>
              </div>

              <div className="mt-2">
                <CheckBoxField
                  name="isSubsitude"
                  control={control}
                  label="Is Substitude Drug"
                />
              </div>
              <div className="flex items-center space-x-3 w-5/12 mb-2">
                <CreateableSelect
                  control={control}
                  name="drugName"
                  isDisabled={isSubsitudeValue === false ? true : false}
                  showSearch={true}
                  placeholder="Drug Name*"
                  dataArray={drugNameOptions}
                  isSearchable={false}
                  onInputChange={handleSearchDrugName}
                />
              </div>
              {loadingSpinner === false && timeSlotsData?.length > 0 ? (
                <DrugScheduleTable
                  displayView={displayView}
                  data={timeSlotsData}
                  setDrugScheduleData={setTimeSlotsData}
                  modifyDrugData={props.modifyDrugData}
                  userActions={props.userActions}
                  privilege={props.privilege}
                  setPrivilege={props.setPrivilege}
                  editRow={props.editRow}
                  selectedRow={props.row}
                />
              ) : (
                <div className="flex justify-center my-28">
                  {loadingSpinner === true ? (
                    <LoadingSpinner />
                  ) : (
                    <span className="text-gray-600 font-semibold">
                      No Records Found ...
                    </span>
                  )}
                </div>
              )}
            </div>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
