import { Box, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { ModalStyleFeedback } from "../../../../common/components/ModalStyle";
import { useForm } from "react-hook-form";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import {
  getPatientForDemographic,
  patientInfoChangeRequestList,
  searchRequestsLists,
} from "../../../services/pathology/demographicServices/DemographicServices";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import ApprovedModal from "./ApprovedModal";
import { UseCheckConsecutiveCharacters } from "../../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";

const DemographicRequestsModal = ({
  open,
  handleClose,
  locationValue,
  setOpenBackdrop,
}) => {
  const { watch, control, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      searchRequest: null,
      patientType: "Both",
      fromDate: new Date(),
      toDate: new Date(),
      status: "All",
    },
  });

  let fromDatevalue = watch("fromDate");
  let toDateValue = watch("toDate");
  let patientTypeValue = watch("patientType");
  let statusValue = watch("status");
  let serachRequestValue = watch("searchRequest");
  const hasMounted = useRef(false);

  const [patientRequestList, setPatientRequestList] = useState([]);
  const [searchRequestList, setSearchRequestList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [count, setCount] = useState(null);
  const [selectedApprovedRow, setSelectedApprovedRow] = useState(null);

  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [openApprovedModal, setOpenApprovedModal] = useState(false);

  const populateTable = (forPagination) => {
    const defaultParams = {
      page: !forPagination ? 0 : 0,
      size: rowsPerPage,
      opdIpdBoth:
        patientTypeValue === "OPD" ? 0 : patientTypeValue === "IPD" ? 1 : null,
      fromDate: fromDatevalue,
      toDate: toDateValue,
      status:
        statusValue === "Approved"
          ? true
          : statusValue === "Pending"
          ? false
          : null,
      requestId: serachRequestValue !== null ? serachRequestValue?.id : null,
    };

    setLoadingSpinner(true);
    patientInfoChangeRequestList(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setLoadingSpinner(false);
        if (forPagination) {
          setPatientRequestList((prevData) => [...prevData, ...res.result]);
        } else {
          console.log("requestList", res);
          setPatientRequestList(res.result);
          setPage(0);
        }
        setCount(res.count);
      })
      .catch((error) => {
        setLoadingSpinner(false);
      });
  };

  const handleSearchByPatientName = (searchString) => {
    if (UseCheckConsecutiveCharacters(searchString) && searchString !== "") {
      searchRequestsLists(searchString, patientTypeValue === "OPD" ? 0 : 1)
        .then((response) => response.data)
        .then((res) => {
          setSearchRequestList(res.result);
        })
        .catch((error) => error);
    }
  };

  const renderActions = (row, index) => {
    return (
      <>
        {row.isApproved === false ? (
          <CommonButton
            type="button"
            tooltipTitle="Approved"
            onClick={() => {
              setOpenApprovedModal(true);
              setSelectedApprovedRow(row);
            }}
            label={
              <>
                <CheckCircleOutlineIcon />
              </>
            }
            className=" rounded h-7 text-customGreen px-0 pr-2  text-xs"
          />
        ) : (
          <div className="text-gray-300 h-7">
            <CheckCircleOutlineIcon />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      console.log("First load, calling populateTable");
      populateTable(false);
    } else {
      console.log("Dependencies changed, calling populateTable");
      populateTable(false);
    }
  }, [
    fromDatevalue,
    toDateValue,
    patientTypeValue,
    statusValue,
    serachRequestValue,
  ]);

  return (
    <>
      <Modal open={open}>
        <Box
          sx={ModalStyleFeedback}
          className="w-[98%] 2xl:w-[80%] h-[60%] overflow-y-auto"
        >
          <h1 className="text-center font-semibold text-customBlue text-xl">
            Demographic Corrections
          </h1>
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />
          <div>
            <div className="flex w-full space-x-2 items-center mt-2">
              <div className="flex space-x-2  ">
                <h2 className="pt-[3px] font-semibold">
                  Patient Type &nbsp;:{" "}
                </h2>
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={[
                    { id: "Both", value: "Both", label: "Both" },
                    { id: "OPD", value: "OPD", label: "OPD" },
                    { id: "IPD", value: "IPD", label: "IPD" },
                  ]}
                />
              </div>
              <div className="w-2/12">
                <SearchDropdown
                  control={control}
                  name="searchRequest"
                  placeholder="Request"
                  isClearable={true}
                  searchIcon={true}
                  dataArray={searchRequestList}
                  handleInputChange={handleSearchByPatientName}
                />
              </div>

              <div>
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  inputFormat="dd-MM-yyyy"
                  disableFuture={true}
                />
              </div>
              <div>
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  inputFormat="dd-MM-yyyy"
                  disableFuture={true}
                />
              </div>
              <div className="flex space-x-2  ">
                <h2 className="pt-[3px] font-semibold">Status &nbsp;:</h2>
                <RadioField
                  control={control}
                  name="status"
                  dataArray={[
                    { id: "All", value: "All", label: "All" },
                    { id: "Approved", value: "Approved", label: "Approved" },
                    { id: "Pending", value: "Pending", label: "Pending" },
                  ]}
                />
              </div>
            </div>
            {loadingSpinner && (
              <div className="flex justify-center my-40">
                <LoadingSpinner />
              </div>
            )}
            {loadingSpinner === false && patientRequestList?.length > 0 ? (
              <CommonDynamicTablePaginationNew
                dataResult={patientRequestList}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                setCount={setCount}
                removeHeaders={[
                  "AdmissionId",
                  "FirstName",
                  "LastName",
                  "MRNo",
                  "MiddleName",
                  "ageInDays",
                  "ageInMonth",
                  "ageInYear",
                  "gender",
                  "prefix",
                  "requestId",
                  "isApproved",
                  "opdIpd",
                  "opdIpdId",
                  "isAgeInDays",
                  "isAgeInMonth",
                  "isAgeInYear",
                  "isFirstName",
                  "isGender",
                  "isLastName",
                  "isMiddleName",
                  "isPrefix"
                ]}
                highlightRow={false}
                renderActions={renderActions}
                tableClass={"h-[400px]"}
              />
            ) : (
              <>
                {loadingSpinner === false && (
                  <p className="my-40 text-center font-semibold text-gray-500">
                    No Records Found<span className="animate-pulse">...</span>
                  </p>
                )}
              </>
            )}
          </div>
        </Box>
      </Modal>
      {openApprovedModal && (
        <ApprovedModal
          open={openApprovedModal}
          handleClose={() => setOpenApprovedModal(false)}
          selectedApprovedRow={selectedApprovedRow}
          setSelectedApprovedRow={setSelectedApprovedRow}
          populateTable={populateTable}
          locationValue={locationValue}
          setOpenBackdrop={setOpenBackdrop}
        />
      )}
    </>
  );
};

export default DemographicRequestsModal;

