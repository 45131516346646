import { Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
//internal project functions and componants import
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import SearchBar from "../../../common/components/FormFields/SearchBar";
//LodingSpinner
import { format, isAfter, isValid } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CancelOffIcon,
  CancelOnIcon,
  EtuCsOffIcon,
  EtuCsOnIcon,
  EyeOnIcon,
  OpdTreatRecordOffIcon,
  OpdTreatRecordOnIcon,
  TransferToWardOffIcon,
  TransferToWardOnIcon,
} from "../../../assets/icons/CustomIcons";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../common/components/FormFields/RadioField";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import MLCModalEdit from "../../../common/components/mlcDetails/MLCModalEdit";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import {
  OrderSheetIcon,
  OrderSheetIconOff,
} from "../../../nursingStation/components/clinicalcarechart/assets/OrderSheetIcon";
import OrderSheetModal from "../../../nursingStation/components/clinicalcarechart/ordersheetEmergency/OrderSheetModal";
import {
  emergencyPatientListAutoComplete,
  fetchAllEmergency,
  fetchEmergencyMlcPrint,
  fetchIpdConsentFormPrint,
  fetchPrintOpdChargingSlip,
} from "../../services/emergencyservice/EmergencyServices";
import AdmissionDenialModal from "./AdmissionDenialModal";
import EmergencyModal from "./EmergencyModal";
import TransferToWardModal from "./TransferToWardModal";
import OpdTreatmentRecordModal from "./opdTretmentRecord/OpdTreatmentRecordModal";

//function start
export default function Emergency(props) {
  const { updateComponent } = props;
  const location = useLocation();
  let navigate = useNavigate();

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [userActions, setUserActions] = useState([]);

  const [searchString, setSearchString] = useState("");
  const [searchId, setSearchId] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [privilege, setPrivilege] = React.useState("");
  const [menuId, setMenuId] = React.useState("");

  ///related to table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = React.useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  //
  const [selectedRow, setSelectedRow] = React.useState();
  //Transfer To Ward
  const [openTransferToWardModal, setOpenTransferToWardModal] =
    React.useState(false);

  // admission deianl
  const [openAdmissionDenialModal, setOpenAdmissionDenialModal] =
    React.useState(false);
  const handleOpenAdmissionDenialModal = () =>
    setOpenAdmissionDenialModal(true);
  const handleCloseAdmissionDenialModal = () =>
    setOpenAdmissionDenialModal(false);
  const [emergencyId, setEmergencyId] = React.useState(null);

  const handleOpenTransferToWardModal = () => setOpenTransferToWardModal(true);
  const handleCloseTransferToWardModal = () =>
    setOpenTransferToWardModal(false);

  //edit mlc
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [selectedMlcData, setSelectedMlcData] = React.useState(false);
  //order sheet
  const [openOrderSheetModal, setOpenOrderSheetModal] = React.useState(false);
  const handleOpenOrderSheetModal = () => setOpenOrderSheetModal(true);
  const handleCloseOrderSheetModal = () => setOpenOrderSheetModal(false);

  //Opd Treatment Record
  const [openOpdTreatmentRecordModal, setOpenOpdTreatmentRecordModal] =
    React.useState(false);
  const handleOpenOpdTreatmentRecordModal = () =>
    setOpenOpdTreatmentRecordModal(true);
  const handleCloseOpdTreatmentRecordModal = () =>
    setOpenOpdTreatmentRecordModal(false);

  //prints print
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  const handleOpenPrintModal = (row, index, category) => {
    if (category === "mlc") {
      let id = row.AdmissionId > 0 ? row.AdmissionId : row.EmergencyPatientId;
      let formId = row.AdmissionId > 0 ? 1 : 2;

      fetchEmergencyMlcPrint(id, formId).then((response) => {
        setUrlforPrint(response);
        setOpenPrintModal(true);
      });
    }

    if (category === "chargingSlip") {
      fetchPrintOpdChargingSlip(row.EmergencyPatientId).then((response) => {
        setUrlforPrint(response);
        setOpenPrintModal(true);
      });
    }

    if (category === "consentForm") {
      fetchIpdConsentFormPrint(row.EmergencyPatientId).then((response) => {
        setUrlforPrint(response);
        setOpenPrintModal(true);
      });
    }
  };
  //

  //OPEN Emergency Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // open confirmation for admission denial

  const handleCloseConfirmatoin = () => {
    setConfirmationOpen(false);
  };

  const checkboxVisible = false; // for table checkbox

  const opdIpd = [
    {
      id: 0,
      value: "OPD",
      label: "OPD",
    },
    {
      id: 1,
      value: "IPD",
      label: "IPD",
    },
    {
      id: 2,
      value: "all",
      label: "All",
    },
  ];

  const { control, watch, setValue, register } = useForm({
    mode: "onChange",
    defaultValues: {
      opdIpd: 2,
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  const selectedFromDate = format(
    new Date(watch("fromDate")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );
  const selectedToDate = format(
    new Date(watch("toDate")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );

  ///
  let opdIpdVal = watch("opdIpd");

  //

  //used for fetch apdateed data at midnight
  useEffect(() => {
    if (updateComponent) {
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
      // setSelectedFromDate(new Date())
      // setSelectedToDate(new Date())
    }
  }, [updateComponent]);

  ///
  const onInputChange = (enteredData) => {
    let searchString = enteredData;

    const autocompleteObj = {
      // fromDate:
      //   selectedFromDate !== undefined
      //     ? selectedFromDate !== null &&
      //       isValid(new Date(selectedFromDate)) &&
      //       format(selectedFromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
      //     : new Date(),
      fromDate: selectedFromDate,
      // toDate:
      //   selectedToDate !== undefined
      //     ? selectedToDate !== null &&
      //       isValid(new Date(selectedToDate)) &&
      //       format(selectedToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
      //     : new Date(),
      toDate: selectedToDate,

      opdIpd: Number(opdIpdVal),
      searchString: searchString,
    };

    if (enteredData.length > 0) {
      emergencyPatientListAutoComplete(autocompleteObj)
        .then((response) => {
          setOptions(response.data.result);
        })
        .catch(() => {});
    }
  };
  ///
  useEffect(() => {
    // isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
    //   isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
    populateTable();
  }, [
    selectedFromDate,
    selectedToDate,
    opdIpdVal,
    searchString,
    // updateComponent,
  ]);

  let token = JSON.parse(localStorage.getItem("userInfo"));
  let userId = token?.userId;

  const populateTable = (forPagination) => {
    setShowSpinner(true);
    let defaultParam = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      isCancel: 0,
      size: rowsPerPage,
      opdIpd: Number(opdIpdVal),
      searchId: searchString === "" ? null : searchString,
      searchString: null,
      menuId: location?.state?.menuId,
      userId: userId,
      page: !forPagination ? 0 : page,
    };

    fetchAllEmergency(defaultParam)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (forPagination) {
          //  setDataResult((prevData) => [...prevData, ...res.result]);
          setDataResult((prevData) => {
            const existingIds = new Set(
              prevData.map((item) => item.EmergencyPatientId)
            );

            const newData = res.result.filter(
              (item) => !existingIds.has(item.EmergencyPatientId)
            );

            return [...prevData, ...newData];
          });
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setUserActions(res.actions);
        setCount(res.count);
        setShowSpinner(false);
      })
      .catch(() => {
        setShowSpinner(false);
      });
  };

  ////////table related
  const renderActions = (row, index) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {userActions.map((actions, i) => (
            <>
              {actions.isAction ? (
                <>
                  <div className="flex cursor-pointer">
                    {actions.action === "ETU Case Sheet" ? (
                      <>
                        {row.OPD_IPD === 1 && !row.IsCancel ? (
                          <EtuCsOnIcon
                            title="ETU Case Sheet"
                            onClick={() => {
                              navigate(actions.menuKey, {
                                state: {
                                  row,
                                  isEditInfo: true,
                                  menuId: actions.menuId,
                                },
                              });
                            }}
                          />
                        ) : (
                          <EtuCsOffIcon />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {actions.action === "OPD Treatement Record" ? (
                      <>
                        {row.OPD_IPD === 0 && !row.IsCancel ? (
                          <OpdTreatRecordOnIcon
                            title="OPD Treatement Record"
                            onClick={() => {
                              setMenuId(actions.menuId);
                              setPrivilege(actions.action);
                              handleOpenOpdTreatmentRecordModal(row, index);
                              setSelectedRow(row);
                            }}
                          />
                        ) : (
                          <OpdTreatRecordOffIcon />
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {actions.action === "Transfer To Ward" ? (
                      <>
                        {row.OPD_IPD === 1 &&
                        row.IsTransferToWard !== 1 &&
                        !row.IsCancel ? (
                          <TransferToWardOnIcon
                            title="Transfer To Ward"
                            onClick={() => {
                              if (row.AdmissionId === 0) {
                                warningAlert(" Patient Is Not Admitted");
                              } else {
                                setMenuId(actions.menuId);
                                setPrivilege(actions.action);
                                handleOpenTransferToWardModal(row, index);
                                setEmergencyId(row);
                              }
                            }}
                          />
                        ) : (
                          <TransferToWardOffIcon />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {actions.action === "Cancel" ? (
                      <>
                        {!row.IsCancel ? (
                          <CancelOnIcon
                            title="Denial Admission"
                            onClick={() => {
                              setMenuId(
                                actions.menuId || location?.state?.menuId
                              ); //modified counnter null menuId
                              setPrivilege(actions.action);
                              handleOpenAdmissionDenialModal(row, index);
                              setEmergencyId(row);
                            }}
                          />
                        ) : (
                          <CancelOffIcon />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {actions.action === "Print" && (
                      <>
                        <Tooltip title="Print">
                          <LocalPrintshopIcon
                            className={
                              !row.IsCancel
                                ? `text-customGray`
                                : `text-gray-300`
                            }
                            onClick={() => {
                              !row.IsCancel &&
                                handleOpenPrintModal(
                                  row,
                                  index,
                                  row.OPD_IPD === 0
                                    ? "chargingSlip"
                                    : "consentForm"
                                );
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                    {actions.action === "Order Sheet" && (
                      <>
                        {!row.IsCancel && row.OPD_IPD === 1 ? (
                          <Tooltip title="Order Sheet">
                            <button
                              onClick={() => {
                                handleOpenOrderSheetModal();
                                setPrivilege(actions.action);
                                setSelectedRow(row);
                              }}
                            >
                              <OrderSheetIcon />
                            </button>
                          </Tooltip>
                        ) : (
                          <OrderSheetIconOff />
                        )}
                      </>
                    )}
                    {actions.action === "Edit" && (
                      <>
                        <Tooltip title="Edit Emergency">
                          <DriveFileRenameOutlineIcon
                            className={
                              !row.IsCancel
                                ? `text-customBlue`
                                : `text-gray-300`
                            }
                            onClick={() => {
                              if (!row.IsCancel) {
                                setPrivilege(actions.action);
                                handleOpen();
                                setSelectedRow(row);
                              }
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  };

  const renderInput = (row, index, header) => {
    return (
      <>
        {header === "Medico Legal Case" &&
        row[header] &&
        userActions.some(
          (item) => item.action === "MLCEdit" && item.isAction === true
        ) ? (
          <>
            <div className="flex gap-1">
              <Tooltip title="Edit MLC">
                <DriveFileRenameOutlineIcon
                  // sx={{ color: "#1e3a8a" }}
                  className={
                    !row.IsCancel ? `text-customGray` : `text-gray-300`
                  }
                  onClick={(e) => {
                    handleOpenModal();

                    setSelectedMlcData(row);
                    setPrivilege("MLCEdit");
                  }}
                />
              </Tooltip>

              <Tooltip title="Print MLC">
                <LocalPrintshopIcon
                  className={
                    row.MLCNo !== null ? `text-customGray` : `text-[#d7d7d7]`
                  }
                  onClick={() => {
                    row.MLCNo !== null &&
                      handleOpenPrintModal(row, index, "mlc");
                  }}
                />
              </Tooltip>
            </div>
          </>
        ) : row[header] &&
          userActions.some(
            (item) => item.action === "MLCView" && item.isAction === true
          ) ? (
          <>
            <div className="flex gap-1">
              <EyeOnIcon
                title="View MLC"
                onClick={(e) => {
                  handleOpenModal();
                  setSelectedMlcData(row);
                  setPrivilege("MLCView");
                }}
              />

              <Tooltip title="Print MLC">
                <LocalPrintshopIcon
                  className={
                    row.MLCNo !== null ? `text-customGray` : `text-[#d7d7d7]`
                  }
                  onClick={() => {
                    row.MLCNo !== null &&
                      handleOpenPrintModal(row, index, "mlc");
                  }}
                />
              </Tooltip>
            </div>
          </>
        ) : (
          <div className="ml-2">No</div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="grid items-center pt-10 px-6 mt-8 md:rounded-md">
        <div className="flex justify-center text-xl">
          <h1 className="text-black font-Poppins  whitespace-nowrap">
            Emergency Details
          </h1>
        </div>

        {/*searchable dropdown */}
        <div className="grid gap-2 w-full items-center mt-2">
          <div className="grid grid-cols-3 lg:grid-cols-12 w-full gap-2 items-center">
            <div className="w-full col-span-3 lg:col-span-4">
              <SearchBar
                type="button"
                name="SearchableSelect"
                placeholder="Search by Patient Name / MRNo."
                dataArray={options}
                handleInputChange={onInputChange}
                onChange={(e) => {
                  if (e !== null) {
                    setSearchString(e.id);
                  } else {
                    setSearchString("");
                    setPage(0);
                  }
                }}
                searchIcon={true}
              />
            </div>
            {/* opdIpd */}
            <div className="flex md:justify-start lg:justify-center col-span-2 lg:col-span-3 ">
              <RadioField
                label=""
                name="opdIpd"
                control={control}
                dataArray={opdIpd}
              />
            </div>
            <div className="grid grid-cols-2 col-span-3 gap-3">
              {/* from Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  // value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                  // inputRef={{
                  //   ...register("fromDate", {
                  //     onChange: (e) => {
                  //       if (e.target.value !== null) {
                  //         setSelectedFromDate(e.target.value);
                  //       }
                  //     },
                  //   }),
                  // }}
                />
              </div>

              {/* to Date */}
              <div className="md:col-span-2 lg:col-span-1">
                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  // value={new Date()}
                  disableFuture={true}
                  disablePast={false}
                  inputFormat="dd-MM-yyyy"
                  // inputRef={{
                  //   ...register("toDate", {
                  //     onChange: (e) => {
                  //       if (e.target.value !== null) {
                  //         setSelectedToDate(e.target.value);
                  //       }
                  //     },
                  //   }),
                  // }}
                />
              </div>
            </div>
            <div className="">
              <button
                className="border border-gray-500 h-[2.3rem] rounded-md  w-16 cursor-pointer p-1 bg-white"
                onClick={(e) => {
                  e.preventDefault();
                  setValue("fromDate", new Date());
                  setValue("toDate", new Date());
                  // populateTable();
                }}
              >
                <RefreshIcon />
              </button>
            </div>

            <div className="flex justify-between md:col-span-2 md:justify-end lg:col-span-1 md:gap-3">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <CommonButton
                        label="New"
                        onClick={() => {
                          handleOpen();
                          setPrivilege(obj.action);
                        }}
                        className="bg-customGreen text-white"
                      />
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
          <CommonBackDrop openBackdrop={openBackdrop} />
        </div>

        {showSpinner ? (
          <div className="flex justify-center mt-20">
            <LoadingSpinner />
          </div>
        ) : dataResult?.length > 0 ? (
          <CommonDynamicTablePaginationNew
            dataResult={dataResult}
            highlightRow={false}
            renderActions={renderActions}
            renderInput={renderInput}
            populateTable={populateTable}
            editableColumns={["Medico Legal Case"]}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            count={count}
            tableClass="2xl:h-[650px]"
            removeHeaders={[
              "EmergencyPatientId",
              "IsCancel",
              "OPD_IPD",
              "MLCNo",
              "denialAdmission",
              "IsTransferToWard",
              "AdmissionId",
              "mlcDate",
              "PatientId",
            ]}
          />
        ) : (
          <p className="text-center my-6 ">No Data Found</p>
        )}

        <div className="flex justify-end">
          {open ? (
            <EmergencyModal
              populateTable={populateTable}
              edit={edit}
              setEdit={setEdit}
              open={open}
              setOpen={setOpen}
              handleOpen={handleOpen}
              handleClose={handleClose}
              checkboxVisible={checkboxVisible}
              openBackdrop={openBackdrop}
              setOpenBackdrop={setOpenBackdrop}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              location={location}
              privilege={privilege}
            />
          ) : null}
        </div>

        {/* Print Modal */}
        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={handleOpenPrintModal}
            handleClose={handleClosePrintModal}
            urlforPrint={urlforPrint}
          />
        ) : null}

        {/* Transfer To Ward Modal */}
        {openTransferToWardModal ? (
          <TransferToWardModal
            open={openTransferToWardModal}
            setOpen={setOpenTransferToWardModal}
            handleOpen={handleOpenTransferToWardModal}
            handleClose={handleCloseTransferToWardModal}
            emergencyId={emergencyId}
            populateTable={populateTable}
            menuId={menuId}
            privilege={privilege}
          />
        ) : null}

        {openAdmissionDenialModal ? (
          <AdmissionDenialModal
            open={openAdmissionDenialModal}
            setOpen={setOpenAdmissionDenialModal}
            handleOpen={handleOpenAdmissionDenialModal}
            handleCloseAdmissionDenialModal={handleCloseAdmissionDenialModal}
            emergencyId={emergencyId}
            populateTable={populateTable}
            menuId={menuId}
            privilege={privilege}
          />
        ) : null}

        {/* mlc Modal */}
        {openModal ? (
          <MLCModalEdit
            open={openModal}
            setOpen={setOpenModal}
            handleOpen={handleOpenModal}
            handleClose={handleCloseModal}
            //
            patientId={selectedMlcData.EmergencyPatientId}
            isEmergency={true}
            //
            //patientName={selectedMlcData.PatientName}
            //gender={selectedMlcData.Sex}
            //age={selectedMlcData.AgeYear}
            registered={selectedMlcData.Date}
            //mrNo={selectedMlcData.MRNo}
            admissionId={
              selectedMlcData.AdmissionId !== 0
                ? selectedMlcData.AdmissionId
                : selectedMlcData.EmergencyPatientId
            }
            ipdEmergency={selectedMlcData.AdmissionId !== 0 ? 1 : 2}
            emergencyId={selectedMlcData?.EmergencyPatientId}
            opdIpd={1}
            isEdit={true}
            isFromForm={false}
            populateTable={populateTable}
            selectedMlcData={selectedMlcData}
            MLCNo={selectedMlcData.MLCNo}
            //
            privilege={privilege}
            menuId={location?.state?.menuId}
          />
        ) : null}

        {/* Opd Treatment Record Modal*/}
        {openOpdTreatmentRecordModal ? (
          <OpdTreatmentRecordModal
            open={openOpdTreatmentRecordModal}
            setOpen={setOpenOpdTreatmentRecordModal}
            handleOpen={handleOpenOpdTreatmentRecordModal}
            handleClose={handleCloseOpdTreatmentRecordModal}
            // patientName={selectedRow.PatientName}
            //gender={selectedRow.Sex}
            age={selectedRow.AgeYear}
            registered={selectedMlcData.Date}
            //mrNo={selectedRow.MRNo}
            //admissionId={selectedMlcData.EmergencyPatientId}
            admissionId={
              selectedRow?.AdmissionId !== 0
                ? selectedRow?.AdmissionId
                : selectedRow?.EmergencyPatientId
            }
            isEdit={true}
            populateTable={populateTable}
            selectedMlcData={selectedMlcData}
            MLCNo={selectedMlcData.MLCNo}
            EmergencyPatientId={selectedRow?.EmergencyPatientId}
            menuId={menuId}
            privilege={privilege}
            isMlcPatient={selectedRow["Medico Legal Case"]}
          />
        ) : null}

        {openOrderSheetModal ? (
          <OrderSheetModal
            open={openOrderSheetModal}
            setOpen={setOpenOrderSheetModal}
            handleOpen={handleOpenOrderSheetModal}
            handleClose={handleCloseOrderSheetModal}
            displayInfo={selectedRow}
            userActions={userActions}
            menuId={location?.state?.menuId}
            privilege={privilege}
            isFromEmergency={true}
            patientId={selectedRow?.PatientId || 0}
          />
        ) : null}

        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleCloseConfirmatoin}
          confirmationSubmitFunc={() => {
            handleCloseConfirmatoin();
          }}
          confirmationLabel="Confirmation"
          confirmationMsg="Do you want to Cancel Admission ?"
          confirmationButtonMsg="Save"
        />
      </div>
    </>
  );
}
