import authHeader from "../../authentication/authservices/auth-header";
import apiClient from "../../http-common";

export const getSalesReturnList = (obj) => {
  return apiClient.post(`/counterSale/getSalesList`, obj, {
    headers: authHeader(),
  });
};

export const getSalesItemDetails = (salesId) => {
  return apiClient.get(`/counterSale/salesItemDetails/${salesId}`, {
    headers: authHeader(),
  });
};

export const saveCounterSalesReturn = (postObject) => {
  return apiClient.post(`/counterSale/saleCounterSaleReturn`, postObject, {
    headers: authHeader(),
  });
};

//print counter sales return
export const printCounterSalesReturn = (salesReturnId) => {
  return apiClient.get(
    `/reports/inventory/CounterSaleReturnVoucher/${salesReturnId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

export const getSearchedPatients = (searchObject) => {
  return apiClient.post(`/counterSale/getSalesListSearch`, searchObject, {
    headers: authHeader(),
  });
};

