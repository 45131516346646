import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import HelpValuesModal from "./HelpValuesModal";

export default function ParametersTable(props) {
  const [tableData, setTableData] = React.useState([]);
  const [helpValuesModal, sethelpValuesModal] = useState(false);
  const [helpValuesData, sethelpValuesData] = useState([]);
  const [selectedHelpValue, setSelectedHelpValue] = useState(null);
  const [requiredTableIndex, setRequiredTableIndex] = useState();
  const [parentTableIndex, setParentTableIndex] = useState();
  const [targetValue, setTargetValue] = useState(null);
  const [machineValues, setMachineValues] = useState(false);

  const {
    setPostArray,
    values,
    requiredTableArr,
    setRequiredTableArr,
    machineParameterValues,
    authorizationArr,
    isView,
  } = props;
  const searchDropdownElement = useRef("");

  const formulaColor = "bg-red-400";
  const helpValueColor = "bg-green-400";
  const {
    control,
    watch,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  var val;

  const handleFirstLevelValue = (detail, index, data, indexP, e) => {
    let fieldValue = e.target.value;

    let modifiedObj = {};
    modifiedObj.subTest = detail.subTest;
    modifiedObj.parameterName = detail.parameterName;
    modifiedObj.parameterId = detail.parameterId;
    modifiedObj.normalRange = detail.normalRange;
    modifiedObj.methodName = detail.methodName;
    modifiedObj.reportDetailId = detail.reportDetailId;
    modifiedObj.machineResult = detail.machineResult;
    modifiedObj.reportId = detail.reportId;
    modifiedObj.helpValues = detail.helpValues;
    modifiedObj.defaultValue = detail.defaultValue;
    // modifiedObj.formula = detail.formula;
    modifiedObj.firstLevelValue = fieldValue;
    if (
      fieldValue !== "" &&
      (Number(fieldValue) < detail?.normalRange.split("-")[0] ||
        Number(fieldValue) > detail?.normalRange.split("-")[1])
    ) {
      modifiedObj.testColor = "bg-blue-400";
    } else if (fieldValue === "") {
      modifiedObj.testColor = "bg-none";
    }
    if (detail.helpValues.length > 0) {
      modifiedObj.helpValueColor = "bg-green-400";
    }

    requiredTableArr[indexP].pathParametersDtoList[index] = modifiedObj;
  };

  const handleKeypressHelpValues = (detail, index, data, indexP, e) => {
    if (e.keyCode === 116) {
      e.preventDefault();
      sethelpValuesModal(true);
      sethelpValuesData(
        requiredTableArr[indexP].pathParametersDtoList[index].helpValues
      );
    }
  };

  console.log("machineParameterValues123", requiredTableArr);
  React.useEffect(() => {
    if (machineParameterValues.length > 0) {
      let reportArray = [];
      for (let i = 0; i < machineParameterValues.length; i++) {
        let pathParametersDtoList = [];
        let testName = machineParameterValues[i].testName;
        let orderId = machineParameterValues[i].OrderId;
        let orderDtlsId = machineParameterValues[i].orderDtlsId;
        let testNote = machineParameterValues[i].testNote;
        let footNote = machineParameterValues[i].footNote;
        let reportId = machineParameterValues[i].reportId;
        setMachineValues(machineParameterValues[i].isMachineValue);
        for (let obj of machineParameterValues[i].pathParametersDtoList) {
          if (obj.parameterName !== "") {
            let requiredObj = {};

            if (obj.helpValues?.length > 0) {
              requiredObj.helpValueColor = "bg-green-400";
            }
            requiredObj.helpValues = obj.helpValues;
            requiredObj.subTest = obj.subTest;
            requiredObj.parameterName = obj.parameterName;
            requiredObj.normalRange = obj.normalRange;
            requiredObj.methodName = obj.methodName;
            requiredObj.defaultValue = obj.defaultValue;
            requiredObj.firstLevelValue = obj.firstLevelValue;
            requiredObj.reportDetailId = obj.reportDetailId;
            requiredObj.machineResult = obj.machineResult;
            requiredObj.reportId = obj.reportId;
            requiredObj.formula = obj.formula;
            requiredObj.parameterId = obj.parameterId;
            requiredObj.isDisabled = obj.Formula !== "" ? true : false;
            if (
              (Number(obj.firstLevelValue) &&
                Number(obj.firstLevelValue) < obj?.normalRange.split("-")[0]) ||
              Number(obj.firstLevelValue) > obj?.normalRange.split("-")[1]
            ) {
              requiredObj.testColor = "bg-blue-400";
            } else if (obj.firstLevelValue === "") {
              requiredObj.testColor = "bg-none";
            }

            pathParametersDtoList.push(requiredObj);
          }
        }
        reportArray.push({
          testName,
          orderDtlsId,
          pathParametersDtoList,
          testNote,
          footNote,
          reportId,
        });
      }
      setRequiredTableArr(reportArray);
    }
  }, [machineParameterValues]);

  React.useEffect(() => {
    if (
      props.pathReportDetails !== undefined &&
      props.pathReportDetails?.length > 0
    ) {
      console.log("props.pathReportDetails", props.pathReportDetails);
      let reportArray = [];
      for (let i = 0; i < props.pathReportDetails.length; i++) {
        let pathParametersDtoList = [];
        let testName = props.pathReportDetails[i].testName;
        let orderDtlsId = props.pathReportDetails[i].orderDtlsId;
        let testNote = props.pathReportDetails[i].testNote;
        let footNote = props.pathReportDetails[i].footNote;
        let reportId = props.pathReportDetails[i].reportId;
        setMachineValues(props.pathReportDetails[i].isMachineValue);
        for (let obj of props.pathReportDetails[i].pathParametersDtoList) {
          if (obj.parameterName !== "") {
            let requiredObj = {};

            if (obj.helpValues?.length > 0) {
              requiredObj.helpValueColor = "bg-green-400";
            }
            requiredObj.helpValues = obj.helpValues;
            requiredObj.subTest = obj.subTest;
            requiredObj.parameterName = obj.parameterName;
            requiredObj.normalRange = obj.normalRange;
            requiredObj.methodName = obj.methodName;
            requiredObj.defaultValue = obj.defaultValue;
            for (let a = 0; a < authorizationArr?.length; a++) {
              if (
                authorizationArr[a]?.OrderDtlsId ===
                props.pathReportDetails[i].orderDtlsId
              ) {
                if (authorizationArr[a]?.FirstLevel === true) {
                  requiredObj.firstLevelValue = obj.firstLevelValue;
                } else {
                  requiredObj.firstLevelValue = obj.defaultValue;
                }
              }
            }
            requiredObj.reportDetailId = obj.reportDetailId;
            requiredObj.machineResult = obj.machineResult;
            requiredObj.reportId = obj.reportId;
            requiredObj.formula = obj.formula;
            requiredObj.parameterId = obj.parameterId;
            requiredObj.isDisabled = obj.Formula !== "" ? true : false;
            if (
              (Number(obj.firstLevelValue) &&
                Number(obj.firstLevelValue) < obj?.normalRange.split("-")[0]) ||
              Number(obj.firstLevelValue) > obj?.normalRange.split("-")[1]
            ) {
              requiredObj.testColor = "bg-blue-400";
            } else if (obj.firstLevelValue === "") {
              requiredObj.testColor = "bg-none";
            }

            pathParametersDtoList.push(requiredObj);
          }
        }
        reportArray.push({
          testName,
          orderDtlsId,
          pathParametersDtoList,
          testNote,
          footNote,
          reportId,
        });
      }

      setRequiredTableArr(reportArray);
    }
  }, [props.pathReportDetails]);

  useEffect(() => {
    if (selectedHelpValue?.label) {
      let temp = requiredTableArr;
      temp[parentTableIndex].pathParametersDtoList[
        requiredTableIndex
      ].firstLevelValue = selectedHelpValue?.label;
      setRequiredTableArr([...temp]);
    }
    setSelectedHelpValue(null);
  }, [selectedHelpValue]);

  useEffect(() => {
    let finalArr = [];
    for (let i = 0; i < requiredTableArr?.length; i++) {
      let pathParametersDtoList = [
        ...requiredTableArr[i].pathParametersDtoList,
      ];

      let testName = requiredTableArr[i].testName;
      let orderDtlsId = requiredTableArr[i].orderDtlsId;
      let testNote = requiredTableArr[i].testNote;
      let footNote = requiredTableArr[i].footNote;
      let reportId = requiredTableArr[i].reportId;
      // let pathParametersDtoList = [...pathParametersDtoList];
      for (let ia = 0; ia < pathParametersDtoList.length; ia++) {
        if (
          pathParametersDtoList[ia].formula !== null &&
          pathParametersDtoList[ia].formula !== undefined &&
          pathParametersDtoList[ia].formula !== "" &&
          pathParametersDtoList[ia].firstLevelValue !== undefined
        ) {
          let formulaSplit = pathParametersDtoList[ia].formula
            .split(" ")
            .join(" ")
            .split(/([-,/,*,+,(,)])/)
            .map(function (value) {
              return value.trim();
            });

          let newFormulaSplit = formulaSplit.filter(Boolean);
          newFormulaSplit.forEach((element, index) => {
            let splitElement = element.replace("(", "").replace(")", "");

            let tempIndex = pathParametersDtoList.findIndex(
              (tempObj) => tempObj.parameterName === splitElement
            );

            if (tempIndex !== -1) {
              newFormulaSplit[index] =
                pathParametersDtoList[tempIndex].firstLevelValue !== null &&
                pathParametersDtoList[tempIndex].firstLevelValue !== ""
                  ? parseFloat(pathParametersDtoList[tempIndex].firstLevelValue)
                  : null;
            }
          });

          function otherThanNull(arr) {
            return arr.some(
              (el) => el === undefined || el === "" || el === null
            );
          }
          let a = otherThanNull(newFormulaSplit);

          if (!a) {
            let stringFormula = newFormulaSplit
              .toString()
              .replaceAll(",", "")
              .toString();
            // let newAnswer = stringFormula.toString();
            if (stringFormula !== null && stringFormula !== undefined) {
              console.log("stringFormula123", stringFormula);
              if (stringFormula?.includes("^")) {
                let splitPower = stringFormula.split("^");
                let base = eval(splitPower[0]);
                let exponent = splitPower[1];

                let result = Math.pow(base, exponent);
                pathParametersDtoList[ia].firstLevelValue = result.toFixed(2);

                console.log("Result:", result, base, exponent);
              } else {
                pathParametersDtoList[ia].firstLevelValue =
                  eval(stringFormula).toFixed(2);
              }
            }

            // pathParametersDtoList.push(formulaArr);
          } else if (a) {
            pathParametersDtoList[ia].firstLevelValue = "";
          }
        }
      }
      finalArr.push({
        testName,
        orderDtlsId,
        pathParametersDtoList,
        testNote,
        footNote,
        reportId,
      });
    }
    setRequiredTableArr(finalArr);
  }, [targetValue]);

  useEffect(() => {
    setPostArray(requiredTableArr);
  }, [requiredTableArr]);

  function findNextTabStop(el) {
    var universe = document.querySelectorAll(
      "input, button, select, textarea, a[href]"
    );
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    var index = list.indexOf(el);
    return list[index + 1].focus() || list[0];
  }

  function findPreviousTabStop(el) {
    var universe = document.querySelectorAll(
      "input, button, select, textarea, a[href]"
    );
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    var index = list.indexOf(el);
    return list[index - 1].focus() || list[0];
  }

  const focusUpDown = (e) => {
    if (e.code === "ArrowDown") {
      findNextTabStop(document.activeElement);
      setSelectedHelpValue(null);
    } else if (e.code === "ArrowUp") {
      findPreviousTabStop(document.activeElement);
      setSelectedHelpValue(null);
    }
  };

  const firstMethod = (e) => {
    const re = /[]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  console.log("macineValues123", machineValues, isView);

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 1 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgrorequiredTableArrundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded min-h-40 max-h-[500px]"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",
                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell className="whitespace-nowrap">
                      Test Name
                    </TableCell>
                    <TableCell className="whitespace-nowrap">Subtest</TableCell>
                    <TableCell className="whitespace-nowrap">
                      Parameter Name
                    </TableCell>
                    {machineValues === true && isView !== true && (
                      <TableCell className="whitespace-nowrap">
                        Machine Result
                      </TableCell>
                    )}

                    <TableCell className="whitespace-nowrap">
                      Report Value
                    </TableCell>
                    {/* <TableCell className="whitespace-nowrap">
                      Help Values
                    </TableCell> */}
                    <TableCell className="whitespace-nowrap">
                      Normal Range
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      Method Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                {requiredTableArr?.map((data, indexP) => {
                  return (
                    <TableBody>
                      <React.Fragment>
                        <TableRow>
                          <TableCell
                            rowSpan={100}
                            className="whitespace-nowrap border-r-2"
                          >
                            {data.testName}
                          </TableCell>
                        </TableRow>
                        {data.pathParametersDtoList?.map((detail, index) => {
                          return (
                            <TableRow>
                              <TableCell className="whitespace-nowrap border-r-2">
                                {detail.subTest}
                              </TableCell>

                              <TableCell className="whitespace-nowrap border-r-2">
                                {detail.parameterName}
                              </TableCell>
                              {machineValues === true && isView !== true && (
                                <TableCell className="whitespace-nowrap border-r-2">
                                  {detail.machineResult}
                                </TableCell>
                              )}

                              <TableCell className="whitespace-nowrap border-r-2">
                                {/* {detail.formula ? (
                                  <fieldset>
                                    <input
                                      className={
                                        detail.formula ? formulaColor : ""
                                      }
                                      type="text"
                                      autoComplete="off"
                                      name={`data${indexP}firstLevelValue${index}`}
                                      style={{
                                        border: "1px solid grey",
                                        borderRadius: "5px",
                                        paddingLeft: "5px",
                                        width: "100%",
                                      }}
                                      value={
                                        detail?.firstLevelValue
                                          ? detail?.firstLevelValue
                                          : ""
                                      }
                                      {...register(
                                        `data${indexP}firstLevelValue${index}`
                                      )}
                                      onKeyDown={(e) => {
                                        firstMethod(e);
                                      }}
                                    />
                                  </fieldset>
                                ) : ( */}
                                <fieldset>
                                  <input
                                    className={
                                      detail.formula
                                        ? formulaColor
                                        : detail.helpValueColor ||
                                          detail.testColor
                                    }
                                    type="text"
                                    autoComplete="off"
                                    name={`data${indexP}firstLevelValue${index}`}
                                    style={{
                                      border: "1px solid grey",
                                      borderRadius: "5px",
                                      paddingLeft: "5px",
                                      width: "100%",
                                    }}
                                    value={detail?.firstLevelValue}
                                    {...register(
                                      `data${indexP}firstLevelValue${index}`
                                    )}
                                    onChange={(e) => {
                                      handleFirstLevelValue(
                                        detail,
                                        index,
                                        data,
                                        indexP,
                                        e
                                      );
                                      setRequiredTableIndex(index);
                                      setParentTableIndex(indexP);
                                      setTargetValue(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                      }
                                      if (detail.formula) {
                                        firstMethod(e);
                                      }
                                      handleKeypressHelpValues(
                                        detail,
                                        index,
                                        data,
                                        indexP,
                                        e
                                      );
                                      setRequiredTableIndex(index);
                                      setParentTableIndex(indexP);
                                      focusUpDown(e);
                                    }}
                                  />
                                </fieldset>
                                {/* )} */}
                              </TableCell>
                              <TableCell className="whitespace-nowrap border-r-2">
                                {detail.normalRange}
                              </TableCell>
                              <TableCell className="whitespace-nowrap">
                                {detail.methodName}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </React.Fragment>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
      <HelpValuesModal
        helpValuesModal={helpValuesModal}
        sethelpValuesModal={sethelpValuesModal}
        helpValuesData={helpValuesData}
        sethelpValuesData={sethelpValuesData}
        setSelectedHelpValue={setSelectedHelpValue}
        selectedHelpValue={selectedHelpValue}
        findNextTabStop={findNextTabStop}
      />
    </>
  );
}
