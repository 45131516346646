import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function BatchSelectionTable(props) {
  const {
    setBatchSelctedRowData,
    handleClose,
    previousBatchRowData,
    setBatchData,
    setIndex,
  } = props;
  var token = JSON.parse(localStorage.getItem("userInfo"));

  const [selectedID, setSelectedID] = React.useState(0);

  // State for sorting
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  var arrLength = props.data.length;

  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  const allHeaders = Object.keys(props.data[0]);
  const headers = removeHeaders(allHeaders, [
    "id",
    token.cashCounter === "Central Pharmacy" ? "" : "UnitPurchaseRate",
    "isBatchExpired",
  ]);

  // Key press hooks
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");

  // useEffect(() => {
  //   // If previousBatchRowData is available, move to the next row (if any)
  //   if (previousBatchRowData?.current !== null) {
  //     const nextIndex = selectedID + 1 < arrLength ? selectedID + 1 : 0;
  //     setSelectedID(nextIndex);
  //   }
  // }, [previousBatchRowData?.current]);

  useEffect(() => {
    if (arrowUpPressed) {
      if (selectedID <= 0) {
        setSelectedID(arrLength - 1);
      } else {
        setSelectedID(selectedID - 1);
      }
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      if (selectedID >= arrLength - 1) {
        setSelectedID(0);
      } else {
        setSelectedID(selectedID + 1);
      }
    }
  }, [arrowDownPressed]);

  useEffect(() => {
    if (!props.data[selectedID]?.isBatchExpired && enterPressed === true) {
      setBatchSelctedRowData(props.data[selectedID]);
      handleClose();
      setBatchData([]);
      setSelectedID(null);
    }
    if (props.data[selectedID]?.isBatchExpired === true) {
      warningAlert("Batch Expired!");
    }
  }, [enterPressed, selectedID]);

  console.log("previousBatchRowData", previousBatchRowData);

  return (
    <div className="grid w-auto">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 1 }}>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded lg:h-52 md:h-72"
          >
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              sx={{
                border: 1,
                borderColor: "#e0e0e0",
                paddingY: "scroll",
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  {headers.map((header, index) => (
                    <TableCell
                      sortDirection={orderBy === header ? order : false}
                      className="whitespace-nowrap"
                    >
                      <TableSortLabel
                        active={false}
                        direction={orderBy === header ? order : "asc"}
                        onClick={createSortHandler(header)}
                        key={index}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                        {orderBy === header ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data &&
                  props.data.map((row, index) => {
                    const isRowSelected = selectedID === index;
                    const isRowExpired = row?.isBatchExpired;

                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            !isRowExpired && isRowSelected
                              ? "#FFC44B"
                              : isRowExpired
                              ? "#e88ba2"
                              : "",
                        }}
                        sx={{
                          "& td": {
                            paddingY: 0.2,
                          },
                        }}
                        onClick={() => {
                          setSelectedID(index);
                        }}
                      >
                        {headers &&
                          headers.map((header, i) => (
                            <TableCell className="whitespace-nowrap" key={i}>
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}
