import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import BatchSelectionModal from "./BatchSelectionModal";

///key press action
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default function ItemDetailsTable(props) {
  const { batchInputFocus, setItemDetails, itemDetails } = props;

  const [selectedItemDtlsID, setSelectedItemDtlsID] = React.useState(0);
  const [selctedRowData, setSelctedRowData] = React.useState();
  //batch table data
  const [batchData, setBatchData] = useState(null);

  // Entered row id before open batch selection modal....i.e. for update batch number of only entered row data
  const [enteredId, setEnteredId] = React.useState(null);
  //for error input

  const [qtyChange, setQtyChange] = useState(0);
  // selected row data of batch table inside batch selection modal
  const [batchSelctedRowData, setBatchSelctedRowData] = React.useState(null);

  //
  //open batch selection modal
  const [openBatchModal, setOpenBatchModal] = React.useState(false);
  const handleOpenBatchModal = () => setOpenBatchModal(true);
  const handleCloseBatchModal = () => setOpenBatchModal(false);

  ///keypress fuctionality code

  const enterPressed = useKeyPress("Enter");

  // enter
  React.useEffect(() => {
    if (enterPressed === true) {
      setEnteredId(selectedItemDtlsID);
      // handleOpenBatchModal();
    }
  }, [enterPressed, selectedItemDtlsID]);

  useEffect(() => {
    // case:3 total gst amount
    let gst = 0;
    let totalgst = 0;
    for (var i = 0; i < props.data.length; i++) {
      gst = props.data[i].GstAmount !== undefined ? props.data[i].GstAmount : 0;
      totalgst += Number(gst);
      console.log(" props.data", props.data);
    }
    props.setTotalGst(totalgst.toFixed(2));

    ///case:4 total return amount && net return amt

    let returnAmt = 0;
    let totalReturnAmt = 0;
    for (var i = 0; i < props.data.length; i++) {
      returnAmt =
        props.data[i].returnPrice !== undefined ? props.data[i].returnPrice : 0;
      totalReturnAmt += Number(returnAmt);
    }
    props.setTotalReturnAmt(totalReturnAmt.toFixed(2));
  }, [props.data, qtyChange]);

  // quantity onchange
  const handleTableQuantityChange = (e, index) => {
    let temp = [...itemDetails];
    //add return qty label in arr
    temp[index].Quantity = Number(e.target.value);
    //
    if (e.target.value !== "") {
      setQtyChange(parseInt(e.target.value));
    } else if (e.target.value == "") {
      setQtyChange(parseInt(0));
    }

    //  calculations
    let onChangeQty = parseInt(e.target.value !== "" ? e.target.value : 0);
    if (onChangeQty !== undefined && onChangeQty !== null) {
      //
      //case:1  calculate return price

      temp[index].returnPrice = (
        onChangeQty * Number(temp[index].UnitMRP)
      ).toFixed(2);

      //case:2  calculate Gst Amount
      let cost = onChangeQty * Number(temp[index].UnitMRP);
      let gstPerc = Number(temp[index].VATPercentage);

      let totalGST =
        ((cost * 100) / (Number(gstPerc) + 100)) * (Number(gstPerc) / 100);

      console.log("totalGST", cost, gstPerc, totalGST);

      temp[index].GstAmount = totalGST?.toFixed(2);

      //case:3 Set error to input if enterred return qty greter than available retuern qty
      if (temp[index].QtyAvlForReturn < onChangeQty) {
        temp[index].error = true;
        props.setErrorToTblInput(true);
      } else if (temp[index].QtyAvlForReturn >= onChangeQty) {
        temp[index].error = false;
        props.setErrorToTblInput(false);
      }
    }

    setItemDetails(temp);
  };

  //
  const deleteRow = (index) => {
    let newTemplateData = [...props.data];
    newTemplateData.splice(index, 1);
    props.setItemDetails(newTemplateData);

    handleTableQuantityChange();
  };
  //update batch number
  useEffect(() => {
    if (
      props.data[enteredId] &&
      batchData?.length > 0 &&
      batchSelctedRowData !== null
    ) {
      props.data[enteredId].BatchNumber = batchSelctedRowData.BatchCode;
      props.data[enteredId].BatchQty = batchSelctedRowData.BalQty;
      props.data[enteredId].UnitMRP = batchSelctedRowData.UnitMRP;
    }
  }, [batchSelctedRowData, enteredId, batchData]);

  useEffect(() => {
    if (props.data?.length > 0) {
      let nextField = document.getElementById(`Quantity${0}`);
      if (nextField) {
        nextField.focus();
      }
    }
  }, []);

  return (
    <>
      <div className="grid w-auto ">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#ebebeb",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#7393b3",
                  borderRadius: 4,
                },
              }}
              className="rounded h-44 xl:h-96"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.2,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Name
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Batch Number
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Return Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Available Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Issue Quantity
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Unit MRP
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Rate
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Return Price
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Expiry Date
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Gst %
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Gst Amount
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Net Payable Amount
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Total For Vat
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        ChargeId
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data &&
                    props.data.map((items, index) => {
                      // items.inputRef = null;
                      return (
                        <TableRow
                          ref={batchInputFocus}
                          key={index}
                          hover={false}
                          sx={{
                            "& td": {
                              paddingY: 0.2,
                            },
                          }}
                        >
                          <TableCell className="whitespace-nowrap">
                            {items.ItemCode}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.ItemName}
                          </TableCell>

                          {/* //Batch no// */}
                          <TableCell className="whitespace-nowrap">
                            {items.BatchCode}
                          </TableCell>

                          {/* //qty// */}
                          <TableCell className="whitespace-nowrap">
                            <span>
                              <input
                                name={`Quantity${[index]}`}
                                id={`Quantity${[index]}`}
                                onKeyDown={(e) => {
                                  if (
                                    e?.key === "-" ||
                                    e?.key === "+" ||
                                    e?.code === "ArrowUp" ||
                                    e?.code === "NumpadDecimal"
                                  ) {
                                    e.preventDefault();
                                  }
                                  if (e.key === "Enter") {
                                    let nextField = document.getElementById(
                                      `Quantity${index + 1}`
                                    );
                                    if (nextField) {
                                      nextField.focus();
                                    }
                                  }
                                }}
                                disabled={
                                  items.QtyAvlForReturn === 0 ? true : false
                                }
                                className={
                                  items.QtyAvlForReturn === 0
                                    ? "bg-slate-300 border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                    : items.error !== true
                                    ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                                    : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                                }
                                style={{
                                  border:
                                    items.BatchNumber !== "" &&
                                    items.Quantity > items.BatchQty
                                      ? "1px solid #ef4444"
                                      : "",
                                }}
                                onChange={(e) =>
                                  handleTableQuantityChange(e, index)
                                }
                                value={items.Quantity}
                                type="number"
                                min="0"
                                onWheel={(event) => event.currentTarget.blur()}
                              />
                            </span>
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.QtyAvlForReturn}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.IssueQty}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.UnitMRP}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.MRP}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.returnPrice}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.BatchExpiryDate}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.VATPercentage}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.GstAmount}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.returnPrice}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.Code}
                          </TableCell>

                          <TableCell className="whitespace-nowrap">
                            {items.chargeid}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>

      {/* Batch Modal */}
      {openBatchModal ? (
        <BatchSelectionModal
          open={openBatchModal}
          setOpen={setOpenBatchModal}
          handleOpen={handleOpenBatchModal}
          handleClose={handleCloseBatchModal}
          selctedRowData={selctedRowData}
          setSelctedRowData={setSelctedRowData}
          //
          batchSelctedRowData={batchSelctedRowData}
          setBatchSelctedRowData={setBatchSelctedRowData}
          batchData={batchData}
          setBatchData={setBatchData}

          //

          //
        />
      ) : null}
    </>
  );
}
