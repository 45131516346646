import React from "react";
import { useForm } from "react-hook-form";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import { ForceCloseIcon } from "../../../assets/CommonAssets";

// import { prescriptionPreview } from "../../services/EMRServices/emrServices";
export const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 1,
  boxShadow: 20,
  p: 4,
};

function PrintOptions({ open, close, printObject }) {
  const [modifiedPrintObject, setModifiedPrintObject] = React.useState(null);

  const [printList, setPrintList] = React.useState([
    "vitals",
    "complaints",
    "prescription",
    "diagnosis",
    "investigations",
    "instructions",
  ]);
  const [urlForPrint, setUrlForPrint] = React.useState("");

  const defaultValues = {
    vitals: true,
    complaints: false,
    prescription: true,
    diagnosis: false,
    investigation: true,
    instruction: false,
  };

  const { watch, setValue, control } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const handleCheckboxes = (e, value) => {
    if (e.target.checked === true) {
      let dataArray = [...printList];
      dataArray.push(value);
      setPrintList(dataArray);
    } else if (e.target.checked === false) {
      let dataArray = [...printList];
      let filtered = dataArray.filter((item) => item !== value);
      setPrintList(filtered);
    }
  };

  React.useEffect(() => {
    let printObj = structuredClone(printObject);
    let dataArray = [...printList];

    if (!dataArray?.includes("complaints")) {
      printObj.patientEmr.complaints = [];
    }

    if (!dataArray?.includes("prescription")) {
      printObj.medicineDetails = [];
    }
    
    if (!dataArray?.includes("diagnosis")) {
      printObj.vitals.diagnosisList = [];
    }

    if (!dataArray?.includes("investigations")) {
      printObj.pathologyList = [];
    }

    if (!dataArray?.includes("instructions")) {
      printObj.instructionList = [];
    }

    setModifiedPrintObject(printObj);
  }, [printObject, printList]);

  React.useEffect(() => {
    // if (modifiedPrintObject !== null) {
    //   let printObj = structuredClone(modifiedPrintObject);

    //   printObj.pagesList = [...printList];
    //   prescriptionPreview(printObj)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         const blb = new Blob([response.data], { type: "text/plain" });
    //         const reader = new FileReader();
    //         reader.readAsText(blb);
    //         reader.addEventListener("loadend", (e) => {
    //           if (e.target.result.charAt(0) !== "{") {
    //             const file = new Blob([response.data], {
    //               type: "application/pdf",
    //             });
    //             const fileURL = URL.createObjectURL(file);

    //             setUrlForPrint(fileURL);
    //           }
    //         });
    //       }
    //     })
    //     .catch((err) => err);
    // }
  }, [modifiedPrintObject]);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyles}>
          <div className="flex justify-between">
            <div className=" ">
              <div className="flex justify-center font-bold text-xl my-2 px-3">
                Print Preview
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => {
                  close();
                }}
                type="button"
              >
                <ForceCloseIcon />
              </button>
            </div>
          </div>

          <div className="flex px-5 gap-5 items-center">
            <fieldset
              onChange={(e) => {
                handleCheckboxes(e, "vitals");
              }}
            >
              <CheckBoxField name={"vitals"} control={control} label="Vitals" />
            </fieldset>
            <fieldset
              onChange={(e) => {
                handleCheckboxes(e, "complaints");
              }}
            >
              <CheckBoxField
                name={"complaints"}
                control={control}
                label="Complaints"
              />
            </fieldset>
            <fieldset
              onChange={(e) => {
                handleCheckboxes(e, "prescription");
              }}
            >
              <CheckBoxField
                name={"prescription"}
                control={control}
                label="Prescription"
              />
            </fieldset>
            <fieldset
              onChange={(e) => {
                handleCheckboxes(e, "diagnosis");
              }}
            >
              <CheckBoxField
                name={"diagnosis"}
                control={control}
                label="Diagnosis"
              />
            </fieldset>
            <fieldset
              onChange={(e) => {
                handleCheckboxes(e, "investigations");
              }}
            >
              <CheckBoxField
                name={"investigation"}
                control={control}
                label="Investigation"
              />
            </fieldset>
            <fieldset
              onChange={(e) => {
                handleCheckboxes(e, "instructions");
              }}
            >
              <CheckBoxField
                name={"instruction"}
                control={control}
                label="Instruction"
              />
            </fieldset>
          </div>
          <hr className="mt-2 border border-customBlue px-2" />
          <embed
            className="mt-3"
            width="100%"
            height="100%"
            src={urlForPrint}
            target="_blank"
          />
        </Box>
      </Modal>
    </div>
  );
}

export default PrintOptions;
