import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const ipdPatientInfoAutoComplete = (patientInfo, currentAdmitted) => {
  console.log("ipdPatientInfoAutoComplete", patientInfo);
  return apiClient.get(
    `/ipdCharges/patientInfo/${patientInfo}/${currentAdmitted}`,
    {
      headers: authHeader(),
    }
  );
};
export const ipdPatientInfoForBilling = (
  admissionId,
  category,
  consolidateFlag,
  patientId
) => {
  return apiClient.get(
    `/ipdCharges/patientInfoBillCharges?admissionId=${admissionId}&category=${category}&consolidate=${consolidateFlag}&patientId=${patientId}`,
    {
      headers: authHeader(),
    }
  );
};
export const postIPDBill = (postObj) => {
  console.log("poset resutl of post api", postObj);
  return apiClient.post(`/ipdBilling/saveIpdBill`, postObj, {
    headers: authHeader(),
  });
};
// /api/ipdBilling/chargesConversion/{chargeId}/{sponsorType}
export const moveServiceCompanyToPatient = (postobject) => {
  console.log("poset resutl of post api");
  return apiClient.post(`/ipdBilling/chargesConversion`, postobject, {
    headers: authHeader(),
  });
};

// draft bill service
// /api/ipdBilling/saveDraftBill/{admissionId}/{tariffId}
export const saveDraftBillService = (admissionId, tariffId) => {
  console.log(
    "saveDraftBillServicesaveDraftBillService",
    admissionId,
    tariffId
  );
  return apiClient.get(`/ipdBilling/saveDraftBill/${admissionId}/${tariffId}`, {
    headers: authHeader(),
  });
};

// updated ipd billing service based on Category i.e Patient,Company and Package
// /api/ipdBilling/previousChargeList
export const fetchAllIPDBillingChargeList = (postObj) => {
  console.log("fetchAllIPDBillingChargeList", postObj);
  return apiClient.post(`/ipdBilling/previousChargeList`, postObj, {
    headers: authHeader(),
  });
};

// ipd previous bill list
// /ipdBilling/previousBillList/2006047
export const fetchPerviousBillsList = (patientId) => {
  console.log("fetchAllIPDBillingChargeList", patientId);
  return apiClient.get(`/ipdBilling/previousBillList/${patientId}`, {
    headers: authHeader(),
  });
};

// print draft bill
export const ipdDraftBillPdfPrint = (
  admissionId,
  billId,
  isFinal,
  consolidatedFlagVal,
  noAdmin
) => {
  return apiClient.get(
    `/reports/bills/getIPdBillPdf?admissionId=${admissionId}&billId=${billId}&isFinal=${isFinal}&isConsolidated=${consolidatedFlagVal}&noAdmin=${noAdmin}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};

// print final bill
export const ipFinalBillPdfPrint = (
  admissionId,
  billId,
  consolidatedFlag,
  isFinal,
  noAdmin
) => {
  return apiClient.get(
    `/reports/bills/getIPdBillPdf?admissionId=${admissionId}&billId=${billId}&isConsolidated=${consolidatedFlag}&isFinal=${isFinal}&noAdmin=${noAdmin}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};

