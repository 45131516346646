import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Snackbar, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, isAfter } from "date-fns";
import PropTypes from "prop-types";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import {
  OfficePermissionIcon,
  SymptomsIcon,
} from "../../../assets/CommonAssets";
import {
  BloodIssueNoteIcon,
  BloodRequisitionIcon,
  CarePlanIcon,
  ComplaintStatusOnIcon,
  ConsentIcon,
  DischargeI,
  DischargeSummaryIcon,
  DivaToolIcon,
  DrugAdmChartIcon,
  DrugOrderIcon,
  EditOnIcon,
  InitiateDischargeIcon,
  NarcoticDrugIcon,
  PatientTransferIcon,
  PendingAntibioticIcon,
  PushToolIcon,
  VentilatorBundleIcon,
} from "../../../assets/icons/CustomIcons";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { removeLocationData } from "../../../common/components/RemoveLocationdata";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import InitiatDischargeMessage from "../../../ipd/components/admission/admissionlisting/InitiatDischargeModal";
import ReceivePatientModal from "../../../ipd/components/admission/admissionlisting/ReceivePatientModal";
import TentativeDischargeModal from "../../../ipd/components/dischargeSummary/dischargeSummaryTabwise/TentativeDischargeModal";
import { fetchPrintPatientList } from "../../../ipd/services/admissionServices/AdmissionServices";
import NewOfficeModal from "../../../operationTheater/components/officePermissions/NewOfficeModal";
import NursesNotesPrint from "../../prints/NursingNotePrint";
import OxyVentiPrint from "../../prints/OxyVentiPrint";
import PrintPainAsseUseFilter from "../../prints/PrintPainAsseUseFilter";
import SuccioSaturationPrint from "../../prints/SuccioSaturationPrint";
import SugarLevelPrint from "../../prints/SugarLevelPrint";
import VitalsTPRPrint from "../../prints/VitalsTPRPrint";
import {
  fetchOrderSheetPendingTodos,
  fetchPatientSearchCCC,
  fetchPrintClinicalCareChartForm,
  fetchpatientListCCC,
  getFloorList,
  getPatientInfoData,
} from "../../services/cliinicalChartServices/ClinicalCareChartService";
import { IntakeOuttakePrintData } from "../../services/cliinicalChartServices/inputoutputServices/InoutOutputServices";
import { getWard } from "../../services/cliinicalChartServices/painAssesmentService/PainAssesmentService";
import Charges from "./Charges/Charges";
import HumanBody from "./HumanBody/HumanBody";
import QSofaCalcModal from "./QsofaCalcModal";
import SugarLevel from "./SugarLevel/SugarLevel";
import ApacheScore from "./apacheScore/ApacheScore";
import InPatientReturnIcon from "./assets/InPatientReturnIcon";
import InpatientIssueIcon from "./assets/InpatientIssueIcon";
import LIMSIcon from "./assets/LIMSIcon";
import { OrderSheetIcon } from "./assets/OrderSheetIcon";
import PatientIndentIcon from "./assets/PatientIndentIcon";
import PrintIcon from "./assets/PrintIcon";
import SampleCollectionIcon from "./assets/SampleCollectionIcon";
import ClinicalCareChartInvestigationModal from "./clinicalFileModals/ClinicalInvestigationModal";
import ClinicalIconModal from "./clinicalFileModals/Cllinicallniconmodal";
import SymptomsModal from "./clinicalFileModals/SymptomsModal";
import InputOutput from "./inputoutput/InputOutput";
import NursingNote from "./nursingnote/NursingNote";
import OrderSheetModal from "./ordersheet/OrderSheetModal";
import OxygenVentilation from "./oxygenventilation/OxygenVentilation";
import PainAssessment from "./painassessment/PainAssessment";
import Pews from "./pews/Pews";
import SuccionSaturation from "./succionSaturation/SuccionSaturation";
import Vitals from "./vitals/Vitals";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const scoreBlocksArr = [
  {
    id: 1,
    label: "QSOFA",
    value: "QSOFA",
    indicatorColour: "bg-[#5CD75E]",
    score: 0,
  },
  {
    id: 2,
    label: "MEWS",
    value: "MEWS",
    indicatorColour: "bg-[#EE8936]",
    score: 0,
  },
  {
    id: 3,
    label: "GCS",
    value: "GCS",
    indicatorColour: "bg-[#FB5959]",
    score: 0,
  },
  {
    id: 4,
    label: "APACHE",
    value: "APACHE",
    indicatorColour: "bg-[#5CD75E]",
    score: 0,
  },
];

export const VisitContext = createContext();

function PatientPainAssessment({ drawerOpen }, props) {
  //scores
  const [scoreBlocks, setScoreBlocks] = React.useState([...scoreBlocksArr]);
  const [GCSData, setGCSData] = React.useState(0);
  const [qsofaScorefinal, setQsofaScorefinal] = React.useState(0);
  const [pendingToDos, setPendingToDos] = React.useState([]);

  console.log("scoreBlocks", scoreBlocks);
  //Receive modal
  const [selectedRow, setSelectedRow] = useState(null);
  const [openInitiateDischargeModal, setOpenInitiateDischargeModal] =
    useState(false);
  const [openQSofaCalcModal, setOpenQSofaCalcModal] = useState(false);
  const [scoreType, setScoreType] = useState(null);
  const [openReceivePatientModal, setOpenReceivePatientModal] = useState(false);
  const handleOpenReceivePatientModal = () => setOpenReceivePatientModal(true);
  const handleCloseReceivePatientModal = () =>
    setOpenReceivePatientModal(false);
  //
  // const [fromTime, setFromTime] = useState(new Date());
  // const [toTime, setToTime] = useState(new Date());
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const location = useLocation();

  // state variables
  const [menuId, setMenuId] = useState(null);
  const [privilege, setPrivilege] = useState("");
  const [functionalityId, setFunctionalityId] = useState(null);
  const [userActions, setUserActions] = useState([]);
  const [openClinicalIconModal, setOpenClinicalIconModal] = useState(false);
  const [openNewRquestModal, setOpenNewRequestModal] = useState(false);
  const [openSymptomsModal, setOpenSymptomsModal] = useState(false);
  const [dataResultAssessment, setDataResultAssessment] = useState([]);
  // Weight Table
  const [weightDataResult, setWeightDataResult] = useState([]);

  //Tentative Discharge Modal
  const [openTentativeDischargeModal, setOpenTentativeDischargeModal] =
    useState(false);
  //
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //

  const [dataResult, setDataResult] = useState([]);
  const [patientInformation, setPatientInformation] = useState("");
  const [displayInfo, setDisplayInfo] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  //Display info against ipd admmision patient

  const [admissionId, setAdmissionId] = useState(null);
  const [nursingNoteId, setNursingNoteId] = useState(null);
  const [tariffId, setTariffId] = useState(null);
  const [admissionPatientId, setAdmissionPatientId] = useState(null);
  const [floorList, setFloorList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [searchPatientList, setSearchPatientList] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  console.log("openSnackBar", openSnackBar);
  const [sliderReset, setSliderReset] = useState(0);
  const [selectedAssesmentListRow, setSelectedAssesmentListRow] =
    useState(null);

  //nursing note
  const [noteInfo, setNoteInfo] = useState();

  //oxygen start state variables
  const oxygenIncrement = useRef(null);
  const [oxygenTimer, setOxygenTimer] = useState(0);
  const [oxygenIsActive, setOxygenIsActive] = useState(false);
  const [oxygenIsPaused, setOxygenIsPaused] = useState(false);

  //for oxygen time calculation
  const [oxygenhours, setOxygenHours] = useState(0);
  const [oxygenminutes, setOxygenMinutes] = useState(0);
  const [oxygenseconds, setOxygenSeconds] = useState(0);
  //end

  //oxygen start state variables
  const ventilationIncrement = useRef(null);
  const [ventilationTimer, setVentilationTimer] = useState(0);
  const [ventilationIsActive, setVentilationIsActive] = useState(false);
  const [ventilationIsPaused, setVentilationIsPaused] = useState(false);

  //for ventilation time calculation
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  //end

  // local variable
  const navigate = useNavigate();

  //Calculate yesterday date
  let dateObj = new Date(selectedPatient?.AdmissionDate.split("/").join("-"));
  // Subtract one day from current time
  //dateObj.setDate(dateObj.getDate() - 1);
  console.log("dateObj", dateObj);
  const defaultValues = {
    searchPatient: null,
    wardId: null,
    floor: null,
    fromDate: new Date(),
    toDate: new Date(),
    fromTime: new Date(),
    toTime: new Date(),
    isOldPatient: false,
  };
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  // Thu Dec 07 2023 18:10:24 GMT+0530 (India Standard Time)
  let fromDateVal = watch("fromDate");
  let toDateVal = watch("toDate");
  let fromTime = watch("fromTime");
  let toTime = watch("toTime");
  let ward = watch("wardId");
  let floor = watch("floor");
  let searchPatient = watch("searchPatient");
  let isOldPatientValue = watch("isOldPatient");

  let newFromDate =
    isAfter(fromDateVal, new Date(1900, 1, 1)) &&
    format(fromDateVal, "yyyy-MM-dd");

  let newToDate =
    isAfter(toDateVal, new Date(1900, 1, 1)) && format(toDateVal, "yyyy-MM-dd");
  let newFromTime;

  if (fromTime !== null) {
    newFromTime =
      isAfter(fromTime, new Date(1900, 1, 1)) &&
      format(fromTime, "HH:mm:ss.000");
  }
  let newToTime;
  if (toTime !== null) {
    newToTime =
      isAfter(toTime, new Date(1900, 1, 1)) && format(toTime, "HH:mm:ss.000");
  }

  //Print
  const [openPtPrintModal, setOpenPtPrintModal] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 7000);

    return (
      <div className="hidden">
        {tabValue === 0 && (
          <PrintPainAsseUseFilter
            admissionId={admissionId || patientInformation?.AdmissionId}
            fromDate={newFromDate}
            toDate={newToDate}
            fromTime={newFromTime || null}
            toTime={newToTime || null}
          />
        )}
        {tabValue === 1 && (
          <VitalsTPRPrint
            admissionId={admissionId || patientInformation?.AdmissionId}
            fromDate={newFromDate}
            toDate={newToDate}
            fromTime={newFromTime || null}
            toTime={newToTime || null}
          />
        )}
        {tabValue === 2 && (
          <SugarLevelPrint
            admissionId={admissionId || patientInformation?.AdmissionId}
            fromDate={newFromDate}
            toDate={newToDate}
            fromTime={newFromTime || null}
            toTime={newToTime || null}
            // result={resultSuger}
            // patientInfo={patientSugerInfo}
            // patientInfoDate={patientInfoSugerDate}
          />
        )}
        {tabValue === 3 && (
          <OxyVentiPrint
            admissionId={admissionId || patientInformation?.AdmissionId}
            fromDate={newFromDate}
            toDate={newToDate}
            fromTime={newFromTime || null}
            toTime={newToTime || null}
          />
        )}
        {tabValue === 4 && (
          <SuccioSaturationPrint
            admissionId={admissionId || patientInformation?.AdmissionId}
            fromDate={newFromDate}
            toDate={newToDate}
            fromTime={newFromTime || null}
            toTime={newToTime || null}
          />
        )}
        {/*tabValue === 5 && (
          <InputOutputPrint
            admissionId={admissionId || patientInformation?.AdmissionId}
            fromDate={newFromDate}
            toDate={newToDate}
            fromTime={newFromTime || null}
            toTime={newToTime || null}
          />
        )*/}
        {tabValue === 7 && (
          <NursesNotesPrint
            admissionId={admissionId || patientInformation?.AdmissionId}
            fromDate={newFromDate}
            toDate={newToDate}
            fromTime={newFromTime || null}
            toTime={newToTime || null}
            ID={nursingNoteId}
          />
        )}
      </div>
    );
  };

  // Course Creation Preview

  //
  const [isOpenFilter, setIsOpenFilter] = useState(true);

  //LIMS
  const [openLabModal, setOpenLabModal] = useState(false);
  const handleOpenLabModal = () => {
    setOpenLabModal(true);
  };
  const handleCloseLabModal = () => {
    setOpenLabModal(false);
  };

  const [openCarePlanModal, setOpenCarePlanModal] = useState(false);

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openClinicalPrintModal, setOpenClincalPrintModal] = useState(false);

  // open or Close button filter
  const toggleDrawerOpen = () => {
    setIsOpenFilter((prevIsOpen) => !prevIsOpen);
  };

  // function filterData() {}
  function handleChangeTabs(event, newValue) {
    if (!!displayInfo || !!admissionId) {
      setTabValue(newValue);
      setSelectedAssesmentListRow(null);
      setSliderReset(0);
    } else {
      warningAlert("Please Select Patient...!");
      setTabValue(0);
    }
  }
  function getRowData(row) {
    setDisplayInfo(row);
  }

  const handlePatientInfo = () => {
    console.log("getPatientInfo");
    if (!!patientInformation?.AdmissionId || !!admissionId) {
      console.log("getPatientInfo1");
      getPatientInfoData(patientInformation?.AdmissionId || admissionId)
        .then((response) => response.data)
        .then((res) => {
          if (res.result !== null) {
            setSelectedPatient(res.result);
            setOpenSnackBar(true);

            setScoreBlocks((prev) => {
              const scores = {
                GCS: res.result?.gcs,
                QSOFA: res.result.qsofa,
                MEWS: res.result.Mews,
                APACHE: res.result.ApacheScore,
              };

              return prev.map((item) =>
                scores[item.label] !== undefined
                  ? { ...item, score: scores[item.label] }
                  : item
              );
            });
          } else {
            setSelectedPatient(null);
            setOpenSnackBar(false);
          }
        });
    }
  };

  const handleClosePrintModal = () => {
    setOpenClincalPrintModal(false);
  };

  const handleOpenPrintModal = (type) => {
    if (type === "InputOutput") {
      const postObj = {
        admissionId: admissionId || patientInformation?.AdmissionId,
        fromDate: newFromDate,
        toDate: newToDate,
        fromTime: newFromTime || null,
        toTime: newToTime || null,
      };
      IntakeOuttakePrintData(postObj).then((response) => {
        setUrlforPrint(response);
        setOpenClincalPrintModal(true);
      });
    } else {
      fetchPrintClinicalCareChartForm(sliderReset).then((response) => {
        setUrlforPrint(response);
        setOpenClincalPrintModal(true);
      });
    }
  };

  const handleClick = (row, index) => {
    setPatientInformation(row);
  };

  const handleSelectedRow = (row, index) => {
    row.AllowToGO && warningAlert("Allow to go is given to this patient.");
    setSelectedRow(row);
    // setValue("fromTime", new Date());
    setValue("toTime", new Date());

    let dateObj = new Date(row?.AdmissionDate.split("+")[0]);

    let formattedDate = format(dateObj, "yyyy-MM-dd", {
      timeZone: "UTC",
    });

    setValue("fromDate", new Date(formattedDate));

    if (
      isOldPatientValue ||
      row?.isrecievebed ||
      userInfo.cashCounter === "Etu"
      //&& !row?.IsNursingClearanceDone
    ) {
      setAdmissionId(null);
      handleClick(row, index);
      getRowData(row, index);
      setNoteInfo();
      !!location.state &&
        removeLocationData(
          location.state.menuId,
          "/nursing/clinicalCareChart",
          navigate
        );
      // Navigate(location.state, { replace: true });
      //oxygen
      clearInterval(props?.oxygenIncrement?.current);
      setOxygenTimer(0);
      setOxygenIsActive(false);
      setOxygenIsPaused(false);
      //ventilation
      clearInterval(props?.ventilationIncrement?.current);
      setVentilationTimer(0);
      setVentilationIsActive(false);
      setVentilationIsPaused(false);

      //timer 00
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      setOxygenHours(0);
      setOxygenMinutes(0);
      setOxygenSeconds(0);
      handlePatientInfo();
    } else {
      clearData();
      // setSelectedPatient(null);
      // setScoreBlocks([...scoreBlocksArr]);
      // setAdmissionId(null);
      // setDisplayInfo(null);
      // setTabValue(0);
      // setDataResultAssessment([]);

      !isOldPatientValue &&
        row?.IsPharmacyClearanceDone &&
        warningAlert("Pharmacy clearance is given to this patient.");
      ///////
      !isOldPatientValue &&
        !row?.isrecievebed &&
        warningAlert("Patient is not received on bed.");

      !isOldPatientValue &&
        !row?.isrecievebed &&
        setOpenReceivePatientModal(true);
      let receiveObj =
        userActions?.length > 0 &&
        userActions?.find(
          (item) => item?.isAction && item.action === "Receive Patient"
        );

      setMenuId(receiveObj?.menuId);
      setPrivilege(receiveObj?.action);
      setFunctionalityId(receiveObj?.functionalityId);
      ////
    }
  };

  let clearData = () => {
    setSelectedPatient(null);
    setScoreBlocks([...scoreBlocksArr]);
    setAdmissionId(null);
    setDisplayInfo(null);
    setTabValue(0);
    setDataResultAssessment([]);
    setWeightDataResult([]);
    setPendingToDos([]);
  };

  //  useEffects
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    getFloorList()
      .then((response) => response.data)
      .then((res) => {
        setFloorList(res.result);
      });
  }, []);

  useEffect(() => {
    if (floor !== null) {
      getWard(floor?.id)
        .then((response) => response.data)
        .then((res) => {
          setWardList(res.result);
        });
    }
  }, [floor]);

  const getPatientListCCC = () => {
    let searchObj = {
      floorId: floor && floor?.id,
      wardId: ward ? ward?.id : 0,
      admissionId: searchPatient !== null ? searchPatient.id : null,
      isOldPatient: isOldPatientValue,
      searchString: "",
    };
    // setDataResult([]);
    fetchpatientListCCC(searchObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setDataResult(res.result);
        clearData();
      });
  };

  useEffect(() => {
    getPatientListCCC();
  }, [floor, ward, searchPatient, isOldPatientValue]);

  useEffect(() => {
    userInfo?.floorId !== null &&
      userInfo?.floor &&
      setValue("floor", {
        id: userInfo?.floorId,
        label: userInfo?.floor,
        value: userInfo?.floor,
      });
  }, []);

  useEffect(() => {
    if (
      location.state &&
      location.state !== null &&
      location?.state?.params?.AdmissionId
    ) {
      setAdmissionId(location.state.params?.AdmissionId);
      setAdmissionPatientId(location.state.params?.PatientId);
      setTariffId(location.state.params?.TariffId);
    }
  }, [admissionId, admissionPatientId, selectedPatient]);

  const handlePenndingToDos = () => {
    fetchOrderSheetPendingTodos(patientInformation?.AdmissionId || admissionId)
      .then((response) => response.data)
      .then((res) => {
        console.log("res", res.result);
        setPendingToDos(res.result);
      });
  };

  useEffect(() => {
    if (patientInformation?.AdmissionId !== undefined || admissionId !== null) {
      handlePatientInfo();
      //
      setSelectedAssesmentListRow(null);
      setSliderReset(0);

      //
      handlePenndingToDos();
    }
  }, [admissionId, patientInformation?.AdmissionId]);

  // "Drug Administrator Chart(Inventory)"

  console.log("information123", displayInfo, selectedPatient);

  const renderInput = (row, index, header) => {
    return (
      <>
        <div
          className={
            row.CompanyName === "Pmc" ||
            row.CompanyName === "Covid (pmc)" ||
            row.CompanyName === "Pmc Staff"
              ? "text-[#dc2626]"
              : row.LockIndent
              ? "text-[#f542e0]"
              : row.AllowToGO
              ? "text-[#059669]"
              : "text-gray-800"
          }
        >
          <Tooltip
            title={
              row.LockIndent
                ? "LockIndent Patient"
                : row.CompanyName === "Pmc" ||
                  row.CompanyName === "Covid (pmc)" ||
                  row.CompanyName === "Pmc Staff"
                ? "PMC Patient"
                : row.AllowToGO
                ? "Allowed To Go"
                : ""
            }
          >
            {row[header]}
          </Tooltip>
        </div>
      </>
    );
  };

  const handleOpenPatientListPrintModal = () => {
    const printObj = {
      date: new Date(),
      floorId: floor?.id,
      currentAdmission: true,
    };
    // print service
    fetchPrintPatientList(printObj).then((response) => {
      if (response) {
        setUrlforPrint(response);
        setOpenPtPrintModal(true);
      }
    });
  };

  useEffect(() => {
    const currentTime = new Date();
    setValue("fromTime", currentTime);
    setValue("toTime", currentTime);
    console.log("currentTime", currentTime);
  }, [openClinicalIconModal, tabValue, open]);

  const handleCloseSnackbar = (event, reason) => {
    console.log("handleCloseSnackbar", event);
    setOpenSnackBar(false);
  };

  console.log("patientInformation", displayInfo, selectedPatient);

  return (
    <>
      <VisitContext.Provider value={patientInformation.AdmissionId}>
        <div className="mt-11 px-2 w-full  ">
          <div className="grid grid-cols-12 gap-2 pb-2">
            <div className=" pb-1 text-lg font-semibold text-black col-span-2 text-center">
              Clinical Care Chart
            </div>

            <div
              className={`col-span-9 shadow-md border w-full flex items-center text-sm space-x-1 justify-between flex-wrap bg-gray-100 overflow-hidden p-2 rounded `}
            >
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Patient Name :</span>
                </h1>
                <h1 className="">
                  {selectedPatient?.PatientName || displayInfo?.PatientName}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold">
                  <span className="">MRNo :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.MRNo || selectedPatient?.MRNo}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Dr. Name :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.DoctorName || selectedPatient?.DoctorName}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Ref Doctor :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.AdmissionReferDoctor ||
                    selectedPatient?.AdmissionReferDoctor}
                </h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <h1 className="font-semibold ">
                  <span className="">Age :</span>
                </h1>
                <h1 className="">
                  {displayInfo?.AgeYear || selectedPatient?.AgeYear}
                </h1>
              </div>
            </div>
          </div>

          <div className="lg:flex lg:space-x-1 w-full  ">
            {isOpenFilter && (
              <form className="md:w-full lg:w-[25%] 2xl:w-[17%] ">
                <div className="md:grid-cols-3 lg:grid-cols-1 grid gap-2 w-full my-2 lg:my-0">
                  <div className=" w-full md:grid-cols-3 grid lg:grid-cols-2 gap-2 col-span-3 lg:col-span-1">
                    <div className="w-full z-50">
                      <DropdownField
                        control={control}
                        name="floor"
                        placeholder="Floor"
                        dataArray={floorList}
                        isClearable={true}
                      />
                    </div>

                    <div className="w-full hidden lg:block z-40">
                      <DropdownField
                        control={control}
                        name="wardId"
                        placeholder="Ward"
                        dataArray={wardList}
                        isClearable={true}
                      />
                    </div>

                    <div className="w-full lg:hidden z-40">
                      <DropdownField
                        control={control}
                        name="wardId"
                        placeholder="Ward"
                        dataArray={wardList}
                        isClearable={true}
                      />
                    </div>
                    <div className="lg:col-span-2 lg:flex space-x-2 mt-1 items-center">
                      <SearchDropdown
                        control={control}
                        searchIcon={true}
                        isDisabled={false}
                        isSerchable={true}
                        isClearable={true}
                        name="searchPatient"
                        placeholder="Search By Patient Name / MR No"
                        isMulti={false}
                        dataArray={searchPatientList}
                        handleInputChange={(e) => {
                          let tempObj = {
                            admissionId: null,
                            floorId: floor?.id,
                            isOldPatient: isOldPatientValue,
                            searchString: e,
                            wardId: ward?.id,
                          };

                          if (e !== "" && UseCheckConsecutiveCharacters(e)) {
                            fetchPatientSearchCCC(tempObj)
                              .then((response) => {
                                setSearchPatientList(response.data.result);
                              })
                              .catch((error) => {});
                          }
                        }}
                      />
                    </div>
                    <div
                      onClick={(e) => {
                        if (e?.target?.checked === true) {
                          console.log("e?.target?.checked", e?.target?.checked);
                          setValue("searchPatient", null);
                          setDataResult([]);
                        }
                      }}
                    >
                      <CheckBoxField
                        control={control}
                        name="isOldPatient"
                        label="Old Patient"
                      />
                    </div>
                    <div className="flex justify-end md:col-span-2 lg:col-span-1 items-center gap-2">
                      <Tooltip title={"Print Patient List"}>
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563", marginTop: "7px " }}
                          onClick={(e) => {
                            floor && floor?.id !== null
                              ? handleOpenPatientListPrintModal()
                              : warningAlert("Please Select Floor..");
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={"Refresh List"}>
                        <button
                          className="border border-gray-500 rounded-md   cursor-pointer py-1 px-2 bg-white"
                          onClick={(e) => {
                            e.preventDefault();
                            // resetField("fromDate");
                            // resetField("toDate");
                            getPatientListCCC();
                            // setSelectedPatient(null);
                            // setAdmissionId(null);
                            // setDisplayInfo(null);
                            // setTabValue(0);
                          }}
                        >
                          <RefreshIcon fontSize="small" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="my-2">
                  {dataResult.length > 0 ? (
                    <>
                      <CommonDynamicTableNew
                        dataResult={dataResult}
                        handleSelectedRow={handleSelectedRow}
                        removeHeaders={[
                          "DepartmentId",
                          "TariffId",
                          "Discharged",
                          "PatientId",
                          "Description",
                          "AdmissionId",
                          // "MRNo",
                          "ClassId",
                          "CompanyId",
                          "AgeYear",
                          "Age",
                          "DoctorId",
                          "Department",
                          "DoctorName",
                          "DoctorId",
                          "IsBillGenerated",
                          "isrecievebed",
                          "BedId",
                          "LockIndent",
                          "PrescriptionDate",
                          "IsNursingClearanceDone",
                          "isTransferToWard",
                        ]}
                        tableClass={
                          "rounded h-[200px] xl:h-[360px] 2xl:h-[370px]"
                        }
                        renderInput={renderInput}
                        editableColumns={["Patient Name", "MRNo", "Bed No"]}
                      />
                    </>
                  ) : (
                    <h3 className="flex justify-center mt-20 font-bold text-gray-600">
                      No Records Found...
                    </h3>
                  )}
                </div>

                {/* Pending To Do List Card */}
                {pendingToDos.length > 0 && (
                  <div className="bg-white shadow-md mt-3 rounded-lg">
                    <div className="font-semibold text-sm text-gray-700 py-1 pt-2 bg-[#FEFFD4] px-2 rounded-t-lg flex justify-between items-center">
                      <div>Pending To Do List</div>
                      {pendingToDos.length > 0 && (
                        <div>
                          <EditOnIcon
                            title={"Edit Tasks"}
                            onClick={() => {
                              setOpenClinicalIconModal(true);
                              setPrivilege("SubmitToDo");
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="p-2 rounded-b-lg ">
                      {pendingToDos.length > 0 ? (
                        <CommonDynamicTableNew
                          dataResult={pendingToDos}
                          tableClass="h-44"
                          highlightRow={false}
                          removeHeaders={[
                            "id",
                            "task_service_id",
                            "group_id",
                            "todoId",
                            "id",
                            "id",
                          ]}
                        />
                      ) : (
                        <div className="text-center py-5">
                          No Pending To Do's
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </form>
            )}

            {isOpenFilter === false ? (
              <div className={`${dataResult.length > 0 ? "lg:w-[100px]" : ""}`}>
                {dataResult.length > 0 ? (
                  <>
                    <CommonDynamicTableNew
                      dataResult={dataResult}
                      handleSelectedRow={handleSelectedRow}
                      removeHeaders={[
                        "DepartmentId",
                        "TariffId",
                        "Discharged",
                        "PatientId",
                        "Description",
                        "AdmissionId",
                        //"MRNo",
                        "AgeYear",
                        "DoctorId",
                        "Department",
                        "DoctorName",
                        "DoctorId",
                        "IsBillGenerated",
                        "isrecievebed",
                        "BedId",
                        "LockIndent",
                        "CompanyName",
                        "PrescriptionDate",
                        "dob",
                        "IsNursingClearanceDone",
                        "isTransferToWard",
                        "ClassId",
                        "Patient Name",
                        "CompanyId",
                      ]}
                      tableClass={
                        "rounded h-[300px] xl:h-[500px] 2xl:h-[700px]"
                      }
                      renderInput={renderInput}
                      editableColumns={["Patient Name", "MRNo"]}
                    />
                  </>
                ) : null}
              </div>
            ) : null}

            <div className="text-center align-middle items-center invisible md:hidden lg:visible lg:flex justify-end">
              {isOpenFilter === true ? (
                <button
                  className="h-10 w-[0.80rem] flex items-center justify-center rounded-t rounded-b bg-customBlue text-white"
                  onClick={toggleDrawerOpen}
                >
                  <ArrowLeftIcon />
                </button>
              ) : (
                <button
                  className="h-10 w-[0.80rem] flex items-center justify-center rounded-t rounded-b bg-customBlue text-white"
                  onClick={toggleDrawerOpen}
                >
                  <Tooltip title="Open List">
                    <ArrowRightIcon />
                  </Tooltip>
                </button>
              )}
            </div>

            <div
              className={`${
                isOpenFilter === true
                  ? "w-auto lg:w-[80%]   "
                  : "w-full lg:w-[83%] xl:w-[92%] "
              }`}
            >
              <div className="w-full">
                <div className="flex  items-center w-full gap-1 my-1">
                  {displayInfo !== null || admissionId !== null ? (
                    <div className="grid grid-cols-12 gap-2">
                      <div className="w-full grid col-span-7 gap-3">
                        <div className="w-full  flex  space-x-3 items-center overflow-x-auto whitespace-nowrap">
                          {userActions.map((obj) => (
                            <fieldset
                              // disabled={
                              //   !!selectedPatient && selectedPatient?.AllowToGO
                              // }
                              className=""
                            >
                              {!obj.isAction && (
                                <>
                                  {obj.action === "Tentative Discharge" && (
                                    <>
                                      <Tooltip title="Tentative Discharge">
                                        <button
                                          type="button"
                                          className=""
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                !patientInformation?.AllowToGO &&
                                                !selectedPatient?.AllowToGO
                                              ) {
                                                // handleOpenInpatientIssue();
                                                setOpenTentativeDischargeModal(
                                                  true
                                                );
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                                setPrivilege(obj.action);
                                              } else {
                                                warningAlert(
                                                  "Allow to go is given for this patient. Access denied..!"
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <DischargeI />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "In Patient Issue" && (
                                    <>
                                      <Tooltip title="In Patient Issue">
                                        <button
                                          type="button"
                                          className=""
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                !patientInformation?.AllowToGO &&
                                                !selectedPatient?.AllowToGO
                                              ) {
                                                // handleOpenInpatientIssue();
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                                setPrivilege(obj.action);
                                              } else {
                                                warningAlert(
                                                  "Allow to go is given for this patient. Access denied..!"
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <InpatientIssueIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Discharge Summary" && (
                                    <>
                                      <DischargeSummaryIcon
                                        title={"Discharge Summary"}
                                        onClick={() => {
                                          if (
                                            displayInfo !== null ||
                                            admissionId !== undefined
                                          ) {
                                            setOpenClinicalIconModal(true);
                                            setPrivilege(obj.action);
                                            setMenuId(obj.menuId);
                                            setPrivilege(obj.action);
                                          } else {
                                            warningAlert(
                                              "Please Select Patient...!"
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  )}

                                  {obj.action === "Patient Transfer" && (
                                    <>
                                      <PatientTransferIcon
                                        title={"Patient Transfer"}
                                        onClick={() => {
                                          if (
                                            displayInfo !== null ||
                                            admissionId !== undefined
                                          ) {
                                            if (
                                              isOldPatientValue ||
                                              (!patientInformation?.BedReleased &&
                                                !selectedPatient?.BedReleased)
                                            ) {
                                              setOpenClinicalIconModal(true);
                                              setPrivilege(obj.action);
                                              setMenuId(obj.menuId);
                                              setPrivilege(obj.action);
                                            } else {
                                              warningAlert(
                                                "The patient has already been released from bed."
                                              );
                                            }
                                          } else {
                                            warningAlert(
                                              "Please Select Patient...!"
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  )}

                                  {obj.action === "In Patient Return" && (
                                    <>
                                      <Tooltip title="In Patient Return">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                !patientInformation?.AllowToGO &&
                                                !selectedPatient?.AllowToGO
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                                setPrivilege(obj.action);
                                              } else {
                                                warningAlert(
                                                  "Allow to go is given for this patient. Access denied..!"
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <InPatientReturnIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action ===
                                    "Sample Collection For Colour Tube" && (
                                    <>
                                      <Tooltip title="Sample Collection">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                                setPrivilege(obj.action);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <SampleCollectionIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Patient Indent" && (
                                    <>
                                      <Tooltip title="Patient Indent">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                !patientInformation?.AllowToGO &&
                                                !selectedPatient?.AllowToGO
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "Allow to go is given for this patient. Access denied..!"
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <PatientIndentIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Order Sheet" && (
                                    <>
                                      <Tooltip title="Order Sheet">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                handleOpen(); //need to make it common
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <OrderSheetIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Invenstigations" && (
                                    <>
                                      <Tooltip title="Investigation">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                handleOpenLabModal();
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <LIMSIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Push Tool" && (
                                    <>
                                      <Tooltip title="Push Tool">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                // setOpenPushToolModal(true);
                                                setOpenClinicalIconModal(true);

                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <PushToolIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Care Plan" && (
                                    <>
                                      <Tooltip title="Care Plan">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <CarePlanIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Care Bundle" && (
                                    <>
                                      <Tooltip title="Care Bundle">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <VentilatorBundleIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "DIVA Tool Score" && (
                                    <>
                                      <Tooltip title="Diva Tool">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <DivaToolIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Complaint Management" && (
                                    <>
                                      <Tooltip title="Complaint Management">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <ComplaintStatusOnIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Blood Requisition Note" && (
                                    <>
                                      <Tooltip title="Blood Requisition Note">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <BloodRequisitionIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}

                                  {obj.action === "Blood Issue Note" && (
                                    <>
                                      <Tooltip title="Blood Issue Note">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <BloodIssueNoteIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}
                                  {obj.action ===
                                    "Drug Administrator Order" && (
                                    <>
                                      <Tooltip title="Drug Administrator Order">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                !patientInformation?.AllowToGO &&
                                                !selectedPatient?.AllowToGO
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "Allow to go is given for this patient. Access denied..!"
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <DrugOrderIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}
                                  {obj.action ===
                                    "Drug Administrator Chart(Inventory)" && (
                                    <>
                                      <Tooltip title="Drug Administrator Chart">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <DrugAdmChartIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}
                                  {obj.action === "Narcotic Drug Form" && (
                                    <>
                                      <Tooltip title="Narcotic Drug Form">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              if (
                                                isOldPatientValue ||
                                                (!patientInformation?.BedReleased &&
                                                  !selectedPatient?.BedReleased)
                                              ) {
                                                setOpenClinicalIconModal(true);
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                              } else {
                                                warningAlert(
                                                  "The patient has already been released from bed."
                                                );
                                              }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <NarcoticDrugIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  )}
                                  {obj.action === "Pending Antibiotic Form" && (
                                    <>
                                      <PendingAntibioticIcon
                                        title={"Pending Antibiotic Form"}
                                        onClick={() => {
                                          if (
                                            displayInfo !== null ||
                                            admissionId !== undefined
                                          ) {
                                            if (
                                              isOldPatientValue ||
                                              (!patientInformation?.BedReleased &&
                                                !selectedPatient?.BedReleased)
                                            ) {
                                              setOpenClinicalIconModal(true);
                                              setPrivilege(obj.action);
                                              setMenuId(obj.menuId);
                                            } else {
                                              warningAlert(
                                                "The patient has already been released from bed."
                                              );
                                            }
                                          } else {
                                            warningAlert(
                                              "Please Select Patient...!"
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  )}
                                  {obj.action === "Add Consent" && (
                                    <>
                                      <ConsentIcon
                                        title={"Consent"}
                                        onClick={() => {
                                          if (
                                            displayInfo !== null ||
                                            admissionId !== undefined
                                          ) {
                                            if (
                                              isOldPatientValue ||
                                              (!patientInformation?.BedReleased &&
                                                !selectedPatient?.BedReleased)
                                            ) {
                                              setOpenClinicalIconModal(true);
                                              setPrivilege(obj.action);
                                              setMenuId(obj.menuId);
                                            } else {
                                              warningAlert(
                                                "The patient has already been released from bed."
                                              );
                                            }
                                          } else {
                                            warningAlert(
                                              "Please Select Patient...!"
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  )}
                                  {obj.action === "Discharge Initiate" && (
                                    <>
                                      <InitiateDischargeIcon
                                        title={"Discharge Initiate"}
                                        onClick={() => {
                                          if (
                                            displayInfo !== null ||
                                            admissionId !== undefined
                                          ) {
                                            if (
                                              !patientInformation?.BedReleased &&
                                              !selectedPatient?.BedReleased
                                            ) {
                                              !patientInformation?.IsDischargeInitiated &&
                                              !selectedPatient.IsDischargeInitiated
                                                ? setOpenInitiateDischargeModal(
                                                    true
                                                  )
                                                : warningAlert(
                                                    "Discharge has already been initiated for this patient."
                                                  );
                                              setPrivilege(obj.action);
                                              setMenuId(obj.menuId);
                                            } else {
                                              warningAlert(
                                                "The patient has already been released from bed."
                                              );
                                            }
                                          } else {
                                            warningAlert(
                                              "Please Select Patient...!"
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  )}
                                  {obj.action ===
                                    "Create Office Permission" && (
                                    <Tooltip title="Office Permission">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          if (
                                            displayInfo !== null ||
                                            admissionId !== undefined
                                          ) {
                                            setOpenNewRequestModal(true);
                                            setPrivilege(obj.action);
                                            setMenuId(obj.menuId);
                                          } else {
                                            warningAlert(
                                              "Please Select Patient...!"
                                            );
                                          }
                                        }}
                                      >
                                        <OfficePermissionIcon />
                                      </button>
                                    </Tooltip>
                                  )}
                                  {obj.action ===
                                    "Create Office Permission" && (
                                    <Tooltip title="Symptoms">
                                      <button
                                        type="button"
                                        className="ml-2"
                                        onClick={() => {
                                          if (
                                            displayInfo !== null ||
                                            admissionId !== undefined
                                          ) {
                                            setOpenSymptomsModal(true);
                                            setPrivilege("Symptoms");
                                            setMenuId(obj.menuId);
                                          } else {
                                            warningAlert(
                                              "Please Select Patient...!"
                                            );
                                          }
                                        }}
                                      >
                                        <SymptomsIcon />
                                      </button>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </fieldset>
                          ))}
                        </div>

                        <div className="flex gap-2 items-center ">
                          <div className="w-full bg-white">
                            <DatePickerFieldNew
                              control={control}
                              name="fromDate"
                              label="From Date"
                              value={new Date()}
                              onChange={(newValue) => {
                                setValue("fromDate", newValue);
                              }}
                              disablePast={false}
                              disableFuture={false}
                              inputFormat="dd-MM-yyyy"
                            />
                          </div>
                          <div className="w-full bg-white">
                            <DatePickerFieldNew
                              control={control}
                              name="toDate"
                              label="To Date"
                              value={new Date()}
                              onChange={(newValue) => {
                                setValue("toDate", newValue);
                              }}
                              disablePast={false}
                              disableFuture={false}
                              inputFormat="dd-MM-yyyy"
                            />
                          </div>
                          <div className="w-full">
                            <Controller
                              control={control}
                              name="fromTime"
                              render={({ field: { value, onChange } }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopTimePicker
                                    label="From Time"
                                    value={value}
                                    onChange={onChange}
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        {...params}
                                        error={errors.fromTime}
                                        helperText={errors.fromTime?.message}
                                        inputProps={{
                                          ...params.inputProps,
                                          readOnly: true,
                                        }}
                                        InputLabelProps={{
                                          style: {
                                            fontSize: "14px",
                                            position: "absolute",
                                            top: "-2px",
                                          },
                                        }}
                                        sx={{
                                          svg: {
                                            color: "#1e3a8a",
                                            height: 22,
                                            width: "100%",
                                            marginRight: "16px",
                                          },
                                          backgroundColor: "white",
                                          overflow: "visible",

                                          "& .MuiOutlinedInput-root": {
                                            "& .MuiOutlinedInput-input": {
                                              // border: 0,
                                              fontSize: 14,
                                              height: "18px",
                                              width: "100%",

                                              borderColor: "  ",
                                              overflow: "hidden",
                                            },
                                            "& .MuiFormLabel-root": {
                                              fontSize: "14px",
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </div>
                          <div className="w-full">
                            <Controller
                              control={control}
                              name="toTime"
                              render={({ field: { value, onChange } }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopTimePicker
                                    label=" To Time"
                                    value={value}
                                    onChange={onChange}
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        {...params}
                                        error={errors.toTime}
                                        helperText={errors.toTime?.message}
                                        inputProps={{
                                          ...params.inputProps,
                                          readOnly: true,
                                        }}
                                        InputLabelProps={{
                                          style: {
                                            fontSize: "14px",
                                            position: "absolute",
                                            top: "-2px",
                                          },
                                        }}
                                        sx={{
                                          svg: {
                                            color: "#1e3a8a",
                                            height: 22,
                                            width: "100%",
                                            marginRight: "16px",
                                          },
                                          backgroundColor: "white",
                                          overflow: "visible",

                                          "& .MuiOutlinedInput-root": {
                                            "& .MuiOutlinedInput-input": {
                                              // border: 0,
                                              fontSize: 14,
                                              height: "18px",
                                              width: "100%",

                                              borderColor: "  ",
                                              overflow: "hidden",
                                            },
                                            "& .MuiFormLabel-root": {
                                              fontSize: "14px",
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </div>

                          {displayInfo !== null ||
                          admissionPatientId !== null ? (
                            <div className="flex items-center justify-end">
                              {userActions.map((obj) => (
                                <>
                                  {!obj.isAction && obj.action === "Print" ? (
                                    <>
                                      <Tooltip title="Print">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              displayInfo !== null ||
                                              admissionId !== undefined
                                            ) {
                                              //new Format

                                              if (tabValue === 5) {
                                                handleOpenPrintModal(
                                                  "InputOutput"
                                                );
                                              } else {
                                                setOpenPrintModal(true);
                                              }

                                              // if (tabValue === 0 && openClinicalPrintModal) {
                                              //   if (
                                              //     selectedAssesmentListRow !==
                                              //     null
                                              //   ) {
                                              //     handleOpenPrintModal(
                                              //       selectedAssesmentListRow[
                                              //         "Pain Assessment"
                                              //       ]
                                              //     );
                                              //   } else {
                                              //     warningAlert(
                                              //       "Please Select Patient Assessment Record...!"
                                              //     );
                                              //   }
                                              // }
                                            } else {
                                              warningAlert(
                                                "Please Select Patient...!"
                                              );
                                            }
                                          }}
                                        >
                                          <PrintIcon />
                                        </button>
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </>
                              ))}
                            </div>
                          ) : null}
                        </div>
                        <div className="border rounded px-2 bg-blue-200 shadow-sm">
                          <div className="flex items-center space-x-2 ">
                            <h1 className="font-semibold ">
                              <span className="">Diagnosis :</span>
                            </h1>
                            <h1 className=""> </h1>
                          </div>
                        </div>
                      </div>
                      <div className="items-center col-span-5 grid grid-cols-4 gap-2">
                        {scoreBlocks?.map((item) => (
                          <button
                            type="button"
                            onClick={() => {
                              console.log("item?.label", item?.label);
                              setScoreType(item?.label);

                              if (item?.label === "GCS") {
                                setOpenQSofaCalcModal(true);
                              }

                              if (item?.label === "QSOFA") {
                                setOpenQSofaCalcModal(true);
                              }

                              if (item?.label === "APACHE") {
                                setTabValue(9);
                              }

                              if (item?.label === "MEWS") {
                                setTabValue(1);
                              }
                            }}
                          >
                            <div
                              key={item?.id}
                              className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                            >
                              <div className="w-full bg-[#20407E] text-white  text-lg font-semibold  text-center">
                                {item?.label}
                              </div>
                              <div
                                className={`w-full flex items-center justify-center ${item?.indicatorColour} py-5 text-3xl font-bold text-gray-700`}
                              >
                                <div className="text-4xl ">
                                  {item?.score > 0 ? item?.score : "--"}
                                </div>
                              </div>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {!!selectedPatient &&
                !!selectedPatient?.ReferDoctors &&
                !!openSnackBar && (
                  <Snackbar
                    open={openSnackBar}
                    ContentProps={{
                      sx: {
                        background: "#a4c2f5",
                      },
                    }}
                    //autoHideDuration={6000}
                    // onClose={handleCloseSnackbar}
                    // TransitionProps={{ onExited: handleExited }}
                    message={
                      <div className="text-gray-900">
                        <div className="font-bold py-1 text-sm">
                          Referral Doctors
                        </div>
                        {JSON.parse(selectedPatient?.ReferDoctors).map(
                          (item, index) => (
                            <>
                              <div className="font-semibold text-xs text-gray-700">{`${
                                index + 1
                              }.${item.DoctorName}`}</div>
                            </>
                          )
                        )}
                      </div>
                    }
                    action={
                      <React.Fragment>
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          sx={{ p: 0.5, color: "black" }}
                          onClick={handleCloseSnackbar}
                        >
                          <CloseIcon />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                )}

              <Box
                sx={{ overflow: "hidden" }}
                className="text-left w-auto md:text-xl col-span-3 my-2 "
              >
                <div className="rounded-md " style={{ background: "#F1F1F1" }}>
                  <Tabs
                    className="text-left  font-semibold  "
                    onChange={handleChangeTabs}
                    value={tabValue}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#133e87",
                        color: "#133e87",
                      },
                    }}
                    textColor="#133e87"
                    indicatorColor="#133e87"
                    variant="scrollable"
                    scrollButtons
                  >
                    <Tab
                      label="Pain Assessment"
                      className="PainAssessment"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Vitals"
                      className="Vitals"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="Sugar Level"
                      className="SugarLevel"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(2)}
                    />
                    <Tab
                      label="Oxygen / Ventilation"
                      className="Oxygen/Ventilation"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(3)}
                    />
                    <Tab
                      label="Suction / Saturation"
                      className="Succion/Saturation"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(4)}
                    />
                    <Tab
                      label="Input / Output"
                      className="Input/Output"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(5)}
                    />
                    <Tab
                      label="Human Body"
                      className="humanBody"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(6)}
                    />
                    <Tab
                      label="Nursing Note"
                      className="nursingNote"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(7)}
                    />
                    <Tab
                      label="Charges"
                      className="charges"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(8)}
                    />
                    <Tab
                      label="Apache Score"
                      className="human"
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                      {...a11yProps(9)}
                    />
                    {selectedPatient?.Age <= 14 ? (
                      <Tab
                        label="PEWS"
                        className="Pews"
                        style={{
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                        {...a11yProps(10)}
                      />
                    ) : null}
                  </Tabs>
                </div>
                <div className="">
                  <div className="">
                    <TabPanel value={tabValue} index={0}>
                      <PainAssessment
                        patientInformation={patientInformation}
                        admissionId={admissionId}
                        selectedPatient={selectedPatient}
                        displayInfo={displayInfo}
                        sliderReset={sliderReset}
                        setSliderReset={setSliderReset}
                        selectedAssesmentListRow={selectedAssesmentListRow}
                        setSelectedAssesmentListRow={
                          setSelectedAssesmentListRow
                        }
                        userActions={userActions}
                        menuId={location?.state?.menuId}
                        privilege={privilege}
                        //
                        dataResultAssessment={dataResultAssessment}
                        setDataResultAssessment={setDataResultAssessment}
                        weightDataResult={weightDataResult}
                        setWeightDataResult={setWeightDataResult}
                      />
                    </TabPanel>
                  </div>
                  <TabPanel value={tabValue} index={1}>
                    <Vitals
                      patientInformation={patientInformation}
                      admissionId={admissionId}
                      selectedPatient={selectedPatient}
                      displayInfo={displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      setPrivilege={setPrivilege}
                      handlePatientInfo={handlePatientInfo}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <SugarLevel
                      patientInformation={patientInformation}
                      selectedPatient={selectedPatient}
                      admissionId={admissionId}
                      displayInfo={displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      setPrivilege={setPrivilege}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <OxygenVentilation
                      patientInformation={patientInformation}
                      selectedPatient={selectedPatient}
                      admissionId={admissionId}
                      displayInfo={displayInfo}
                      oxygenTimer={oxygenTimer}
                      setOxygenTimer={setOxygenTimer}
                      oxygenIsPaused={oxygenIsPaused}
                      setOxygenIsPaused={setOxygenIsPaused}
                      oxygenIsActive={oxygenIsActive}
                      setOxygenIsActive={setOxygenIsActive}
                      oxygenIncrement={oxygenIncrement}
                      ventilationTimer={ventilationTimer}
                      setVentilationTimer={setVentilationTimer}
                      ventilationIsPaused={ventilationIsPaused}
                      setVentilationIsPaused={setVentilationIsPaused}
                      ventilationIsActive={ventilationIsActive}
                      setVentilationIsActive={setVentilationIsActive}
                      ventilationIncrement={ventilationIncrement}
                      setHours={setHours}
                      setMinutes={setMinutes}
                      setSeconds={setSeconds}
                      setOxygenHours={setOxygenHours}
                      setOxygenMinutes={setOxygenMinutes}
                      setOxygenSeconds={setOxygenSeconds}
                      oxygenhours={oxygenhours}
                      oxygenminutes={oxygenminutes}
                      oxygenseconds={oxygenseconds}
                      hours={hours}
                      minutes={minutes}
                      seconds={seconds}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      setPrivilege={setPrivilege}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    <SuccionSaturation
                      admissionId={admissionId}
                      displayInfo={displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      selectedPatient={selectedPatient}

                      // privilege={privilege}
                      // setPrivilege={setPrivilege}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    <InputOutput
                      patientInformation={patientInformation}
                      selectedPatient={selectedPatient}
                      selectedRow={selectedRow}
                      admissionId={admissionId}
                      displayInfo={displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      setPrivilege={setPrivilege}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={6}>
                    <HumanBody
                      drawerOpen={drawerOpen}
                      admissionId={admissionId}
                      displayInfo={displayInfo && displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      setPrivilege={setPrivilege}
                      selectedPatient={selectedPatient}
                      patientInformation={patientInformation}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={7}>
                    <NursingNote
                      selectedPatient={selectedPatient}
                      admissionId={admissionId}
                      displayInfo={displayInfo}
                      setNoteInfo={setNoteInfo}
                      noteInfo={noteInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      fromDate={fromDateVal}
                      toDate={toDateVal}
                      fromTime={fromTime}
                      toTime={toTime}
                      isOldPatientValue={isOldPatientValue}
                      setOpenPrintModal={setOpenPrintModal}
                      setNursingNoteId={setNursingNoteId}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={8}>
                    <Charges
                      admissionId={admissionId}
                      displayInfo={displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      setPrivilege={setPrivilege}
                      selectedPatient={selectedPatient}
                      patientInformation={patientInformation}
                      tariffId={tariffId}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={9}>
                    <ApacheScore
                      admissionId={admissionId}
                      displayInfo={displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      setPrivilege={setPrivilege}
                      selectedPatient={selectedPatient}
                      patientInformation={patientInformation}
                      setScoreBlocks={setScoreBlocks}
                      handlePatientInfo={handlePatientInfo}
                    />
                  </TabPanel>
                  {selectedPatient?.Age <= 14 ? (
                    <TabPanel value={tabValue} index={10}>
                      <Pews
                        admissionId={admissionId}
                        displayInfo={displayInfo}
                        selectedPatient={selectedPatient}
                        userActions={userActions}
                        menuId={location?.state?.menuId}
                        privilege={privilege}
                        setPrivilege={setPrivilege}
                        patientInformation={patientInformation}
                      />
                    </TabPanel>
                  ) : null}

                  {open ? (
                    <OrderSheetModal
                      admissionId={
                        admissionId || patientInformation?.AdmissionId
                      }
                      open={open}
                      setOpen={setOpen}
                      handleOpen={handleOpen}
                      handleClose={handleClose}
                      patientInformation={patientInformation}
                      selectedPatient={selectedPatient}
                      displayInfo={displayInfo}
                      userActions={userActions}
                      menuId={location?.state?.menuId}
                      privilege={privilege}
                      admissionPatientId={admissionPatientId}
                      isFromEmergency={false}
                    />
                  ) : null}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </VisitContext.Provider>
      {openClinicalPrintModal ? (
        <CommonPrintModal
          open={openClinicalPrintModal}
          setOpen={setOpenClincalPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openLabModal ? (
        <ClinicalCareChartInvestigationModal
          openLabModal={openLabModal}
          handleCloseLabModal={handleCloseLabModal}
          selectedPatient={selectedPatient}
          displayInfo={displayInfo}
          PatientId={
            displayInfo?.PatientId ? displayInfo?.PatientId : admissionPatientId
          }
          userActions={userActions}
        />
      ) : null}

      {/* //Receive Patient Modal// */}
      {openReceivePatientModal ? (
        <ReceivePatientModal
          open={openReceivePatientModal}
          setOpen={setOpenReceivePatientModal}
          handleOpen={handleOpenReceivePatientModal}
          handleClose={handleCloseReceivePatientModal}
          selectedRow={selectedRow}
          getPatientListCCC={getPatientListCCC}
          // setReplaceSelectedRow={setReplaceSelectedRow}
          // updateRowOfAdmTbl={updateRowOfAdmTbl}
          menuId={menuId}
          privilege={privilege}
          functionalityId={functionalityId}
        />
      ) : null}

      {/* openInitiateDischargeModal */}
      {openInitiateDischargeModal ? (
        <InitiatDischargeMessage
          open={openInitiateDischargeModal}
          setOpen={setOpenInitiateDischargeModal}
          handleOpen={() => {
            setOpenInitiateDischargeModal(true);
          }}
          handleClose={() => {
            setOpenInitiateDischargeModal(false);
          }}
          selectedRowData={selectedRow || selectedPatient}
          menuId={menuId}
          privilege={privilege}
        />
      ) : null}

      {console.log("openQSofaCalcModal", openQSofaCalcModal)}

      {/* openInitiateDischargeModal */}
      {openQSofaCalcModal ? (
        <QSofaCalcModal
          open={openQSofaCalcModal}
          setOpen={setOpenQSofaCalcModal}
          handleOpen={() => {
            setOpenQSofaCalcModal(true);
          }}
          handleClose={() => {
            setOpenQSofaCalcModal(false);
          }}
          selectedRowData={selectedRow || selectedPatient}
          menuId={location?.state?.menuId}
          privilege={privilege}
          GCSData={GCSData}
          setGCSData={setGCSData}
          qsofaScorefinal={qsofaScorefinal}
          setScoreBlocks={setScoreBlocks}
          admissionId={admissionId || patientInformation?.AdmissionId}
          scoreType={scoreType}
        />
      ) : null}

      {openClinicalIconModal && (
        <ClinicalIconModal
          open={openClinicalIconModal}
          setOpen={setOpenClinicalIconModal}
          selectedPatient={selectedPatient}
          menuId={menuId}
          privilege={privilege}
          admissionId={
            displayInfo?.AdmissionId ? displayInfo?.AdmissionId : admissionId
          }
          PatientId={
            displayInfo?.PatientId ? displayInfo?.PatientId : admissionPatientId
          }
          isFromClinicalCarechart={true}
          patientInformation={patientInformation}
          patientInfo={patientInformation}
          MRNo={
            displayInfo?.MRNo
              ? displayInfo?.MRNo
              : location?.state?.params?.MRNo
          }
          setOpenCarePlanModal={setOpenCarePlanModal}
          IsBillGenerated={
            !patientInformation?.IsBillGenerated &&
            !selectedPatient?.IsBillGenerated
          }
          //
          pendingToDos={pendingToDos}
          setPendingToDos={setPendingToDos}
          handlePenndingToDos={handlePenndingToDos}
        />
      )}

      {/* Tentative Discharge Modal */}

      {openTentativeDischargeModal ? (
        <TentativeDischargeModal
          open={openTentativeDischargeModal}
          setOpen={setOpenTentativeDischargeModal}
          handleClose={() => {
            setOpenTentativeDischargeModal(false);
          }}
          admissionId={
            displayInfo?.AdmissionId ? displayInfo?.AdmissionId : admissionId
          }
          patientId={
            displayInfo?.PatientId ? displayInfo?.PatientId : admissionPatientId
          }
          getPatientInfo={handlePatientInfo}
          tentativediscDate={
            selectedPatient && selectedPatient.TentativeDischargeDate !== null
              ? selectedPatient.TentativeDischargeDate
              : new Date()
          }
        />
      ) : null}

      {/* Print Modal */}
      {openPtPrintModal ? (
        <CommonPrintModal
          open={openPtPrintModal}
          setOpen={setOpenPtPrintModal}
          handleOpen={() => setOpenPtPrintModal(true)}
          handleClose={() => setOpenPtPrintModal(false)}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {!!openPrintModal && renderPrint()}

      {openNewRquestModal && (
        <NewOfficeModal
          openNewRquestModal={openNewRquestModal}
          setOpenNewRequestModal={setOpenNewRequestModal}
          actionObject={{ action: privilege, menuId: menuId }}
          floorId={floor}
          displayInfo={displayInfo}
          getUserInfo={userInfo}
        />
      )}
      {openSymptomsModal && (
        <SymptomsModal
          open={openSymptomsModal}
          handleClose={() => setOpenSymptomsModal(false)}
          admissionId={
            displayInfo?.AdmissionId ? displayInfo?.AdmissionId : admissionId
          }
          patientId={
            displayInfo?.PatientId ? displayInfo?.PatientId : admissionPatientId
          }
          menuId={menuId}
          privilege={privilege}
        />
      )}
    </>
  );
}

export default PatientPainAssessment;
