import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Checkbox, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";

import { format, isAfter } from "date-fns";
import { CancelOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";
import { isCloseIndent } from "../../../services/commonServices/StoreIndent/StoreIndentService";
import {
  fetchIndentItems,
  fetchIndentList,
  fetchPatientIndentListSearch,
  fetchPrintGetIndent,
  fetchSelectedIndentPatientInfo,
} from "../../../services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  bgcolor: "background.paper",
  // borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
  padding: 2,
};

const IsUrgent = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "Yes",
    label: "Yes",
  },
  {
    id: 0,
    value: "No",
    label: "No",
  },
];

const Actions = [
  {
    id: 0,
    action: "checkbox",
    isAction: true,
  },

  {
    id: 1,
    action: "print",
    isAction: true,
  },

  {
    id: 2,
    action: "select",
    isAction: false,
  },

  {
    id: 3,
    action: "Close Indent",
    isAction: true,
  },
];

export default function GetIndentModal(props) {
  var token = JSON.parse(localStorage.getItem("userInfo"));
  const [showSpinner, setShowSpinner] = useState(false);

  const [listAutocomplete, setListAutocomplete] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedRowData, setSelectedRowData] = React.useState(null);

  //tbl-1
  const [indentList, setIndentList] = useState([]);

  //tbl-2
  const [indentListItems, setIndentListItems] = useState([]);
  // //selected from get indent table-2
  //selected from table-2
  const [indentPatientData, setIndentPatientData] = useState(
    props.patientDetails
  );
  //from Store
  const [fromStore, setFromStore] = useState();
  //from selected FromStore id
  const [selectedFromStore, setSelectedFromStore] = useState(0);
  //
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  ///

  const [
    openlCloseInndentConfirmationModal,
    setOpenlCloseInndentConfirmationModal,
  ] = useState(false);
  //
  const { open, handleClose, getPatientDetails } = props;

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      isUrgentRadio: 2,
      fromDate: new Date(),
      toDate: new Date(),
      listSearch: null,
    },
  });
  const { control, reset, watch,setValue, register } = methods;

  console.log("indentListItems", indentListItems);

  ///watch
  var IsUrgentRadio = watch("isUrgentRadio");
  let IndentNumber = watch("indentNumber");

  useEffect(() => {
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      getPatientInfoAutoComplete();
  }, [selectedFromDate, selectedToDate, selectedFromStore]);

  const getPatientInfoAutoComplete = (SearchString) => {
    const autoCopmleteObj = {
      fromDate: format(
        selectedFromDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
      ),
      toDate: format(
        selectedToDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
      ),
      fromStoreId: selectedFromStore,
      indentFrom: "InPatientIssue",
      searchString: SearchString || "",
      toStoreId: token.storeId,
    };
    fetchPatientIndentListSearch(autoCopmleteObj)
      .then((response) => response.data)
      .then((res) => {
        setListAutocomplete(res.result);
        props.setPatientDetails(null);
      });
  };

  useEffect(() => {
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [
    selectedFromDate,
    selectedToDate,
    selectedPatient,
    IsUrgentRadio,
    props.patientDetails,
    selectedFromStore,
    //IndentNumber,
  ]);

  const populateTable = (forPagination) => {
    setShowSpinner(true);
    const indentObj = {
      admissionId:
        props.patientDetails !== null
          ? props.patientDetails.AdmissionId
          : selectedPatient?.id || 0,
      fromDate: format(
        selectedFromDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ),
      fromStoreId: selectedFromStore,
      indentNo: IndentNumber,
      indentStatus: 2,
      isApproved: 2,
      isCancelled: 2,
      isForcefullyClosed: 2,
      isUrgent: IsUrgentRadio !== "" ? Number(IsUrgentRadio) : 0,
      toDate: format(
        selectedToDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ),
      toStoreId: token.storeId,
      indentFrom: "InPatientIssue",
      //
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
    };
    setIndentListItems([]);
    fetchIndentList(indentObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIndentList((prevData) => [...prevData, ...res.result]);
          setSelectedID(null);
        } else {
          setIndentList(res.result);
          setPage(0);
          setSelectedID(null);
        }
        setCount(res.count);
        setShowSpinner(false);
      })
      .catch((error) => setShowSpinner(false));
  };

  useEffect(() => {
    getStore();
  }, []);

  // storename dropdown
  const getStore = () => {
    fetchApplicableStores(token.storeId)
      .then((response) => response.data)
      .then((res) => {
        setFromStore(res.result);
      });
  };

  // patientId

  //add selected items in item details table
  const AddSelectedIndentItems = () => {
    const newArr = indentListItems.filter((item) => item.isChecked);
    if (newArr.length > 0) {
      for (let i = 0; i < newArr.length; i++) {
        newArr[i].IndentQty = Number(newArr[i].IndentQty);
        newArr[i].Quantity = Number(newArr[i].PendIssueQty);
      }
    }
    methods.resetField("SearchPatient");
    props.focusBatchNumber(0);
    props.setSelectedDrugData(newArr);
    getPatientDetails(props.indentDtlsTableRow.PatientId);
    handleClose();
  };

  ////////table related

  const getSelectedIndentPatientInfo = (e, row) => {
    if (e.target.checked === true) {
      fetchSelectedIndentPatientInfo(row.PatientId)
        .then((response) => response.data)
        .then((res) => {
          setIndentPatientData(res.result);
          props.setResetdropdown(true)
        });
    }
  };

  //////print

  const handleOpenPrintModal = (row) => {
    // print service
    fetchPrintGetIndent(
      row.IndentId,
      row?.PatientId > 0 && row?.PatientId !== null ? true : false
    ).then((response) => {
      setOpenPrintModal(true);
      setUrlforPrint(response);
    });
  };

  //

  const getIndentListTableData = (row) => {
    fetchIndentItems(token?.storeId, row.IndentId)
      .then((response) => response.data)
      .then((res) => {
        let updatedResult = res.result.map((item, index) => ({
          ...item,
          isChecked: true,
        }));
        setIndentListItems(updatedResult);
      });
    // }
  };

  const handleCloseInndent = (data) => {
    setOpenlCloseInndentConfirmationModal(false);

    let closeIndentObj = {
      indentId: data.IndentId,
      menuId: props.menuId,
      privilege: props.privilege,
    };

    isCloseIndent(closeIndentObj)
      .then((response) => response.data)
      .then((res) => {
        populateTable();
        successAlert(res.message);
        props.setPrivilege("");
        setIndentListItems([]);
      });
  };

  console.log("propsforInpatientIssue", props.userActions);

  const renderActions = (row, index) => {
    return (
      <div className="flex items-center">
        <div>
          <Checkbox
            size="small"
            className="border  border-gray-600 rounded bg-transparent"
            type="checkbox"
            color="primary"
            checked={selectedID === index ? true : false}
            disabled={row.Closed}
            onClick={(e) => {
              if (!row.Closed) {
                setSelectedID(index);
                getSelectedIndentPatientInfo(e, row);
                getIndentListTableData(row);
                props.setIndentDtlsTableRow(row);
              } else {
                warningAlert("This Indent is Closed ...");
              }
            }}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </div>
        {props.userActions.map((actions, i) => (
          <>
            {actions.isAction === true && actions.action === "Print" ? (
              <>
                <Tooltip title={"Print"}>
                  <LocalPrintshopIcon
                    sx={{ color: "#4B5563" }}
                    onClick={() => {
                      handleOpenPrintModal(row);
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
            {actions.isAction === true && actions.action === "Close Indent" ? (
              <div className="ml-3 mt-1">
                <CancelOnIcon
                  title="Close Indent"
                  onClick={() => {
                    setOpenlCloseInndentConfirmationModal(true);
                    props.setPrivilege(actions.action);
                    setSelectedRowData(row);
                  }}
                />
              </div>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  const rowBackgroundColor = (row, index) => {
    if (row?.IsUrgent) {
      return "#f87171";
    } else if (row?.isPmcPatient) {
      return "#80deea";
    } else if (row?.isPpnPatient) {
      return "#9fa8da";
    }
  };

  const renderTableHeader = () => {
    return (
      <div className="flex space-x-2 items-center flex-wrap">
        <div className="flex items-center space-x-2">
          <span className="h-4 w-4 bg-[#f87171] rounded"></span>
          <span>Is Urgent</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="h-4 w-4 bg-[#80deea] rounded"></span>
          <span>PMC Patient</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="h-4 w-4 bg-[#9fa8da] rounded"></span>
          <span>PPN Patient</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              reset();
              props.setIndentDtlsTableRow(null);
            }}
          />

          <div className=" flex flex-wrap rounded text-lg justify-center text-black font-semibold">
            In Patient Issue
          </div>
          {/* search */}
          <div className="grid gap-2 w-full items-center mt-2">
            <h1 className="text-lg text-black font-semibold my-1">
              Get Indent
            </h1>
            <div className="grid grid-cols-3 lg:grid-cols-12 w-full gap-2 items-center">
              <div className="w-full col-span-3 lg:col-span-4">
                <SearchDropdown
                  control={control}
                  placeholder="Search By Patient Name / MR.No."
                  dataArray={listAutocomplete}
                  name="listSearch"
                  searchIcon={true}
                  isClearable={true}
                  isDisabled={props.patientInfo !== undefined ? true : false}
                  handleInputChange={getPatientInfoAutoComplete}
                  inputRef={{
                    ...register("listSearch", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          setSelectedPatient(e.target.value);
                        } else {
                          setSelectedPatient("");
                          methods.reset();
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div className="grid grid-cols-2 col-span-3 gap-3">
                {/* from Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("fromDate", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedFromDate(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>

                {/* to Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("toDate", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedToDate(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="md:col-span-2 lg:col-span-3 grid grid-cols-2 gap-3">
                <div>
                  <DropdownField
                    control={control}
                    // error={errors.prefix}
                    name="fromStore"
                    dataArray={fromStore}
                    placeholder="From Store"
                    isSearchable={false}
                    isClearable={false}
                    inputRef={{
                      ...register("fromStore", {
                        onChange: (e) => {
                          setSelectedFromStore(e.target.value.id);
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <InputField
                    name="indentNumber"
                    variant="outlined"
                    label="Indent Number"
                    control={control}
                    inputProps={{
                      style: { textTransform: "capitalize" },
                    }}
                  />
                </div>
              </div>

              {/* is urgent */}
              <div className="flex md:justify-start lg:justify-center col-span-1 lg:col-span-2 gap-2">
                <label className="font-semibold whitespace-nowrap">
                  Is Urgent:
                </label>
                <RadioField
                  name="isUrgentRadio"
                  control={control}
                  dataArray={IsUrgent}
                />
              </div>
            </div>
          </div>
          {/* patient Info */}
          <div>
            <fieldset
              className="border border-gray-300 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
            >
              <div className="py-2 grid  grid-cols-4 lg:grid-cols-3 2xl:grid-cols-4 gap-2 p-3  ">
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Patient Name</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {(props.patientDetails &&
                        props.patientDetails.patientName) ||
                        (indentPatientData && indentPatientData.patientName)}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">MR. No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {indentPatientData && indentPatientData.mrNo}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">IPD No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {indentPatientData && indentPatientData.IpdNo}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Bed No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {indentPatientData && indentPatientData.BedNo}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Ward</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {indentPatientData && indentPatientData.Ward}
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          {/* tables */}
          <div className="grid grid-cols-4 gap-3 mt-3">
            <div className="col-span-2">
              <p className="font-semibold pl-3">Indent Table</p>
              {showSpinner ? (
                <div className="flex justify-center mt-20">
                  <LoadingSpinner />
                </div>
              ) : indentList?.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={indentList}
                  populateTable={populateTable}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  renderActions={renderActions}
                  tableClass={"rounded lg:h-96 md:h-72"}
                  // highlightRow={false}
                  rowBackgroundColor={rowBackgroundColor}
                  renderTableHeader={renderTableHeader}
                  removeHeaders={[
                    "AdmNo",
                    "AdmissionId",
                    "Cash_Credit",
                    "DrId",
                    "FromStoreId",
                    "IndentId",
                    "PatientId",
                    "isprinted",
                    "VATPercentage",
                  ]}
                />
              ) : (
                <p className="text-center my-32 ">No Indent Found</p>
              )}
            </div>
            <div className="col-span-2 pl-3">
              <p className="font-semibold">Items Table</p>

              {indentListItems?.length > 0 ? (
                // <IndendItemListTable
                //   data={indentListItems}
                //   selectedIndentListItems={selectedIndentListItems}
                //   setSelectedIndentListItems={setSelectedIndentListItems}
                // />
                <CommonDynamicTableNew
                  dataResult={indentListItems}
                  //renderInput={renderInput}
                  highlightRow={false}
                  enableSelectAll={true}
                  removeHeaders={[
                    "BatchesRequired",
                    "IndentDetailId",
                    "IndentId",
                    "Id",
                    "IsConsignment",
                    "Conversion",
                    "VATInClusive",
                    "CompanyName",
                    "Bins",
                    "ConcessionApp",
                    "FreeApplicable",
                    "ItemClass",
                    "ItemGenericId",
                    "Onco",
                    "OncoDist",
                    "Rack",
                    "Shelf",
                    "VATPercentage",
                    "VATInClusive",
                    "isbarcodeprint",
                    "UnitMRP",
                    "isChecked",
                    "Code",
                    "ItemCategory",
                    "ItemForm",
                    "ItemType",
                    "MRP",
                    "PurchaseRate",
                    "Rate",
                    "TotalCost",
                    "BalanceQty",
                    "Quantity",
                    "PendQty",
                  ]}
                  tableClass={"rounded lg:h-[426px] md:h-72 border"}
                  setDataResult={setIndentListItems}
                  // defaultCheckedIndex={
                  //   indentListItems.map((item) => item.index) || []
                  // }
                />
              ) : (
                <p className="text-center my-32 ">No Items Found</p>
              )}
            </div>
          </div>
          {/* buttons */}
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
            {Actions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "select" ? (
                  <div>
                    <CommonButton
                      type="submit"
                      onClick={AddSelectedIndentItems}
                      label="Select"
                      className="bg-customGreen text-white"
                    />
                  </div>
                ) : null}
              </>
            ))}
            <div>
              <CommonButton
                onClick={() => handleClose()}
                label="Close"
                className="border border-customRed text-customRed"
              />
            </div>
            <div>
              <CommonButton
                className="border border-customRed text-customRed"
                label="Reset"
                onClick={() => {
                  reset();
                  setValue("listSearch",null)
                  methods.resetField("SearchPatient");
                  props.setIndentDtlsTableRow(null);
                  setIndentList([]);
                  setIndentListItems([]);
                  setIndentPatientData(null);
                  props.setPatientDetails(null);
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {/* /// modal */}
      <Modal open={openlCloseInndentConfirmationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Close Indent
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want To Close Indent Forcefully ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  label="Cancel"
                  onClick={() => {
                    setOpenlCloseInndentConfirmationModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      handleCloseInndent(selectedRowData);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

// {
//   "Drug": "S L REVISION STEM 265*14 (01.00102.614)",
//   "BatchesRequired": true,
//   "ItemCategory": "IMPLANT",
//   "ItemForm": "IMPLANTS",
//   "VATInClusive": true,
//   "CompanyName": "3M",
//   "Bins": null,
//   "VATPercentage": 6,
//   "ConcessionApp": true,
//   "FreeApplicable": true,
//   "IsConsignment": false,
//   "Onco": "2",
//   "OncoDist": 0,
//   "ItemType": 5,
//   "MRP": 0,
//   "PurchaseRate": 53587,
//   "Conversion": 1,
//   "ItemGenericId": 1476,
//   "ItemClass": 69,
//   "IndentId": 3495914,
//   "Id": 250014,
//   "Quantity": 10,
//   "IndentQty": 10,
//   "PendQty": 10,
//   "PendIssueQty": 10,
//   "Rate": 67520,
//   "TotalCost": 675200,
//   "IndentDetailId": 8758975,
//   "Code": "60052",
//   "BalanceQty": 0,
//   "Rack": null,
//   "Shelf": null,
//   "isbarcodeprint": false,
//   "UnitMRP": 0,
//   "isChecked": true
// }
