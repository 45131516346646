// import React from "react";
// import { FormControl, FormHelperText, TextField } from "@mui/material";
// import { Controller } from "react-hook-form";

// const InputField = ({
//   sx,
//   variant,
//   defaultValue,
//   inputProps,
//   type,
//   disabled,
//   inputRef,
//   name,
//   label,
//   error,
//   control,
//   dontCapitalize,
//   color,
//   referance,
//   onKeyDown,
//   autoComplete,
//   InputLabelProps,
// }) => {
//   // const handleOnChange = (event) => {
//   //   const text = event.target.value.toLowerCase();
//   //   const capitalizedText = text
//   //     .split(" ")
//   //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//   //     .join(" ");
//   //   onChange(capitalizedText);
//   // };
//   return (
//     <FormControl fullWidth size="small" sx={sx}>
//       <Controller
//         render={({ field }) => (
//           <TextField
//             className="h-[36px] text-[14px] bg-white"
//             inputRef={inputRef}
//             ref={referance}
//             onKeyDown={onKeyDown}
//             autoComplete={autoComplete}
//             inputProps={
//               dontCapitalize
//                 ? (inputProps,
//                   {
//                     style: {
//                       textTransform: "capitalize",
//                       fontSize: "14px",
//                       height: "18.5px",
//                     },
//                   })
//                 : (inputProps,
//                   {
//                     style: {
//                       textTransform: "capitalize",
//                       fontSize: "14px",
//                       height: "18.5px",
//                     },
//                   })
//             }
//             sx={{
//               "& .MuiFormLabel-root": {
//                 fontSize: "14px",
//               },
//             }}
//             onWheel={(e) => {
//               if (type == "number") {
//                 e.target.blur();
//               }
//             }}
//             type={type}
//             disabled={disabled}
//             error={!!error?.message}
//             color={color}
//             variant={variant}
//             label={label}
//             placeholder={label}
//             name={name}
//             fullWidth
//             {...field}
//             size="small"
//             InputLabelProps={InputLabelProps}
//           />
//         )}
//         name={name}
//         control={control}
//         defaultValue={defaultValue}
//       />
//       {/* <FormHelperText style={{ color: "#d32f2f" }}>
//         {error?.message}
//       </FormHelperText> */}
//     </FormControl>
//   );
// };

// export default InputField;

import React from "react";
import { Controller } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState } from "react";

const PasswordInput = ({ showPassword, handleClick }) => (
  <InputAdornment position="end">
    <IconButton onClick={handleClick} edge="end">
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>
);

const InputField = ({
  sx,
  variant,
  defaultValue,
  inputProps,
  type,
  disabled,
  inputRef,
  name,
  label,
  error,
  control,
  dontCapitalize,
  id,
  color,
  onKeyDown,
  autoComplete,
  maxLength,
  referance,
  showPasswordIcon,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl fullWidth size="small" sx={sx}>
      <Controller
        render={({ field }) => (
          <TextField
            id={id}
            className="h-[36px] text-[14px] bg-white"
            inputRef={inputRef}
            ref={referance}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            inputProps={{
              ...inputProps,
              maxLength,
              style: {
                textTransform: "capitalize",
                fontSize: "14px",
                height: "18.5px",
              },
            }}
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "14px",
              },
            }}
            onWheel={(e) => {
              if (type == "number") {
                e.target.blur();
              }
            }}
            type={showPassword ? "text" : type}
            disabled={disabled}
            error={!!error?.message}
            color={color}
            variant={variant}
            label={label}
            placeholder={label}
            name={name}
            fullWidth
            {...field}
            size="small"
            InputProps={
              showPasswordIcon
                ? {
                    endAdornment: (
                      <PasswordInput
                        showPassword={showPassword}
                        handleClick={handleClickShowPassword}
                      />
                    ),
                  }
                : undefined
            }
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {/* <FormHelperText style={{ color: "#d32f2f" }}>
        {error?.message}
      </FormHelperText> */}
    </FormControl>
  );
};

export default InputField;

