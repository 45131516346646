import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

//orderOrderView/getDIPatientDashboardList

export const getDIPatientDashboardList = (sortBy) => {
  return apiClient.get(`orderOrderView/getDIPatientDashboardList/${sortBy}`, {
    headers: authHeader(),
  });
};

export const getDiDashboardVitals = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardVitals/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardLabParameters = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardLabParameters/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardLines = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardLines/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardScores = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardScores/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardVentilator = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardVentilator/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardIntakeOutput = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardIntakeOutput/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardDiet = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardDiet/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardTreatmentAndInfusion = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardTreatmentAndInfusion/${admId}`, {
    headers: authHeader(),
  });
};
export const getDiDashboardToDoList = (admId) => {
  return apiClient.get(`dashboard/getDiDashboardToDoList/${admId}`, {
    headers: authHeader(),
  });
};

