import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import { ForceCloseIcon } from "../../../assets/CommonAssets";
// import { getComplaintsChips } from "../../services/EMRServices/emrServices";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "40%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "scroll",
  p: 1,
};

export default function ComplaintsModal(props) {
  const {
    open,
    close,
    complaints,
    setComplaints,
    selectedComplaints,
    setSelectedComplaints,
    resetComplaints,
  } = props;

  const defaultValues = {
    duration: "",
    searchComplaints: null,
  };

  const { watch, control, setValue } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  let complaintWatch = watch("searchComplaints");
  const [complaintsOptions, setComplaintsOptions] = React.useState([]);

  let combinedComplaints = [...selectedComplaints, ...complaints];
  combinedComplaints = combinedComplaints.filter(
    (item) => item.label && item.isChecked
  );

  const uniqueArray = Array.from(
    new Map(combinedComplaints.map((item) => [item.label, item])).values()
  ).sort((a, b) => {
    if (a.value === null) return 1;
    if (b.value === null) return -1;
    return a.value - b.value;
  });

  const handleSelectComplaint = (complaintIndex) => {
    let dataArray = [...complaints];
    dataArray[complaintIndex].isChecked = !dataArray[complaintIndex].isChecked;
    setComplaints(dataArray);
  };

  const handleAddDuration = (duration, complaintIndex) => {
    let dataArray = [...uniqueArray];
    dataArray[complaintIndex].duration = duration;
    setComplaints(dataArray);
  };

  const handleAddComplaints = () => {
    let dataArray = [...uniqueArray];
    let filtered = dataArray.filter((item) => item.isChecked === true);
    setSelectedComplaints(filtered);
    close();
  };

  React.useEffect(() => {
    let dataArray = [...uniqueArray];
    let filtered = dataArray.filter((item) => item.isChecked === true);
    if (filtered?.length > 0) {
      setSelectedComplaints(filtered);
    }
  }, [resetComplaints]);

  React.useEffect(() => {
    // getComplaintsChips()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setComplaintsOptions(res.result);
    //   });
  }, []);

  React.useEffect(() => {
    let filterdComplaints = uniqueArray.filter(
      (list) => list.label === complaintWatch?.label
    );
    if (
      complaintWatch !== null &&
      filterdComplaints?.length > 0 &&
      complaintWatch !== undefined
    ) {
      errorAlert("Complaint Already Exist!");
      setValue("searchComplaints", null);
    } else {
      if (complaintWatch !== null && complaintWatch !== undefined) {
        let complaintObject = {
          id: complaintWatch?.label,
          label: complaintWatch?.label,
          value: complaintWatch?.value,
          since: "",
          duration: "",
          isChecked: true,
        };
        let dataArray = [...uniqueArray];

        dataArray.push(complaintObject);
        setComplaints(dataArray);
        setValue("searchComplaints", null);
      } else {
        let dataArray = [...uniqueArray];
        for (let object of dataArray) {
          if (object?.label === complaintWatch?.label) {
            object.isChecked = true;
          }
          setComplaints(dataArray);
          setValue("searchComplaints", null);
        }
      }
    }
  }, [complaintWatch]);

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div>
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Complaints
                </div>
              </div>
              <button
                onClick={() => {
                  close();
                }}
                type="button"
              >
                <ForceCloseIcon />
              </button>
            </div>
            <div className="my-1">
              <CreateableSelect
                control={control}
                name="searchComplaints"
                placeholder="Chief Complaints"
                showSearch={true}
                isClearable={true}
                dataArray={complaintsOptions}
              />
            </div>
            <div className="border-t border-slate-400 p-2"></div>
            <div className="mb-2 h-[500px] overflow-y-scroll">
              {uniqueArray?.length > 0
                ? uniqueArray.map((complaint, complaintIndex) => {
                    return (
                      <div
                        className={`py-1 border  border-gray-200 flex gap-2 items-center ${
                          complaint.isChecked === true ? `bg-green-300` : ``
                        }`}
                      >
                        <div
                          className="px-2 font-semibold w-[60%] "
                          onClick={() => {
                            handleSelectComplaint(complaintIndex);
                          }}
                        >
                          {complaint?.label}
                        </div>
                        <div className="flex gap-2 items-center">
                          <input
                            name={`duration${complaint.id}`}
                            style={{ outline: "none" }}
                            placeholder="Since"
                            className="text-center w-20 border border-gray-400 rounded"
                            defaultValue={complaint?.since}
                            disabled={
                              complaint.isChecked === true ? false : true
                            }
                            onChange={(e) => {
                              if (Number(e.target.value) > 0) {
                                let dataArray = [...complaints];
                                dataArray[complaintIndex].since =
                                  e.target.value;
                                dataArray[complaintIndex].duration = "D";
                                setComplaints(dataArray);
                              } else {
                                let dataArray = [...complaints];
                                dataArray[complaintIndex].since = "";
                                dataArray[complaintIndex].duration = "";
                                setComplaints(dataArray);
                              }
                            }}
                          />
                          <div
                            onClick={() => {
                              handleAddDuration("D", complaintIndex);
                            }}
                            className={`h-6 w-6 rounded-full ${
                              complaint.duration === "D" &&
                              complaint.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            D
                          </div>
                          <div
                            onClick={() => {
                              handleAddDuration("M", complaintIndex);
                            }}
                            className={`h-6 w-6 rounded-full ${
                              complaint.duration === "M" &&
                              complaint.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            M
                          </div>
                          <div
                            onClick={() => {
                              handleAddDuration("Y", complaintIndex);
                            }}
                            className={`h-6 w-6 rounded-full ${
                              complaint.duration === "Y" &&
                              complaint.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            Y
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="flex justify-end my-2 px-2">
              <CommonButton
                label={"Add"}
                type={"button"}
                className={"px-3 bg-[#368505] text-white h-9 rounded"}
                onClick={() => {
                  handleAddComplaints();
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
