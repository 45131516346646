import SearchIcon from "@mui/icons-material/Search";
import { FormControl } from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { components, createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
const CustomCreateable = ({
  className,
  isDisabled,
  placeholdernotVisible,
  isMulti,
  inputRef,
  control,
  error,
  dataArray,
  name,
  handleInputChange,
  placeholder,
  isClearable,
  defaultValue,
  searchIcon,
  isSearchable,
  menuPlacement,
  menuShouldBlockScroll,
  ref
}) => 
  {
  const [fullwidth, setFullWidth] = useState(0);
  const [width, setWidth] = React.useState(0);
  // dummyChanges
  if (menuPlacement) {
  } else {
    menuPlacement = "auto";
  }
  if (menuShouldBlockScroll !== true) {
    menuShouldBlockScroll = false;
  }
  // useLayoutEffect(() => {
  //   if (isClearable == true) {
  //     let inPx = `${ref.current.offsetWidth - 96}px`;
  //     setWidth(inPx);
  //   } else {
  //     let inPx = `${ref.current.offsetWidth - 64}px`;
  //     setWidth(inPx);
  //   }
  //   // setWidth(ref.current.offsetWidth - 100);
  // }, []);

  if (isSearchable !== true) {
    isSearchable = false;
  }
  let isError = !!error?.message;
  let bgColor = "rgba(255, 255, 255, 1)";

  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      position: "absolute",
      textAlign: "left",
      boxShadow: "0 20px 54px 0 rgba(0,0,0,0.2)",
      zIndex: 50,
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "16px",
      minWidth: "100%",
      width: "100%",
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => ({
      ...provided,
      whiteSpace: "nowrap",
      justifyContent: "start",
      text: "start",
      overflow: "visible",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "rgba(222,235,255,1)"
        : isFocused
        ? "rgba(222,235,255,0.5)"
        : undefined,

      color: isDisabled
        ? undefined
        : isSelected
        ? "#000000"
        : isFocused
        ? "#000000"
        : undefined,
    }),
    control: (Colstyles, state) => ({
      ...Colstyles,
      borderRadius: "5px",
      minHeight: "20px",
      maxHeight: "25px",
      borderColor: "#0B83A5",
      textOverflow: "ellipsis",
      display: "flex",
      flexWrap: isMulti ? "wrap" : "nowrap",
      border: isError
        ? state.isSelected
          ? "1px solid #0B83A5"
          : state.isFocused
          ? "1px solid #0B83A5"
          : state.hasValue || state.selectProps.inputValue
          ? "1px solid #d32f2f"
          : "1px solid #d32f2f"
        : state.hasValue || state.selectProps.inputValue
        ? ""
        : "",
    }),
    singleValue: (Singstyles) => ({
      ...Singstyles,
      // border:'2px solid black',
      display: "flex",
      // width:'2px',
      fontSize: "14px",
      // maxWidth: "270px",
      textOverflow: "clipped",
    }),
    indicatorSeparator: (styles) => ({ display: "none", paddingX: "2px" }),

    valueContainer: (provided, state) => ({
      ...provided,
      position: "relative",
      fontSize: "12px",
      width: width,
      whiteSpace: "nowrap",
      overflow: "visible",
      padding: "0px",
      display: "flex",
      flexWrap: isMulti ? "wrap" : "nowrap",
      maxHeight: "fit-content",
      textOverflow: "clipped",
      paddingLeft: state.hasValue || state.selectProps.inputValue ? 3 : 2,
      fontStyle: "normal",
      justifyContent: "start",
    }),
    input: (provided, state) => ({
      ...provided,
      // width:'2px',
      // maxWidth: "fit-content",
      // maxHeight: 'fit-content',
      // textOverflow:'ellipsis',
      // display:'flex',
      overflow: "clip",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      display: placeholdernotVisible
        ? state.menuIsOpen ||
          state.selectProps.menuIsOpen ||
          state.hasValue ||
          state.selectProps.inputValue
          ? "none"
          : "block"
        : "block",

      position: "absolute",
      borderRadius: "2px",
      color:
        state.menuIsOpen || state.selectProps.menuIsOpen
          ? isDisabled
            ? "#575554"
            : "#1976D2"
          : isError
          ? "#d32f2f"
          : state.hasValue
          ? "#2019ce"
          : "#575554",

      fontSize:
        (state.menuIsOpen ||
          state.selectProps.menuIsOpen ||
          state.hasValue ||
          state.selectProps.inputValue) &&
        "13px",
      transition: "top 0.1s, font-size 0.1s",

      top:
        state.menuIsOpen ||
        state.selectProps.menuIsOpen ||
        state.hasValue ||
        state.selectProps.inputValue
          ? isSearchable
            ? -12
            : -17
          : isSearchable
          ? 4
          : "9%",

      paddingLeft:
        state.menuIsOpen ||
        state.selectProps.menuIsOpen ||
        state.hasValue ||
        state.selectProps.inputValue
          ? 5
          : "9px",

      paddingRight:
        state.menuIsOpen ||
        state.selectProps.menuIsOpen ||
        state.hasValue ||
        state.selectProps.inputValue
          ? 4
          : "",

      marginBottom:
        state.menuIsOpen ||
        state.selectProps.menuIsOpen ||
        state.hasValue ||
        state.selectProps.inputValue
          ? 2
          : "",

      backgroundColor:
        state.menuIsOpen ||
        state.selectProps.menuIsOpen ||
        state.hasValue ||
        state.selectProps.inputValue
          ? isDisabled
            ? "#f0f0f0"
            : bgColor
          : "",

      zIndex:
        state.menuIsOpen ||
        state.selectProps.menuIsOpen ||
        state.hasValue ||
        state.selectProps.inputValue
          ? 10
          : "",

      fontStyle: "normal",
    }),
  };
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    if (name == "maritalStatus") {
      // console.log(
      //   "working selectProps.inputValue",
      //   props.selectProps.inputValue
      // );
      // console.log("working length", props.hasValue);
    }
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <div className=" w-full" ref={ref}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <CreatableSelect
            // className={
            //   className +
            //   "text-sm w-[120px] border-customBlue lg:text-base text-gray-600"
            // }
            placeholder={placeholder}
            isDisabled={isDisabled}
            inputRef={inputRef}
            // autoFocus={true}
            {...field}
            ref={ref}
            isMulti={isMulti}
            closeMenuOnSelect={isMulti ? false : true}
            options={dataArray}
            isClearable={isClearable}
            clearValue={true}
            filterOption={createFilter({
              matchFrom: "start",
            })}
            isSearchable={isSearchable}
            defaultValue={defaultValue}
            openMenuOnClick={true}
            styles={selectStyles}
            blurInputOnSelect={true}
            menuPlacement={menuPlacement}
            menuShouldBlockScroll={menuShouldBlockScroll}
            // onSelectResetsInput={false} onBlurResetsInput={false}
            onInputChange={handleInputChange}
            components={{
              DropdownIndicator: () => (
                <SearchIcon fontSize="small" className="mr-1 text-slate-500" />
              ),
              IndicatorSeparator: () => null,
              // Placeholder : CustomPlaceholder
            }}
          />
        )}
      />
    </div>
  );
};

export default CustomCreateable;
