import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
// import MuiSelect from "../../Common Components/FormFields/MuiSelect";

const AntibioticsTableNew = (props) => {
  const [sensitivityOptions, setSensitivityOptions] = React.useState([
    {
      value: "Sensitive",
      label: "S",
    },
    {
      value: "Resistant",
      label: "R",
    },
    {
      value: "Moderate",
      label: "M",
    },
  ]);
  const [antibioticArray, setAntibioticArray] = useState([]);

  let getReportId = props.selectedTestId && props.selectedTestId.ReportId;

  const {
    rows,
    onSubmit,
    organismId,
    authObj,
    setAuthObj,
    reportEntryDetails,
    antibioticsData,
    machineParameterValues,
  } = props;

  // useEffect(() => {}, [organismId, rows]);

  const defaultValues = {
    antiBioTicName: "",
    result: "",
    value: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (antibioticsData !== undefined && antibioticsData?.length > 0) {
      let tempAnti = [];
      for (let io = 0; io < antibioticsData?.length; io++) {
        let finalObj = {};
        finalObj.organismId = antibioticsData[io]?.organism?.id;
        tempAnti.push(finalObj);

        let antibioticList = antibioticsData[io]?.antiBioTicsList;
        let antibioticDetailsArr = [];
        for (let ia = 0; ia < antibioticList?.length; ia++) {
          let requiredObj = {};
          if (
            antibioticList[ia]?.value !== "" &&
            antibioticList[ia]?.value !== undefined
          ) {
            requiredObj.antibioticId = antibioticList[ia]?.antiBioticId;
            requiredObj.firstLevelResult = antibioticList[ia]?.value;
            requiredObj.micValue = antibioticList[ia]?.result;
            requiredObj.pathTestSensivityNormalDetailsId =
              antibioticList[ia]?.pathTestSensivityNormalDetailId;
            antibioticDetailsArr.push(requiredObj);
          }
          // finalObj.organismId = antibioticsData[io]?.organism?.id;
          finalObj.pathTestSensitivityNormalId =
            antibioticsData[io]?.pathTestSensivityNormalId;
          finalObj.pathTestSensitivityNormalDetailsDto = antibioticDetailsArr;
          finalObj.reportId = getReportId;
        }
        tempAnti.push(finalObj);
      }
      setAntibioticArray(tempAnti);
      props.setFinalArr(tempAnti);
    }
  }, [antibioticsData, machineParameterValues]);
  console.log("antibioticsData321", antibioticsData);

  const handleValueChange = (e, row, organismId) => {
    console.log("eeeeeeeee321", e);

    let finalObj = {};
    finalObj.organismId = organismId;
    let antibioticDetailsArr = [];
    let requiredObj = {};
    requiredObj.antibioticId = row.antiBioticId;
    requiredObj.firstLevelResult = e !== null ? e?.value : "";
    requiredObj.micValue = e !== null ? row.result : "";
    requiredObj.pathTestSensitivityNormalDetailsId =
      row?.pathTestSensivityNormalDetailId;
    antibioticDetailsArr.push(requiredObj);
    console.log("antibioticDetailsArr", antibioticDetailsArr);

    finalObj.pathTestSensitivityNormalDetailsDto = antibioticDetailsArr;
    finalObj.reportId = getReportId;
    let defaultId = antibioticArray?.find(
      (val) => val?.organismId === row?.organismId
    );
    finalObj.pathTestSensitivityNormalId =
      defaultId?.pathTestSensitivityNormalId;

    // props.setRequiredArr(antibioticDetailsArr);
    let temp = [...props.finalArr];
    console.log("temptemp321", temp);
    temp.push(finalObj);
    //

    props.setFinalArr([...antibioticArray, ...temp]);

    //
  };

  const handleResultValueChange = (e, row, organismId) => {
    console.log("resultValue123", e.target.value);

    let finalObj = {};
    finalObj.organismId = organismId;
    let antibioticDetailsArr = [];
    let requiredObj = {};
    requiredObj.antibioticId = row.antiBioticId;
    requiredObj.firstLevelResult = row.value;
    requiredObj.micValue = e.target.value;
    requiredObj.pathTestSensitivityNormalDetailsId =
      row?.pathTestSensivityNormalDetailId;
    antibioticDetailsArr.push(requiredObj);
    console.log("antibioticDetailsArr963", antibioticDetailsArr);

    finalObj.pathTestSensitivityNormalDetailsDto = antibioticDetailsArr;
    finalObj.reportId = getReportId;
    let defaultId = antibioticArray?.find(
      (val) => val?.organismId === row?.organismId
    );
    finalObj.pathTestSensitivityNormalId =
      defaultId?.pathTestSensitivityNormalId;

    // props.setRequiredArr(antibioticDetailsArr);
    let temp = [...props.finalArr];
    temp.push(finalObj);
    console.log("temptemp321", temp);

    //
    props.setFinalArr([...antibioticArray, ...temp]);

    //
  };

  return (
    <div className="flex flex-col">
      {rows?.length > 0 && rows && (
        <fieldset
          disabled={
            reportEntryDetails?.authenticationdetails?.length > 0
              ? reportEntryDetails?.authorizationLevel &&
                reportEntryDetails?.authorizationLevel === authObj.authLevel
                ? false
                : true
              : false
          }
        >
          <form>
            <TableContainer component={Paper} elevation={2}>
              <Table
                sx={{ height: "100%" }}
                aria-label="simple table"
                size="small"
              >
                <TableHead sx={{ backgroundColor: "lightgrey" }}>
                  <TableRow>
                    <TableCell>Antibiotics</TableCell>
                    <TableCell>Sensitivity</TableCell>
                    <TableCell>Values</TableCell>
                  </TableRow>
                </TableHead>
                {rows?.map((row, index) => {
                  setValue(
                    `${props.item?.organism?.id}value${index}`,
                    row.value === "Sensitive"
                      ? {
                          value: "Sensitive",
                          label: "S",
                        }
                      : row.value === "Resistant"
                      ? {
                          value: "Resistant",
                          label: "R",
                        }
                      : row.value === "Moderate"
                      ? {
                          value: "Moderate",
                          label: "M",
                        }
                      : null
                  );
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>
                        <div key={index} className="my-2 w-40">
                          <span>{row.antiBioTicName}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {/* <FormControl fullWidth size="small" className="">
                          <InputLabel id="demo-simple-select-label">
                            Value
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            //  value={age}
                            defaultValue={
                              row?.value
                              // row.value === "Sensitive" && {
                              //   value: "Sensitive",
                              //   label: "S",
                              // }
                            }
                            label="Value"
                            onChange={(e) => {
                              console.log("onChangeValue", e.target.value);

                              row.value = e.target.value;
                              handleValueChange(
                                e,
                                row,
                                props.item?.organism?.id
                              );
                            }}
                            IconComponent={() => (
                              <>
                                {row?.firstLevelResult !== null &&
                                row?.firstLevelResult !== "" ? (
                                  <IconButton
                                    onClick={() => {
                                      rows[index].firstLevelResult = "";
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton>
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                          >
                            {sensitivityOptions.map((item) => {
                              return (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl> */}
                        <DropdownField
                          control={control}
                          name={`${props.item?.organism?.id}value${index}`}
                          placeholder="Value"
                          isClearable={true}
                          dataArray={sensitivityOptions}
                          inputRef={{
                            ...register(
                              `${props.item?.organism?.id}value${index}`,
                              {
                                onChange: (e) => {
                                  console.log("e.target.value", e);

                                  if (e.target?.value !== null) {
                                    handleValueChange(
                                      e.target.value,
                                      row,
                                      props.item?.organism?.id
                                    );
                                    rows[index].value = e.target.value?.value;
                                  } else {
                                    handleValueChange(
                                      null,
                                      row,
                                      props.item?.organism?.id
                                    );
                                    rows[index].value = "";
                                    rows[index].result = "";
                                  }
                                },
                              }
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className="w-28"
                          size="small"
                          placeholder="Result"
                          label="Result"
                          value={row?.result ? row?.result : ""}
                          onChange={(e) => {
                            handleResultValueChange(
                              e,
                              row,
                              props.item?.organism?.id
                            );
                            row.result = e.target.value;
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </TableContainer>
          </form>
        </fieldset>
      )}
    </div>
  );
};

export default AntibioticsTableNew;
