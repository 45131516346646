import { Checkbox } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";

const tableData = [
  {
    administrative: [
      {
        administrative:
          "1.  Discharge Summary / Death Summary and Photocopy of PMC Form 4A",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        administrative: "2. IPD File Checklist & Patient Report Handover Form",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        administrative: "3. Admission Consent Form ",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        administrative: "4. Admission Form/Admission Checklist",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        administrative: "5. ID Proof of Patient/Relatives",
        nursing: "",
        MRD: "",
        noOfDocumentsAttached: "",
      },
      {
        administrative: "6. Registration Form",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        administrative:
          "7. Photocopy of C-Form (NRI Patient Case) (Attached from MRD) ",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
    ],
  },
  {
    patientCare: [
      {
        patientCare: "1.  TPR/BP Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "2. I/O Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "3. BSL Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "4.GCS Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "5. Investigation Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },

      {
        patientCare: "6. Doctor’s Reference/Prescription",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "7. ETU Case Sheet/Patient Transfer Form",
        nursing: "",
        MRD: "",
        noOfDocumentsAttached: "",
      },

      {
        patientCare: "8. Neonatal Case Record",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare:
          "9. Admission History & Physical Assessment/Gynaec Case Sheet",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "10. Care Plan ",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "11. Order Sheet",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "12. Drug Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "13. ICU/CCU Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "14. PICU/NICU Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "15. Blood Transfusion Sheet",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        patientCare: "16. Antibiotics Form",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
    ],
  },
  {
    oTConsentsDocuments: [
      {
        oTConsentsDocuments: "1. Physician fitness",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments: "2. All Consent Forms",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments:
          "3. MTP Consent/Form C/Form I/ID Proof of patient/USG Report (in MTP Case)",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments: "4. Pre Op checklist",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments: "5. Anesthesia Records (PAC)",
        nursing: "",
        MRD: "",
        noOfDocumentsAttached: "",
      },
      {
        oTConsentsDocuments: "6. OT Note",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments: "7. Post operative Record",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments: "8. Swab & Sponge Record",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments: "9. Surgical Safety Checklist",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        oTConsentsDocuments: "10. OT Layout",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
    ],
  },
  {
    supportiveCare: [
      {
        supportiveCare: "1. Nutritional Assessment Note",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare: "2. Physiotherapy Note",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare: "3.  Initial Nursing Assessment",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare: "4. Video/Verbal Counseling Form",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare: "5. Position Chart/DP-PT Pulses Chart",
        nursing: "",
        MRD: "",
        noOfDocumentsAttached: "",
      },
      {
        supportiveCare: "6. Narcotic Form/Fluvir Form/Pharmacy Bill",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare:
          "7.  Any other Documents (Please specify Document Name) ",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare: "8.  Outside Medication Form",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare: "9.  Photocopy of ECG/ABG/NST/MRI/CT/Covid-19 Report",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      // {
      //   supportiveCare: "10.  IPD Final Bill/Pharmacy Clearance",
      //   nursing: "",
      //   MRD: "",
      //   noOfDocumentsAttach: "",
      // },
      {
        supportiveCare:
          "10.  Pharmacy Clearance/OT Billing Sheet/Pink Billing Sheet",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
      {
        supportiveCare:
          "11.  IPD Credit Return Voucher/Undertaking Form/Bed Category Change Form/ Admission Kit & Checklist/IPD Bill",
        nursing: "",
        MRD: "",
        noOfDocumentsAttach: "",
      },
    ],
  },
];

function IpdFileCheckListPrint(props) {
  const { ipdCheckListData } = props;
  const printRef = useRef(null);
  const [hospitalInfo, setHospitalInfo] = useState({
    name: "Inamdar Multispeciality Hospital",
    address: "Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.",
    phone: "Phone No : 020-66812222/44",
  });

  useEffect(() => {
    printRef && GeneratePrint(printRef);
  }, [printRef]);

  return (
    <div ref={printRef} className="">
      <table className="w-full font-bold ">
        <thead
          className=" w-full  "
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          {/*}
          <div className="grid grid-cols-3 items-center justify-center">
            <div className="flex justify-end col-span-2">
              <div className="items-center justify-center text-center">
                <div className="text-[13px] font-bold">{hospitalInfo.name}</div>
                <div className="text-[12px] font-semibold">
                  {hospitalInfo.address}
                </div>
                <div className="text-[12px] font-semibold">
                  {hospitalInfo.phone}
                </div>
              </div>
            </div>  
          </div>
        */}
          <div className="flex  -mt-12 -mb-5 items-center w-full">
            <h1 className="font-semibold border-black flex justify-end ml-28 text-end text-[16px] w-full">
              IPD FILE CHECKLIST
            </h1>
            <div className="flex justify-end w-full ">
              <InamdarHospiLogo />
            </div>
          </div>
          <div class="grid gap-2 border-t-[1px] border-b-[1px] border-black text-[12px]">
            <div className="flex space-x-5">
              <div className="font-semibold">Patient Name&nbsp;</div>
              <div>:&nbsp;{ipdCheckListData?.PatientInfo?.PatientName}</div>
            </div>
            <div className="flex space-x-[55px]">
              <div className="font-semibold">MR No.</div>
              <div>&nbsp;:&nbsp;{ipdCheckListData?.PatientInfo?.MRNo}</div>
            </div>
            <div className="flex space-x-2">
              <div className="font-semibold ">Admission Date</div>
              <div>:&nbsp;{ipdCheckListData?.PatientInfo?.AdmissionDate}</div>
            </div>
          </div>
        </thead>
        <tbody>
          <div className="grid grid-cols-[59%_15.5%_13%_10%] mt-1 text-[11px] font-semibold">
            <div className="underline font-bold text-lg">I. Administrative</div>
            <div>Nursing</div>
            <div>MRD</div>
            <div className="">No.of Doc Attached</div>
          </div>
          {tableData.map((item) => {
            return item?.administrative?.map((rowData) => (
              <div className="grid grid-cols-[60%_15%_15%_15%] py-0.5 2xl:py-0 text-[12px] `">
                <div>{rowData.administrative}</div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.nursing}
                  />
                </div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.MRD}
                  />
                </div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.noOfDocumentsAttached}
                  />
                </div>
              </div>
            ));
          })}
          <div className="font-bold text-lg underline ">II. Patient Care</div>
          {tableData.map((item) => {
            return item?.patientCare?.map((rowData) => (
              <div className="grid grid-cols-[60%_15%_15%_15%] py-1.5 2xl:py-0 text-[12px] ">
                <div>{rowData.patientCare}</div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.nursing}
                  />
                </div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.MRD}
                  />
                </div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.noOfDocumentsAttached}
                  />
                </div>
              </div>
            ));
          })}

          <div className="grid grid-cols-[59%_15.5%_13%_10%] text-[12px] font-semibold print:break-after-all 2xl:mt-2">
            <div className="underline font-bold text-lg">
              III. OT Consents Documents
            </div>
            <div>Nursing</div>
            <div>MRD</div>
            <h5 className="">No.of Doc Attached</h5>
          </div>
          {tableData.map((item) => {
            return item?.oTConsentsDocuments?.map((rowData) => (
              <div className="grid grid-cols-[60%_15%_15%_15%] py-0.5 2xl:py-2 text-[12px] ">
                <div>{rowData.oTConsentsDocuments}</div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.nursing}
                  />
                </div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.MRD}
                  />
                </div>
                <div>
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={rowData?.noOfDocumentsAttached}
                  />
                </div>
              </div>
            ));
          })}
          <div className="font-bold text-lg underline">IV. Supportive Care</div>
          {tableData.map((item) => {
            return item?.supportiveCare?.map((rowData, index) => (
              <div className="grid grid-cols-[60%_15%_15%_15%] py-0.5 2xl:py-2 items-center text-[12px]">
                <div
                  key={rowData?.id}
                  className={` ${
                    index % 7 === 6
                      ? "my-1 border-b-[1px] border-black pb-4 2xl:pb-8"
                      : ""
                  }`}
                >
                  <h3
                    className={`${
                      index % 8 === 7
                        ? "my-2 border-t-[1px] pt-4 2xl:pt-8  border-black"
                        : ""
                    }`}
                  >
                    {rowData.supportiveCare}
                  </h3>
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    sx={{ padding: 0, margin: 0 }}
                    className={` ${
                      index % 9 === 8 ? "mt-5 2xl:mt-8 border-black" : ""
                    }`}
                    checked={rowData.nursing}
                  />
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    sx={{ padding: 0, margin: 0 }}
                    className={` ${
                      index % 9 === 8 ? "mt-5 2xl:mt-8 border-black" : ""
                    }`}
                    checked={rowData.MRD}
                  />
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    sx={{ padding: 0, margin: 0 }}
                    className={`pt-[10px] ${
                      index % 9 === 8 ? "mt-5 2xl:mt-8 border-black" : ""
                    }`}
                    checked={rowData.noOfDocumentsAttached}
                  />
                </div>
              </div>
            ));
          })}
          <div className="font-semibold text-[12px]  pr-7 px-4 flex justify-between mt-10 2xl:mt-10 2xl:my-5">
            <h5>Nursing Staff Signature</h5>
            <h5 className="text-right">MRD Staff Signature</h5>
          </div>
          <br />
          <div className="font-bold text-lg border-black border-b-[1px] text-center text-[14px] mt-2">
            Pathology
          </div>
          <div className="grid grid-cols-[34%_30%_10%_17%_10%] border-black border-b-[1px]  py-2 text-[12px]  font-semibold">
            <div>ReportDate</div>
            <div>TestName</div>
            <div>Authorised</div>
            <div>Handed Over</div>
            <div>MRD</div>
          </div>
          {ipdCheckListData?.PathTestList?.map((data) => {
            return (
              <>
                {data.TestName !== null && (
                  <>
                    <div className="grid w-full text-[11px] grid-cols-[25%_40%_10%_15%_10%] items-center gap-1 py-1">
                      <div>
                        {data.ReportDate === null ? "" : data.ReportDate}
                      </div>
                      <div>{data.TestName}</div>
                      <div>
                        <Checkbox
                          type="checkbox"
                          sx={{ padding: 0, margin: 0 }}
                          className=""
                          checked={data.Status}
                        />
                      </div>
                      <div>
                        <Checkbox
                          sx={{ padding: 0, margin: 0 }}
                          type="checkbox"
                          className=""
                        />
                      </div>
                      <div>
                        <Checkbox
                          sx={{ padding: 0, margin: 0 }}
                          type="checkbox"
                          className=""
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}
          <br />

          <div className="font-bold text-lg  border-black border-t-[1px] border-b-[1px] text-center text-[14px]">
            Radiology
          </div>
          <div className="grid grid-cols-[34%_30%_10%_17%_10%]  border-black border-b-[1px] py-1 text-[12px]  font-semibold">
            <div>ReportDate</div>
            <div>TestName</div>
            <div>Authorised</div>
            <div>Handed Over</div>
            <div>MRD</div>
          </div>
          {ipdCheckListData?.RadiologyTestList.map((data) => {
            return (
              <>
                {data.TestName !== null && (
                  <>
                    <div className="grid w-full text-[11px] grid-cols-[25%_40%_10%_15%_10%] items-center gap-1 py-1   ">
                      <div>{data?.ReportDate}</div>
                      <div>{data?.TestName}</div>
                      <div>
                        <Checkbox
                          type="checkbox"
                          sx={{ padding: 0, margin: 0 }}
                          className=""
                          checked={data?.Status}
                        />
                      </div>
                      <div>
                        <Checkbox
                          type="checkbox"
                          sx={{ padding: 0, margin: 0 }}
                          className=""
                          checked={data?.handedOver}
                        />
                      </div>
                      <div>
                        <Checkbox
                          sx={{ padding: 0, margin: 0 }}
                          type="checkbox"
                          className=""
                          checked={data?.mrd}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}

          <div className="grid grid-cols-2  font-semibold text-[12px] mt-5 mx-6">
            <div className="flex flex-col justify-between space-y-3  w-full">
              <div className="flex items-center space-x-3 py-1">
                <div>Total Reports Pending:_______________</div>
              </div>
              <div className="flex items-center space-x-4">
                <div>Discharge Summary</div>
                <div>
                  <Checkbox sx={{ padding: 0, margin: 0 }} type="checkbox" />
                </div>
              </div>
              <div className="flex items-center space-x-6">
                <div>Received Visitor ID</div>
                <div>
                  <Checkbox sx={{ padding: 0, margin: 0 }} type="checkbox" />
                </div>
              </div>
              <div className="pt-6">
                <h5>Nursing Staff Signature</h5>
              </div>
            </div>
            <div className="flex flex-col justify-between  ml-auto space-y-3">
              <div className="">Total Reports Handed Over:_______________</div>
              <div className="">Handed Over To:_______________</div>
              <div className="">Telephone No:_______________</div>
              <div className="py-3 pt-5">
                <h5>Relative /Patient Signature</h5>
              </div>
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
}

export default IpdFileCheckListPrint;

