import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";

const CommonButton = ({
  type,
  onClick,
  label,
  className,
  disabled,
  searchIcon,
  referance,
  onKeyDown,
  tooltipTitle,
}) => {
  //
  return (
    <Tooltip title={tooltipTitle}>
      <button
        type={type ? type : "button"}
        onClick={!disabled && onClick}
        disabled={disabled}
        ref={referance}
        onKeyDown={onKeyDown}
        className={
          !disabled
            ? `h-9 px-3 w-max rounded text-base font-medium ${className}`
            : `h-9 px-3 w-max rounded text-base font-medium bg-gray-400  text-white`
        }
      >
        {label}
        {searchIcon && <SearchIcon className="cursor-pointer" />}
      </button>
    </Tooltip>
  );
};

export default CommonButton;

