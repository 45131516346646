import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import { ForceCloseIcon } from "../../../assets/CommonAssets";
// import { getICDCodeSerachBy } from "../../services/EMRServices/emrServices";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";


const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "40%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "scroll",
  p: 1,
};

export default function DiagnosisModal(props) {
  const {
    open,
    close,
    diagnosisList,
    setDiagnosisList,
    selectedDiagnosis,
    setSelectedDiagnosis,
    location,
  } = props;

  const defaultValues = {
    duration: "",
    searchDiagnosis: null,
  };

  const { watch, control, setValue } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  let watchDiagnosis = watch("searchDiagnosis");

  const [diagnosisOptions, setDiagnosisOptions] = React.useState([]);

  const handleSelectDiagnosis = (complaintIndex, value) => {
    let dataArray = [...diagnosisList];
    dataArray[complaintIndex].isChecked = !dataArray[complaintIndex].isChecked;
    setDiagnosisList(dataArray);
  };

  const handleAddDuration = (duration, complaintIndex, value) => {
    let dataArray = [...diagnosisList];

    dataArray[complaintIndex].duration = duration;
    setDiagnosisList(dataArray);
  };

  const combinedArray = [...diagnosisList, ...selectedDiagnosis];
  const uniqueArray = Array.from(
    new Map(combinedArray.map((item) => [item.label, item])).values()
  );

  uniqueArray.sort((a, b) => {
    if (a.value === null) return 1;
    if (b.value === null) return -1;
    return a.value - b.value;
  });

  const handleAddDiagnosis = () => {
    setSelectedDiagnosis(uniqueArray);
    close();
  };

  React.useEffect(() => {
    if (watchDiagnosis !== null && watchDiagnosis !== undefined) {
      let dataArray = [...diagnosisList];
      let diagno = watchDiagnosis;
      let filterdDiagnosis = dataArray.filter(
        (list) => list.value === watchDiagnosis.value
      );
      if (filterdDiagnosis.length > 0) {
        errorAlert("Diagnosis Already Exist!");
      } else {
        diagno.isChecked = true;
        dataArray.push(diagno);
        setDiagnosisList(dataArray);
        setValue("searchDiagnosis",null)
      }
    }
  }, [watchDiagnosis]);

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div>
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Diagnosis
                </div>
              </div>
              <button
                onClick={() => {
                  close();
                }}
                type="button"
              >
                <ForceCloseIcon />
              </button>
            </div>
            <div className="my-1">
              <CreateableSelect
                control={control}
                name="searchDiagnosis"
                placeholder="Diagnosis"
                showSearch={true}
                isClearable={true}
                dataArray={diagnosisOptions}
                // onInputChange={(e) => {
                //   if (UseCheckConsecutiveCharacters(e) !== null) {
                //     getICDCodeSerachBy(
                //       location?.state?.departmentId,
                //       location?.state?.doctorId,
                //       e
                //     )
                //       .then((response) => response.data)
                //       .then((res) => {
                //         if (res.result?.length > 0) {
                //           let tempArr = [...diagnosisOptions];
                //           for (let obj of res.result) {
                //             let tempObj = {
                //               value: obj.value,
                //               icdcode: obj.icdcode,
                //               label: obj.icdcode,
                //               since: "",
                //               isNew: true,
                //               duration: "",
                //             };
                //             tempArr.push(tempObj);
                //           }
                //           setDiagnosisOptions(tempArr);
                //         }
                //       })
                //       .catch((error) => error);
                //   }
                // }}
              />
            </div>
            <div className="border-t border-slate-400 p-2"></div>
            <div className="mb-2 h-[500px] overflow-y-scroll">
              {diagnosisList?.length > 0
                ? diagnosisList.map((diagnosis, diagnosisIndex) => {
                    return (
                      <div
                        className={`py-1 border  border-gray-200 flex gap-2 items-center ${
                          diagnosis.isChecked === true ? `bg-green-300` : ``
                        }`}
                      >
                        <div
                          className="px-2 font-semibold w-[60%] "
                          onClick={() => {
                            handleSelectDiagnosis(
                              diagnosisIndex,
                              diagnosis.value
                            );
                          }}
                        >
                          {diagnosis?.label}
                        </div>
                        <div className="flex gap-2 items-center">
                          <input
                            name={`duration${diagnosis.value}`}
                            style={{ outline: "none" }}
                            placeholder="Since"
                            disabled={
                              diagnosis?.isChecked === false ? true : false
                            }
                            defaultValue={diagnosis.since}
                            className="text-center w-20 border border-gray-400 rounded"
                            onChange={(e) => {
                              if (Number(e.target.value) > 0) {
                                let dataArray = [...diagnosisList];
                                dataArray[diagnosisIndex].since =
                                  e.target.value;
                                dataArray[diagnosisIndex].duration = "D";
                                setDiagnosisList(dataArray);
                              } else {
                                let dataArray = [...diagnosisList];
                                dataArray[diagnosisIndex].since = "";
                                dataArray[diagnosisIndex].duration = "";
                                setDiagnosisList(dataArray);
                              }
                            }}
                          />
                          <div
                            onClick={() => {
                              handleAddDuration(
                                "D",
                                diagnosisIndex,
                                diagnosis.value
                              );
                            }}
                            className={`h-6 w-6 rounded-full ${
                              diagnosis.duration === "D" &&
                              diagnosis.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            D
                          </div>
                          <div
                            onClick={() => {
                              handleAddDuration(
                                "M",
                                diagnosisIndex,
                                diagnosis.value
                              );
                            }}
                            className={`h-6 w-6 rounded-full ${
                              diagnosis.duration === "M" &&
                              diagnosis.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            M
                          </div>
                          <div
                            onClick={() => {
                              handleAddDuration(
                                "Y",
                                diagnosisIndex,
                                diagnosis.value
                              );
                            }}
                            className={`h-6 w-6 rounded-full ${
                              diagnosis.duration === "Y" &&
                              diagnosis.since !== ""
                                ? `bg-[#073763] text-white`
                                : `border border-slate-600`
                            } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
                          >
                            Y
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="flex justify-end my-2 px-2">
              <CommonButton
                label={"Add"}
                type={"button"}
                className={"px-3 bg-[#368505] text-white h-9 rounded"}
                onClick={() => {
                  handleAddDiagnosis();
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
