import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import { addDays, format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import {
  getDrugGenericNameDropdown,
  getDrugInstructionDropdown,
  getDrugList,
  getDrugNameDropdown,
  getOrderedDrugList,
  getRouteDropdown,
  getdrugFormDropdown,
  saveDrugAdminstratorOrder,
} from "../../../ipd/services/drugadministratororderservices/DrugAdministratOrorderServices";
import { getFrequencyForDrugOrder } from "../../services/drugAdministrationChartService/DrugAdministartionChartService";
import { CapitalizeStatement } from "../../../common/components/Custom Hooks/CapitalizeStatement";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateableSelectAutoCapitalize from "../../../common/components/FormFields/CreateableSelectAutoCapitalize";

//style for model
export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "85%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

function DrugAdministratorOrderModal(props) {
  const Actions = [
    {
      id: 0,
      action: "clear All",
      isAction: false,
    },
    { id: 1, action: "add", isAction: false },
  ];
  const actions = ["assessmentPrint"];

  const schema = yup.object().shape({
    drugName: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    // form: yup
    //   .object()
    //   .nullable()
    //   .required("Required")
    //   .shape({
    //     value: yup.string().required("Required"),
    //     label: yup.string().required("Required"),
    //   }),
    dosage: yup.string().required("Required"),
    // .matches(/^[+1-9]+[0-9]*$/, "Only numbers are allowed"),
    frequency: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    durationInDays: yup
      .string()
      .required("Required")
      .matches(/^[+1-9]+[0-9]*$/, "Only numbers are allowed"),
    route: yup
      .object()
      .nullable()
      .required("Required")
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      }),
    hours: yup.string().when("perHourDrug", (perHourDrug) => {
      if (perHourDrug === true) {
        return yup.string().required();
      } else if (perHourDrug === false) {
        return yup.string().notRequired();
      }
    }),
  });

  const defaultValues = {
    search: "",
    drugName: null,
    genericName: null,
    form: null,
    dosage: "",
    onceInWeekDrug: false,
    alternativeDayDrug: false,
    perHourDrug: false,
    frequency: null,
    durationInDays: "",
    quantity: "",
    route: null,
    isOutSideMedication: false,
    instructions: null,
    fromDateFilter: new Date(),
    toDateFilter: new Date(),
    fromDate: new Date(),
    toDate: null,
    durationIn: "Days",
    hours: "",
    isOutsideMedication: false,
    immediate: false,
  };
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    trigger,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  // set focus using input ref
  const dosageValueRef = useRef();
  //local variables
  let freqArray;
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let drugNameVal = watch("drugName");
  let drugGenericNameVal = watch("genericName");
  let dosageVal = watch("dosage");
  let onceInWeekDrugVal = watch("onceInWeekDrug");
  let alternativeDayDrugVal = watch("alternativeDayDrug");
  let perHourDrugVal = watch("perHourDrug");
  let durationInDaysVal = watch("durationInDays");
  let quantityVal = watch("quantity");
  let formVal = watch("form");
  let frequencyVal = watch("frequency");
  let routeVal = watch("route");
  let outsideMedicineVal = watch("isOutsideMedication");
  let instructionValue = watch("instructions");
  let fromDateValue = watch("fromDate");
  let toDateValue = watch("toDate");
  let filterFromDate = watch("fromDateFilter");
  let filterToDate = watch("toDateFilter");
  let hoursValue = watch("hours");
  let durationInValue = watch("durationIn");

  //state variables
  const [orderedDrug, setOrderedDrug] = useState([]);
  const [drugList, setDrugList] = useState([]);
  const [genericName, setGenericName] = useState([]);
  const [formList, setFormList] = useState([]);
  const [routeOptions, setRouteOptions] = useState([]);
  const [drugFrequencyOptions, setDrugFrequencyOptions] = useState([]);
  const [drugNameOptions, setDrugNameOptions] = useState([]);
  const [drugInstructionsOptions, setDrugInstructionsOptions] = useState([]);
  const [orderDrug, setOrderDrug] = useState([]);
  const [finalData, setFinalData] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [privilege, setPrivilege] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [openConfiramtionModal, setOpenConfiramtionModal] = useState(false);

  //get options for Generic name dropdown
  function getGenericNameDropdownList(searchGenericName) {
    if (
      searchGenericName &&
      searchGenericName !== undefined &&
      UseCheckConsecutiveCharacters(searchGenericName)
    ) {
      getDrugGenericNameDropdown(searchGenericName)
        .then((response) => response.data)
        .then((res) => {
          setGenericName(res.result);
        });
    }
  }

  let defaultParams = {
    admissionId: props.rowData?.AdmissionId,
    fromDate: filterFromDate,
    page: page,
    size: rowsPerPage,
    toDate: filterToDate,
  };

  const orderedDrugList = () => {
    if (props.rowData !== null) {
      getOrderedDrugList(defaultParams)
        .then((response) => {
          setCount(response.data.count);
          return response.data;
        })
        .then((res) => {
          setOrderedDrug(res.result);
        });
    }
  };

  //get PrescriptionId id from table and set
  function displayDrugListRowData(row) {
    if (row?.PrescriptionId) {
      drugListing(row.PrescriptionId);
    }
  }

  //drug listing
  function drugListing(prescription) {
    getDrugList(prescription)
      .then((response) => response.data)
      .then((res) => {
        setDrugList(res.result);
      });
  }

  const handleSearchDrugName = (searchString) => {
    if (searchString !== "" && UseCheckConsecutiveCharacters(searchString)) {
      getDrugNameDropdown(CapitalizeStatement(searchString))
        .then((response) => {
          setDrugNameOptions(response.data.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setDrugNameOptions([]);
    }
  };

  // add dugs into table
  function orderDrugs() {
    let isAdminValue = 0;
    if (onceInWeekDrugVal === true) {
      isAdminValue = 1;
    } else if (alternativeDayDrugVal === true) {
      isAdminValue = 2;
    } else if (perHourDrugVal === true) {
      isAdminValue = 3;
    }
    let orderDrugObj = {};
    const fromDt = format(fromDateValue, "yyyy-MM-dd'T'HH:mm:ss");
    if (
      drugNameVal !== null &&
      routeVal !== null &&
      frequencyVal !== null &&
      dosageVal !== "" &&
      durationInDaysVal !== "" &&
      quantityVal !== ""
    ) {
      const toDt = format(toDateValue, "yyyy-MM-dd'T'HH:mm:ss");

      orderDrugObj["Drug Name"] =
        drugNameVal && CapitalizeStatement(drugNameVal.label);
      orderDrugObj.drugId = drugNameVal && drugNameVal.id ? drugNameVal.id : 0;
      orderDrugObj["Drug Generic Name"] =
        drugGenericNameVal !== null
          ? CapitalizeStatement(drugGenericNameVal.label)
          : "";
      orderDrugObj.genericId =
        drugGenericNameVal !== null ? drugGenericNameVal.id : 0;
      orderDrugObj["Form Name"] =
        formVal !== null ? CapitalizeStatement(formVal.label) : "";
      orderDrugObj.formId = formVal === null ? 0 : formVal.id;
      orderDrugObj["Dose"] = dosageVal;
      orderDrugObj.adminAs = isAdminValue;
      orderDrugObj.Frequency =
        frequencyVal && CapitalizeStatement(frequencyVal.label);
      orderDrugObj.frequencyId = frequencyVal && frequencyVal.id;
      orderDrugObj["Duration In Days"] = durationInDaysVal;
      orderDrugObj.Duration = durationInDaysVal;
      orderDrugObj.Quantity = quantityVal;
      orderDrugObj.Route = routeVal && CapitalizeStatement(routeVal.label);
      orderDrugObj.routeId = routeVal && routeVal.Id;
      orderDrugObj["Is Outside Medicine"] = outsideMedicineVal;
      orderDrugObj.Instruction =
        instructionValue && CapitalizeStatement(instructionValue.label);
      orderDrugObj.instructionId = instructionValue && instructionValue.Id;
      orderDrugObj["Start Date"] = fromDt;
      orderDrugObj["Start Time"] = fromDt;
      orderDrugObj["End Date"] = toDt;
      orderDrugObj["End Time"] = toDt;
      orderDrugObj.printDrugName = 0;
      orderDrugObj.pendingQty = 0;
      orderDrugObj.canceledBy = 0;
      orderDrugObj.isNew = true;
      orderDrugObj.isMarkForDrugAdmin = true;
      orderDrugObj.isInstructionNew = true;
      orderDrugObj.isFinalizeForIndent = true;
      orderDrugObj["Once In Week"] = onceInWeekDrugVal;
      orderDrugObj["Alternative Day"] = alternativeDayDrugVal;
      orderDrugObj["Per Hour"] = perHourDrugVal;
      orderDrugObj.Hours = perHourDrugVal === true ? Number(hoursValue) : 0;
      let temp = [...orderDrug];
      temp.push(orderDrugObj);
      setOrderDrug(temp);
      reset(defaultValues);
      setValue("drugName", null);
      setDrugNameOptions([]);
      setTimeout(() => {
        const searchMedicineInput = document.querySelector(".drugName-0 input");
        if (searchMedicineInput) {
          searchMedicineInput.focus();
        }
      }, 100);
    } else {
      trigger();
    }
  }

  function postDrugAdministration(obj) {
    saveDrugAdminstratorOrder(obj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setFinalData(null);
        setOrderDrug([]);
        props.populateAdministrationOrderListTable();
        props.handleClose();
        reset(defaultValues);
        props.setOpenLoader(false);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenConfiramtionModal(false);
        props.setOpenLoader(false);
      });
  }

  function addRecord() {
    setOpenConfiramtionModal(false);
    postDrugAdministration(finalData);
    props.setOpenLoader(true);
  }

  function onSubmitDataHandler(data) {
    let useInfoObj = JSON.parse(localStorage.getItem("userInfo"));
    if (orderDrug.length > 0) {
      let tempArr = [];
      for (let orderDrugObj of orderDrug) {
        let tempObj = {
          drugName: orderDrugObj["Drug Name"],
          drugId: orderDrugObj.drugId,
          drugGenericName: orderDrugObj["Drug Generic Name"],
          genericId: orderDrugObj.genericId,
          formname: orderDrugObj["Form Name"],
          formId: orderDrugObj.formId,
          dose: orderDrugObj.Dose,
          adminAs: orderDrugObj.adminAs,
          frequency: orderDrugObj.Frequency,
          frequencyId: orderDrugObj.frequencyId,
          formId: orderDrugObj.formId,
          durationInDays: orderDrugObj["Duration In Days"],
          duration: orderDrugObj.Duration,
          quantity: orderDrugObj.Quantity,
          route: orderDrugObj.Route,
          routeId: orderDrugObj.routeId,
          isOutsideMedication: orderDrugObj["Is Outside Medicine"],
          instruction: orderDrugObj.Instruction,
          instructionId: orderDrugObj.instructionId,
          startDate: orderDrugObj["Start Date"],
          startTime: orderDrugObj["Start Time"],
          endDate: orderDrugObj["End Date"],
          endTime: orderDrugObj["End Time"],
          printDrugName: orderDrugObj.printDrugName,
          pendingQty: orderDrugObj.pendingQty,
          canceledBy: orderDrugObj.canceledBy,
          isNew: orderDrugObj.isNew,
          isMarkForDrugAdmin: orderDrugObj.isMarkForDrugAdmin,
          isInstructionNew: orderDrugObj.isInstructionNew,
          isFinalizeForIndent: orderDrugObj.isFinalizeForIndent,
          onceInWeek: orderDrugObj["Once In Week"],
          alternativeDay: orderDrugObj["Alternative Drug"],
          isPerHour: orderDrugObj["Per Hour"],
          hours: orderDrugObj.Hours,
        };
        tempArr.push(tempObj);
      }

      let postObj = {
        menuId:
          props.patientInfoClinical.menuId !== undefined
            ? props.patientInfoClinical.menuId
            : props.menuId,
        privilege: "Create",
        addedBy: useInfoObj.userId,
        admissionId: props.rowData?.AdmissionId,
        opdIpd: 1,
        prescriptionDetailsDtoList: tempArr,
      };
      setFinalData(postObj);
      setOpenConfiramtionModal(true);
    } else {
      warningAlert("Please Add Item First");
    }
  }
  const renderButtons = () => {
    return Actions.map((buttons) => (
      <>
        <div className="flex gap-2 justify-end">
          {!buttons.isAction && buttons.action === "clear All" ? (
            <>
              <CommonButton
                type="button"
                className="border border-customRed  text-customRed"
                label="Clear All"
                onClick={() => {
                  reset(defaultValues);
                }}
              />
            </>
          ) : null}

          {!buttons.isAction && buttons.action === "add" ? (
            <div>
              <CommonButton
                type="button"
                className="bg-customBlue text-white"
                label="+ Add"
                onClick={() => {
                  orderDrugs();
                }}
              />
            </div>
          ) : null}
        </div>
      </>
    ));
  };

  useEffect(() => {
    orderedDrugList();
    setOrderDrug([]);
  }, [props.rowData]);

  useEffect(() => {
    //Generic Name service
    getGenericNameDropdownList();
    // getForm dropdown list
    getdrugFormDropdown()
      .then((response) => response.data)
      .then((res) => {
        setFormList(res.result);
      });
    //getRoute dropdownlist
    getRouteDropdown()
      .then((response) => response.data)
      .then((res) => {
        setRouteOptions(res.result);
      });
    //getFrequency dropdown serivce

    //getInstruction dropdown service
    getDrugInstructionDropdown()
      .then((response) => response.data)
      .then((res) => {
        setDrugInstructionsOptions(res.result);
      });

    //get options for drug name dropdown
  }, []);

  // frequency options
  useEffect(() => {
    let isAdminValue = 0;
    if (onceInWeekDrugVal === true) {
      isAdminValue = 1;
    } else if (alternativeDayDrugVal === true) {
      isAdminValue = 2;
    } else if (perHourDrugVal === true) {
      isAdminValue = 3;
    }
    //getFrequency dropdown serivce
    getFrequencyForDrugOrder(isAdminValue)
      .then((response) => response.data)
      .then((res) => {
        setDrugFrequencyOptions(res.result);
      });
  }, [onceInWeekDrugVal, alternativeDayDrugVal, perHourDrugVal]);

  useEffect(() => {
    if (frequencyVal && frequencyVal !== null) {
      freqArray = frequencyVal.label.split("-");
      setFrequency(freqArray);
    }
  }, [frequencyVal, durationInDaysVal]);

  // calculate quantity using frequency dropdown value

  useEffect(() => {
    let frequencyCountValue = 0;
    if (frequency && Array.isArray(frequency)) {
      const frequencyMap = {
        "1/2": 0.5,
        "½": 0.5,
        "¼": 0.25,
        1: 1,
        "1½": 1.5,
        "1/8": 0.125,
        2: 2,
        "2½": 2.5,
        3: 3,
        "3½": 3.5,
        4: 4,
        "4½": 4.5,
        STAT: 1,
        SOS: 1,
        "Stat/sos": 1,
        "Per Hour": 1,
        bolus: 1,
        9: 9,
      };

      frequency.forEach((element) => {
        const value = element.trim();
        frequencyCountValue += frequencyMap[value] || 0;
      });
    }

    let quantityCount = 0;
    const durationDays = Number(durationInDaysVal);

    if (durationInValue === "Week") {
      quantityCount = durationDays * 1 * frequencyCountValue;
    } else if (durationInValue === "Days") {
      quantityCount = durationDays * frequencyCountValue;
    }

    const totalQuantity = Math.ceil(quantityCount);

    try {
      setValue("quantity", !isNaN(totalQuantity) ? totalQuantity : 0, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      trigger("quantity");
    } catch (error) {
      console.error("Error setting quantity value:", error);
    }
  }, [
    durationInDaysVal,
    durationInValue,
    frequency,
    frequencyVal,
    alternativeDayDrugVal,
    onceInWeekDrugVal,
    perHourDrugVal,
    hoursValue,
  ]);

  // useEffect(() => {
  //   let durationDayValue = durationInDaysVal;
  //   let frequencyCountValue = 0;
  //   if (frequency) {
  //     frequency.forEach((element) => {
  //       let value = element.trim();
  //       if (value === "1/2" || value === "½") {
  //         frequencyCountValue = frequencyCountValue + 0.5;
  //       } else if (value === "¼") {
  //         frequencyCountValue = frequencyCountValue + 0.25;
  //       } else if (value === "1") {
  //         frequencyCountValue = frequencyCountValue + 1;
  //       } else if (value === "1½") {
  //         frequencyCountValue = frequencyCountValue + 1.5;
  //       } else if (value === "1/8") {
  //         frequencyCountValue = frequencyCountValue + 0.125;
  //       } else if (value === "2") {
  //         frequencyCountValue = frequencyCountValue + 2;
  //       } else if (value === "2½") {
  //         frequencyCountValue = frequencyCountValue + 2.5;
  //       } else if (value === "3") {
  //         frequencyCountValue = frequencyCountValue + 3;
  //       } else if (value === "3½") {
  //         frequencyCountValue = frequencyCountValue + 3.5;
  //       } else if (value === "4") {
  //         frequencyCountValue = frequencyCountValue + 4;
  //       } else if (value === "4½") {
  //         frequencyCountValue = frequencyCountValue + 4.5;
  //       } else if (value === "STAT") {
  //         frequencyCountValue = frequencyCountValue + 1;
  //       } else if (value === "SOS") {
  //         frequencyCountValue = frequencyCountValue + 1;
  //       } else if (value === "Stat/sos") {
  //         frequencyCountValue = frequencyCountValue + 1;
  //       } else if (value === "9") {
  //         frequencyCountValue = frequencyCountValue + 9;
  //       } else {
  //         frequencyCountValue = frequencyCountValue + 0;
  //       }
  //     });
  //   }

  //   let quantityCount;

  //   if (
  //     durationDayValue !== "" &&
  //     durationInValue !== null &&
  //     perHourDrugVal === false
  //   ) {
  //     if (durationInValue === "Days") {
  //       quantityCount =
  //         Number(durationInDaysVal) * parseFloat(frequencyCountValue);
  //     } else if (durationInValue === "Week") {
  //       quantityCount =
  //         Number(durationInDaysVal) * parseFloat(frequencyCountValue);
  //     }
  //     let totalQuantity = Math.ceil(quantityCount);
  //     setValue("quantity", !isNaN(totalQuantity) ? totalQuantity : 0, {
  //       shouldValidate: true,
  //     });
  //   }
  // }, [
  //   durationInDaysVal,
  //   durationInValue,
  //   frequency,
  //   frequencyVal,
  //   alternativeDayDrugVal,
  //   onceInWeekDrugVal,
  //   perHourDrugVal,
  //   hoursValue,
  // ]);

  useEffect(() => {
    if (
      frequencyVal?.label === "SOS" ||
      frequencyVal?.label === "STAT" ||
      frequencyVal?.label === "Stat/sos"
    ) {
      setValue("durationInDays", "1");
    }
  }, [frequencyVal]);

  useEffect(() => {
    // let quantityCount;
    let frequencyObject;
    if (perHourDrugVal === true) {
      frequencyObject = drugFrequencyOptions.find(
        (list) => list.label.toLowerCase() === "per hour"
      );
      setValue("frequency", frequencyObject);
      setValue("hours", 1);
    } else if (perHourDrugVal === false) {
      setValue("frequency", null);
      setValue("hours", "");
      setValue("immediate", false);
      setValue("quantity", "");
      clearErrors("quantity");
      clearErrors("hours");
      if (frequencyVal && frequencyVal.label.toLowerCase() === "per hour") {
        setValue("frequency", null);
      }
      setValue("hours", "");
      clearErrors("quantity");
    }
  }, [perHourDrugVal]);

  // calculate to date
  useEffect(() => {
    if (durationInValue !== null && durationInDaysVal !== "") {
      const validFromDateValue =
        fromDateValue instanceof Date && !isNaN(fromDateValue)
          ? fromDateValue
          : new Date();

      if (alternativeDayDrugVal === true) {
        const parsedValue = Number(durationInDaysVal) * 2 - 2;
        const updatedDate = addDays(validFromDateValue, parsedValue);
        setValue("toDate", updatedDate);
      } else if (durationInValue === "Days") {
        const parsedValue = Number(durationInDaysVal) - 2;
        const updatedDate = addDays(validFromDateValue, parsedValue + 1);
        setValue("toDate", updatedDate);
      } else if (durationInValue === "Week") {
        const parsedValue = Number(durationInDaysVal) * 7;
        const updatedDate = addDays(validFromDateValue, parsedValue);
        setValue("toDate", updatedDate);
      }
    }
  }, [
    fromDateValue,
    durationInDaysVal,
    durationInValue,
    alternativeDayDrugVal,
    perHourDrugVal,
  ]);

  // set focus using useRef

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        const searchMedicineInput = document.querySelector(".drugName-0 input");
        if (searchMedicineInput) {
          searchMedicineInput.focus();
        }
      }, 100);
    }
  }, [props.open]);

  //new useEffect
  useEffect(() => {
    const now = new Date();
    const dayEndTime = new Date(now);
    dayEndTime.setHours(23, 59, 59, 999);

    const timeleft = dayEndTime - now;
    const remainingHoursToday = Math.floor(timeleft / (1000 * 60 * 60));
    const remainingMinutesToday = Math.floor(
      (timeleft % (1000 * 60 * 60)) / (1000 * 60)
    );
    const getPerHourDrug = watch("perHourDrug");
    const getHour = watch("hours");
    const getDuration = watch("durationInDays");

    let forQuantity;

    if (getPerHourDrug === true) {
      if (getDuration === "") {
        forQuantity = remainingHoursToday / getHour;
      } else {
        const totalDurationHours =
          getDuration * 24 - (24 - remainingHoursToday);
        forQuantity = totalDurationHours / getHour;
      }
      if (Number(remainingMinutesToday) > 30) {
        setValue("quantity", Math.max(1, Math.floor(forQuantity + 1)));
      } else {
        setValue("quantity", Math.max(1, Math.floor(forQuantity)));
      }
    }
    const frequencyVal = watch("frequency");
    if (frequencyVal?.label === "STAT" || frequencyVal?.label === "SOS") {
      setValue("quantity", 1);
    }
  }, [durationInDaysVal, hoursValue, frequencyVal]);

  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[80%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
            }}
          />

          <div>
            <h1 className="text-center text-gray-600 font-bold text-xl py-2">
              Drug Administrator Order
            </h1>
            <div className="lg:flex lg:space-x-3 w-full">
              <div className="overflow-hidden  w-full">
                <div className="flex  mx-2 gap-2">
                  <fieldset className=" mx-auto border w-full rounded my-1 ">
                    <div className="bg-gray-100 lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 text-xs 2xl:text-sm gap-1  p-2">
                      <div className="flex  items-center  w-full font-semibold">
                        <span className="w-28 font-semibold ">
                          Patient Name
                        </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {props.rowData?.["Patient Name"] ||
                              props.rowData?.["PatientName"]}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-enter w-full font-semibold">
                        <span className="w-16 font-semibold"> MR No. </span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {props.rowData?.MRNo}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center w-full font-semibold">
                        <span className="w-28 font-semibold">Doctor Name</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {props.rowData?.["Doctor Name"] ||
                              props.rowData?.DoctorName}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center w-full font-semibold">
                        <span className="w-16 font-semibold">Bed No</span>
                        <div className="flex space-x-2 items-center">
                          <span>:</span>
                          <span className="text-gray-700 font-normal">
                            {props.rowData?.BedNo || props.rowData?.["Bed No"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <Accordion
                  elevation={2}
                  sx={{
                    border: "1px solid lightgray",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                    sx={{
                      "&.Mui-expanded": {
                        marginBottom: "0.3rem",
                        minHeight: "40px",
                      },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: 0,
                      },
                      backgroundColor: "#CCE4FB",
                    }}
                  >
                    <p className="font-bold tracking-wide text-sm font-Poppins">
                      Ordered Drug
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="grid gap-2">
                      <div className="grid grid-cols-4 gap-2 items-center mt-2">
                        <DatePickerFieldNew
                          control={control}
                          name="fromDateFilter"
                          label="From Date"
                          value={new Date()}
                          disablePast={false}
                          inputFormat="dd-MM-yyyy"
                        />
                        <DatePickerFieldNew
                          control={control}
                          name="toDateFilter"
                          label="To Date"
                          value={new Date()}
                          inputFormat="dd-MM-yyyy"
                        />
                      </div>
                      <div className="flex space-x-1 w-full mt-2">
                        <div className="w-full">
                          <h2 className="font-bold text-base 2xl:text-lg text-gray-600">
                            Ordered Drug List
                          </h2>
                          {orderedDrug.length > 0 ? (
                            <>
                              <CommonDynamicTablePaginationNew
                                dataResult={orderedDrug}
                                handleSelectedRow={displayDrugListRowData}
                                tableClass={"rounded lg:h-48"}
                                removeHeaders={["PrescriptionId", "DoctorId"]}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                setDataResult={setOrderedDrug}
                                count={count}
                              />
                            </>
                          ) : (
                            //
                            <h3 className="text-center font-bold text-gray-600 py-10">
                              No Record Found
                              <span className="tracking-widest animate-pulse">
                                ...
                              </span>
                            </h3>
                          )}
                        </div>
                        <Divider
                          color="#1e3a8a"
                          orientation="vertical"
                          flexItem
                          sx={{ width: 2 }}
                        />
                        <div className="w-full">
                          <h2 className="font-bold text-base 2xl:text-lg text-gray-600">
                            Drug List
                          </h2>

                          {drugList.length > 0 ? (
                            <div className="">
                              <CommonDynamicTableNew
                                dataResult={drugList}
                                highlightRow={false}
                                tableClass={"rounded lg:h-[232px]"}
                                removeHeaders={["PrescriptionId", "Id", "Code"]}
                              />
                            </div>
                          ) : (
                            <h3 className="text-center font-bold text-gray-600 py-10">
                              No Record Found
                              <span className="tracking-widest animate-pulse">
                                ...
                              </span>
                            </h3>
                          )}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Divider color="#1e3a8a" sx={{ height: 2, marginTop: "8px" }} />
                <form>
                  <div className=" mt-2">
                    <span className="font-bold text-base 2xl:text-lg text-gray-600 px-2">
                      Drug Order
                    </span>

                    {orderDrug && orderDrug.length > 0 ? (
                      <CommonDynamicTableNew
                        dataResult={orderDrug}
                        actions={actions}
                        removeHeaders={[
                          "drugId",
                          "formId",
                          "frequencyId",
                          "genericId",
                          "routeId",
                          "instructionId",
                          "startTime",
                          "endTime",
                          "printDrugName",
                          "pendingQty",
                          "isNew",
                          "isMarkForDrugAdmin",
                          "canceledBy",
                          "isInstructionNew",
                          "isFinalizeForIndent",
                          "adminAs",
                        ]}
                        highlightRow={false}
                        tableClass={"h-[200px] border"}
                      />
                    ) : (
                      <h3 className="text-center font-bold text-gray-600 py-5">
                        No Record Found
                        <span className="tracking-widest animate-pulse">
                          ...
                        </span>
                      </h3>
                    )}
                    <Divider
                      color="#1e3a8a"
                      sx={{ height: 2, marginTop: "8px" }}
                    />
                    {orderDrug && orderDrug.length > 0 ? (
                      <div className="flex gap-2 justify-end mt-2">
                        {props.userActions &&
                          props.userActions.map((obj) => (
                            <>
                              {!obj.isAction && obj.action === "Create" ? (
                                <>
                                  <CommonButton
                                    type="button"
                                    className="border border-customRed text-customRed"
                                    label="Reset"
                                    onClick={() => {
                                      reset(defaultValues);
                                      setOrderDrug([]);
                                    }}
                                  />
                                  <CommonButton
                                    type="button"
                                    className="bg-customGreen text-white"
                                    label="Save"
                                    onClick={() => {
                                      setPrivilege("Create");
                                      onSubmitDataHandler();
                                    }}
                                  />
                                </>
                              ) : null}
                            </>
                          ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="px-2">
                    <fieldset className=" mx-auto border w-full rounded my-1">
                      <legend className="ml-6 my-2 rounded  font-bold text-base 2xl:text-lg text-gray-600">
                        Order Drug
                      </legend>

                      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 p-2">
                        <div className={`drugName-${0} w-full`}>
                          <CreateableSelectAutoCapitalize
                            control={control}
                            className={`drugName-${0} w-full`}
                            error={errors.drugName}
                            name="drugName"
                            placeholder="Drug Name*"
                            showSearch={true}
                            dataArray={drugNameOptions}
                            isSearchable={false}
                            onInputChange={handleSearchDrugName}
                            inputRef={{
                              ...register("drugName", {
                                onChange: (e) => {
                                  if (e.target.value !== null) {
                                    let genericNameObj = {
                                      id: e.target.value.genericId,
                                      value: e.target.value.genericName,
                                      label: e.target.value.genericName,
                                    };
                                    const dosageInput =
                                      document.getElementById(`dosage`);
                                    dosageInput?.focus();
                                    let formObj = {
                                      id: e.target.value.formId,
                                      value: e.target.value.form,
                                      label: e.target.value.form,
                                    };
                                    if (
                                      e.target.value.genericId !== null &&
                                      e.target.value.genericId !== undefined
                                    ) {
                                      setValue("genericName", genericNameObj);
                                      clearErrors(["genericName"]);
                                    }
                                    if (
                                      e.target.value.formId !== null &&
                                      e.target.value.formId !== undefined
                                    ) {
                                      setValue("form", formObj);
                                      clearErrors(["form"]);
                                    }
                                  } else {
                                    setValue("genericName", null);
                                    setValue("form", null);
                                  }
                                },
                              }),
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <InputField
                            id={"dosage"}
                            name="dosage"
                            variant="outlined"
                            label="Dosage*"
                            error={errors.dosage}
                            control={control}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                const frequencyFocus = document.querySelector(
                                  `.frequency-${2} input`
                                );
                                if (frequencyFocus) {
                                  frequencyFocus.focus();
                                }
                              }
                            }}
                            // type="number"
                          />
                        </div>
                        <div className={`frequency-${2} w-full`}>
                          <DropdownField
                            className={`frequency-${2}`}
                            control={control}
                            error={errors.frequency}
                            isDisabled={perHourDrugVal}
                            name="frequency"
                            placeholder="Frequency*"
                            dataArray={drugFrequencyOptions}
                            isSearchable={false}
                            menuPlacement={"top"}
                            inputRef={{
                              ...register("frequency", {
                                onChange: (e) => {
                                  if (e.target.value !== null) {
                                    const routeFocus = document.querySelector(
                                      `.route-${3} input`
                                    );
                                    if (routeFocus) {
                                      routeFocus.focus();
                                    }
                                  }
                                },
                              }),
                            }}
                          />
                        </div>

                        <div className={`route-${3} w-full`}>
                          <SearchDropdown
                            control={control}
                            name="route"
                            placeholder="Route*"
                            dataArray={routeOptions}
                            isSearchable={true}
                            menuPlacement={"top"}
                            handleInputChange={(e) => {
                              console.log("", e);
                            }}
                            inputRef={{
                              ...register("route", {
                                onChange: (e) => {
                                  if (e.target.value !== null) {
                                    const durationIndaysFocus =
                                      document.getElementById(`durationInDays`);
                                    if (durationIndaysFocus) {
                                      durationIndaysFocus.focus();
                                    }
                                  }
                                },
                              }),
                            }}
                          />
                        </div>
                        <div className=" w-full flex space-x-2">
                          <div className="w-9/12">
                            <InputField
                              id={"durationInDays"}
                              name="durationInDays"
                              variant="outlined"
                              type="number"
                              label="Duration*"
                              error={errors.durationInDays}
                              control={control}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  const instructionsFocus =
                                    document.querySelector(
                                      `.instructions-${4} input`
                                    );
                                  if (instructionsFocus) {
                                    instructionsFocus.focus();
                                  }
                                }
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <InputField
                              control={control}
                              name="durationIn"
                              label="Dusration In"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className={`instructions-${4} w-full`}>
                          <CreateableSelectAutoCapitalize
                            className={`instructions-${4}`}
                            control={control}
                            name="instructions"
                            placeholder="Instructions"
                            dataArray={drugInstructionsOptions}
                            isSearchable={false}
                            menuPlacement={"top"}
                            inputRef={{
                              ...register("instructions", {
                                onChange: (e) => {
                                  if (drugGenericNameVal === null) {
                                    const genericNameFocus =
                                      document.querySelector(
                                        `.genericName-${5} input`
                                      );
                                    if (genericNameFocus) {
                                      genericNameFocus.focus();
                                    }
                                  } else if (formVal === null) {
                                    const itemFormFocus =
                                      document.querySelector(
                                        `.form-${6} input`
                                      );
                                    if (itemFormFocus) {
                                      itemFormFocus.focus();
                                    }
                                  } else if (perHourDrugVal === true) {
                                    const hoursFocus =
                                      document.getElementById(`hours`);
                                    if (hoursFocus) {
                                      hoursFocus.focus();
                                    }
                                  } else {
                                    const quntityFocus =
                                      document.getElementById(`quantity`);
                                    if (quntityFocus) {
                                      quntityFocus.focus();
                                    }
                                  }
                                },
                              }),
                            }}
                          />
                        </div>
                        <div className={`genericName-${5} w-full`}>
                          <SearchDropdown
                            control={control}
                            name="genericName"
                            placeholder="Generic Name"
                            label="Generic Name"
                            isSearchable={true}
                            searchIcon={true}
                            isClearable={true}
                            dataArray={genericName}
                            handleInputChange={getGenericNameDropdownList}
                            inputRef={{
                              ...register("genericName", {
                                onChange: (e) => {
                                  if (e.target.value !== null) {
                                    const itemFormFocus =
                                      document.querySelector(
                                        `.form-${6} input`
                                      );
                                    if (itemFormFocus) {
                                      itemFormFocus.focus();
                                    }
                                  }
                                },
                              }),
                            }}
                          />
                        </div>

                        <div className={`form-${6} w-full`}>
                          <SearchDropdown
                            className={`form-${6} w-full`}
                            control={control}
                            name="form"
                            placeholder="Form"
                            dataArray={formList}
                            isSearchable={false}
                            menuPlacement={"top"}
                            handleInputChange={(e)=>{}}
                            inputRef={{
                              ...register("form", {
                                onChange: (e) => {
                                  if (e.target.value !== null) {
                                    const quantityFocus =
                                      document.getElementById(`quantity`);
                                    if (quantityFocus) {
                                      quantityFocus.focus();
                                    }
                                  }
                                },
                              }),
                            }}
                          />
                        </div>

                        <div>
                          <fieldset
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setValue("onceInWeekDrug", true);
                                setValue("durationIn", "Week");
                              } else if (e.target.checked === false) {
                                setValue("onceInWeekDrug", false);
                                setValue("durationIn", "Days");
                              }
                              setValue("alternativeDayDrug", false);
                              setValue("perHourDrug", false);
                            }}
                          >
                            <CheckBoxField
                              control={control}
                              name="onceInWeekDrug"
                              label="Once In Week Drug"
                              placeholder="Once In Week Drug"
                            />
                          </fieldset>
                        </div>
                        <div className="w-full">
                          <fieldset
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setValue("alternativeDayDrug", true);
                                setValue("durationIn", "Days");
                              } else if (e.target.checked === false) {
                                setValue("alternativeDayDrug", false);
                                setValue("durationIn", "Days");
                              }
                              setValue("onceInWeekDrug", false);
                              setValue("perHourDrug", false);
                            }}
                          >
                            <CheckBoxField
                              control={control}
                              name="alternativeDayDrug"
                              label="Alternative Day Drug"
                              placeholder="Alternative Day Drug"
                            />
                          </fieldset>
                        </div>
                        {/* Checkbox component */}
                        <div className="w-full flex space-x-2 items-center">
                          <fieldset
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setValue("perHourDrug", true);
                                setValue("durationIn", "Days");
                              } else if (e.target.checked === false) {
                                setValue("perHourDrug", false);
                                setValue("durationIn", "Days");
                              }
                              setValue("onceInWeekDrug", false);
                              setValue("alternativeDayDrug", false);
                            }}
                          >
                            <CheckBoxField
                              control={control}
                              name="perHourDrug"
                              label="Per Hour Drug"
                              placeholder="Per Hour Drug"
                            />
                          </fieldset>

                          <div>
                            {perHourDrugVal === true ? (
                              <div className="w-full flex space-x-2 items-center">
                                <InputField
                                  control={control}
                                  id={"hours"}
                                  type="number"
                                  name="hours"
                                  label="Hour"
                                  error={errors.hours}
                                  inputRef={{
                                    ...register("hours", {
                                      onChange: (e) => {
                                        if (e.target.value > 24) {
                                          setValue("hours", "24");
                                        } else if (e.target.value === "") {
                                          setValue("quantity", "");
                                        }
                                      },
                                    }),
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                    }
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="w-full">
                          <InputField
                            id={"quantity"}
                            name="quantity"
                            type="number"
                            variant="outlined"
                            label="Quantity*"
                            control={control}
                            // disabled={
                            //   frequencyVal?.label === "STAT" ||
                            //   frequencyVal?.label === "SOS"
                            //     ? false
                            //     : frequencyVal?.label === "STAT" ||
                            //       frequencyVal?.label === "SOS"
                            //     ? false
                            //     : true
                            // }
                          />
                        </div>

                        <div>
                          <CheckBoxField
                            control={control}
                            name="isOutsideMedication"
                            label="Is Outside Medication"
                            placeholder="Is Outside Medication"
                          />
                        </div>

                        <div className="w-full">
                          <DatePickerFieldNew
                            control={control}
                            name="fromDate"
                            label="From Date"
                            disablePast={true}
                            inputFormat="dd-MM-yyyy"
                            inputRef={{
                              ...register("fromDate", {
                                onChange: (e) => {
                                  const parsedValue =
                                    parseInt(durationInDaysVal);
                                  if (!isNaN(parsedValue)) {
                                    const currentDate = e.target.value;
                                    const updatedDate = addDays(
                                      currentDate,
                                      parsedValue
                                    );
                                    setValue("toDate", updatedDate);
                                  }
                                },
                              }),
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <DatePickerFieldNew
                            control={control}
                            name="toDate"
                            label="To Date"
                            disabled
                            inputFormat="dd-MM-yyyy"
                          />
                        </div>
                      </div>

                      <div className="flex gap-2 justify-end mb-2 mr-2">
                        {renderButtons()}
                      </div>
                    </fieldset>
                  </div>
                  <div className="flex space-x-2 justify-end gap-2 px-2"></div>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openConfiramtionModal}
        confirmationHandleClose={() => setOpenConfiramtionModal(false)}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}
export default React.memo(DrugAdministratorOrderModal);
